<template>
  <div
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    class="icon-component"
  >
    <icon :name="name" class="icon-component-question-icon"></icon>
    <div
      class="icon-component-tooltip"
      :style="$i18n.locale === 'zh_CN' ? '' : 'width: 399px'"
      v-html="content"
      v-show="hovered"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      hovered: false,
    };
  },
  methods: {
    showHover() {
      this.hovered = true;
    },
  },
};
</script>

<style lang="less" scoped>
.icon-component {
  display: inline-block;
  position: relative;
  &-question-icon {
    color: #99c0d8;
    margin-left: 8px;
    vertical-align: text-bottom;
    width: 16px;
    height: 16px;
  }
  &-tooltip {
    position: absolute;
    right: 0;
    font-size: 12px;
    line-height: 20px;
    width: 299px;
    // right: -150px;
    padding: 18px;
    text-align: left;
    // white-space: pre;
    color: rgb(4, 251, 251);
    background: rgba(17, 53, 78, 0.8);
    // box-shadow: 0px 0px 10px 3px rgba(#04FBFB ,.3) inset;
    box-shadow: 0px 0px 10px 3px rgba(#04ffff, 0.8) inset;
    font-weight: 300;
    // background-image: url(../../../assets/images/hover-bc.png);
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    // clip-path: polygon(
    //   0% 0%,
    //   100% 1%,
    //   100% 5px,
    //   100% 100%,
    //   calc(100% - 5px) 100%,
    //   5px 100%,
    //   0 calc(100% - 5px),
    //   0 5px
    // );
  }
}
</style>
