import Cookies from "js-cookie";
import store from "@/store/index.js";

const TokenKey = "Saas_Token";
const Language = "language";
const LoginFrom = "loginFrom";

const TokenKey1 = "token-y.hlestudy.com";

const thirdCode = "bussinessCode";

const HidePrepareMenu = "hidePrepareMenu";

let host = location.hostname;
let secondHost = host.substring(host.indexOf("."), host.length);
// const options = {
//   secure: true,
//   sameSite: "none",
// };
export function getToken() {
  return (
    window.localStorage.getItem(TokenKey) ||
    window.sessionStorage.getItem(TokenKey)
  );
}

export function setToken(token) {
  window.sessionStorage.setItem(TokenKey, token);
  return window.localStorage.setItem(TokenKey, token);
}

export function getThirdCode() {
  return Cookies.get(thirdCode) || window.sessionStorage.getItem(thirdCode);
}

export function setThirdCode(code) {
  window.sessionStorage.setItem(thirdCode, code);
  return Cookies.set(thirdCode, code, {
    path: "/",
    domain: secondHost,
  });
}

export function setHidePrepareMenu(hidePrepareMenu) {
  window.sessionStorage.setItem(HidePrepareMenu, hidePrepareMenu);
  return Cookies.set(HidePrepareMenu, hidePrepareMenu, {
    path: "/",
    domain: secondHost,
  });
}

export function getHidePrepareMenu() {
  return (
    Cookies.get(HidePrepareMenu) ||
    window.sessionStorage.getItem(HidePrepareMenu)
  );
}

export function getCookieLanguage() {
  return Cookies.get(Language) || window.sessionStorage.getItem(Language);
}

export function setCookieLanguage(language) {
  window.sessionStorage.setItem(Language, language);
  return Cookies.set(Language, language, {
    path: "/",
    domain: secondHost,
  });
}

export function removeToken() {
  window.localStorage.removeItem(TokenKey);
  window.sessionStorage.removeItem(TokenKey);
  window.sessionStorage.removeItem(TokenKey1);
  Cookies.remove(TokenKey, { path: "/", domain: secondHost });
  Cookies.remove(TokenKey1, { path: "/", domain: secondHost });
  return Cookies.remove(TokenKey);
}
export function setLoginFrom(loginFrom) {
  window.sessionStorage.setItem(LoginFrom, loginFrom);
  return Cookies.set(LoginFrom, loginFrom, {
    path: "/",
    domain: secondHost,
  });
}

export function getLoginFrom() {
  return Cookies.get(LoginFrom) || window.sessionStorage.getItem(LoginFrom);
}

export function removeLoginFrom() {
  window.sessionStorage.removeItem(LoginFrom);
  Cookies.remove(LoginFrom, { path: "/", domain: secondHost });
  return Cookies.remove(LoginFrom);
}

export function toZnxyLogin() {
  // console.log(process.env.NODE_ENV);
  // if (process.env.NODE_ENV === "production") {
  //   window.location.href = "https://i.hailiangedu.com/third/login";
  // } else {
  //   window.location.href = "https://i.hailiangedu.com/third/login";
  //   // window.location.href="http://10.30.5.34/third/login"
  // }
  window.location.href = process.env.VUE_APP_THIRD;
}

export function setReturnUrl(url) {
  return store.commit("setReturnUrl", url);
}

export function getLanguage() {
  return localStorage.getItem("language");
}

export function setLanguage(language) {
  return localStorage.setItem("language", language);
}
