import Vue from "vue";
import { dict } from "@/filters";

// 挂载实例方法
Object.defineProperty(Vue.prototype, "$D", {
  value: dict,
});

// 挂载Vue下
Object.defineProperty(Vue, "$D", {
  value: dict,
});

export default dict;
