import { getCourseList, getCourseChooseList } from "@/api/manage/course-manage";
import {
  getGradeList,
  getSubjectList,
  getVersion,
} from "@/api/manage/compared-analyze";
import {
  getClassInfoList,
  getClassInfoListNew,
  addClassInfo,
  updateClassInfo,
  deleteClassInfo,
  getCourseClassPage,
  addStudentClass,
  updateStudentClass,
  deleteStudentClass,
  getCourseClassTree,
  getSelectedStudentList,
} from "@/api/manage/classManage";

import {
  planTeachingCourse,
  planTeachingTeacher,
  planTeachingClass,
  getCourseTablePreview,
  courseTableComplete,
  getCourseTableShow,
  getMyCourseTable,
} from "@/api/manage/course-list";

export default {
  state: {
    allGradeList: [],
    allSubjectList: [],
    previewCourseList: [],
    previewTeacherList: [],
    previewClassList: [],
  },
  mutations: {
    SET_GRADE_LIST(state, value) {
      state.allGradeList = value;
    },
    SET_SUBJECT_LIST(state, value) {
      state.allSubjectList = value;
    },
    SET_PREVIEW_COURSE_LIST(state, value) {
      state.previewCourseList = value;
    },
    SET_PREVIEW_CLASS_LIST(state, value) {
      state.previewClassList = value;
    },
    SET_PREVIEW_TEACHER_LIST(state, value) {
      state.previewTeacherList = value;
    },
  },
  actions: {
    /**
     * 获取课程列表
     */
    getCourseList({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        getCourseList(params)
          .then((res) => {
            //   let data = res.data;
            // console.log('res',res);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    /**
     * 获取课程选择列表
     */
    getCourseChooseList({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        getCourseChooseList(params)
          .then((res) => {
            //   let data = res.data;
            // console.log('res',res);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // getGradeList({ state, commit }) {
    //   return new Promise((resolve, reject) => {
    //     getGradeList()
    //       .then((res) => {
    //         let data = res.data;
    //         // console.log('res',res);
    //         data.forEach((element) => {
    //           element.value = element.id;
    //           element.label = element.name;
    //         });
    //         console.log(data)
    //         commit("SET_GRADE_LIST", data);
    //         resolve(res);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },
    // getSubjectList({ state, commit }) {
    //   return new Promise((resolve, reject) => {
    //     getSubjectList()
    //       .then((res) => {
    //         let data = res.data;
    //         // console.log('res',res);
    //         data.forEach((element) => {
    //           element.value = element.id;
    //           element.label = element.name;
    //         });
    //         console.log(data)
    //         commit("SET_SUBJECT_LIST", data);
    //         resolve(res);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },
    getClassInfoList({ state, commit }, { schoolCode, courseId }) {
      return new Promise((resolve, reject) => {
        getClassInfoList(schoolCode, courseId)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getClassInfoListNew({ state, commit }, { courseId }) {
      return new Promise((resolve, reject) => {
        getClassInfoListNew(courseId)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 新增班级
    addClassInfo({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        addClassInfo(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 编辑班级
    updateClassInfo({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        updateClassInfo(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 删除班级
    deleteClassInfo({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        deleteClassInfo(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取课程学生列表
    getCourseClassPage({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        getCourseClassPage(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 添加学生
    addStudentClass({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        addStudentClass(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 调班
    updateStudentClass({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        updateStudentClass(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 学生移出班级
    deleteStudentClass({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        deleteStudentClass(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 根据学校编码获取树形列表
    getCourseClassTree({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        getCourseClassTree(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 默认选中的学生数据
    getSelectedStudentList(
      { state, commit },
      { schoolCode, courseId, classId }
    ) {
      return new Promise((resolve, reject) => {
        getSelectedStudentList(schoolCode, courseId, classId)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 排课选择课程列表
    planTeachingCourse({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        planTeachingCourse(params)
          .then((res) => {
            if (res.data) {
              commit("SET_PREVIEW_COURSE_LIST", res.data);
            } else {
              commit("SET_PREVIEW_COURSE_LIST", []);
            }
            resolve(res);
          })
          .catch((error) => {
            commit("SET_PREVIEW_COURSE_LIST", []);
            reject(error);
          });
      });
    },
    // 排课选择老师列表
    planTeachingTeacher({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        planTeachingTeacher(params)
          .then((res) => {
            if (res.data) {
              commit("SET_PREVIEW_TEACHER_LIST", res.data);
            } else {
              commit("SET_PREVIEW_TEACHER_LIST", []);
            }
            resolve(res);
          })
          .catch((error) => {
            commit("SET_PREVIEW_TEACHER_LIST", []);
            reject(error);
          });
      });
    },
    // 排课选择班级列表
    planTeachingClass({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        planTeachingClass(params)
          .then((res) => {
            if (res.data) {
              commit("SET_PREVIEW_CLASS_LIST", res.data);
            } else {
              commit("SET_PREVIEW_CLASS_LIST", []);
            }
            resolve(res);
          })
          .catch((error) => {
            commit("SET_PREVIEW_CLASS_LIST", []);
            reject(error);
          });
      });
    },
    // 获取查询课表
    getCourseTablePreview({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        getCourseTablePreview(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取我的课表
    getMyCourseTable({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        getMyCourseTable(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 生成课表
    courseTableComplete({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        courseTableComplete(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 获取查看列表
    getCourseTableShow({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        getCourseTableShow(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
