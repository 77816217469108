<template>
  <div class="text-tooltip">
    <el-tooltip
      class="item"
      effect="dark"
      :disabled="isShowTooltip"
      :enterable="enterable"
      placement="top"
      :popper-class="popperClass ? 'popper-class-tooltip' : ''"
    >
      <div
        slot="content"
        :class="[customContentClass ? customContentClass : 'text-content']"
      >
        {{ content }}
      </div>
      <p
        :class="[
          overFlowNum
            ? `over-flow-${overFlowNum} ${className}`
            : singleAble
            ? `over-flow ${className}`
            : `over-flow-2 ${className}`,
          computedClass(refName),
        ]"
        @mouseover="onMouseOver(refName)"
      >
        <span :ref="refName">{{ content }}</span>
      </p>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: "textTooltip",
  props: {
    // 显示的文字内容
    content: {
      type: String,
      default: () => {
        return "";
      },
    },
    // 外层框的样式，在传入的这个类名中设置文字显示的宽度
    className: {
      type: String,
      default: () => {
        return "";
      },
    },
    // 自定义文字样式
    customContentClass: {
      type: String,
      default: () => {
        return "";
      },
    },
    // 是否计算行高
    computedLineHeight: {
      type: Boolean,
      default: false,
    },
    // 当需要计算行高的时候，span的宽度
    spanWidth: {
      type: Number,
      default: 0,
    },
    // 鼠标是否可进入到 tooltip 中
    enterable: {
      type: Boolean,
      default: true,
    },
    // 为页面文字标识（页面中调用多次组件，此参数不可重复）
    refName: {
      type: String,
      default: () => {
        return "";
      },
    },
    // 鼠标是否可进入到 tooltip 中
    singleAble: {
      type: Boolean,
      default: true,
    },
    overFlowNum: {
      type: Number,
      default: 0,
    },
    popperClass: {
      type: Boolean,
      default: true,
    },
    // 两行时 内容文字 font-size，判断是否显示
    contentFontSize: {
      type: String,
      default: "14px",
    },
  },
  data() {
    return {
      isShowTooltip: true,
    };
  },
  methods: {
    computedClass() {
      let classname = "";
      if (!this.singleAble) {
        /* currentWidth 为文本在页面中所占的宽度，创建标签，加入到页面，获取currentWidth ,最后在移除*/
        let TemporaryTag = document.createElement("span");
        TemporaryTag.innerText = this.content;
        TemporaryTag.style.fontSize = this.contentFontSize;
        TemporaryTag.className = "getTextWidth";
        document.querySelector("body").appendChild(TemporaryTag);
        let currentWidth = document.querySelector(".getTextWidth").offsetWidth;
        document.querySelector(".getTextWidth").remove();
        // console.log("currentWidth", currentWidth);
        if (this.computedLineHeight) {
          if (currentWidth < this.spanWidth) {
            classname = "more-line-height";
          }
        }
      }
      // console.log("classname", classname);
      return classname;
    },
    onMouseOver(str) {
      let parentWidth = this.$refs[str].parentNode.offsetWidth;
      let contentWidth = this.$refs[str].offsetWidth;
      if (!this.singleAble) {
        /* currentWidth 为文本在页面中所占的宽度，创建标签，加入到页面，获取currentWidth ,最后在移除*/
        let TemporaryTag = document.createElement("span");
        TemporaryTag.innerText = this.content;
        TemporaryTag.style.fontSize = this.contentFontSize;
        TemporaryTag.className = "getTextWidth";
        document.querySelector("body").appendChild(TemporaryTag);
        let currentWidth = document.querySelector(".getTextWidth").offsetWidth;
        document.querySelector(".getTextWidth").remove();
        if (this.overFlowNum) {
          contentWidth = currentWidth / this.overFlowNum;
        } else {
          contentWidth = currentWidth / 2;
        }
      }

      // console.log("contentWidth",contentWidth);
      // console.log("parentWidth",parentWidth);

      // 判断是否开启tooltip功能
      if (contentWidth > parentWidth) {
        this.isShowTooltip = false;
      } else {
        this.isShowTooltip = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.over-flow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text-content {
  max-height: 300px;
  overflow: auto;
}
.over-flow-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.over-flow-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
p {
  margin: 0;
}
</style>
<style lang="less">
.popper-class-tooltip {
  background-color: rgba(50, 50, 50, 0.7) !important;
  color: #fff !important;
}
.popper-class-tooltip[x-placement^="top"] .popper__arrow {
  border-top-color: transparent !important;
}
.popper-class-tooltip[x-placement^="top"] .popper__arrow:after {
  border-top-color: rgba(50, 50, 50, 0.7) !important;
}
</style>
