import Vue from "vue";
import * as utils from "@/utils";

// 挂载实例方法
Object.defineProperty(Vue.prototype, "$utils", {
  value: utils,
});

// 挂载Vue下
Object.defineProperty(Vue, "utils", {
  value: utils,
});

export default utils;
