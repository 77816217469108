var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-message-contain"},[(_vm.showMoreBtn)?_c('div',{staticClass:"chat-message-contain-more",on:{"click":_vm.getMoreMessage}},[_vm._v(" "+_vm._s(_vm.$t("el.chatgpt.ReadMore"))+" ")]):_vm._e(),_vm._l((_vm.messageList),function(item,index){return _c('div',{key:item.messageId},[_c('div',[_c('div',{staticClass:"flex chat-message-content"},[_c('el-image',{staticClass:"chat-avthor",attrs:{"src":item.messageType === 0 || item.messageType === 2
              ? _vm.iconChatgpt
              : _vm.iconUser}}),(item.messageType === 0 || item.messageType === 2)?_c('div',{staticClass:"chat-answer",style:({
            width: item.messageType === 0 ? 'calc(100% - 76px)' : '',
            maxWidth: item.messageType === 2 ? 'calc(100% - 110px)' : '',
          })},[(item.messageType === 0)?_c('div',[_c('div',{staticClass:"message-title"},[_vm._v(" "+_vm._s(_vm.$t("el.chatgpt.YouyouAssistant"))),_c('br'),_vm._v(_vm._s(_vm.$t("el.chatgpt.AskMeQuestions"))+" ")]),_vm._l((item.tipsList),function(obj,idx){return _c('div',{key:obj.id,staticClass:"flex flex-m",staticStyle:{"width":"100%","position":"relative","cursor":"pointer"},on:{"click":function($event){return _vm.defaultMessageClick(obj, idx)}}},[_c('span',{staticClass:"message-item0"},[_vm._v(_vm._s(obj.message))]),_c('el-image',{staticClass:"chat-arrow",attrs:{"src":_vm.chatArrow}}),(idx !== item.tipsList.length - 1)?_c('div',{staticClass:"white-line",staticStyle:{"position":"absolute","bottom":"0"}}):_vm._e()],1)})],2):_c('div',[_c('v-md-preview',{attrs:{"text":item.message}})],1)]):_vm._e(),(item.messageType === 1)?_c('div',{staticClass:"chat-question"},[_c('div',{staticClass:"message-question"},[_vm._v(_vm._s(item.message))])]):_vm._e()],1),(item.messageType === 2 && item.status === 1)?_c('div',{staticClass:"flex flex-m btn-control",staticStyle:{"min-height":"14px"},on:{"click":function($event){return _vm.onMessageControl(item, index, 1)}}}):(
          item.messageType !== 0 &&
          _vm.messageList.length > 1 &&
          (index === _vm.messageList.length - 1 ||
            (index === _vm.messageList.length - 2 &&
              _vm.messageList[_vm.messageList.length - 1].messageType === 0))
        )?_c('div',{staticClass:"flex flex-m btn-control",on:{"click":function($event){return _vm.onMessageControl(item, index, 0)}}},[_c('el-image',{staticClass:"icon-control",attrs:{"src":_vm.iconReGenerate}}),_vm._v(" "+_vm._s(_vm.$t("el.chatgpt.Regenerate"))+" ")],1):_vm._e()])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }