import Vue from "vue";
import NProgress from "nprogress"; // Progress 进度条
import "nprogress/nprogress.css"; // Progress 进度条样式
import { MessageBox, Loading } from "element-ui";
import router from "@/router";
import store from "@/store";
import {
    getToken,
    getCookieLanguage,
    setLanguage,
    toZnxyLogin,
    getLoginFrom,
    removeLoginFrom,
} from "@/core/auth"; // 验权
// import io from 'socket.io-client';
// import io from "socket.io-client/dist/socket.io.slim.js";
// "socket.io-client": "^2.3.0", // package.json 去掉
import { findLast } from "lodash";
import { getCurrentAuthority, check, checkAuthMenu } from "../utils/auth";
import i18n from "@/tools/i18n.js";
import { logOutTip } from "@/plugins/axios";
import { getOriginTenant } from "@/api/classroom";
import url from "url";
const { pathToRegexp } = require("path-to-regexp");
// 白名单
const WHITE_LIST = [
    // "/login",
    "/portal",
    "/portal/",
    "/portal/portal-front",
    "/portal/portal-product",
    "/portal/portal-case",
    "/portal/portal-about",
];
// 定时器
let timeoutClearNo = null;
class SocketError extends Error {
    constructor(message, code) {
        super();
        this.message = message;
        this.code = code;
    }
}

// 权限拦截器
router.beforeEach(async (to, from, next) => {
    // 路由有Authorization时不执行后续操作，防止因sso文件调用问题，导致部分场景下用户信息获取两次
    if (to.query.Authorization) {
        return;
    }
    store.commit("setChildPageLoaded", 0);
    // 是否启用权限拦截器
    if (process.env.VUE_APP_AUTH === "disabled") {
        next();
        return;
    }
    if (getCookieLanguage()) {
        setLanguage(getCookieLanguage());
    }
    if (getToken()) {
        // 已有token
        if (to.path === "/login") {
            // 如果是登录页面自动跳转到首页
            next("/");
        } else if (store.state.hasUserInfo) {
            // 有用户信息
            NProgress.start();
            successNext(to, from, next);
            NProgress.done();
        } else if (to.path === "/third/auth") {
            // 第三方页面
            if (to.query.redirect_path) {
                const redurectPath = decodeURIComponent(to.query.redirect_path);
                const redirectQuery = url.parse(redurectPath, true).query;
                const queryParams = {};
                if (redurectPath.includes("hdCourseDetails")) {
                    // hdCourseDetails
                    queryParams.courseId = redirectQuery.id;
                } else {
                    // hdClassroomWps hdTeaching
                    queryParams.knowledgeId = redirectQuery.id;
                }
                if (
                    queryParams.courseId ||
                    queryParams.knowledgeId ||
                    redurectPath.includes("hdCourseDetails")
                ) {
                    try {
                        const originRes = await getOriginTenant(queryParams);
                        location.replace(
                            redurectPath +
                            `&originTenantId=${originRes.data.originTenantId}&originTenantType=${originRes.data.originTenantType}&courseId=${originRes.data.courseId}&courseTenantInfoId=${originRes.data.courseTenantInfoId}`
                        );
                    } catch (error) {
                        location.replace(redurectPath);
                    }
                } else {
                    location.replace(redurectPath);
                }
            } else {
                next({
                    path: "/error/404",
                });
            }
        } else {
            // 获取用户信息
            const loading = Loading.service({
                message: i18n.t("el.teaching.loadingData"),
            });
            if (to.path === "/home/" && to.query.Authorization) {
                successNext(to, from, next);
            } else {
                // 数据看板页面 只调用获取用户基本信息以及菜单权限接口
                const isBoardPage = to.path.includes("/board/statisticalBoard");
                await store
                    .dispatch(isBoardPage ? "loadBoardData" : "loadData")
                    .then(() => {
                        store.commit("setHasUserInfo", true);
                        // eslint-disable-next-line eqeqeq
                        // if (store.state.user.eduAuth) {
                        //   // TODO: 待定
                        //   // 为监管机构时候直接跳转看板
                        //   next({
                        //     name: "statisticalBoard",
                        //     query: {
                        //       staticType: 2,
                        //     },
                        //   });
                        //   return;
                        // }
                        if (store.state.user.eduAuth === "NULL") {
                            //
                            next({
                                path: "/error/403",
                            });
                            return;
                        }
                        successNext(to, from, next);
                        //   return new Promise(function (resolve, reject) {
                        //     // 创建socket实例
                        //     loading.setText('正在连接服务');
                        //     var socket = io();
                        //     Vue.prototype.$io = socket; // 挂载全局
                        //     clearTimeout(timeoutClearNo);
                        //     timeoutClearNo = setTimeout(function () { // 连接超时120s
                        //       reject(new SocketError('服务注册失败'));
                        //     }, 120000);
                        //     // 登出
                        //     socket.on('token', () => {
                        //       store.dispatch('forceLogout').then(function () {
                        //         // 三秒回到登录页
                        //         setTimeout(function () {
                        //           router.push('/login');
                        //         }, 3000);
                        //       });
                        //     });
                        //     // 连接成功
                        //     socket.on('connect', () => {
                        //       loading.setText('服务注册中');
                        //       socket.emit('token', getToken());
                        //     });
                        //     // 注册成功
                        //     socket.on('push_event', () => {
                        //       clearTimeout(timeoutClearNo); // 清除连接超时
                        //       loading.setText('服务注册成功');
                        //       resolve();
                        //     });
                        //     // 连接失败处理
                        //     socket.on('connect_error', (error) => {
                        //       clearTimeout(timeoutClearNo); // 清除连接超时
                        //       socket.close();
                        //       socket = null;
                        //       reject(error);
                        //     });
                        //   });
                        // }).then(() => {
                        //   store.commit('setHasUserInfo', true);
                        //   next({
                        //     ...to
                        //   });
                    })
                    .catch((error) => {
                        var message = i18n.t("el.axios.initDataErrorLoad");
                        if (error instanceof SocketError) {
                            message = error.message;
                        }
                        let loginReturnUrl = sessionStorage.getItem("loginReturnUrl");
                        if (
                            loginReturnUrl ||
                            to.query.return_url ||
                            from.query.return_url
                        ) {
                            MessageBox.confirm(message, i18n.t("el.common.errorWords"), {
                                confirmButtonText: i18n.t("el.axios.reLoadData"),
                                cancelButtonText: i18n.t("el.common.back"),
                                type: "error",
                            })
                                .then(() => {
                                    next();
                                })
                                .catch(() => {
                                    location.replace(loginReturnUrl);
                                });
                        } else {
                            logOutTip();
                        }
                        throw error;
                    })
                    .finally(() => {
                        loading.close();
                    });
            }
        }
    } else {
        // 未登录
        if (WHITE_LIST.indexOf(to.path) !== -1) {
            // 过滤白名单
            NProgress.start();
            next();
        } else {
            // TODO: ?
            if (getLoginFrom() || from.query.loginFrom || to.query.loginFrom) {
                removeLoginFrom();
                toZnxyLogin();
                // alert('需要跳转的网址')
            } else {
                // next(`/login?redirect=${to.fullPath}`); // 跳转钉钉免登页面
                // 跳转至saas的登录页面
                window.location.href =
                    window.baseConfig.VUE_APP_SAAS_ADMIN_URL + "?saas_logout=ai-front";
            }
        }
    }
});

// 白名单路径(不带params参数)，不受权限控制
const frontAuthWhiteList = [
    "/manage/teachManage",
    "/tip/install",
    "/error/404",
    "/error/403",
    "/tip/auth",
    "/tip/resource",
    "/login",
    "/sso/auth",
    "/third/auth",
    "/courseManage/hdCourseDetails", // 第三方页面默认可以访问
    "/hdTeaching",
];
// 白名单路径(带params参数)，不受权限控制
const frontAuthWhiteListDynaimc = ["/hdClassroomWps/:type"];
const frontAuthWhiteListDynaimcRegexp = frontAuthWhiteListDynaimc.map((path) =>
    pathToRegexp(path)
);

/**
 * 成功下一步
 * @param {*} to 目标路由
 * @param {*} from 来源路由
 * @param {*} next 下一步
 */
function successNext(to, from, next) {
    // 判断路由是否配置权限
    const record = findLast(to.matched, (record) => record.meta.authority);
    if (record) {
        // 配置权限的路由，如果权限不匹配，跳转403
        if (!check(record.meta.authority) && to.path !== "/error/403") {
            next({
                path: "/error/403",
            });
            return;
        }
    } else {
        // 没有配置权限的路由， 如果用户有权限配置，且权限中不包含 allow 权限，让他无法访问，否则，可以访问没配置权限的路由
        if (
            getCurrentAuthority().length > 0 &&
            !check(["allow"]) &&
            to.path !== "/error/403"
        ) {
            next({
                path: "/error/403",
            });
            return;
        }
    }

    // 角色授权的菜单，可以跳转
    if (
        !frontAuthWhiteList.includes(to.path) &&
        !frontAuthWhiteListDynaimcRegexp.some((i) => i.test(to.path)) &&
        !checkAuthMenu(to.path)
    ) {
        next({
            path: "/error/403",
        });
        return;
    }

    // 校验权限
    let { eduAuth } = to.meta;
    if (!(eduAuth && eduAuth.length) || store.state.user.regulator === 1) {
        return subLoginFrom(to, from, next); // 无权限设置
    }

    if (store.state.user.eduAuth === "") {
        // 接口返回没有eduAuth 字段时 只有老师权限
        return subLoginFrom(to, from, next);
    }

    subLoginFrom(to, from, next);
    //   eduAuth.indexOf(store.state.user.eduAuth) !== -1 // TODO: 待定
    //     ? subLoginFrom(to, from, next) // 有权限
    //     : getLoginFrom() || from.query.loginFrom
    //       ? next({
    //         name: "home",
    //         query: {
    //           ...to.query,
    //           loginFrom: getLoginFrom() || from.query.loginFrom,
    //         },
    //       })
    //       : next({
    //         name: "home",
    //         query: {
    //           ...to.query,
    //         },
    //       }); // 无权限跳转教师管理
}

function subLoginFrom(to, from, next) {
    if (getLoginFrom() || from.query.loginFrom) {
        if (to.query.loginFrom) {
            // next({
            //   path: to.path,
            //   query: {
            //     ...to.query,
            //   },
            // });
            next();
        } else {
            next({
                path: to.path,
                query: {
                    ...to.query,
                    loginFrom: getLoginFrom() || from.query.loginFrom,
                },
            });
        }
    } else {
        next();
    }
}

// 标题国际化
export const setPageTitleTxt = (meta) => {
    const { title } = meta;
    if (title && title.startsWith("el")) {
        window.document.title = i18n.t(title);
    }
};

router.afterEach((to) => {
    store.commit("setChildPageLoaded", 1);
    const title = (to.meta && to.meta.title) || process.env.VUE_APP_TITLE;
    setPageTitleTxt(to.meta);
    NProgress.done(); // 结束Progress
});
