/**
 * 教务管理-教室管理
 */
import axios from "@/plugins/axios.js";
/**
 * 根据学校code获取当前学校下的所有年级数据
 * @return {Promise}
 */
export function getGradeData(schoolCode) {
  return axios.get(`/schoolGrade/list/${schoolCode}`);
}

// 获取当前年级下的班级的数据
export function getClassData(gradeId) {
  return axios.get(`/schoolClass/list/${gradeId}`);
}

// 新增当前学校下的年级
export function addGradeData(data) {
  return axios.post("/schoolGrade/add", data);
}

// 编辑年级
export function editGradeData(data) {
  return axios.put(`/schoolGrade/update/${data.gradeId}`, data.form);
}

// 删除年级
export function deleteGradeData(gradeId) {
  return axios.delete(`/schoolGrade/delete/${gradeId}`);
}

// 新增当前年级下的班级
export function addClassData(data) {
  return axios.post("/schoolClass/add", data);
}

// 编辑班级
export function editClassData(data) {
  return axios.put(`/schoolClass/update/${data.classId}`, data.form);
}

// 删除班级
export function deleteClassData(classId) {
  return axios.delete(`/schoolClass/delete/${classId}`);
}

// 根据树选择的学校/年级/班级 获取学生列表数据
export function toGetStudentList(params) {
  return axios.get("/schoolStudent/list", { params });
}

// 根据树选择的学校/年级/班级 学生列表数据 通过id获取详情
export function toGetStudentDetail(id) {
  return axios.get(`/schoolStudent/detail/${id}`);
}

// 导入学生数据
export function importStudentData(data) {
  return axios.post("/schoolStudent/import", data);
}

// 添加学生
export function addSchoolStudentData(data) {
  return axios.post("/schoolStudent/add", data);
}

// 编辑学生
export function updateSchoolStudentData(data) {
  return axios.put(`/schoolStudent/update/${data.studentId}`, data.form);
}

// 删除学生
export function deleteStudentData(studentId) {
  return axios.delete(`/schoolStudent/delete/${studentId}`);
}

// 根据学生编码判断学生是否存在
export function checkStudentCodeExist(studentCode) {
  return axios.get(`/schoolStudent/check/${studentCode}`);
}

// 获取学生的已报课程数据
export function getStudentReportedList(data) {
  return axios.get(`/schoolStudent/course/${data.studentId}`, {
    params: data.params,
  });
}
