import i18n from "../../tools/i18n";
function _has(obj, key) {
  return obj && Object.prototype.hasOwnProperty.call(obj, key);
}

/**
 * 安全配置项
 */
const securityConfig = {
  state: {
    LOGIN_ERROR_PASSWORD_COUNT: "5", // 默认错误5次
    LOGIN_LOCK_TIME: "0", // 默认关闭
    LOGIN_COMPLEX: "lettersCheck", // 默认不校验  'notRequired'
    LOGIN_PASSWORD_MIN_LENGTH: "8", // 默认密码最小6位
  },
  getters: {
    /**
     * 密码校验
     */
    checkPassword: (state) => (value) => {
      if (value === "") return i18n.t("el.forgotPwd.PasswordNotEmpty");
      if (value.length < state.LOGIN_PASSWORD_MIN_LENGTH || value.length > 16)
        return (
          i18n.t("el.forgotPwd.PasswordLength") +
          `${state.LOGIN_PASSWORD_MIN_LENGTH}` +
          i18n.t("el.forgotPwd.characters")
        );
      // 需要密码复杂度校验
      if (state.LOGIN_COMPLEX === "lettersCheck") {
        if (!(/[a-z]+/.test(value) && /[A-Z]+/.test(value)))
          return i18n.t("el.forgotPwd.ContainUpperLowerLetters");
        if (!/\d+/.test(value))
          return i18n.t("el.forgotPwd.MustcontainNumbers");
      }
      // 判断是否使用空格、和中文
      let reg = /(\s|[\u4e00-\u9fa5])+/g;
      if (reg.test(value)) return i18n.t("el.forgotPwd.SpacesCharacters");
      // 判断是否含有非法字符
      reg = /[@#\$%\^&\*]+/;
      if (reg.test(value))
        return i18n.t("el.forgotPwd.SpecialCharacters") + "@#$%^&*";
      return true;
    },
  },
  mutations: {
    /**
     * 更新数据
     * @param {*} state 状态
     * @param {*} data 配置数据
     */
    SET_DATA(state, data) {
      Object.keys(state).forEach((key) => {
        if (_has(data, "PORTAL_" + key)) state[key] = data["PORTAL_" + key];
      });
    },
  },
  actions: {
    /**
     * 获取安全配置数据
     * @param {*} param0 提交数据方法
     */
    // getLoginConfig({ commit }) {
    //   return getLoginConfig().then(({ data }) => {
    //     commit("SET_DATA", data);
    //   });
    // },
  },
};

export default securityConfig;
