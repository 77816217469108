import Vue from "vue";
import { clearState } from "../index.js";
import store from "@/store";
import i18n from "@/tools/i18n.js";
import { checkAuthMenu } from "@/utils/auth.js";
import { getPathByName } from "@/router/index.js";
import { getCourseAuth } from "@/api/home";

// 数据字典模块
/**
 * 根据多层业务id来查询字典列表
 * @param {*} state 源数据
 * @param {String} type 业务id
 */
function queryDictList(state, type) {
    type = type || "";
    var types = type.split(",");
    if (types.length > 1) {
        // eslint-disable-next-line no-prototype-builtins
        var list = state.hasOwnProperty(types[0]) ? state[types[0]] : [];
        for (var i = 1; i < types.length; i++) {
            var val = types[i];
            var res = list.find(function (value) {
                return value.key == val;
            });
            if (res !== void 0) list = res.children || [];
            else {
                list = [];
                break;
            }
        }
        return list;
    } else {
        // eslint-disable-next-line no-prototype-builtins
        return state.hasOwnProperty(type) ? state[type] : [];
    }
}

/**
 * 将list转为默认的options数据
 * @param {*} list 源数据列表
 */
function list2options(
    list,
    keyKey = "dictionaryKey",
    labelKey = "dictionaryText"
) {
    return list.map(function (item) {
        if (item.children && item.children.length)
            item.children = list2options(item.children, keyKey, labelKey);
        return Object.assign({}, item, {
            key: item[keyKey],
            value: item[keyKey],
            label: item[labelKey],
        });
    });
}

// 初始化数据
const initState = () => ({
    // 性别
    XBM: [
        {
            key: 1,
            label: "男",
        },
        {
            key: 2,
            label: "女",
        },
    ],
    // 身份
    SF: [
        {
            key: 0,
            label: "el.infrastructure.EducationalAdministrators",
        },
        {
            key: 1,
            label: "el.infrastructure.educationalAdministrator",
        },
        {
            key: null,
            label: "el.infrastructure.averageUser",
        },
    ],
    GRADE: [],
    SCHOOL_YEAR: [],
    TERM: [],
    leftTabList: [
        {
            // 课程推荐
            key: "COURSE_RECOMMENDATIONS",
            name: "courseRecommendations",
            sort: 2,
            label: [],
        },
        {
            // 基础设置
            key: "BASIC_SETTING",
            sort: 1,
            label: [
                {
                    name: "teachManage",
                    label: "el.saas.permissionManage",
                    children: [
                        {
                            name: "authorize",
                            label: "el.infrastructure.authorizationTeachers",
                        },
                        {
                            name: "batchUserPermission",
                            label: "el.infrastructure.TeacherBatchAuthorization",
                        },
                    ],
                },
                {
                    name: "roleManage",
                    label: "el.saas.roleManage",
                    children: [
                        {
                            name: "rolePermission",
                            label: "el.saas.roleManage",
                        },
                        {
                            name: "allotUser",
                            label: "el.saas.roleManage",
                        },
                    ],
                },
                // {
                //   name: "classStudent",
                //   label: "el.infrastructure.studentManagement",
                //   children: [
                //     {
                //       name: "classStudentReported",
                //       label: "el.infrastructure.RegisteredCourses",
                //     },
                //   ],
                // },
                {
                    name: "attendClassTime",
                    label: "el.infrastructure.timeManagementClass",
                },
                {
                    name: "classroomSetting",
                    label: "el.infrastructure.classroomManagement",
                    children: [
                        {
                            name: "classroomSchedule",
                            label: "上课管理",
                        },
                    ],
                },
            ],
        },
        {
            // 数据分析
            key: "DATA_ANALYSIS",
            sort: 5,
            label: [
                {
                    name: "dataOverview",
                    label: "el.router.dataOverview",
                },
                {
                    name: "usage",
                    label: "el.dataAnalysis.usageStateAnalysis",
                },
                {
                    name: "course",
                    label: "el.router.teachingAnalysis",
                },
                {
                    name: "teachingRecord",
                    label: "el.router.TeachingRecord",
                },
                {
                    name: "prepareRecord",
                    label: "el.router.PrepareRecord",
                },
                {
                    name: "compared",
                    label: "el.router.TeachersComparativeAnalysis",
                },
                {
                    name: "rescourceAnalyze",
                    label: "el.router.ResourceUsageAnalysis",
                },
                {
                    name: "statisticalBoard",
                    label: "el.router.DataPanel",
                },
            ],
        },
        {
            // 订单管理
            key: "ORDER",
            sort: 6,
            label: [
                {
                    name: "orderList",
                    label: "el.order.orderList",
                    children: [
                        {
                            name: "orderList",
                            label: "el.order.orderList",
                        },
                        {
                            name: "orderDetail",
                            label: "el.order.orderDetail",
                        },
                    ],
                },

                {
                    name: "refundApplication",
                    label: "el.order.refundApplication",
                    children: [
                        {
                            name: "refundApplication",
                            label: "el.order.refundApplication",
                        },
                        {
                            name: "refundDetail",
                            label: "el.order.refundDetail",
                        },
                    ],
                },

                {
                    name: "settlement",
                    label: "el.order.settlement",
                    children: [
                        {
                            name: "settlement",
                            label: "el.order.settlement",
                        },
                    ],
                },
            ],
        },
        {
            // 学校资源管理
            key: "SCHOOL_RESOUSE",
            sort: 4,
            label: [
                {
                    name: "resourceManage",
                    label: "el.schoolResourceManage.schoolResourcesManagement",
                },
                {
                    name: "auditManage",
                    label: "el.schoolResourceManage.auditmanagement",
                },
                {
                    name: "shareRecord",
                    label: "el.schoolResourceManage.crossSchoolSharingRecords",
                },
            ],
        },
        {
            // 排课管理
            key: "PLAN_COURSE",
            sort: 3,
            label: [
                // {
                //   name: "totalCourseList",
                //   label: "el.planCourse.totalSchedule",
                // },
                {
                    name: "totalCourseListByImport",
                    label: "el.planCourse.totalScheduleByImport",
                },
                // {
                //   name: "planCourseList",
                //   label: "el.planCourse.classSchedule",
                //   children: [
                //     {
                //       name: "planCourseDetails",
                //       label: "el.planCourse.arrangeClass",
                //     },
                //     {
                //       name: "schoolTimetabelCheck",
                //       label: "el.planCourse.scheduleView",
                //     },
                //   ],
                // },
                {
                    name: "courseManage",
                    label: "el.planCourse.courseManagement",
                    children: [
                        {
                            name: "courseClassManage",
                            label: "el.planCourse.classManagement",
                        },
                    ],
                },
                {
                    name: "selectCourses",
                    label: "el.planCourse.selectCourses",
                    children: [
                        {
                            name: "selectCoursesDetail",
                            label: "选课详情",
                        },
                    ],
                },
                {
                    name: "teacherAttendance",
                    label: "el.planCourse.teacherAttendance",
                    children: [
                        {
                            name: "teacherAttendance",
                            label: "el.planCourse.teacherAttendance",
                        },
                    ],
                },
                {
                    name: "studentAttendance",
                    label: "el.planCourse.studentAttendance",
                    children: [
                        {
                            name: "studentAttendance",
                            label: "el.planCourse.studentAttendance",
                        },
                    ],
                },
                {
                    name: "questionnaireManage",
                    label: "el.planCourse.questionnaireManage",
                    children: [
                        {
                            name: "questionnaireManage",
                            label: "el.planCourse.questionnaireManage",
                        },
                        {
                            name: "questionnaireResult",
                            label: "el.planCourse.questionnaireResult",
                        },
                    ],
                },
                {
                    name: "evaluationManage",
                    label: "el.planCourse.evaluationManage",
                    children: [
                        {
                            name: "evaluationManage",
                            label: "el.planCourse.evaluationManage",
                        },
                        {
                            name: "evaluationDetail",
                            label: "el.planCourse.evaluationDetail",
                        },
                    ],
                },
            ],
        },
    ],
    languageList: [
        {
            key: "LANGUAGE",
            label: [
                {
                    name: "zh_CN",

                    label: "简体中文",
                },
                {
                    name: "en_US",
                    label: "English",
                },
            ],
        },
    ],
    courseTermId: [],
    TENANT_GRADE: [], // 当前学校下的年级
    saasDictData: [], // 暂存saas auth接口中的字典数据，避免重复调用接口
    ORDER_STATUS: [],
    VERITY_STATUS: [],
    REFUND_STATUS: [],
    TENANT_TYPE: [], // 租户类型 1 运维 2 学校 3 区域 4 机构
    SUBMIT_STATUS: [], // 提交状态 1 未提交 2 已提交
    SETTLE_STATUS: [], // 结算状态 1 未完成 2 已撤销 3 已完成
});

// 配置部分字段从新接口中获取，【state字段名：新接口返回code】
const saasSourceKey = {
    TERM: "termId",
    SCHOOL_YEAR: "SCHOOL_YEAR",
    TENANT_GRADE: "gradeId",
};

const dict = {
    state: initState(),
    getters: {
        /**
         * 根据type获取字典列表数据
         * 如果是获取大于两层字典根据层级使用逗号隔开
         * @param {String} type 字典类型
         * @return {Array}
         */
        getDictionary: (state) => (type) => {
            return queryDictList(state, type);
        },
        /**
         * 根据传递type和key查询字典翻译
         * @param {type: String, key: String} 参数
         * @return {String}
         */
        queryDictionary: (state) => (options) => {
            if (typeof options !== "object" || options === null) return "";
            var type = options.type;
            var list = queryDictList(state, type);
            var key = options.key;
            for (const item of list) {
                if (item.key + "" === key + "") return item.label;
            }
            return "";
        },
        // 教务管理默认进入的路由
        teachManageAccess: (state) => {
            let pathname = "";
            let sortLeftTab = state.leftTabList.toSorted((a, b) => a.sort - b.sort);
            sortLeftTab.forEach((item) => {
                if (!pathname) {
                    if (item.name && checkAuthMenu(getPathByName(item.name))) {
                        pathname = item.name;
                    } else if (item.label?.length) {
                        item.label.forEach((subItem) => {
                            if (!pathname) {
                                if (
                                    subItem.name &&
                                    checkAuthMenu(getPathByName(subItem.name))
                                ) {
                                    pathname = subItem.name;
                                }
                            }
                        });
                    }
                }
            });

            return pathname;
        },
        // 针对学校端使用的租户类型，只展示区域和机构
        tenantTypeListForSchool: (state) => {
            return state.TENANT_TYPE.filter(
                (type) => type.value === "3" || type.value === "4"
            );
        },
        // 提交状态
        submitStatusList: (state) => state.SUBMIT_STATUS,
        // 针对学校端的结算状态，只展示未完成 已完成
        settleStatusListForSchool: (state) => {
            return state.SETTLE_STATUS.filter(
                (type) => type.value === "1" || type.value === "3"
            );
        },
    },
    mutations: {
        initDict(state, data) {
            clearState(state, initState());
            // localStorage.setItem("userMsg", JSON.stringify(store.state));
        },
        SET_SAAS_DICT_DATA(state, data) {
            state.saasDictData = data;
        },
    },
    actions: {
        loadBaseData({ state, commit }) {
            return Vue.axios.get("/dictionary/selectDictionary").then(({ data }) => {
                if (!data || data === null || typeof data !== "object") return;
                for (const key in data) {
                    const value = data[key];
                    // eslint-disable-next-line no-prototype-builtins
                    if (
                        Object.prototype.hasOwnProperty.call(state, key) &&
                        Array.isArray(value) &&
                        !Object.keys(saasSourceKey).includes(key)
                    ) {
                        state[key] = list2options(value);
                    }
                }
            });
        },
        // 部分接口从本接口获取
        loadSaasBase({ state, commit }) {
            getCourseAuth().then((res) => {
                if (res?.data?.length) {
                    commit("SET_SAAS_DICT_DATA", res.data);
                    res.data.forEach((item) => {
                        for (let key of Object.keys(saasSourceKey)) {
                            if (item.code === saasSourceKey[key]) {
                                state[key] = item.children.map(({ cnName, id }) => ({
                                    key: id,
                                    label: cnName,
                                    dictionaryKey: id,
                                    dictionaryText: cnName,
                                }));
                            }
                        }
                    });
                }
            });
        },
    },
};

export default dict;
