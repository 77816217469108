<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :custom-class="
      [
        isExpandDialog ? 'chat-dialog-expand' : 'chat-dialog',
        'chat-dialog-content',
        !isExpandDialog
          ? direction === 'left'
            ? 'dialog-left'
            : 'dialog-right'
          : '',
      ].join(' ')
    "
    :close-on-press-escape="false"
    :show-close="false"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="t-l">
      <div class="flex flex-m">
        <span class="chat-dialog-title">{{
          $t("el.chatgpt.chatgptTitle")
        }}</span>
        <span class="chat-dialog-version">{{
          $t("el.chatgpt.InternalTestVersion")
        }}</span>
        <el-image
          @click="onExpandDialog"
          :src="isExpandDialog ? icoSmaller : iconMaximize"
          class="icon-max"
        ></el-image>
        <el-image
          @click="onCloseDialog"
          :src="iconChatClose"
          class="icon-chat-close"
        ></el-image>
      </div>
      <div class="point-desc flex flex-m">
        {{ $t("el.chatgpt.NumberResourcePoints") }} {{ resourcePoint }}
        <el-tooltip
          effect="dark"
          :content="chatTips"
          placement="top"
          popper-class="chat-tips"
          :enterable="true"
        >
          <el-image class="icon-chat-desc" :src="iconChatDesc"></el-image>
        </el-tooltip>
      </div>
      <div style="width: calc(100% + 28px)" class="dash-line"></div>

      <!-- <div class="message-content">{{ messageData }}</div> -->
      <el-scrollbar ref="myScrollbar" class="message-content">
        <ChatMessage
          :isExpandDialog="isExpandDialog"
          :messageList="messageList"
          :showMoreBtn="showMoreBtn"
          @default-message-click="defaultMessageClick"
          @getMoreMessage="getMoreMessage"
          @onMessageControl="onMessageControl"
        ></ChatMessage>
      </el-scrollbar>

      <div style="width: calc(100% + 28px)" class="dash-line"></div>
      <div class="input-content t-c" style="position: relative">
        <div class="flex flex-m input-text">
          <!--@keyup.enter.native="
              !sendDisabled ? toSendMessage(message, 1) : null
            " -->
          <el-input
            v-model="message"
            :disabled="sendInputDisabled"
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 3 }"
            :placeholder="chatInputPlaceholder"
            @keydown.native="
              (...defaultArgs) =>
                returnKeySendMessage(message, 1, ...defaultArgs)
            "
          >
          </el-input>
          <el-button
            class="btn-chat-send"
            slot="append"
            :disabled="sendDisabled"
            @click="toSendMessage(message, 1)"
          >
            {{ $t("el.chatgpt.Send") }}
          </el-button>
        </div>
        <div class="chat-dialog-desc">
          {{ $t("el.chatgpt.chatDialogTips") }}
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { getToken } from "@/core/auth";
import {
  sendChatMessage,
  getChatTokenCount,
  searchIndexInfo,
} from "@/api/chatgpt/index.js";
import ChatMessage from "@/components/biz-components/ChatMessage/index.vue";
import { random_string } from "../../../utils";
// import io from "socket.io-client/dist/socket.io.slim.js";
export default {
  data() {
    return {
      dialogVisible: false,
      isShowReGenerate: false, // 是否显示重新生成
      iconMaximize: require("@/assets/images/icon/icon-maximize.png"),
      icoSmaller: require("@/assets/images/icon/icon-chat-smaller.png"),
      iconChatClose: require("@/assets/images/icon/icon-chat-close.png"),
      iconChatDesc: require("@/assets/images/icon/icon-chat-desc.png"),
      iconReGenerate: require("@/assets/images/icon/icon-re-generate.png"),
      iconStopGenerate: require("@/assets/images/icon/icon-stop-generate.png"),
      pageItem: {
        limit: 10,
        offset: 0,
        page: 1,
      },
      messageList: [], // 聊天框消息列表
      showMoreBtn: false, // 是否显示查看更多
      resourcePoint: "-",
      message: "",
      loadingMessage: "",
      websock: null,
      wsId: "", // 用来区分 webSocket 链接
      isExpandDialog: false, // 是否是放大的页面
      reConnectCount: 0, // 断线重连次数
      sendInputDisabled: false, // 禁止输入
      sendDisabled: false, // 禁止发送
      isWebsockting: false, // Websocket 是否在监听
      lastScrollHeight: 0,
      allLoading: false,
      heartCheck: {},
      isPinyinInput: false,
      chatInputPlaceholder: "",
    };
  },
  components: {
    ChatMessage,
  },
  created() {
    // this.sse_init();
  },
  mounted() {},
  computed: {
    chatTips() {
      return this.$t("el.chatgpt.ChatTips");
    },

    initMessageList() {
      return [
        {
          messageId: 0,
          messageType: 0, // 0 : 默认消息 1： 问题  2： 答案
          message: "",
          status: 0,
          tipsList: [
            {
              tipId: 0,
              message: this.$t("el.chatgpt.Example1"),
            },
            {
              tipId: 1,
              message: this.$t("el.chatgpt.Example2"),
            },
            {
              tipId: 2,
              message: this.$t("el.chatgpt.Example3"),
            },
          ],
        },
      ];
    },
    defaultLoadingMessage() {
      return this.$t("el.chatgpt.Answering");
    },
  },
  props: {
    // 是否显示 ChatDialog
    showChatDialog: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String,
      default: "right",
    },
  },
  watch: {
    showChatDialog(newVal) {
      this.dialogVisible = newVal;
      if (newVal) {
        this.isExpandDialog = false;
        this.loadingMessage = this.defaultLoadingMessage;
        this.messageList = this.initMessageList;
        this.messageData = "";
        this.initWebSocket();
        this.getTokenCount(); // 获取剩余 token，此处应改为 webSocket 发送获取

        this.getSearchIndexInfo(this.pageItem);
      }
    },
  },
  methods: {
    // 查看更多
    getMoreMessage() {
      let pageItem = {
        limit: this.pageItem.limit,
        offset: this.pageItem.limit * this.pageItem.page,
        page: ++this.pageItem.page,
      };
      this.getSearchIndexInfo(pageItem, true);
    },
    getSearchIndexInfo(pageItem, noScrollDown) {
      return searchIndexInfo(pageItem)
        .then((result) => {
          console.log("result===", result);
          let messageResult = [];
          if (result.data && Array.isArray(result.data.records)) {
            let data = result.data.records.reverse();
            if (data.length >= 10) {
              this.showMoreBtn = true;
            } else {
              this.showMoreBtn = false;
            }
            data.forEach((element) => {
              let sendMessageItem = {
                messageId: element.id + 1,
                messageType: 1,
                message: element.sendMessage,
                status: 0,
              };
              messageResult.push(sendMessageItem);
              let replyMessageItem = {
                messageId: element.id + 2,
                messageType: 2,
                message: element.replyMessage,
                status: 0,
              };
              messageResult.push(replyMessageItem);
            });
          }
          console.log("messageResult", messageResult);
          this.messageList = messageResult.concat(this.messageList);
          if (this.messageList.length > 1) {
            this.isShowReGenerate = true;
          } else {
            this.isShowReGenerate = false;
          }
          this.scrollDown(noScrollDown);
        })
        .catch(() => {
          //
        });
    },
    // sse 连接， get 请求  type: 1 国内服务器 2 海外服务器
    sse_init(message, type) {
      let self = this;
      // http://localhost:8000/sse/data/
      // http://110.238.107.59:8282/chat/sse?id=kej5x7J4wJpmbKEnnDJrWYcQWNnzYBYW&prompt=回我一个字好吗&key
      // let url = `http://110.238.107.59:8282/chat/sse?id=kej5x7J4wJpmbKEnnDJrWYcQWNnzYBYW&prompt=${message}&key`;
      let url;
      let uuid = random_string(32);
      let chatId = random_string(18);
      console.log("uuid =====", uuid);
      self.messageData = "";
      if (type === 1) {
        url = `/sse/ChatGPT/send/message?chatId=${chatId}&message=${message}&staffCode=89010600&sseId=${uuid}`;
      } else {
        url = `http://110.238.107.59:8282/chat/sse?id=${chatId}&prompt=${message}&key`;
      }
      // let url = `/sse/ChatGPT/send/message?id=ssjdhwwidwhdew&message=${message}&staffCode=89010600`;
      // let xhr = new XMLHttpRequest();
      // xhr.open("GET", url);
      // xhr.setRequestHeader("Authorization", getToken());
      var evtSource = new EventSource(url);
      evtSource.onopen = function () {
        // open事件
        console.log("EventSource连接成功");
      };
      evtSource.onmessage = function (event) {
        try {
          console.log(event);
          self.loadingMessage = "";
          if (event.data == "[END]") {
            evtSource.close();
          } else {
            if (type === 2) {
              self.messageData =
                self.messageData + JSON.parse(event.data).content;
            } else {
              self.messageData = self.messageData + event.data;
            }
            self.sendMessageToDialog(self.messageData, 1);
          }
        } catch (error) {
          console.log("EventSource结束消息异常", error);
        }
      };
      evtSource.onclose = function (error) {
        console.log("关闭=======", error);
        evtSource.close();
      };
      evtSource.onerror = function (error) {
        console.log("EventSource连接异常", error);
        evtSource.close();
      };
    },
    // sse 连接， post 请求
    ssePostInit() {
      class RetriableError extends Error {}
      class FatalError extends Error {}
      let self = this;
      const ctrl = new AbortController();
      const headers = {
        Authorization: getToken(),
        // "Content-Type": "application/json",
      };

      const requestBody = {
        id: "kej5x7J4wJpmbKEnnDJrWYcQWNnzYBYW",
        message: "回我一个字好吗",
      };
      // /bskpt-front/ChatGPT/send/message
      var evtSource = fetchEventSource("http://localhost:8000/sse/data/", {
        headers,
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: JSON.stringify(requestBody),
        signal: ctrl.signal,
        async onopen(response) {
          console.log(
            "response.headers.get====",
            response.headers.get("content-type")
          );

          console.log("response===", response);

          response.text().then((contentType) => {
            console.log("response.headers.get====", contentType);

            if (response.ok && contentType.includes("text/event-stream")) {
              console.log("return=============");
              return; // everything's good
            } else if (
              response.status >= 400 &&
              response.status < 500 &&
              response.status !== 429
            ) {
              // client-side errors are usually non-retriable:
              throw new FatalError();
            } else {
              throw new RetriableError();
            }
          });
        },
        onmessage(msg) {
          // if the server emits an error message, throw an exception
          // so it gets handled by the onerror callback below:

          console.log("msg==========", msg);

          if (msg.event === "FatalError") {
            throw new FatalError(msg.data);
          } else {
            if (msg.data == "[END]") {
              evtSource.close();
            } else {
              self.messageData = self.messageData + msg.data;
            }
          }
        },
        onclose() {
          // if the server closes the connection unexpectedly, retry:

          throw new RetriableError();
        },
        onerror(err) {
          console.log("err======", err);

          if (err instanceof FatalError) {
            throw err; // rethrow to stop the operation
          } else {
            // do nothing to automatically retry. You can also
            // return a specific retry interval here.
          }
        },
      });
    },
    // 默认消息单击
    defaultMessageClick(item, index) {
      this.message = item.message;
    },
    // 发送消息框回车
    returnKeySendMessage(message, type, e) {
      if (!e.shiftKey && e.keyCode == 13) {
        e.cancelBubble = true; // ie阻止冒泡行为
        e.stopPropagation(); // Firefox阻止冒泡行为
        e.preventDefault(); // 取消事件的默认动作*换行
        // 以下处理发送消息代码
        if (!message.trim()) {
          return;
        }
        if (this.sendDisabled) {
          this.$message.warning(this.$t("el.chatgpt.limitSendMessage"));
          return;
        }
        this.toSendMessage(message, type);
      }
    },
    // 发送消息 type: 2  重新生成
    async toSendMessage(message, type) {
      this.messageData = "";
      if (!message) {
        this.$message.warning(this.$t("el.chatgpt.EnteKnow"));
        return;
      }

      this.sendDisabled = true;
      // this.sendInputDisabled = true;

      if (type !== 2) {
        this.sendMessageToDialog(message, 0);
        this.scrollDown();
      }

      this.loadingMessage = this.$t("el.chatgpt.Answering");
      // 收到消息之前 添加 list
      let messageItem = {
        messageId: random_string(32),
        messageType: 2,
        message: this.loadingMessage,
        status: 1, // 回答中
      };
      this.messageList.push(messageItem);
      this.scrollDown();
      // 如果不是 长连接状态
      if (!this.isWebsockting) {
        this.initWebSocket(message); // 断线重连

        // this.toWebsocketCloseStatus();
        // this.$message.error("服务器连接失败，无法接收消息！");
        // this.messageList[this.messageList.length - 1].message =
        //   "服务器连接失败，无法接收消息！";
        // this.$set(
        //   this.messageList,
        //   this.messageList.length - 1,
        //   this.messageList[this.messageList.length - 1]
        // );
      } else {
        this.sendWebSocketMesage(message);
      }

      // this.sse_init(message, type);
    },
    // 滚动到底部
    scrollDown(noScrollDown) {
      this.$nextTick(() => {
        if (!noScrollDown) {
          this.lastScrollHeight = this.$refs["myScrollbar"].wrap.scrollHeight;
        } else {
          this.lastScrollHeight =
            this.$refs["myScrollbar"].wrap.scrollHeight - this.lastScrollHeight;
        }
        this.$refs["myScrollbar"].wrap.scrollTop = this.lastScrollHeight;
      });
    },
    // 关闭弹框
    onCloseDialog() {
      this.closeWebSocket();
      console.log("=========", this.$t("el.chatgpt.Example1"));
      // 重置组件数据
      const { isExpandDialog, ...rest } = {
        ...this.$options.data(),
        isExpandDialog: this.isExpandDialog,
      };

      Object.assign(this.$data, rest);
      this.$emit("setChatDialogVisible", false);
    },
    // 关闭 长连接
    closeWebSocket() {
      if (this.websock) {
        this.websock.close();
        this.websock = null;
        this.heartCheck.reset();
        this.heartCheck = null;
      }
    },
    // 将信息发送到聊天框
    sendMessageToDialog(message, type, noScrollDown) {
      // type: 0 问题  1 回答
      if (type === 0) {
        let messageItem = {
          messageId: Math.random(10000000),
          messageType: 1,
          message,
        };
        // let messageList = [];
        // messageList.push(messageItem);
        // this.messageList = this.messageList.concat(messageList);
        this.messageList.push(messageItem);
        console.log("this.messageList====", this.messageList);
        this.message = "";
      } else if (type === 1) {
        this.messageList[this.messageList.length - 1].message = message;
        this.$set(
          this.messageList,
          this.messageList.length - 1,
          this.messageList[this.messageList.length - 1]
        );
        !noScrollDown && this.scrollDown(); // 有换行符时不往下拉
      } else if (type === 2) {
        // 如果是重新生成，
      }
    },

    // 获取剩余token
    getTokenCount() {
      return getChatTokenCount()
        .then((result) => {
          console.log("result===", result);
          if (result) {
            this.resourcePoint = result.data;
            this.setInputStatus(this.resourcePoint);
          }
        })
        .catch(() => {
          //
          this.resourcePoint = "-";
          this.setInputStatus(this.resourcePoint);
        });
    },
    // 根据剩余token 设置 输入状态
    setInputStatus(resourcePoint) {
      if (resourcePoint <= 0 || resourcePoint === "-") {
        this.sendInputDisabled = true;
        this.sendDisabled = true;
        this.chatInputPlaceholder = this.$t("el.chatgpt.UsageLimit");
      } else {
        this.sendInputDisabled = false;
        this.sendDisabled = false;
        this.chatInputPlaceholder = this.$t("el.chatgpt.EnteKnow");
      }
    },

    // 发送消息
    sendWebSocketMesage(message) {
      this.messageData = "";
      let data = {
        message,
        wsId: this.wsId,
      };
      return sendChatMessage(data)
        .then((result) => {
          console.log("result===", result);
        })
        .catch((error) => {
          // this.sendDisabled = false; // 发送消息结束允许发送
          console.log("error======", error);
          this.messageList[this.messageList.length - 1].message = error.message;
          this.messageList[this.messageList.length - 1].status = 0;
          this.$set(
            this.messageList,
            this.messageList.length - 1,
            this.messageList[this.messageList.length - 1]
          );
          this.sendDisabled = false;
        });
      // 此处应该改用 webSocket 发消息
      // if (this.websock) {
      //   this.websock.send(JSON.stringify(data));
      // } else {
      //   this.reConnectCount++;
      //   if (this.reConnectCount < 5) {
      //     this.initWebSocket();
      //   } else {
      //     this.$message.error("无法与服务器连接！");
      //   }
      // }
    },
    // 初始化webSocket 心跳
    initWebSocketTimeout() {
      var self = this;
      // 心跳检测
      this.heartCheck = {
        timeout: 30000, // 半分钟发一次心跳
        timeoutObj: null,
        serverTimeoutObj: null,
        reset: function () {
          clearTimeout(this.timeoutObj);
          clearTimeout(this.serverTimeoutObj);
          return this;
        },
        start: function () {
          this.timeoutObj = setTimeout(() => {
            // 通过使用箭头函数，内部的回调函数将继承外部的 this 对象，确保了 this.serverTimeoutObj 正确地指向外部对象。
            // 这里发送一个心跳，后端收到后，返回一个心跳消息，
            // onmessage拿到返回的心跳就说明连接正常
            self.websock.send("HeartBeat");
            console.log("HeartBeat!==========");
            this.serverTimeoutObj = setTimeout(() => {
              console.log("没有重置后端 serverTimeoutObj ");
              // 如果超过一定时间还没重置，说明后端主动断开了
              self.websock.close(); // 如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
            }, 60000);
          }, this.timeout);
        },
      };
    },
    // 使用 webSocket 传递消息
    initWebSocket(message) {
      console.log("initWebSocket========");
      this.initWebSocketTimeout();
      this.wsId = random_string(32);
      // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
      this.websock = new WebSocket(
        `${window.baseConfig.VUE_APP_WS_API}ws/bskpt-front/websocket/${this.wsId}`
      );
      this.websock.onopen = (...defaultArgs) =>
        this.websocketonopen(message, ...defaultArgs);
      this.websock.onerror = this.websocketonerror;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onclose = this.websocketclose;
    },
    // webSocket 打开监听
    websocketonopen(message, event) {
      console.log("WebSocket连接成功...");

      this.heartCheck.reset().start(); // 心跳检测重置

      this.isWebsockting = true;
      if (message) {
        // 断线重连发送
        this.sendWebSocketMesage(message);
      }
    },
    // webSocket 报错监听
    websocketonerror(e) {
      console.log("WebSocket连接发生错误...");
      console.log(e);
      this.$message.error("WebSocket连接发生错误...");
      this.toWebsocketCloseStatus();
    },

    // webSocket 接收消息监听
    websocketonmessage(e) {
      console.log("e   Message====", e);
      console.log(e.data);
      this.heartCheck.reset().start(); // 心跳检测重置

      let data = e.data;
      console.log("data=====", data);
      if (data === "[END]") {
        // close
        console.log("[END]=====");
        // this.sendDisabled = false; // 发送消息结束允许发送
        // 回答结束 还原 回答状态
        this.messageList[this.messageList.length - 1].status = 0;
        this.$set(
          this.messageList,
          this.messageList.length - 1,
          this.messageList[this.messageList.length - 1]
        );
        console.log("end=messageList====", this.messageList);
      } else if (data === "HeartBeat") {
        // 返回心跳
        console.log("[HeartBeat]=====收到");
      } else if (data.startsWith("remainderTokens")) {
        console.log("remainderTokens====", data);
        let content = data.split(":");
        this.resourcePoint = content[1];
        this.setInputStatus(this.resourcePoint);
      } else {
        let status = 1;
        let noScrollDown = true;
        if (data) {
          data = JSON.parse(data);
          this.messageData = this.messageData + data.content;
          // 如果是重新生成
          if (data.status === "reGenerate") {
            status = 2;
          }
        } else {
          this.messageData = this.messageData + data;
        }

        noScrollDown = data.content ? data.content.indexOf("\n") > -1 : true;

        this.sendMessageToDialog(this.messageData, status, noScrollDown);
      }
    },
    // webSocket 关闭消息监听
    websocketclose(e) {
      console.log("connection closed (" + e.code + ")");
      this.toWebsocketCloseStatus();
    },
    // 还原 webSocket 关闭状态
    toWebsocketCloseStatus() {
      this.websock = null;
      this.isWebsockting = false;
      this.sendDisabled = false;

      if (this.messageList[this.messageList.length - 1]) {
        this.messageList[this.messageList.length - 1].status = 0;
        this.$set(
          this.messageList,
          this.messageList.length - 1,
          this.messageList[this.messageList.length - 1]
        );
      }
    },

    // 放大弹框
    onExpandDialog() {
      // this.toSendMessage(this.message, 2);
      this.isExpandDialog = !this.isExpandDialog;
    },
    // 消息控制点击
    onMessageControl(item, index, status) {
      // 如果是重新生成
      if (!status) {
        // let message = "";
        // this.reGenerateIndex = this.messageList.length - 2;
        // if (this.messageList[this.messageList.length - 1].messageType === 0) {
        //   message = this.messageList[this.messageList.length - 3].message;
        // } else {
        //   message = this.messageList[this.reGenerateIndex].message;
        // }

        // 反转数组并查找满足条件的最后一个元素
        let lastItem = [...this.messageList]
          .reverse()
          .find((item) => item.messageType === 1);

        if (lastItem) {
          this.toSendMessage(lastItem.message, 2); // 2 重新生成
        }
      } else {
        // 如果点击停止按钮
        this.stopGenerateMessage();
      }
    },
    // 发送停止生成信息
    stopGenerateMessage() {
      let data = {
        status: "stop",
      };
      this.websock && this.websock.send(JSON.stringify(data));
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .chat-dialog-content {
  .el-scrollbar__bar.is-horizontal {
    display: none;
  }
  .icon-max {
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: 14px;
    cursor: pointer;
  }
  .icon-chat-close {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .point-desc {
    width: fit-content;
    font-size: 12px;
    padding-top: 8px;
    font-weight: 400;
    color: #666666;
    line-height: 17px;
  }
  .icon-chat-desc {
    width: 15px;
    height: 15px;
    margin-left: 5px;
  }
  .chat-dialog-title {
    width: fit-content;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .chat-dialog-version {
    width: fit-content;
    height: fit-content;
    padding: 2px 6px;
    background: rgba(90, 83, 245, 0.15);
    border-radius: 10px;

    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5a53f5;
    line-height: 17px;
    margin-left: 14px;
  }
  .message-content {
    position: relative;
    width: 100%;
    height: 366px;
  }
  .el-scrollbar__wrap {
    overflow: scroll;
    height: 100%;
    overflow-x: hidden;
  }
  .input-content {
    padding-top: 14px;
    .btn-control {
      position: absolute;
      width: fit-content;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);

      cursor: pointer;
      padding: 4px 8px;
      border: solid 1px;
      border-radius: 4px;
      border-color: #5a53f5;
      font-size: 12px;
      .icon-control {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
    .chat-dialog-desc {
      width: fit-content;
      height: fit-content;
      font-size: 12px;
      font-weight: 500;
      color: #999999;
      line-height: 17px;
      margin-top: 14px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 14px;
      padding-right: 14px;
      text-align: center;
    }
    .input-text {
      width: 100%;
      height: fit-content;
      min-height: 40px;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #5a53f5;
    }
    .el-textarea__inner {
      border: 0px solid #dcdfe6;

      border-radius: 8px;
      font-size: 12px;

      font-weight: 400;
      color: #666666;
    }
    .btn-chat-send {
      width: fit-content;
      height: fit-content;
      background: #5a53f5;
      border-radius: 8px;
      padding: 6px 12px;
      font-size: 12px;

      font-weight: 500;
      color: #ffffff;
    }
  }
  .dash-line {
    width: 100%;
    height: 2px;
    transform: scaleY(0.5);
    background: #eeeeee;
    margin-top: 8px;
    margin-left: -14px;
  }
}
/deep/ .dialog-left {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
/deep/ .dialog-right {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
/deep/ .chat-dialog-expand {
  width: fit-content;
  background: #fefefe;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 14px 14px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
    box-sizing: border-box;
    width: 920px;
    min-height: 550px;
  }
}
/deep/ .chat-dialog {
  width: fit-content;
  background: #fefefe;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 14px 14px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
    box-sizing: border-box;
    width: 420px;
    min-height: 550px;
  }
}
</style>
<style lang="less">
.chat-tips {
  width: 340px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 400;
  color: #fffefe;
  line-height: 17px;
}
</style>
