// 用户管理
import Vue from "vue";
// import { getDictionary } from "@/api/datadict/index";
import {
  schoolTree,
  courseAuthList,
  getAuthGradeList,
  editBatchUserAuthCopy,
  editBatchUserAuthCopyNew,
  courseAuthListAdd,
} from "@/api/home/teachmanage";
import { courseAuthId } from "@/utils/auth";
// 初始化数据
const initState = () => ({
  gradeList: [],
  schoolTreeList: [],
  courseAuthSubjectList: [],
  courseAuthSubjectTotal: 0,

  // 新增编辑弹窗中使用的数据
  addGradeList: [],
  addCourseAuthSubjectList: [],
  addCourseAuthSubjectTotal: 0,
});
export default {
  state: initState(),
  mutations: {
    setGradeList(state, data) {
      state.gradeList = data;
    },
    setSchoolTreeList(state, data) {
      state.schoolTreeList = data;
    },
    setCourseAuthSubjectList(state, data) {
      state.courseAuthSubjectList = data;
    },
    setCourseAuthSubjectTotalCount(state, data) {
      Vue.set(state, "courseAuthSubjectTotal", data);
    },
    setAddGradeList(state, data) {
      state.addGradeList = data;
    },
    setAddCourseAuthSubjectList(state, data) {
      state.addCourseAuthSubjectList = data;
    },
    setAddCourseAuthSubjectTotalCount(state, data) {
      state.addCourseAuthSubjectTotal = data;
    },
  },
  actions: {
    // 获取年级列表
    getGradeList({ state, commit }, params) {
      return getAuthGradeList(params)
        .then(({ data }) => {
          if (data && Array.isArray(data)) {
            commit("setGradeList", data[0].children);
          }
        })
        .catch(() => {
          commit("setGradeList", []);
        });
    },
    // 获取学校、学部
    getSchoolTree({ state, commit }) {
      return schoolTree()
        .then(({ data }) => {
          commit("setSchoolTreeList", data);
        })
        .catch(() => {
          commit("setSchoolTreeList", []);
        });
    },
    // 年级课程接口
    getCourseAuthList({ state, commit }, data) {
      return courseAuthList(data)
        .then((result) => {
          commit("setGradeList", result.data.gradeList || []);
          commit(
            "setCourseAuthSubjectList",
            (result.data.courseAuthSubjectList || []).map((item) => {
              item.courseAuthInfoList?.forEach((sub) => {
                sub._uniqueId = courseAuthId(sub);
              });
              return item;
            })
          );
          commit("setCourseAuthSubjectTotalCount", result.data.totalCount || 0);
        })
        .catch(() => {
          commit("setGradeList", []);
          commit("setCourseAuthSubjectList", []);
          commit("setCourseAuthSubjectTotalCount", 0);
        });
    },
    // 年级课程接口
    getAddCourseAuthList({ state, commit }, data) {
      return courseAuthListAdd(data)
        .then((result) => {
          commit("setAddGradeList", result.data.gradeList || []);
          commit(
            "setAddCourseAuthSubjectList",
            (result.data.courseAuthSubjectList || []).map((item) => {
              item.courseAuthInfoList?.forEach((sub) => {
                sub._uniqueId = courseAuthId(sub);
              });
              return item;
            })
          );
          commit(
            "setAddCourseAuthSubjectTotalCount",
            result.data.totalCount || 0
          );
        })
        .catch(() => {
          commit("setAddGradeList", []);
          commit("setAddCourseAuthSubjectList", []);
          commit("setAddCourseAuthSubjectTotalCount", 0);
        });
    },
    // 批量编辑用户权限
    updateBatchUserAuth({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        editBatchUserAuthCopyNew(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
