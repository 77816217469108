import format from "date-fns/format"; // 日期格式化插件
import store from "@/store";

/**
 * 日期格式化
 */
export const dateFormat = format;

/**
 * 翻译字典
 * @param {*} key 值
 * @param {*} type 字典类型
 */
export function dict(key, type) {
  return store.getters.queryDictionary({ key, type });
}

/**
 * office online预览地址拼接
 * @param {*} fileUrl 文件地址
 * @param {*} type 文件后缀名
 * @param {*} isPrint 是否可以打印
 */
export function officePreviewUrl(fileUrl, type, isPrint, isEdit) {
  var previewHost = process.env.VUE_APP_OFFICE_PREVIEW_HOST; // office online 预览服务器地址
  var previewPath =
    {
      ppt: "/p/PowerPointFrame.aspx",
      pptx: "/p/PowerPointFrame.aspx",
      doc: "/wv/wordviewerframe.aspx",
      docx: "/wv/wordviewerframe.aspx",
    }[type] || "";
  var filePath = `${
    process.env[
      isPrint
        ? "VUE_APP_OFFICE_PRINT_HOST"
        : !isEdit
        ? "VUE_APP_OFFICE_HOST_EDIT"
        : "VUE_APP_OFFICE_HOST"
    ]
  }/wopi/files/${fileUrl.replace(/\//g, "_")}`;
  // word文件并且可以打印
  if (["doc", "docx"].indexOf(type) !== -1 && isPrint) {
    return `${previewHost}/wv/WordViewer/request.pdf?type=accesspdf&WOPISrc=${filePath}`;
  }
  // if (['ppt', 'pptx'].indexOf(type) !== -1 && !isPrint) {
  //   return `${previewHost}${previewPath}?PowerPointView=ChromelessView&Embed=1&WOPISrc=${filePath}`;
  // }
  return `${previewHost}${previewPath}?WOPISrc=${filePath}`;
}
