import url from "url";
import {
  setToken,
  getToken,
  setCookieLanguage,
  setLoginFrom,
  setReturnUrl,
  setThirdCode,
  setHidePrepareMenu,
} from "./auth.js";
import store from "@/store/index.js";
// 单点登录
// 入口地址查询
window.url = url;
try {
  if (!navigator.cookieEnabled) {
    // 浏览器不支持 cookie，或者用户禁用了 cookie。
    if (window.self !== window.top) {
      window.parent.postMessage(
        JSON.stringify({
          type: "AIYKT",
          data: {
            type: "COOKIE_ENABLED",
            cookieEnabled: navigator.cookieEnabled,
          },
        }),
        "*"
      );
      alert("如果要使用当前页面，请开启您的浏览器的第三方 cookie 支持");
    } else {
      alert("如果要使用当前页面，请开启您的浏览器的 cookie 支持");
    }
  } else {
    const pathReg = /\/sso\/auth$/;
    const thirdPathReg = /\/third\/auth$/;
    // 符合单点登录授权地址
    const data = url.parse(location.href, true);
    if (
      pathReg.test(location.pathname) &&
      data.query &&
      data.query.Authorization
    ) {
      if (data.query && data.query.saasSource) {
        localStorage.setItem("saasSource", data.query.saasSource);
      }
      const query = Object.assign({}, data.query);
      delete query.Authorization;
      delete query.tokenKey;
      if (query.saasSource) {
        delete query.saasSource;
      }
      if (query.applicationName) {
        delete query.applicationName;
      }
      if (query.language) {
        delete query.language;
      }
      window.location.search = url.format({
        query,
      }); // 删除query参数
      if (data.query && data.query.Authorization)
        setToken(data.query.Authorization);
      if (data.query && data.query.language)
        setCookieLanguage(data.query.language);

      if (data.query && data.query.loginFrom)
        setLoginFrom(data.query.loginFrom);
      if (data.query && data.query.tokenKey) {
        window.localStorage.setItem('tokenKey', data.query.tokenKey)
      } else {
        window.localStorage.setItem('tokenKey', 'Saas_Token')
      }
    } else if (
      thirdPathReg.test(location.pathname) &&
      data.query &&
      data.query.Authorization
    ) {
      if (data.query && data.query.saasSource) {
        localStorage.setItem("saasSource", data.query.saasSource);
      }
      const query = Object.assign({}, data.query);
      delete query.Authorization;
      delete query.tokenKey;

      if (query.saasSource) {
        delete query.saasSource;
      }
      if (query.applicationName) {
        delete query.applicationName;
      }
      if (query.language) {
        delete query.language;
      }
      window.location.search = url.format({
        query,
      }); // 删除query参数
      if (data.query && data.query.Authorization) {
        setToken(data.query.Authorization);
      }
      if (data.query && data.query.bussinessCode) {
        setThirdCode(data.query.bussinessCode);
      }

      if (data.query && data.query.hidePrepareMenu) {
        setHidePrepareMenu(data.query.hidePrepareMenu);
      }

      if (data.query && data.query.language)
        setCookieLanguage(data.query.language);

      if (data.query && data.query.return_url)
        setReturnUrl(data.query.return_url);
      if (data.query && data.query.loginFrom)
        setLoginFrom(data.query.loginFrom);

      if (data.query && data.query.tokenKey) {
        window.localStorage.setItem('tokenKey', data.query.tokenKey)
      } else {
        window.localStorage.setItem('tokenKey', 'Saas_Token')
      }
    }
  }
} catch (error) {
  console.error(error);
}
