var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-tooltip"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","disabled":_vm.isShowTooltip,"enterable":_vm.enterable,"placement":"top","popper-class":_vm.popperClass ? 'popper-class-tooltip' : ''}},[_c('div',{class:[_vm.customContentClass ? _vm.customContentClass : 'text-content'],attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.content)+" ")]),_c('p',{class:[
        _vm.overFlowNum
          ? `over-flow-${_vm.overFlowNum} ${_vm.className}`
          : _vm.singleAble
          ? `over-flow ${_vm.className}`
          : `over-flow-2 ${_vm.className}`,
        _vm.computedClass(_vm.refName),
      ],on:{"mouseover":function($event){return _vm.onMouseOver(_vm.refName)}}},[_c('span',{ref:_vm.refName},[_vm._v(_vm._s(_vm.content))])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }