// 上课时段
// import Vue from 'vue';
import {
  getClassTimePage,
  getSchoolList,
  addPeriod,
  updatePeriod,
  deletePeriod,
} from "@/api/manage/attend-class.js";
import { getManageSchoolList } from "@/api/manage/classroom";

export default {
  state: {
    staffCode: "", // 工号
    classTimeList: "", // 上课时段列表
    attendSchoolList: "", // 学校列表
  },
  getters: {},
  mutations: {
    /**
     * 设置是否需要引导
     */
    setClassTimeList(state, value) {
      state.classTimeList = value;
    },
    SET_SCHOOL_LIST(state, value) {
      state.attendSchoolList = value;
    },
  },
  actions: {
    /**
     * 获取上课时段列表
     * @param {*} param0 提交
     */
    getClassTimePage({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        getClassTimePage(params)
          .then((res) => {
            let data = res.data;
            // console.log('res',res);
            commit("setClassTimeList", data.records);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    /**
     * 获取学校列表
     * @param {}
     */
    getSchoolList({ state, commit }) {
      return new Promise((resolve, reject) => {
        getManageSchoolList()
          .then((res) => {
            let data = res.data;
            // console.log('res',res);
            data.forEach((element) => {
              element.value = element.schoolCode;
              element.label = element.schoolName;
            });
            commit("SET_SCHOOL_LIST", data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 新增上课时段
    addPeriod({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        addPeriod(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 编辑上课时段
    updatePeriod({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        updatePeriod(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 删除上课时段
    deletePeriod({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        deletePeriod(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
