import store from "@/store";

export function getCurrentAuthority() {
  // return  ["third"];
  return store.state.user.authority || [];
}

export function check(authority) {
  const current = getCurrentAuthority();
  return current.some((item) => authority.includes(item));
}

// 判断当前路由是否有权限
export function checkAuthMenu(path) {
  // return true;
  path = removeLast(path); // 去除最后的斜杠
  // 判断外链的进入的形式 全路径路由匹配
  const arrRoute = [...store.getters.authMenu.keys()];
  const flag = isExistOutLink(arrRoute, path);
  return (
    store.getters.authMenu?.has(path) ||
    path === "" ||
    path === "/" ||
    flag ||
    dynamicCheck(path)
  );
}

export function isExternalLink(url) {
  var pattern = /^(http:|https:)/i;
  return pattern.test(url);
}
function isExistOutLink(arr, str) {
  let flag = false;
  arr.forEach((ele) => {
    if (isExternalLink(ele) && ele.includes(str)) {
      flag = true;
    }
  });
  return flag;
}

// 判断动态路由权限
export function dynamicCheck(path) {
  return store.getters.dynamicAuthRegular.some((r) => r.test(path));
}

// 去除最后的斜杠
export function removeLast(path) {
  return path.replace(/(\/*$)/g, "");
}

// 课程权限拼接字段，作为唯一id
export function courseAuthId(item) {
  return `${item.courseId}-${item.originTenantType}-${item.originTenantId}-${item.tenantInfoId}`;
}
export function courseAuthIdToObj(str) {
  const arr = str.split("-");
  return {
    authCourseId: arr[0] || "",
    originTenantType: arr[1] || "",
    originTenantId: arr[2] || "",
    tenantInfoId: arr[3] || "",
  };
}
