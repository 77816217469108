import axios from "@/plugins/axios.js";

/**
 * 备授课时长排行
 */
export function getBskRank(params) {
  return axios.get("/statistics/bskRank", {
    params,
  });
}

/**
 * 备授课时长导出
 */
export function exportBskRank(params) {
  return axios.get("/statistics/bskExport", {
    params,
  });
}

/**
 * 使用情况 左上第一个表单
 */
export function usageData(params) {
  return axios.get("/statistics/usage", {
    params,
  });
}

/**
 * 参与情况--平台 中间下方
 */
export function ptParticipationData(params) {
  return axios.get("/boardParticiPlatform/page", {
    params,
  });
}

/**
 * 导出参与情况--平台 中间下方
 */
export function exportPtParticipationData(params) {
  return axios({
    method: "GET",
    url: "/boardParticiPlatform/export",
    params: params,
    responseType: "blob",
  });
}

/**
 * 参与情况--机构 中间下方
 */
export function jgParticipationData(params) {
  return axios.get("/boardParticiPlatform/org/page", {
    params,
  });
}

/**
 * 导出参与情况--机构 中间下方
 */
export function exportJgParticipationData(params) {
  return axios({
    method: "GET",
    url: "/boardParticiPlatform/org/export",
    params: params,
    responseType: "blob",
  });
}

/**
 * 参与情况--区域 中间下方
 */
export function areaParticipationData(params) {
  return axios.get("/boardParticiArea/page", {
    params,
  });
}
/**
 * 导出参与情况--区域 中间下方
 */
export function exportAreaParticipationData(params) {
  return axios({
    method: "GET",
    url: "/boardParticiArea/export",
    params: params,
    responseType: "blob",
  });
}

/**
 * 参与情况--学校 中间下方
 */
export function schoolParticipationData(params) {
  return axios.get("/boardParticiSchool/page", {
    params,
  });
}

/**
 * 导出参与情况--学校 中间下方
 */
export function exportSchoolParticipationData(params) {
  return axios({
    method: "GET",
    url: "/boardParticiSchool/export",
    params: params,
    responseType: "blob",
  });
}

/**
 * 整体统计-平台
 */
export function getPlatFormAlData(params) {
  return axios.get("/boardStatPlatform/list", {
    params,
  });
}

/**
 * 整体统计-机构
 */
export function getPlatJgAlData(params) {
  return axios.get("/boardStatPlatform/org/list", {
    params,
  });
}

/**
 * 整体统计-区域
 */
export function getAreaAlData(params) {
  return axios.get("/boardStatArea/list", {
    params,
  });
}

/**
 * 整体统计-学校
 */
export function getSchoolAlData(params) {
  return axios.get("/boardStatSchool/list", {
    params,
  });
}

/**
 * 课程热度排行
 */
export function getCourseHeat(params) {
  return axios.get("/heat/courseHeat", {
    params,
  });
}

/**
 * 年级下拉框数据
 */
export function getGradePropertyData() {
  return axios.get("/statistics/grade");
}

/**
 * 科目下拉框数据
 */
export function getSubjectPropertyData() {
  return axios.get("/statistics/subject");
}

/**
 * 科目热度统计
 */
export function getSubjectHeat(params) {
  return axios.get("/heat/subjectHeat", { params });
}

/**
 * 获取区的json文件
 */
export function getJsonCodeData(code) {
  return axios.get(`/location/ad/${code}`);
}

/**
 * 获取区域的下拉框数据
 */
export function getAreaStaticData(orgId) {
  return axios.get(`/statistics/area?orgId=${orgId}`);
}

/**
 * 获取学校的下拉框数据
 */
export function getSchoolStaticData(params) {
  return axios.get("/statistics/school", { params });
}

/**
 * 导出备授课时长排行 左边下方
 */
export function exportBskStatisticsData(params) {
  return axios({
    method: "GET",
    url: "/statistics/bskExport",
    params: params,
    responseType: "blob",
  });
}

/**
 * 导出课程热度排行 右边中间
 */
export function exportCourseHeatData(params) {
  return axios({
    method: "GET",
    url: "/heat/exportCourseHeat",
    params: params,
    responseType: "blob",
  });
}

/**
 * 导出学生每天上课市场 右边下方
 */
export function exportDurationData(params) {
  return axios({
    method: "GET",
    url: "/duration/export",
    params: params,
    responseType: "blob",
  });
}
