import "./core/sso";
import Vue from "vue";
import "./plugins/utils";
import "./plugins/axios";
import "normalize.css";
import "./plugins/element";
import "./assets/less/index.less";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./core/permission";
import "./filters/filters";
import "./plugins/cookie";
import "./plugins/dateFormat";
import "./plugins/dict";
import "./components/global/index";
import "@/utils/check-any";
import "@/assets/less/style.scss";
import { initState } from "./core/init";
// import { checkInnerNetwork } from './core/network';
import ECharts from "vue-echarts/components/ECharts";
import dataV from "@jiaminghi/data-view";
import echarts from "echarts";
// 按需引入vue-awesome图标
import Icon from "vue-awesome/components/Icon";
import "vue-awesome/icons/chart-bar.js";
import "vue-awesome/icons/chart-area.js";
import "vue-awesome/icons/chart-pie.js";
import "vue-awesome/icons/chart-line.js";
import "vue-awesome/icons/align-left.js";
import "vue-awesome/icons/question-circle.js";
// hover组件
import HoverComponent from "@/components/echart/hover/index";

// 引入多语言支持
import i18n from "./tools/i18n";

// import 'echarts/map/js/china';
// import china from "./common/map/china";
// echarts.registerMap("china", china);
/** 此处需要根据浏览器版本判断加载哪个 sdk */
// import * as WPS from "./static/web-office-sdk-v1.1.18.es";
// Vue.prototype.wps = WPS;
// ES6 模块化规范 引入 wps
import WebOfficeSDK from "./static/web-office-sdk-solution-v2.0.5.es";
Vue.prototype.wps = WebOfficeSDK;

import console from "Console";
require("../public/static/aliplayercomponents-1.0.6.min.js");
import "default-passive-events";
import btn from "@/directive/btnPermission";
import bskPermission from "@/directive/checkBSKPermission";
import animate from "animate.css";
Vue.use(bskPermission);
Vue.use(animate);
Vue.use(btn);

Vue.prototype.console = console;

Vue.prototype.$echarts = echarts;

// 注册全局组件
Vue.component("e-charts", ECharts);
Vue.component("icon", Icon);
Vue.component("hover-component", HoverComponent);
Vue.use(dataV);

// process.env.VUE_APP_CHECK_INNER_NETWORK === '1' && checkInnerNetwork();  // 检查网络
initState(); // 初始化

Vue.config.productionTip = false;

// chrome 109 预计在 2023-01-10 正式发布，届时会全面禁用 Event.path，所有源码中使用该属性或第三方库使用该属性的前端项目都可能会出现异常
Object.defineProperty(Event.prototype, "path", {
    get() {
        return this.composedPath();
    },
});

new Vue({
    router,
    store,
    i18n, // 挂载i18n
    render: (h) => h(App),
}).$mount("#app");
