// 1、引入ElementUI组件的语言包
import enLocale from "element-ui/lib/locale/lang/en";
// 2、引入一个Javascript对象深层( 递归) 合并库
import deepmerge from "deepmerge";
// 3、项目中自定义语言包
var enM = {
    el: {
        home: {
            teachingPreparation: "Teaching preparation",
            teachingManage: "Teaching management",
            billboard: "Billboard",
            backEndManagement: "Back-end Management",
            welcomeAIClass: "Welcome to AI Cloud class!",
            myCenter: "My center",
            classManagement: "Class management",
            logOut: "Log out",
            backTop: "Back to the  top",
            noSuggestCourse: "No recommended courses now.",
            hello: "Hello!",
            schoolDivision: "School/Division:",
            mySchedule: "My schedule",
            myCourses: "My courses",
            updatePwd: "Change the password",
            browserUpdateDesc:
                "The browser version is too low. To ensure effective use, please upgrade the browser",
        },
        courseDetails: {
            courseDetails: "Course details",
            types: "Types:",
            details: "Details",
            content: "Content",
            total: "Total",
            keyPoints: "key points",
            prepareLessons: "Prepare lessons",
            teachingLessons: "Teaching lessons",
            toTeachingLessons: "To teaching lessons",
            Subject: "Subject:",
            Grade: "Grade:",
            Semester: "Semester:",
            noPermissionCourse: "No permission for this course",
            applicationCourse: "Application for Course",
            noCourseId: "No id for this course",
            noPermissionCourseContact:
                "You do not have permission for this course at the moment. Please contact the school's academic affairs department.",
            keyPointsName: "Knowledge point name",
        },
        myTimeTable: {
            weeklySchedule: "Weekly schedule",
            lastWeek: "Last week",
            nextWeek: "Next week",
            Courses: "Courses:",
            Class: "Class:",
            Time: "Time:",
            Teacher: "Teacher:",
            Classroom: "Classroom:",
            View: "View",
            timeDate: "Time/Date",
            stop: "The course has been discontinued and cannot be taught.",
        },
        routerName: {
            homePage: "HomePage",
            myCourse: "My courses",
            courseList: "Course list",
            courseDetails: "Course details",
            myTimeTable: "My schedule",
            myCenter: "My center",
            classManage: "Class management",
            studentManage: "Student management",
            manageCourseClass: "Course class",
            authTip: "Permission prompts",
            resourceTip: "Resource prompts",
            teaching: "Teaching lessons",
            classRoom: "Teaching preparation",
        },
        common: {
            goUpgrade: "Go Upgrade",
            totalname: "Total",
            pages: "Pages",
            items: "items",
            confirm: "Confirm",
            cancel: "Cancel",
            centerName: "Hailiang education",
            create: "Create",
            edit: "Edit",
            add: "Add",
            save: "Save",
            operate: "Operate",
            pleaseEnter: "Please enter",
            pleaseSelect: "Please select",
            TeachingClass: "Teaching class",
            search: "Inquire",
            reset: "Reset",
            delete: "Delete",
            import: "Import",
            back: "Back",
            submit: "Submit",
            refresh: "Refresh",
            systemError: "System error",
            noResources: "No resources",
            knowledgeFirst: "Please select knowledge points first",
            learned: "learned",
            noData: "No  data",
            pageMissing: "Page missing",
            isNoPageExist:
                "The page you visited has an incorrect address or does not exist.",
            backHomePage: "Back homepage ",
            isNoResourceExist: "Resources do not exist",
            noPerson: "No personnel data is available",
            deleteConfirm: "Delete confirmation",
            reminder: "Reminder",
            pageUp: "Page up",
            pageDown: "Page down",
            redirectTo: "Redirect to",
            chooseGrade: "Please choose grade",
            chooseSubject: "Please choose subject",
            previousStep: "The previous step",
            nextStep: "Next step",
            submitSuccessfully: "Submitted successfully",
            cannotEmpty: "Description cannot be empty",
            reload: "Reload",
            chooseKnowledge: "Choose Knowledge",
            Monday: "Monday",
            Tuesday: "Tuesday",
            Wednesday: "Wednesday",
            Thursday: "Thursday",
            Friday: "Friday",
            Saturday: "Saturday",
            Sunday: "Sunday",
            week7: "week 7",
            week8: "week 8",
            week9: "week 9",
            week10: "week 10",
            week11: "week 11",
            week12: "week 12",
            week13: "week 13",
            week14: "week 14",
            copyRight:
                "Hailiang Education Group. All Rights Reserved. Zhejiang ICP Record No. 2022027462-1",
            copyRightNum:
                "Network Security Bureau of MOPS Zhejiang Record No. 33010802013010",
            language: "CN",
            supportPartVideo: "The current browser supports partial video playback",
            notSupportVideoPlay: "The current browser does not support video playing",
            notSupportCanvas: "The current browser does not support Canvas",
            overMaxFileSize: "The file size is greater than",
            importErrorMsg:
                "Check errors occur when importing data. Do you want to download the error information file?",
            importError: "Import error",
            onlyAdmit: "Only",
            files: "files can be uploaded",
            notFormElement: "The bound element is not a file upload form element",
            returnError: "Server return exception！",
            elementLoading: "Loading",
            errorServer: "Failed to obtain server information！",
            mobilePreviewError:
                "You are advised to use a computer browser to access  better experience.",
            errorWords: "Error",
            noIntroduction: "No introduction",
            supportVideoPlayNot:
                "This video can't be played right now. Please try again later",
            loggingOut: "Logging out",
            view: "To view",
            download: "download",
            to: "To",
            startTime: "Start time",
            endTime: "End time",
            type: "type",
            coursesArrangingManagement: "Courses arranging management",
            CourseRecommendations: "Course Recommendations",
            infrastructure: "Infrastructure",
            schoolResourcesManagement: "The school resources management",
            dataAnalysis: "The data analysis",
            orderManagement: "Order Management",
            screening: "Screening",
            export: "Export",
            new: "Add",
            startDate: "Start date",
            endDate: "End date",
            colon: ":",
            saveSuccessfully: "Save successfully",
            saveFailed: "Save Failed",
            prompt: "prompt",
            enable: "enable",
            disable: "disable",
            NoLogging: "No logging",
            NoRecord: "No record",
            Exportschedule: "Export the curriculum schedule",
            Saving: "Saving...",
            CheckFormCorrectly: "Please check the form is filled in correctly",
            NoDecimalsnegativeNumbers: "No  decimals and negative numbers",
            ChangeClass: "Change class",
            UploadsDownloads: "Uploads and downloads",
            UploadDownload: "Upload, download",
            resourceUse: "Resource usage",
            AllowUpload: "Allow upload",
            AllowDownload: "Allow download",
            AllowCopy: "Allow replication",
            OnlyUpload: "Only upload",
            JustDownload: "Just download",
            None: "None",
            FunctionalRole: "Functional role",
            AuthorizationRemind: "Authorization to remind",
            DelegateTeacher:
                "Make sure to delegate more than one subject to the teacher ?",
            ProtectIntellectualProperty:
                "* To protect intellectual property rights, please authorize according to actual needs",
            SelectAll: "Select all",
            NoCourse: "No course",
            SelectSchoolDivision: "Please select your school/division",
            SelectSchoolDivision2: "Please select school/division",
            IncompleteCoursePermissions:
                "Incomplete course permissions: grade and subject permissions need to exist at the same time  ",
            importedWait: "The list is being imported. Please wait. ",
            EnterStudentID: "Please enter  student ID",
            Number: "Number",
            width90Auto: "auto",
            width90_120: "120px",
            width100_160: "160px",
            width120_220: "220px",
            width120_240: "240px",
            width220_340: "340px",
            widthP40_50: "50%",
            name: " name",
            AutoSaving: "Auto saving...",
            deleteData: "Do you want to delete this data?",
            numberPeople: "",
            numberOf: "number of ",
            open: "Open",
            Group: "Group",
            teacherName: "Teacher name",
            Course: "Course",
            noLess10M: "Not less than 10 minutes",
            noLess30M: "Not less than 30 minutes",
            cumulativeDuration: "Cumulative duration",
            minutes: " min",
            dataStatistics: "The data of the day is counted every hour",
            accumulate: "Accumulate",
            averageDaily: "Average daily",
            closeDialog: "Close the dialog",
            unableLoad: "Unable to load, playback is not supported!",
            querySuccess: "Modified successfully",
            Custom: "Custom",
            Use: "Use",
            expire: " Expire",
            ModifyExpiration: "Modify Expiration",
            RemoveCourse: "Remove course",
            CancelEditing: "Cancel editing",
            onTrial: "on trial",
            No: "No",
            noResource: "No resources",
            noTaskData: "There are currently no course enrollment tasks.",
            showDetail: "details",
            source: "Source",
        },
        chatgpt: {
            chatgptTitle: "Youyou - Teaching Assistant",
            InternalTestVersion: "Beta",
            NumberResourcePoints: "Number of resource points:",
            chatDialogTips:
                "* The content of the dialogue is output by artificial intelligence models, the accuracy and completeness of its content cannot be guaranteed, and it does not represent our attitude or opinions.",
            Send: "Send",
            Answering: "Answering...",
            ChatTips:
                "5000 resource points per person per day. Asking/answering will consume resource points. The consumption rules are basically: 1 Chinese character consumes about 3 resource points, and 1 English letter/punctuation mark/number consumes about 1 resource point; The actual consumption will vary according to the type and length of the content.",
            UsageLimit:
                "The usage of resource points has reached the upper limit today",
            EnteKnow: "Please enter what you want to know",
            ClickConversation: "Click to chat~",
            Regenerate: "Regenerate",
            ReadMore: "Read more",
            YouyouAssistant: "Hi~ I am Youyou, your intelligent teaching assistant.",
            AskMeQuestions:
                'You can ask me questions in the way of "role played + description of the scene + what to do", for example:',
            Example1:
                "You are now a primary school teacher, ready to design a classroom activity outline that can expand students' thinking, and can appropriately use some items in the classroom as props.",
            Example2:
                "You are a teacher who participates in after-school service activities. Now that the course is over, you need to write a summary of the after-school service course, describing it in detail from the perspective of implementation, curriculum, and teacher guidance.",
            Example3:
                "You are a primary school teacher, and tomorrow you will give students a class on party history for teenagers. Now you need to summarize the major meetings in the development of the Communist Party of China and explain the content and impact of the meetings.",
            limitSendMessage: "Answering, please send a message later",
        },
        axios: {
            serverError: "Server return exception",
            serverError401: "Login failed, please log in again!",
            serverError406:
                "Your account has been logged in on another device, the current device login is invalid, if you do not operate, please contact the administrator to reset the password",
            logoutTip:
                "You have been logged out, you can cancel to remain on the page, or log in again",
            confirmLogout: "Confirm to log out",
            reLogin: "Log in again",
            pathNoexit: "The requested address does not exist",
            serverError2: "Server exception error",
            netWorkError: "Network timeout. Please try again later!",
            initDataErrorLoad: "Initial data load failed!",
            reLoadData: "Reload data",
            loginPage: "Landing Pages",
        },
        pagination: {
            totalname: "Total",
            pages: "Pages",
            items: "items",
        },
        authDialog: {
            name: "Name",
            applicationTitle:
                "To apply for permission, please contact the teaching department/ teaching & research team",
            subjects: "Subjects",
            grades: "Grades",
            courseNames: "Courses",
            telephone: "Telephone",
        },
        courseList: {
            all: "All",
            total: "Total ",
            totalcourses: " courses",
            myCourses: "My courses",
            allGrade: "All grades",
            allSubject: "All the subjects",
            application: "Application for Course ",
            moreFilters: "More filters",
            lessFilters: "Less filters",
            enterCourseName: "Enter course name",
            subject: "Subject",
            grade: "Grade",
            semester: "Semester",
            totalCourses: "courses in total",
            noPermission: "No permission",
            noCourses: "There is no course at present.Please re-screen.",
            deleteSchedulingTask: "Do you want to delete this scheduling task",
            deleteSchedulingTask1:
                "Do you want to delete this course scheduling task? After deleting, all the courses included in this course scheduling will be deleted",
            CourseExpirationTime: "Course expiration time",

            MaximumAuthorizedDate:
                "Maximum authorized date: the expiration date of the educational administration's own course authority. If multiple courses are selected, it is the earliest expiration date.",
            MaximumAuthorized: "Maximum authorized date",
            selectOperate: "Please select the course to operate",
            SelectedCourses: "Selected courses",
            sureSelectedCourse: `Are you sure you want to remove the selected {0} course`,
            ModifyExpirationTime: "Modify expiration time",
            sureDeleteBatch: `Are you sure to delete {0} course in batch`,
            BatchPermissionsSucceeded: "Batch configuration permissions succeeded",
            SuccessfullyDeletedBatch: "Successfully deleted permissions in batch",
            allCourse: "All courses",
            formalCourses: "Formal courses",
            trialCourses: "Trial Courses",
            courseType: "Course Category",
            courseSource: "Source Type",
            courseSourceName: "Source Name",
            coursePrice: "Price",
        },
        MyHome: {
            MyHomePage: "My homepage",
            classManage: "Class Management",
            courseClass: "Course class",
            hello: "Hello, teacher",
            depart: "School/Department",
            identity: "Status",
            normalUser: "Status：Common user",
            myRecord: "My records",
            introduction: "Introduction",
            sumDays: "Accumulated days of use",
            day: "days",
            dataComputed:
                "Preparation that is too short or long time  not performed is not counted as the validity period, and the data on the current day can be delayed up to 1 hour.",
            loginTips:
                "Once login will be  considered  used the platform on the same day",
            preparaHours: "Total effective preparation time",
            effectiveHours: "Accumulated effective teaching hours",
            hours: "hours",
            minutes: "minutes",
            uploadNum: "The number of courseware individually uploaded and edited",
            uploadAndCreate: "Upload and re-create",
            materials: "materials",
            teachingNum:
                "Number of group resources used for lesson preparation or teaching",
            groupNumbers: "View the total number of group resources",
            the: "",
            likeNum: "The number of likes you share with the school",
            likeReceive: "Likes received",
            recentUsage: "Recent usage",
            prepareValidate:
                "Lesson preparation that is too short or not performed for a long time is not included in the validity period. Data on the current day can be delayed by up to 1 hour and the data in lesson preparation is not included in the validity period",
            today: "",
            todayTimes: "Effective lesson preparation time of today",
            lastedMonth: "",
            compare: "Comparative growth",
            equal: "Equal",
            effectiveValidate:
                "Lessons that are too short or not operated for a long time are not counted as the effective time, and the data on the day is delayed by up to 1 hour and the data in the course is not counted.",
            todayEffectiveHours: "Effective teaching time today",
            binding: "Binding",
            unBinding: "Unbinding",
            Bound: "Bound",
            WeChat: "WeChat",
            BindWeChat: "Bind WeChat",
            toWeChatBind: "Open WeChat and scan to bind",
            Cancel: "Cancel",
            Sure: "Determine",
            ChangeWechatbinding: "Change WeChat account binding",
            BindingSuccessful: "Binding successful!",
            UnbindWeChat: "Unbind WeChat",
            UnbindSuccessfully: "Unbind Successfully!",
            SureUnbindDesc:
                "After unbinding, you will no longer be able to log in to the current account using this WeChat account. Are you sure you want to unbind?",
        },
        courseClassManage: {
            classIntro:
                "Class - students can be randomly asked  and called the roll during",
            studentManage: "Students Management",
            class: "Class",
            course: "Course",
            school: "School",
            stuNumber: "Number of students",
            keywordPlaceholder:
                "Please enter the student's name/ID/mobile phone number",
            placeholderWidth: "410px",
            addStudent: "Add students",
            remove: "Remove",
            studentID: "Student ID",
            studentNumber: "Student No.",
            schoolName: "School",
            gradeName: "Belongs to grade",
            schoolClassName: "Administrative class",
            currentClass: "Current class",
            createTime: "Date of registration",
            studentRules: "Please select a student",
            classRules: "Please select a class",
            chooseStudent: "Select a student",
            chooseClass: "Select a class",
            chooseInputLength: "140px",
            addSuccess: "Added successfully!",
            confirmThat: "Confirm that",
            confirmContext: "will be removed from this course？",
            reminder: "Reminder",
            removeSuccess: "Removed successfully！",
            cancelRemove: "Cancelled delete",
            studentManageNbsp: " Student management",
            studentName: "Student name",
            enterNameIDStudentID: "Please enter the student's name/ID/student ID",
            allStudents: "All the students",
            addClass: "Add the class",
            classMenuTitleWidth: "class-menu-title-120",
            determineName: "Determine the removal of this class, the class name:",
            callInClass: "Call in the class",
            mostStudentsNumber: "Most college students number",
            InClass: "Class",
            enterNameStudentID: "Please enter the student's name/student ID",
        },
        teaching: {
            selectCourse: "Select a courseware",
            selectClass: "Select a class",
            selectClassTips: "For random questions  and call the roll",
            selectNoClass: "No class created. Go to create one. ",
            noClass: "No class",
            fullScreen: "Full screen",
            exitFullScreen: "Exit full screen",
            exitClass: "Exit the class",
            randomQuestion: "Random questions",
            start: "Start",
            pause: "Pause",
            rollCall: "The roll call",
            switchFile: "Switch courseware",
            courseResources: "Course Resources",
            studentList: "Student list",
            draw: "Draw",
            clearPage: "Clear this page",
            undo: " Undo",
            recovery: "Recovery",
            clear: "Clear",
            startDraw: "Start drawing",
            endDraw: "End drawing",
            clearSuccess: "Clear successfully",
            confirmExit: "Confirm the exit",
            confirmExitSession: "Confirm the end of the session？",
            logout: "Log out",
            mine: "mine",
            add: "To add",
            chooseCourseWare: "Please choose courseware",
            loadingData: "Loading...",
            noCreateClass: "No class created",
            noSelectClass: "No class selected",
            gotoAdd: "Go to create one",
            exitSession: "The end of the session",
            hideWindow: "Hide window",
            showWindow: "Show window",
            startPng: "pic_button_start_en@2x.png",
            startPressPng: "pic_button_start_active_en@2x.png",
            pausePng: "pic_button_suspend_en@2x.png",
            pausePressPng: "pic_button_suspend_active_en@2x.png",
            noClassOrDelete: "The class does not exist or has been deleted",
            classNoStudent: "There are no students in the current class",
            resourceUnsupport: "This resource does not support lectures!",
            browserUnfullsreen: "Your browser does not support full screen",
            chooseBrowser: "Please change your browser.",
            pleaseChangeBrowser:
                "The current browser cannot function. You are advised to use Google Chrome ",
            looseParams: "The lesson preparation page can't open without parameters",
            dataLoadingFail: "Data loading failure",
            sessionFailQuestion:
                "This lesson preparation has not started or has been finished, please confirm!",
        },
        classManage: {
            classFunction:
                "Class - students can be randomly asked  and called the roll during teaching;The self built class is the class created by the teacher himself, and the authorized class is the class authorized by the educational administration",
            className: "Class name",
            classType: "Class type",
            stuNumber: "Number of students",
            createTime: "The date created",
            studentManage: "Student management",
            newClass: "New classes",
            addSuccess: "Added successfully",
            deleteSuccess: "Delete successfully",
            editClass: "Edit the class",
            enterClassName: "Please enter a class name",
            deleteClassTips:
                "Are you sure to delete the class and students at the same time?",
            editSuccess: "Edit successfully",
            editGrade: "Editing grade",
            addGrade: "Create grade",
        },
        studentManage: {
            studentMag1: "Student management of",
            studentMag2: "class",
            studentName: "Student's name：",
            studentNameN: "Student's name",
            downloadTemplate: "Download the import template",
            createTime: "Date created",
            addStudent: "Add students",
            editStudent: "Edit student information",
            deleteTips:
                "After deletion, this student will not be able to be selected by the function of random questions in the class, so please confirm.",
            deleteSuccess: "Delete successfully",
            addStuTips:
                "The name of the current student already exists. Please confirm whether to add?",
            backModify: "Back to modify",
            confirmAdd: "Confirm the addition",
            importProm: "Import prompts",
            hasSameStu:
                "The same student name exists in the table.Do you want to continue importing?",
            continueImport: "Continue importing",
            dontImport: "No import",
            importSameStu:
                "The same student name exists in the table.Do you want to continue importing?",
            addSuccess: "Added successfully",
            importSuccess: "Import Successfully",
            enterStudentName: "Please enter student name.",
            editStudentTips:
                "The current student name already exists. Are you sure to change it?",
            conformChange: "Confirm the changes",
            skipOrContinue:
                "The following students already exist, do you want to skip or continue to import:",
            skipRepeat: "Skip the repeat",
            editSuccess: "Edit successfully",
            fileTypes: "Only xls、 and xlsx files can be uploaded",
        },
        classroomWps: {
            teacherInstructions: "teacher's instructions",
            UnableToTeach:
                "Unable to teach without courseware. Please upload the courseware and try again!",
            UnableToCompare:
                "No courseware can not be compared, please upload courseware and try again!",
            selectToStart: "Please select a file before starting the course!",
            shift: "Shift",
            courseware: "Courseware",
            lessonPlan: "Lesson plan",
            classmaterials: "Class materials",
            preview: "Preview before class",
            lessonVideo: "Video for preparing lessons",
            classroomRecord: "Classroom record",
            worksheet: "Worksheet",
            homework: "Homework",
            classMsg: "Class management",
            groupResource: "Group resource",
            schoolResource: "School resource",
            myResourse: "My resourse",
            switchTip: "Click here to switch courseware",
            less: "Less",
            more: "More",
            lists: "list",
            viewAll: "View all ",
            myModify: "Modify",
            modify: "Modify",
            like: "Like",
            download: "Download",
            shareToSchool: "Share to school",
            mores: "More",
            rename: "Rename",
            reupload: "Reupload",
            supportingCourseware: "Supporting courseware",
            supportingLessonPlan: "Supporting  lesson plan",
            uploadPPT: "Upload my PPT",
            uploadLessonPlan: "Upload my lesson plan",
            uploadProfile: "Upload my profile",
            uploadResource: "Upload my resource",
            uploadMaterials: "Upload my materials",
            closeList: "Close list",
            openList: "Open list",
            uploading: "Uploading",
            uploaded: "Uploaded",
            uploadedSuccess: "File uploaded sucessfully",
            sameResourceTips:
                "A resource with the same name exists in this chapter. Please modify.",
            renameCourseware: "Rename",
            coursewareName: "Name",
            noLessonPlan:
                "Currently there is no lesson plan, and can not share with the school.",
            noLessonPlanCourseware:
                "When sharing the resources to the school, you need to submit the teaching plan and courseware at the same time.There is no lesson plan to submit. Please go to the lesson plan category to add!  ",
            print: "Print",
            creatorsCenter: "Creators Center",
            inviteMsg:
                "We sincerely invite you to join our course development. Please describe your ideas or suggestions in detail.  Picture paste  is supported.",
            startTeaching: "Start teaching",
            inFullScreen: "Full screen",
            compareLessonPreparation: "Compare lesson preparation",
            exitFullScreen: "Exit full screen",
            ESCexitFullScreen: "Press Esc to exit full screen",
            canotPreview: "The material cannot be previewed",
            usePlatform: "Use platform courseware for lesson preparation",
            usePersonal: "Use personal courseware for lesson preparation",
            sharetoSchool:
                "Share to the school, can be viewed by the teachers of the teaching and research group",
            verification: "Under verification",
            tobemodified: "to be modified",
            passed: "passed",
            backModify: "Return to modify",
            verifyOpinion: "verify opinion",
            shareSchool1: "Share to school, ",
            shareSchool2: "subject teachers can view and use",
            confirmDeleteClass:
                "Are you sure to delete the class and delete the class students at the same time?",
            verifier: "verifier",
            noVerifyOpinion: "no verify opinion at present",
            gotIt: "Got it.",
            howtoTitle: "How do you use centralized lesson preparation?",
            sharetoSchoolinfo:
                "When sharing the resources to the school, the supporting teaching plan and courseware should be submitted at the same time. Please select the supporting resources from the following files:",
            sharetoSchoolTips:
                "Resources shared to the school will not be withdrawn, please carefully check the lesson plan and courseware",
            sharetoSchoolError:
                "There is no courseware at present, and can not share to school!",
            sharetoSchoolneed:
                "When sharing the resources to the school, you need to submit the supporting teaching plan and courseware at the same time. There is no courseware to submit, please go to the courseware category to add!",
            backtoedit: "Are you sure you want to return the changes?",
            backTips:
                "The lesson plan and courseware can be modified after returning. To share with the school, you need to submit it again.",
            noDownloadPermisssion: "No download permissions",
            noUploadPermisssion:
                "Your account is temporarily without uplaod permission. If necessary, please apply for administrator!！",
            cannotBatch: "The browser does not support batch uploading of files",
            uploadFail: "Failed to upload files.",
            cannotVideo: "Uploading videos is not supported",
            selectFile: "Please select file",
            filenoContent: "The file has no content. Please select it again",
            turnPersonplan:
                "A personal lesson plan has been converted for you, and you can modify the personal lesson plan",
            turnPersonCourseware:
                "A personal courseware has been converted for you, and you can modify the personal courseware",
            turnPersonresource:
                " A personal classroom resource has been converted for you.",
            enterfileName: "Please enter a file name",
            fileRecall: "The file has been withdrawn",
            thanksShare: "Thank you for your sharing!",
            togetherShare:
                "Now other teachers of  the lesson preparation team can be informed to view together.",
            cancelShare:
                "After the cancellation, other teachers in the lesson preparation group will unable to check.Still cancel?",
            cancelsuccess: "successfully canceled the sharing",
            sametype: "Only files of the same type can be uploaded",
            cancelUpload: "Cancel the upload",
            cancelPersonal:
                "Personal resources can not be used after deletion. Sure to delete?",
            deleteSuccess: "delete successfully",
            notSupportPreview:
                "This type of resource does not support preview temporarily!",
            selectResourcesCompare: "Please select the resources to compare",
            currentResource: "Current Resources",
            changeResource: "Switching resources",
            sureComparison: "Are you sure to exit the comparison?",
            ExitControl: "Exit control",
            oldLinkTip:
                "The link has expired and will automatically return to My Course. Please save the link again to avoid affecting the use",
        },
        symbol: {
            period: ".",
            comma: ",",
            colon: ":",
            semicolon: ";",
            exdamation: "!",
            questionMark: "?",
        },
        schoolResourceManage: {
            schoolResourcesManagement: "The school resources management",
            teacherName: "The teacher's name",
            workNumber: "Work number",
            school: "school",
            subjects: "subjects",
            grade: "grade",
            teacher: "teacher",
            scienceBooks: "Science books don't",
            chapter: "chapter",
            resourceState: "Resource state",
            all: "all",
            online: "online",
            offline: "offline",
            sectionName: "The name of the section",
            resourceName: "The name of the resource",
            operator: "Operation of",
            operateTime: "Operating time",
            crossSchoolShared: "Cross-school Shared",
            log: "The log",
            crossSchoolSharedWhether: "Whether confirm cross-school sharing?",
            crossSchoolSharedDesc: `Cross-school after sharing, resources will be displayed in the "resources" group, visible to all users oh ~ `,
            note: "Note",
            no: "no",
            audit: "Audit",
            auditmanagement: "Audit management",
            auditStatus: "Review the status",
            toAudit: "To audit",
            returnToModify: "Return to modify",
            approved: "approved",
            auditor: "The reviewer",
            auditDate: "Audit date",
            auditTime: "Audit time",
            auditRecords: "Audit records",
            submitAudit: "Submit audit",
            approvedAndOnline: "Approved and online",
            shutDown: "Shut down",
            crossSchoolSharingRecords: "Cross-school sharing records",
            crossSchoolSharedTime: "Cross-school Shared date",
            derivedForm: "Derived form",
            crossSchoolSharer: "Cross-school sharing operation",
            Highlight: "Highlight",
            CancelHighlight: "Cancel highlight",
            AutoReturn: "Auto-return ",
        },
        dataAnalysis: {
            DepartmentName: "Department Name",
            dataAnalysis: "The data analysis",
            today: "Today",
            nearly7days: "Nearly 7 days",
            nearly30: "Nearly 30",
            schoolOrDepartment: "School/department",
            unit: "unit",
            dateRangeDesc:
                "Date query range cannot be more than 365 days, please choose again",
            day: "day",
            weeks: "weeks",
            month: "month",
            toTop: "Back to the top",
            usageStateAnalysis: "Usage analysis",
            usageAnalysis: "Usage analysis",
            usageAnalysisDesc: [
                "1, platform usage = login a platform on the day of the school teachers/school teachers account for that day",
                "2, available on the day of preparation utilization rate = lessons record the number of teachers/school teachers account for that day",
                "3, teaching, school effective utilization rate = day record the number of teachers/school teachers account for that day",
                "Note: data platform lectures, lessons from June 4, 2020, October 27, 2020, started to record. The data count every hour in the day.",
            ],
            platformUsage: "Platform usage",
            platformUseNum: "Platform using the number",
            preparingUsage: "Preparing the usage",
            preparingUseNum: "Prepares a lesson using the number",
            teachingUtilization: "The teaching utilization",
            teachingUtilizationNum: "Taught to use the number",
            usageTimeAnalysis: "The per capita use duration analysis",
            usageTimeAnalysisDesc: [
                "1, the per capita lessons every day (taught) length = effective on the day of the school lessons (teaching) the sum of the length/school teachers account for that day",
                "2, weekly lessons (taught) length = per capita per capita lessons every day this week (teaching) the sum of the length",
                "3, monthly per capita lessons (taught) length = per capita lessons every day this month (teaching) the sum of the length",
                "Note: data platform lectures, lessons from June 4, 2020, October 27, 2020, started to record. The data count every hour in the day.",
            ],
            perPreparationTime: "The per capita preparation time",
            perteachingTime: "Teaching hours per capita",
            unuseSystemTeacher: "Period did not use teacher preparation system list",
            unuseSystemClientTeacher:
                "Period did not use the teacher teaching system",
            unuseSystemTeachingTeacher:
                "Period did not use the teacher teaching system",
            teachingDailyRacking: "Teachers' teaching time ranking",
            teachingDailyRackingDesc: [
                "1, teachers' average daily lessons (taught) statistical cycle length = teacher's effective preparation (taught) days time/statistics",
                "2, the entire school average school lessons (taught) length = statistical cycle per person a day lessons (teaching) the sum of the length/statistical number",
                "Note: data platform lectures, lessons from June 4, 2020, October 27, 2020, started to record. The data count every hour in the day.",
            ],
            unfoldAllRecords: "View full list",
            foldAllRecords: "Hide full list",
            preparationTimeOnAverage: "All the preparation time on average",
            teachingTimeOnAverage: "The school teaching hours on average",
            preparationDailyTime: "The preparation time",
            teachingDailyTime: "The teaching time",
            topTen: "The top ten",
            afterRanking10: "After ranking 10",
            preparationAnalysisEachGrade: "Each grade - subject preparation analysis",
            preparationAnalysisEachGradeDesc: [
                "1, according to the preparation course's grade, subject, such as A teacher prepares A lesson in grade one, grade two language, respectively in first grade, second grade language of statistics.",
                "Note: data platform lectures, lessons from June 4, 2020, October 27, 2020, started to record. The data count every hour in the day.",
            ],
            preparingTime: "Preparing time",
            teachingAnalysisEachGrade: "Each grade - subject teaching analysis",
            teachingAnalysisEachGradeDesc: [
                "1, according to the teaching course's grade, subject, such as A teacher to teach A Chinese, the language, the second grade grade respectively statistics in first grade, second grade language.",
                "Note: data platform lectures, lessons from June 4, 2020, October 27, 2020, started to record. The data count every hour in the day.",
            ],
            teachingTime: "The teaching time",
            chooseContrastRange: "Choose contrast range",
            selectGrade: "Please select a grade",
            selectSubject: "Please select a subject",
            toContrast: "To contrast",
            selectContrastRange: "Please select contrast range",
            preparingTimeContrast: "The preparing time contrast",
            preparingTimeContrastDesc: [
                "1, teachers of statistics in the query, the subject of the course grade preparation time",
                "Note: data platform lectures, lessons from June 4, 2020, October 27, 2020, started to record. The data count every hour in the day.",
            ],
            preparationTime: "The preparation time",
            TeachingTimeContrast: "The teaching time",
            TeachingTimeContrastDesc: [
                "1, statistics teachers are grade, subject curriculum teaching hours",
                "Note: data platform lectures, lessons from June 4, 2020, October 27, 2020, started to record. The data count every hour in the day.",
            ],
            standardCourseware: "A standard courseware",
            standardCoursewareDerivative: "The standard courseware",
            personalCourseware: "Personal courseware",
            personalCoursewareDerivative: "Personal courseware derivative",
            curriculumTeachingUtilization: "Curriculum teaching utilization",
            curriculumTeachingUtilizationDesc: [
                "1 = choose courses, course teaching utilization, teachers teaching in statistical period the number of knowledge points/total number of curriculum knowledge",
                "Note: data platform lectures, lessons from June 4, 2020, October 27, 2020, started to record. The data count every hour in the day.",
            ],
            selectCoursesToAnalysis: "Select courses to analysis",
            thumbUpNumber: "The number of thumb up",
            downloadsNumber: "The number of downloads",
            downloads: "downloads",
            classesNumber: "Number of classes",
            citations: "citations",
            TheTotal: "The total",
            SchoolAverage: "School average",
            DetailsOfCourse: "Details of the duration of the preparation course",
        },
        order: {
            orderList: "Order List",
            orderDetail: "orderDetail",
            refundApplication: "Refund Application",
            refundDetail: "refundDetail",
            orderNo: "orderNo",
            createTime: "createTime",
            courseName: "courseName",
            shoukuanshanghu: "merchant",
            registeredStudents: "registeredStudents",
            grade: "grade",
            class: "class",
            userPhoneNumber: "userPhoneNumber",
            dingdanjine: "Order Amount",
            shifujine: "Actual Amount",
            yuan: "(CNY)",
            refundAmount: "refundAmount",
            orderStatus: "orderStatus",
            autoreject:
                "Refund application that has not been processed for 7 days will be automatically reviewed and rejected",
            refundNo: "refundNo",
            applyTime: "applyTime",
            refundStatus: "refundStatus",
            orderInfo: "orderInfo",
            payBy: "payBy",
            payTime: "payTime",
            studentNo: "studentNo",
            courseInfo: "courseInfo",
            orderItemNo: "orderItemNo",
            refundInfo: "refundInfo",
            refundReason: "refundReason",
            refundTime: "refundTime",
            changeReason: "changeReason",
            failReason: "failReason",
            refundAudit: "refundAudit",
            auditResult: "auditResult",
            pass: "pass",
            noPass: "noPass",
            reason: "reason",
            passed: "passed",
            noPassed: "noPassed",
            changeRefundAmount: "changeRefundAmount",
            emptyNotice:
                "There is no refund amount data set. Please fill it out completely and submit it",
            zeroNotice:
                "The refund amount cannot be 0 yuan. Please modify the refund amount",
            settlement: "结算管理",
            settlementNumber: "结算单号",
            settlementTimeInterval: "结算时段",
            settlementObjType: "结算对象类型",
            settlementObj: "结算对象",
            settlementStatus: "结算状态",
            settlementObjName: "结算对象名称",
            orderTotalMoney: "订单总额",
            orderTotalRefund: "退款总额",
            settlementMoneyNeed: "需结算金额",
            settlementMoneyUsed: "实际打款金额",
            settlementSubmit: "提交结算",
            settlementRecord: "结算凭证",
            view: "查看订单",
        },
        planCourse: {
            totalSchedule: "The total schedule",
            TheClassSchedule: "The class schedule",
            TheTeacherSchedule: "The teacher schedule",
            TheClassroomSchedule: "The classroom schedule",
            classSchedule: "Class schedule",
            arrangeClass: "Arrange class",
            courseManagement: "Course management",
            selectCourses: "Course Management",
            classManagement: "Class management",
            school: "School",
            course: "Course",
            selectSchool: "Please select a school",
            selectCourse: "Please select a course",
            courseClass: "Course class",
            class: "Class",
            teacher: "Teacher",
            selectTeacher: "Please select a teacher",
            classroom: "The classroom",
            chooseClassroom: "Please choose the classroom",
            periodTime: "Period of time",
            to: "to",
            startTime: "The start time",
            endTime: "The end of time",
            selectTimeRange: "Please select a time range",
            noArrangement: "No arrangement",
            view: "View",
            delete: "Delete",
            courseName: "Course name",
            enterScheduleName: "Please enter the schedule name",
            arrangingDate: "Arranging the date",
            creationDate: "Creation date",
            founder: "Founder",
            selectFounder: "Please select a founder",
            state: "State",
            all: "All",
            courseArranging: "Course arranging",
            completed: "Completed",
            operateWidth: "200px",
            scheduleView: "View schedule",
            schedulePreview: "Schedule a preview",
            changeSetting: "Change the setting",
            complete: "Complete",
            allCourses: "All courses",
            allTeachers: "All the teachers",
            allClass: "All the class",
            infrastructure: "Infrastructure",
            teachingPlan: "Teaching plan",
            numberWeek: "Number of days per week:",
            circulationRules: "Circulation rules:",
            oneWeekIterations: "One-week iterations",
            twoWeekIterations: "Double-week iterations",
            classTime: "Class time:",
            classTimeNo: "Class time",
            editTime: "Edit time",
            deleteClassTime: "Whether you want to delete this class time?",
            pleaseSelectWeekDay: "Please select a number of days per week",
            selectTimeClass: "Please select a time for class",
            pleaseSelectRowCourse: "Please select a row of course",
            pleaseSelectTimePeriod: "Please select a time period",
            rowCourse: "For row of course:",
            choose: "choose",
            add: "add",
            chooseTime: "Choose time",
            newPeriodTime: "The new period of time",
            CourseName: "Course name",
            expirationDate: "Expiration date",
            instructor: "The instructor",
            generateSchedule: "To generate the schedule",
            beingGeneratedSchedule: "Being generated schedule...",
            maximumNumberSection: "Maximum number line section is",
            pleaseSelectCourse: "Please select course",
            confirmDelete: "Confirm delete",
            confirmDeleteCourse: "Confirm delete this course",
            addTeachingPlan: "Please add teaching plan",
            forAWeek: "For a week",
            trainingSession:
                "For a week for this class curriculum arrangement of weekly training session,",
            periodLength: "Each time about choosing course period length",
            pleaseFillWeek: "Please fill in for a week",
            scheduleGenerateSuccess: "Schedule to generate success",
            returnsList: "Returns a list of",
            checkSchedule: "Check the schedule",
            teachingMaterialVersion: "The teaching material version",
            classNumber: "The class number",
            curriculumPlaces: "Curriculum places",
            shifoushoufei: "Is Charge",
            registrationNumber: "Have registration number",
            w100_160: "160px",
            SelectClass: "Select a class",
            SelectState: "Please select a state",
            SelectcirculationRules: "Please select a circulation rules",
            SelectSchedulePeriod: "Please select a schedule period",
            enterNumberSessionsWeek: "Please enter the number of sessions per week",
            NoStudentMatched: "No student name was matched",
            StudentIDNotMatched: "The student ID is not matched",
            StudentIDExist: "This student ID does not exist",
            ContainsCharacters: "Contains 1 to 10 characters",
            ContainsCharactersCustom: "Contains 1 to {0} characters",
            CharactersCustom: "Contains {0} to {1} characters",
            MoreThan0: "Please fill in a number greater than 0",
            ChangeSuccessfully: "Class change successfully",
            MaximumNumberPeople: "Please select maximum number of people",
            SelectRange: "Please select a date range",
            totalScheduleByImport: "Schedule management",
            totalScheduleByImportLoading: "Course schedule is being imported...",
            scheduleImportSuccess: "imported successfully",
            importResult: "The result of the import",
            importResultData: `A total of  {0}  pieces of data,  {1}  were successfully imported and  {2}  were imported unsuccessfully; Please download the import results, view the part where the import failed, modify it, and import it again`,
            showImportRecord:
                "After closing, you can view it on the top menu [Operations Center]-Import record page.",
            downloadToView: "Download to view",
            affiliation: "Affiliation",
            academicYearAndSemester: "Academic year and semester",
            academicYearAndSemesterSelect:
                "Please select the academic year and semester",
            academicYear: "academic year",
            academicYearSelect: "Please select the school year",
            semesterSelect: "Please select semester",
            fallSemester: "fall semester",
            springSemester: "spring semester",
            summerVacation: "summer vacation",
            winterVacation: "winter vacation",
            teacherAttendance: "Teacher attendance",
            teachingClasses: "Teaching classes",
            teachingTime: "Class time",
            teachingDate: "Class date",
            statusOfAttendance: "Status of attendance",
            normal: "Normal",
            absenteeism: "Absenteeism",
            syncedTip:
                "Data for the day is updated hourly (only ended courses are synced)",
            bulkModification: "Bulk modification",
            modifyAttendance: "Modify attendance",
            AutomaticMatching: "Automatic matching",
            teacherTimeSchedules:
                "The current teacher has scheduled lessons for the same time period, please select it again",
            studentAttendance: "Student attendance",
            changeToNormal: "Change to normal",
            changeToNormalConfirm:
                "Confirm to modify the attendance status of this student to normal",
            changedToAbsence: "Change to absence",
            changedToAbsenceConfirm:
                "Confirm to modify this student's attendance status as absence",
            questionnaireManage: "Questionnaire Manage",
            questionnaireResult: "Questionnaire results",
            questionnaireName: "The name of the questionnaire",
            questionnaireDeleteConfirm:
                "Are you sure you want to delete this survey?",
            enterQuestionnaireName: "Please enter a questionnaire name",
            inProgress: "In progress",
            ended: "Ended",
            viewResults: "View the results",
            addQuestionnaire: "Add a questionnaire",
            editQuestionnaire: "Edit a questionnaire",
            Deadline: "Deadline",
            selectDeadline: "Please select the deadline",
            activityCourseType: "The type of intent activity course",
            requirementType: "The type of intent requirement",
            AddNewClass: "Add a new class",
            other: "other",
            otherIntentionalNeeds: "Other intentional needs",
            courseSatisfaction: "Satisfaction with the course",
            satisfaction: "Satisfaction",
            importedRecords: "Imported records",
            exportedRecords: "Export record",
            operationCenter: "Operation center",
            downloadFile: "Download a file",
            serialNumber: "Serial number",
            fileName: "File name",
            ToDownload: 'To download',
            DataOverviewExportInformation: 'The file is being exported. Please download the exported file from the top menu [Operations Center]',
            TeacherUsageStatistics: 'Teacher usage statistics',
            DataRefreshPrompt: 'The statistics are updated at 1: 00 a.m. every day',
            deleteRecordTip:
                "When you delete a record, the imported data is deleted synchronously, so be careful",
            importLocation: "import location",
            importFile: "import fileName",
            importTime: "import time",
            DownloadFailedData: "Download failed data",
            deleteRecordConfirm:
                "Delete this record, the corresponding imported data will be deleted simultaneously, are you sure you want to delete it?",
            teacherName: "Teacher's name",
            inGrade: "Grade",
            viewQuesResult: "Course satisfaction view",
            importing: "Questionnaire is being imported",
            teacherNameInput: "Please enter the teacher's name",
            selectFirst: "Please select data first",
            selectData: "{0} data selected",
            // --------w---------
            selectCouresTaskName: "Course Task Name",
            openingAcademicYear: "Academic Year of Classes",
            openingSemester: "Semester of Classes",
            selectCourseRange: "Course Range",
            selectCourseDate: "Course Time",
            kechengtuikuan: "Course Refund",
            yunxutuikuan: "Allow Refund",
            yunxushenqing: "Allow Apply",
            buketuikuan: "No Refund",
            numberOfCourses: "Number of Courses",
            academicYearSemester: "Academic Year and Semester",
            optionalCourses: "Selectable Courses",
            selectCourseButton: "Choose Courses",
            courseSource: "Source of the Course",
            coursePrice: "Price",
            applyGrade: "Applicable Grade Levels",
            courseCategory: "Course Category",
            numberOfOptionalClasses: "Number of Selectable Classes per Course",
            addSelectCourseTask: "Add Course Selection Task",
            updateSelectCourseTask: "Edit Course Selection Tasks",
            viewSelectCourseTask: "View Course Selection Tasks",
            registeredStudents: "Students Registering",
            userPhoneNumber: "User's Mobile Phone Number",
            refundTime: "Withdrawal Time",
            courseNameText: "Course Name",
            classPlaceholder: "Please Select A Grade First",
            deleteCourseSelectionTask:
                "Do you want to delete the current course selection task?",
            courseStateLabel: "Course Status",
            noChooseCourse: "There are currently no available courses available",
            isRemoveCourseListItem: "Are you sure to remove this course?",
            releaseDate: "Issue date",
            deadline: "Deadline",
            notStart: "Not started",
            releaseTime: "Distribution time",
            selectReleaseTime: "Please select distribution time",
            releaseRange: "Distribution range",
            selectReleaseRange: "Please select distribution range",
            questionnairCanntDelete:
                "Currently, parents have submitted the questionnaire and it cannot be deleted!",
            fillTime: "Time of completion",
            evaluationManage: "Evaluation management",
            evaluationTimes: "Number of evaluations",
            courseAverageScore: "Course average score",
            averageScore: "Average score",
            evaluationDetail: "Evaluation details",
            score: "{0} points",
            evaluationTime: "Evaluation time",
            appraiser: "Evaluator",
            courseRating: "Course rating",
            evaluationContent: "Course rating",
            rollCallCantCancel:
                "Students who have attended class cannot cancel their attendance status. If you need to make changes, please contact the education department.",
            confirmAttendance: "Confirm attendance",
            submitPerson: "Submitter",
            submitTime: "Submission time",
            attendanceNumber:
                "{0} people have arrived, {1} people have not arrived yet",
            rollCallSuccess: "Roll call successful",
            updateMidnight: "This data is updated at 0:00 every day",
            noRefundNotice:
                "Note: No refunds can be made after the end of the corresponding semester for the course class",
        },
        infrastructure: {
            teachersManagement: "Teachers management",
            authorizationTeachers: "authorization for teachers",
            TeacherBatchAuthorization: "Teacher batch authorization",
            BatchConfiguration: "Batch configuration",
            selected: "Selected",
            numberPeople: "",
            BatchAdd: "Batch Add",
            BatchDel: "Batch Delete",
            studentManagement: "Student management",
            timeManagementClass: "Time management in class",
            classroomManagement: "Classroom management",
            teacherNameMobile: "TeacherName/Mobile",
            mobilePhoneNumber: "Mobile phone number",
            idNumber: "certificate Number",
            mobilePhone: "Mobile phone:",
            functionalAuthority: "Functional authority",
            educationalAdministrator: "Educational administration - administrator",
            averageUser: "The average user",
            Regulator: "Regulator",
            coursePermissions: "Course permissions",
            authorization: "authorization",
            allowedModify:
                "The master administrator permissions are not allowed to modify!",
            userInformation: "The user information",
            teacherCannotModified:
                'The teacher as "educational administration - administrator", school/department cannot be modified',
            jurisdiction: "jurisdiction",
            whetherOpenAudit: "Whether open audit authority:",
            allowUploadPersonalResources: "allow upload personal resources",
            allowDownloadResources: "allow the download resources",
            allowCopyResources: "allow the copy text",
            student: " Student",
            withinSystem: "If students existing student ID within the system,",
            throughToAdd: "through the student ID to add",
            notStudentID: "Students are not student ID, ",
            createStudent: "to create a new student",
            accordingIDMatch: "According to the student ID to match",
            enterStudentID: "Please enter the student ID",
            gradeName: "Grade",
            enterGradeName: "Please enter the grade name",
            creationTime: "Creation time",
            inClass: "In the class",
            Transfer: "Transfer.",
            dropSchool: "Drop out of school",
            outSchool: "Out of school",
            graduation: "Graduation",
            numberCourse: "Number has been submitted to the course",
            RegisteredCourses: "Registered courses",
            enterCorrectName: "Please enter the correct student's name",
            enterCorrectStudentID: "Please enter the correct student ID",
            choosefirstGrade: "Please choose first grade",
            downloadTemplate: "Download the template",
            nameClass: "The name of the class",
            canAccommodateNumber: "Can accommodate the number of",
            accommodateNumber: "To accommodate the number of",
            enterNumberStudents: "Please enter the number of college students",
            enterNameClassroom: "Please enter the name of the classroom",
            newClassroom: "The new classroom",
            editClassroom: "Edit the classroom",
            confirmDeleteClassroom: "Confirm to delete this classroom?",
            WhetherLogoffResources:
                "Whether to confirm the logoff matching resources",
            resourcesNotVisible:
                "The resources are not visible to all teachers when offline.",
            logoffReasonLeaveResources:
                "Please enter the logoff reason or leave it, and the system will send it to the resources author.",
            WhetherOnlinedResources: "Whether to be onlined all matching resources?",
            ResourceWillAvailable:
                "Once the resource is online, it will be available to all teachers of the school.",
            MarkedRegenerate:
                "Marked as a conflict course, please modify the Settings and regenerate.",
            EducationalAdministrators:
                "Educational Administration - Master administrator",
            PermissionModifyInformation:
                "The sub-administrator has no permission to modify the information. Contact the primary administrator",
            ReselectCourseDate:
                "Choose the past time, please reselect course arrangement date",
            CoincidesTimePperiod:
                "This time period coincides with the selected time period. Please select again",
            idNumberError1:
                "The ID number is 18 digits. Please enter capital letters for the X at the end!",
            idNumberError2: "ID number Date of birth is wrong!",
            enterIdNumber: "Please enter ID number",
            IdNumber: "ID number",
        },
        board: {
            selectPanel: "Select the panel",
            platformPanel: "Platform panel",
            AreaPanel: "Area panel",
            SchoolPanel: "School panel",
            DataPanelHailiang: "Data panel of Hailiang after-school service platform",
            PlatformDataPanel: " After-school service platform data panel",
            TimeRange: "Time range",
            Nearly7days: "Nearly 7 days",
            Nearly30days: "Nearly 30 days",
            lastModifyTime: "Last updated:",
            NumberTeachers: "Number of participating teachers",
            NumberStudents: "Number of participating students",
            TotalCourses: "Total courses",
            TotalLessonPeriods: "Total lesson periods",
            TotalLessons: "Total Lessons",
            AllKnowledgePoints:
                "Total number of second-level knowledge points of all courses in the system",
            AllStudentsTaken:
                "Total number of courses that all students in the system have taken",
            AreaNumber: "Area number",
            SchoolNumber: "School number",
            usage: "usage",
            UsageTrendChart:
                "The trend chart of usage within the selected time range; ",
            PlatformUsageDesc:
                "Platform usage = Number of users that log in to the platform on that day/Total number of platform users",
            PreparationUsageRateDesc:
                "Lesson preparation usage rate = Number of teachers (including educational administrators) with lesson preparation records on the platform that day/Number of teachers (including educational administrators) accounts on the platform system that day",
            TeachingUsageRateDesc:
                "Teaching usage rate = Number of teachers (including educational administrators) with teaching records on the platform that day/Number of teachers' (including educational administrators) accounts on the platform system that day.",
            UsageRateAreaDesc:
                "Usage rate= Number of users who log in to the platform in the area that day/Total number of users in the area",
            PreparationUsageRateAreaDesc:
                "Lesson preparation usage rate =Number of teachers (including educational administrators) with lesson preparation records in the area that day/Number of teachers' (including educational administrators) accounts in the area that day",
            TeachingUsageRateAreaDesc:
                "Teaching usage rate = Number of teachers (including educational administrators) with teaching record in the area  that day / Number of teachers' (including educational administrators) accounts in the area that day",
            UsageRateDesc:
                "Usage rate= Number of users who log in to the platform that day/Total number of users in the school ",
            PreparationRateDesc:
                "Lesson preparation rate = Number of teachers (including educational administrators) with lesson preparation records that day/Number of teachers (including educational administrators) accounts of the school that day",
            TeachingUsageRateSchoolDesc:
                "Teaching usage rate = Number of teachers (including educational administrators) with teaching record that day / Number of teachers (including educational administrators) accounts  of the school that day",
            AverageDurationUse: "Average duration of use",
            TrendChartTimeRange:
                "The trend chart of per capita usage time within the selected time range;",
            PerCapitaLessonPreparationTime:
                "Per capita lesson preparation time = Total lesson preparation time  that day/Number of teachers and educational administrators' accounts",
            PerCapitaTeachingHours:
                "Per capita teaching hours = Total teaching hours of the school that day/Number of teachers and educational administrators' accounts on the platform",
            PerCapitaLessonPreparationDuration:
                "Per capita lesson preparation duration = Total lesson preparation duration of the area  that day/Number of teachers and educational administrators' accounts of the area",
            PerCapitaTeachingDuration:
                "Per capita teaching duration = Total teaching duration in the area that day/Number of teachers and educational administrators' accounts in the area",
            PerCapitaLessonDuration:
                "Per capita lesson preparation duration = Total lesson preparation duration of the school  that day/Number of teachers and educational administrators' accounts of the school that day",
            PerCapitaTeachingDurationDesc:
                "Per capita teaching duration = Total teaching duration of the school that day/Number of teachers and educational administrators' accounts of the school that day",
            RankingLesson: "Ranking of lesson preparation and teaching duration of ",
            More: "More",
            teachers: "teachers",
            school: "school",
            area: "area",
            areaData: "area",
            DurationTeaching: "The duration of teaching",
            TotalDuration: "The total duration",
            RankingTimeRange:
                "Ranking of lesson preparation and teaching duration within the selected time range",
            AreaLessonDuration:
                "Area lesson preparation duration =Total of lesson preparation time of all teachers in the area",
            AreaTeachingDuration:
                "Area teaching duration = Total of teaching hours of all users in the area",
            TotalDurationTime:
                "Total duration = Area lesson preparation duration + area teaching duration",
            SchoolPreparationDuration:
                "School lesson preparation duration  = Total lesson preparation duration of all teachers in the school",
            SchoolTeachingDuration:
                "School teaching duration  = Total teaching duration of all users of the school",
            TotalTeachingDuration:
                "Total duration = school lesson preparation duration + school teaching duration",
            LessonPreparationTeachers:
                "Lesson preparation duration of teachers = Total  duration of lesson preparation of teachers and educational administrators",
            TeachingDurationTeachers:
                "Teaching duration of the teachers = Total teaching duration of the teacher's and educational administrators'",
            TotalDurationTeacher:
                "Total duration = teachers'  lesson preparation duration + teachers' teaching duration",
            ParticipationGrade: "Participation of each grade",
            ParticipationSchool: "Participation of each school",
            ParticipationArea: "Participation of each area",
            grade: "grade",
            NumberPrticipatingClasses: "Number of participating classes",
            StudentParticipationRate: "Student participation rate",
            SchoolName: "School name",
            NumberPrticipatingSchools: "Number of participating schools",
            AreaName: "Area name",
            AreaDataName: "Area name",
            ParticipationRate: "Participation rate",
            Popularityisciplines: "Popularity  distribution in various disciplines",
            PlatformPopularity:
                "The distribution of popularity value of each subject on the platform within the selected time range",
            PlatformPopularityValue:
                "Popularity value= Total of times of course preparation and teaching of all disciplines/total of times of course preparation and teaching of all disciplines * 100%",
            AreaPopularity:
                "The distribution of popularity value of each subject in the area within the selected time range",
            AreaPopularityValue:
                "Popularity value= Total of times of course preparation and teaching of all disciplines/total of times of course preparation and teaching of all disciplines * 100%",
            SchoolPopularity:
                "The distribution of popularity value of each subject in the school within the selected time range",
            SchoolPopularityValue:
                "Popularity value= Total of times of course preparation and teaching of all disciplines/total of times of course preparation and teaching of all disciplines * 100%",
            CoursePopularityRanking: "Course popularity ranking",
            Ranking: "Ranking",
            Popularity: "The popularity",
            PopularityValueRanking:
                "The popularity value ranking of  platform courses within the selected time range",
            PlatformValueDesc:
                "Popularity value = Total of times of course preparation and teaching/total of times of course preparation and teaching in the same grade * 100%",
            AreaPopularityValueRanking:
                "The popularity value ranking of the area courses within the selected time range",
            AreaValueDesc:
                "Popularity value = Total of times of course preparation and teaching/total of times of course preparation and teaching in the same grade * 100%",
            SchoolPopularityValueRanking:
                "The popularity value ranking of the school courses within the selected time range",
            SchoolValueDesc:
                "Popularity value = Total of times of course preparation and teaching/total of times of course preparation and teaching in the same grade * 100%",
            TopFive: "The top five",
            LastFive: "The last five",
            DurationDayStudent: "Class duration per day of per student",
            NumberCoursesPerson: "Number of registered courses per person",
            PerCapitaDuration: "Per capita class duration per day",
            AverageRange:
                "The average class duration per student per day within the selected time range,",
            AverageClassDuration:
                "Average class duration per day = Average class duration per day = Total scheduling hours of the student's course/number of scheduling days",
            PlatformAverageNumberCourses:
                "Per capita number of courses registered = Total number of courses registered by participating students /number of participating students in the system",
            PlatformClassDuration:
                "Per capita class duration per day = Total average class time per day of students/number of students in the system",
            PlatformDurationPerDay:
                "Area (school) per capita class duration per day = Total average class time per day of participating students in area (school)/number of participating students in area (school)",

            AreaAverageNumberCourses:
                "Per capita number of enrolled courses = Total number of enrolled courses of  the area/total number of enrolled students",
            AreaClassDuration:
                "Per capita class duration per day = Total average class duration per day of participating students of  the area/number of participating students",
            AreaDurationPerDay:
                "Per capita class duration per day = Total average class duration of participating students of  the area/number of participating students Grade (class) per capita class duration per day = Total average class duration per day of participating students in grade (class)/number of participating students in grade (class)",
            SchoolAverageNumberCourses:
                "Per capita number of enrolled courses = Total number of enrolled courses of  the school/total number of enrolled students",
            SchoolClassDuration:
                "Per capita class duration per day = Total average class duration per day of participating students of  the school/number of participating students",
            SchoolDurationPerDay:
                "Per capita class duration per day = Total average class duration of participating students of  the school/number of participating students",
            DurationPerCapita: "Duration per capita (min)",
            AccordingArea: "According to the area",
            AccordingSchool: "According to the school",
            AccordingGrade: "According to the grade",
            AccordingClass: "According to the class",
            UsageRate: "Usage rate",
        },
        router: {
            DataPanel: "Data Board",
            TeachersComparativeAnalysis: "Teachers' comparative analysis",
            teachingAnalysis: "For teaching analysis",
            TeachingRecord: "Teaching record",
            ResourceUsageAnalysis: "Resource usage analysis",
        },
        forgotPwd: {
            SendingSMSMessage: "Sending SMS message",
            ObtainingCode: "Obtaining the verification code",
            EnterMobileNumber: "Please enter your mobile number",
            MobileNumber: "Mobile phone no.",
            EnterVerificationCode: "Please enter the verification code",
            VerificationCodes: "Verification Code",
            SetPassword: "Set the password",
            Charactersnumbers:
                "A maximum of 8 characters, including letters (case sensitive) and numbers",
            ConfirmPassword: "Confirm password",
            ConfirmPasswordAgain: "Confirm the password again",
            VerificationSent: "The verification code has been sent. Please check.",
            ResetSucceeded: "Password reset succeeded",
            passwordnotMeet: "The password cannot meet for complexity requirements!",
            passwordDiff: "The password entered twice is inconsistent!",
            enternewpassword: "enter new password",
            VerificationCode: "Enter 6-digit SMS verification code",
            CorrectMobileNumber: "Please enter the correct mobile phone number",
            PasswordLength: "Password length must be ",
            characters: "-16 characters",
            PasswordNotEmpty: "Password cannot be empty",
            ContainUpperLowerLetters:
                "The password must contain upper and lower case English letters",
            MustcontainNumbers: "The password must contain numbers",
            SpacesCharacters:
                "The password must not contain spaces or Chinese characters",
            SpecialCharacters:
                "The password must not contain the following special characters",
        },
        saas: {
            permissionManage: "Permission Management",
            addTeacher: "Add Teachers",
            downloadTemplate: "Download Templates",
            batchImport: "Batch Import",
            roleManage: "Role Management",
            authorizationRoles: "Role Management",
            menuAuth: "Menu Authorization",
            assigningUsers: "Assign Users",
            chooseUser: "Select Faculty Members",
            chooseMember: "Select Members",
            backToSaas: "Return to the cloud platform",
            pleaseChooseMember: "Please select a member.",
            pleaseChooseRole: "Please select a functional role",
            role: {
                roleCode: "Role Code",
                roleName: "Role Name",
                roleType: "Role Type",
                roleStatus: "Role Status",
                roleDesc: "Role Description",
                roleDeleteConfirm: "Are you sure you want to delete this role?",
                roleNameRequired: "Please enter the role name.",
                roleStatusRequired: "Please select the role status.",
                roleAdd: "Role New",
                roleEdit: "Role Editing",
            },
        },
    },
};
// 4、项目中自定义语言包，与组件语言包合并
enM = deepmerge(enM, enLocale, { clone: true });
export default enM;
