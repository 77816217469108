import axios from "@/plugins/axios.js";
/**
 * 我的课程筛选条件
 * @return {Promise}
 */
export function getCourseAuth(data) {
  return axios.get("/courseManage/queryCourseProperty/auth", {
    params: data,
  });
}

/**
 * 我的课程列表
 * @return {Promise}
 */
export function getQueryMyCoursePage(data) {
  return axios.get("/courseManage/queryMyCoursePage", {
    params: data,
  });
}

/**
 * 点击课程展示知识点
 * @return {Promise}
 */
export function getQueryKnowledg(data) {
  return axios.get("/knowledgeManage/queryKnowledge", {
    params: data,
  });
}
/**
 * 权限列表
 * @return {Promise}
 */
export function getQueryUserAuthInfo(data) {
  return axios.get("/userManage/queryUserAuthInfo", {
    params: data,
  });
}
/**
 * 权限列表
 * @return {Promise}
 */
export function selectUserAuth(type, data) {
  return axios.get(`/userManage/selectUserAuth/${type}`);
}

/**
 * 备授课首页分类
 * @return {Promise}
 */
export function getCourseIndexCategoty() {
  return axios.get(`/courseManage/index`);
}
