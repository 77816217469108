import axios from "@/plugins/axios.js";

/**
 * 获取用户信息
 * @return {Promise}
 */
export function getUserInfo() {
    return axios.get("/login/getUserInfo");
}

/**
 * 检测后记录已检测用户
 */
export function pluginChecked() {
    return axios.get("/checkPlugin/pluginChecked");
}

/**
 * 发送验证码
 * @export
 * @param {*}
 * @returns
 */
export function sendSms(mobile, type) {
    return axios.get(`/sms/send/${mobile}/${type}`);
}

/**
 * 修改密码
 * @param {*} data 数据
 */
export function updatePassword(data) {
    return axios.post("/sms/updatePassword", data);
}

/**
 * 导入记录-导入位置集合
 * @param {*} data 数据
 */
export function positionList(data) {
    return axios.post("/import/record/positionList", data);
}

/**
 * 导入记录-查询接口
 * @param {*} data 数据
 */
export function importRecord(data) {
    return axios.get("/import/record/page", { params: data });
}

/**
 * 导入记录-删除记录
 * @param {*} data 数据
 */
export function deleteRecordById(recordId) {
    return axios.post(`/import/record/delete/${recordId}`);
}
/**
 * 修改手机号
 * @param {g} data
 * @returns
 */
export function updIdNumber(data) {
    return axios.get(`/staffAuth/updateIdNumber`, {
        params: data,
    });
}

/**
 * 获取当前用户权限内的菜单项
 */
export function getMenuAuth() {
    return axios.get("/auth/findAuthByUserId");
}

/**
 * 获取课件上传权限
 * @return {Promise}
 */
export function getUploadAuth() {
    return axios.get("/login/getUploadAuth");
}

/**
 * 获取当前用户权限内的按钮项
 */
export function getBtnAuth() {
    return axios.get("/auth/getInterfaceById");
}

/**
 * 操作中心-导出记录列表
 * @param {*} data 数据
 */
export function getExportRecordList(data) {
    return axios.get("/bsk/handle/", { params: data });
}

/**
 * 操作中心下载
 */
export function operationDownloadApi(params) {
    return axios.get(`/obs/info/getDownloadUrl`, {
        params,
    });
}
