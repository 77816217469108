// 上课时段
// import Vue from 'vue';
import {
  getBoardSelect,
  getBoardOptions,
  getAvgTimes,
  getDayClassTimes,
  getDurationTimeTotal,
  exportTableData,
  getAreaSchoolList,
} from "@/api/board/look-board.js";

export default {
  state: {
    boardAddressList: "", // 区域列表
    boardOptionsList: [], // 看板选项
    lookBoardInfo: {}, // 看板选中信息
    avgTimesList: [],
    dayClassTimeList: [], // 学生每天上课时长
    durationTimeTotal: {},
    areaSchoolList: {}, // 看板学校列表
  },
  getters: {},
  mutations: {
    SET_BOARD_ADDRESS_LIST(state, value) {
      state.boardAddressList = value;
    },
    SET_BOARD_OPTIONS_LIST(state, value) {
      state.boardOptionsList = value;
    },
    SET_LOOKBOARD_INFO(state, value) {
      state.lookBoardInfo = value;
    },
    SET_AVG_TIMES_LIST(state, value) {
      state.avgTimesList = value;
    },
    SET_DAY_CLASS_TIMES_LIST(state, value) {
      state.dayClassTimeList = value;
    },
    SET_DURATION_TIME_TOTAL(state, value) {
      state.durationTimeTotal = value;
    },
    SET_AREA_SCHOOL_LIST(state, value) {
      state.areaSchoolList = value;
    },
  },
  actions: {
    /**
     *
     * @param {*} param0 提交
     */
    getBoardSelect({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        getBoardSelect(params)
          .then((res) => {
            let data = res.data;
            console.log("res", res);
            commit("SET_BOARD_ADDRESS_LIST", data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBoardOptions({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        getBoardOptions()
          .then((res) => {
            let data = res.data;
            console.log("res", res);
            commit("SET_BOARD_OPTIONS_LIST", data);
            resolve(res);
          })
          .catch((error) => {
            commit("SET_BOARD_OPTIONS_LIST", []);
            reject(error);
          });
      });
    },
    getAreaSchoolList({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        getAreaSchoolList(params)
          .then((res) => {
            let data = res.data;
            data.forEach((element) => {
              element.value = element.schoolCode;
              element.label = element.schoolName;
            });
            console.log("res", res);
            commit("SET_AREA_SCHOOL_LIST", data);
            resolve(res);
          })
          .catch((error) => {
            commit("SET_AREA_SCHOOL_LIST", []);
            reject(error);
          });
      });
    },
    // 获取人均使用时长
    getAvgTimes({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        getAvgTimes(params)
          .then((res) => {
            let data = res.data;
            console.log("res", res);
            commit("SET_AVG_TIMES_LIST", data);
            resolve(res);
          })
          .catch((error) => {
            commit("SET_AVG_TIMES_LIST", []);
            reject(error);
          });
      });
    },
    // 获取学生每天上课人均时长
    getDayClassTimes({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        getDayClassTimes(params)
          .then((res) => {
            let data = res.data.records;
            // console.log("res", res);
            // if (params.sort) {
            //   data.reverse();
            // }
            commit("SET_DAY_CLASS_TIMES_LIST", data);
            resolve(res);
          })
          .catch((error) => {
            commit("SET_DAY_CLASS_TIMES_LIST", []);
            reject(error);
          });
      });
    },
    getDayClassTimesTable({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        getDayClassTimes(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 人均时长数据
    getDurationTimeTotal({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        getDurationTimeTotal(params)
          .then((res) => {
            let data = res.data;
            // console.log("res", res);
            commit("SET_DURATION_TIME_TOTAL", data);
            resolve(res);
          })
          .catch((error) => {
            commit("SET_DURATION_TIME_TOTAL", {});
            reject(error);
          });
      });
    },
    // 分开获取无单独筛选的数据
    getBoardAllTableRespectively({ state, dispatch }, params) {
      dispatch("getAvgTimes", params);
      // let dayClassParams = {
      //   limit: 5,
      //   offset: 0,
      //   type: params.kanbanType === 1 ? 4 : params.kanbanType === 3 ? 1 : null,
      //   ...params,
      // };
      dispatch("getDurationTimeTotal", params);
    },
    // 全部获取所有数据
    getBoardAllTable({ state, dispatch }, params) {
      // return Promise.all([dispatch('loadUserInfo')]).then(() => {
      //   state.hasUserInfo = true;
      // });
      console.log("getBoardAllTable", params);
      return Promise.all([dispatch("getAvgTimes", params)]);
    },
    // 导出列表数据
    exportTableData({ state, commit }, params) {
      exportTableData(params.url, params.data);
    },
    // 导出人均使用时长
    exportDurationTime({ state, dispatch }, params) {
      dispatch("exportTableData", { url: "/duration/export", data: params });
    },
  },
};
