import { startPrepare, keepPrepare, finishPrepare } from "@/api/prepare";
import { getToken } from "@/core/auth";
import Vue from "vue";
import router from "@/router";

let hasRemind = false;
let hasAlert = false;
// 备课模块
export default {
  state: {
    monitorId: null, // 监控id
    timer: null, // 持续监控
    resourceId: null, // 资源id
    teachingData: null, // 备课数据
    hasTimeOut: true,
    hasHiddenTimeOut: true,
    videoPlayer: null,
    customFn: null,
  },
  mutations: {
    /**
     * 设置监控id
     * @param {*} state 状态
     * @param {*} value 监控id
     */
    setMonitorId(state, value) {
      state.monitorId = value;
    },
    /**
     * 设置轮询id
     * @param {*} state 状态
     * @param {*} value 轮询id
     */
    setTimer(state, value) {
      state.timer = value;
    },
    /**
     * 设置资源id
     * @param {*} state 状态
     * @param {*} resourceId 资源id
     */
    setResourceId(state, resourceId) {
      state.resourceId = resourceId;
    },
    setTeachingData(state, teachingData) {
      state.teachingData = teachingData;
    },
    setTimeOutAction(state, value) {
      state.timeOutAction = value;
    },
    setHasTimeOut(state, value) {
      state.hasTimeOut = value;
    },
    setHasHiddenTimeOut(state, value) {
      state.hasHiddenTimeOut = value;
    },
    setHiddenTimeOutAction(state, value) {
      state.timeOutHiddenAction = value;
    },
    SET_VIDEO_PLAYER(state, value) {
      state.videoPlayer = value;
    },
    setPrepareFn(state, value) {
      state.customFn = value;
    },
  },
  actions: {
    // 设置播放器对象
    setVideoPlayer({ state, commit, dispatch }, data) {
      commit("SET_VIDEO_PLAYER", data);
    },
    /**
     *
     * 初始化 页面不显示 5分钟 结束方法
     */
    setPageFocusAction({ state, commit, dispatch }, { data, isFocus }) {
      // 页面获取焦点， 如果备课没结束，更新时间，备课结束，不 start 备课记录，有页面操作 再 start
      console.log("isFocus=======", isFocus);
      if (!data) return;
      if (!state.hasTimeOut) {
        if (state.timeOutAction) {
          clearTimeout(state.timeOutAction);
        }
        commit(
          "setTimeOutAction",
          setTimeout(() => {
            // 如果正在播放视频,更新时间
            console.log(
              "videoPlayer111===",
              state.videoPlayer && state.videoPlayer.getStatus()
            );
            // alert(
            //   "videoPlayer===" + state.videoPlayer &&
            //     state.videoPlayer.getStatus()
            // );
            if (
              state.videoPlayer &&
              state.videoPlayer.getStatus() === "playing"
            ) {
              console.log("playing111====", data);
              dispatch("setPageFocusAction", { data, isFocus });
            } else {
              commit("setHasTimeOut", true);
              dispatch("endPrepare", false);
            }
          }, Number.parseInt(isFocus ? process.env.VUE_APP_PAGE_REFRESH_TIME : process.env.VUE_APP_PAGE_HIDDEN_REFRESH_TIME) * 60 * 1000)
        );
      }
    },
    /**
     *
     * 初始化 20分钟 结束方法
     */
    setPageAction({ state, commit, dispatch }, data) {
      console.log(
        "state.timeOutAction====",
        state.timeOutAction,
        'this.hasTimeOut====="',
        state.hasTimeOut,
        "data=====",
        data,
        "process.env.VUE_APP_PAGE_REFRESH_TIME",
        Number.parseInt(process.env.VUE_APP_PAGE_REFRESH_TIME) * 60 * 1000
      );

      if (!data) return;
      if (state.hasTimeOut) {
        commit("setHasTimeOut", false);
        dispatch("startPrepare", data);
      }
      if (state.timeOutAction) {
        clearTimeout(state.timeOutAction);
      }

      commit(
        "setTimeOutAction",
        setTimeout(() => {
          // 如果正在播放视频,更新时间
          // alert(
          //   "videoPlayer===" + state.videoPlayer &&
          //     state.videoPlayer.getStatus()
          // );
          console.log(
            "videoPlayer222===",
            state.videoPlayer && state.videoPlayer.getStatus() === "playing"
          );
          if (
            state.videoPlayer &&
            state.videoPlayer.getStatus() === "playing"
          ) {
            console.log("playing222====", data);
            dispatch("setPageAction", data);
          } else {
            commit("setHasTimeOut", true);
            dispatch("endPrepare", false);
          }
        }, Number.parseInt(process.env.VUE_APP_PAGE_REFRESH_TIME) * 60 * 1000)
      );
    },
    /**
     * 开始备课
     */
    startPrepare({ state, commit, dispatch }, data) {
      if (hasAlert) return;
      if (!data) return;
      hasRemind = false;
      console.log("开始监控======,state", state);
      let teachingData = data;
      return startPrepare(data)
        .then(({ data }) => {
          commit("setTeachingData", teachingData);
          commit("setMonitorId", data?.id || data);
          console.log("data======", data);

          commit(
            "setTimer",
            setInterval(() => {
              dispatch("keepPrepare", data?.id || data);
            }, 2 * 60 * 1000)
          );
          if (data?.remind && !hasRemind) {
            Vue.prototype.$message.warning(
              "由于课程设置，当前时段的课程可用时间已不足10分钟，到时将自动结束，请尽快完成备授课"
            );
            hasRemind = true;
          }
        })
        .catch((err) => {
          console.log("startPrepareErr", err);
          console.log("state", state);

          if (err.message.includes("时段")) {
            router.replace({
              name: "Auth",
              query: {
                title: "课程非可用时段",
              },
            });
          }
        });
    },
    /**
     * 结束备课
     * @param {*} sync 同步结束
     */
    endPrepare({ state, commit }, sync = true) {
      console.log("结束监控", new Date());
      let monitorId = state.monitorId;
      commit("setMonitorId", null);
      commit("setHasTimeOut", true);
      clearInterval(state.timer);
      clearTimeout(state.timeOutAction);
      if (!monitorId) return Promise.resolve();
      let sendData = {
        prepareId: monitorId,
      };
      if (sync) {
        let url =
          "/aiykt-gateway/data-point/prepareLessons/finishPrepare?" +
          new URLSearchParams(sendData).toString();
        if (navigator.sendBeacon) {
          // 是否发送异步请求
          let blob = new Blob([JSON.stringify(sendData)], {
            type: "application/json",
          });
          navigator.sendBeacon(url, blob);
        } else {
          let xhr = new XMLHttpRequest();
          xhr.open("post", url, false);
          xhr.setRequestHeader("Content-Type", "application/json");
          // eslint-disable-next-line no-undef
          const token = getToken();
          xhr.setRequestHeader("Authorization", token);
          xhr.send(sendData);
        }
        return Promise.resolve();
      } else {
        return finishPrepare(sendData);
      }
    },
    /**
     * 持续备课
     */
    keepPrepare({ state, commit, dispatch }) {
      console.log("保持授课", new Date());
      console.log("state", state);
      return keepPrepare({
        prepareId: state.monitorId,
      })
        .then((res) => {
          console.log("res", res);
          console.log("res.data.out", res.data.out);
          if (res.data.out) {
            dispatch("endPrepare", false);
            hasAlert = true;
            Vue.prototype
              .$alert("课程当前可用时段已结束，已自动结束备授课", "提示", {
                center: true,
                type: "warning",
                confirmButtonText: "我知道了",
              })
              .finally(() => {
                if (state.customFn) {
                  state.customFn("keepPrepare结束");
                }
              });
          } else if (res.data.remind && !hasRemind) {
            Vue.prototype.$message.warning(
              "由于课程设置，当前时段的课程可用时间已不足10分钟，到时将自动结束，请尽快完成备授课"
            );
            hasRemind = true;
          }
        })
        .catch(({ message }) => {
          console.log("message", message);
          if (message === "此次备课未开始或已结束,请确认!") {
            console.log("此次备课未开始或已结束,请确认!", new Date());
            // 结束授课
            clearInterval(state.timer);
            commit("setTimer", null);
            commit("setMonitorId", null);
            dispatch("startPrepare", state.teachingData);
          }
        });
    },
  },
};
