// 挂在cookie库
import Vue from "vue";
import cookie from "js-cookie";

// 挂载实例方法
Object.defineProperty(Vue.prototype, "$cookie", {
  value: cookie,
});

// 挂载Vue下
Object.defineProperty(Vue, "cookie", {
  value: cookie,
});

export default cookie;
