import { checkBSKAuth } from "@/api/classroom/index";
function checkBskPermission(el, binding, vnode) {
  // 先移除之前可能添加的事件监听器
  if (el.__vueClickEventHandler__) {
    el.removeEventListener("click", el.__vueClickEventHandler__);
  }
  // 从binding.value获取原始点击事件处理器和传递给checkBSKAuth的参数
  const {
    handler,
    handlerArgs,
    args,
    checkRequestStatus = "isPending",
    noInnerChangePending = false,
  } = binding.value;
  console.log("binding.value===", binding.value);
  // 定义一个新的点击事件处理函数
  const newClickHandler = async () => {
    vnode.context[checkRequestStatus] = false;
    // 检查状态，如果正在检查权限，则不执行
    if (vnode.context[checkRequestStatus]) {
      console.log("正在检查权限，请稍候...");
      return;
    }
    // 更新状态为true，表示开始检查权限
    vnode.context[checkRequestStatus] = true;
    try {
      // 调用checkBSKAuth并传递参数
      const response = await checkBSKAuth(args);
      console.log("response====", response);
      // 根据接口返回结果决定是否执行原始的点击处理逻辑
      if (response && response.data) {
        handler.apply(vnode.context, [].concat(handlerArgs));
      } else {
        // 可以在这里处理不通过的逻辑，例如显示消息
        console.log("没有权限");
      }
    } catch (error) {
      console.error("检查权限时出错:", error);
    } finally {
      // 检查完成后，无论成功或失败，更新状态为false
      if (!noInnerChangePending) {
        vnode.context[checkRequestStatus] = false;
      }
    }
  };

  // 将事件监听器存储在元素上，以便以后可以移除它
  el.__vueClickEventHandler__ = newClickHandler;

  // 用新的点击事件处理函数替换原来的
  el.addEventListener("click", newClickHandler);
}

export default {
  inserted: checkBskPermission,
  update: checkBskPermission,
  unbind(el) {
    // 当指令与元素解绑时移除事件监听器
    if (el.__vueClickEventHandler__) {
      el.removeEventListener("click", el.__vueClickEventHandler__);
      el.__vueClickEventHandler__ = null;
    }
  },
};
