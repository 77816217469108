<template>
  <div>
    <el-upload
      v-if="isEdit"
      class="upload-file"
      ref="upload"
      action="#"
      :http-request="submitUpload"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :file-list="fileList"
      :accept="accept"
      :before-upload="beforeUpload"
      v-bind="$attrs"
    >
      <el-button
        size="small"
        icon="el-icon-upload2"
        :disabled="fileList.length === limit"
        >上传文件</el-button
      >
      <div slot="tip" class="el-upload__tip">
        大小5M以内，支持扩展名：.rar .zip .doc .docx .pdf .jpg .png .xls .xlsx
      </div>
    </el-upload>
    <file-list v-else :files="fileList" @handleClick="handlePreviewList" />
    <!-- class="upload-file-img-dialog" -->
    <!-- <el-dialog
      class="el-image-viewer__canvas"
      :visible.sync="dialogVisible"
      append-to-body
    >
      <img
        class="el-image-viewer__img"
        style="max-width: 100%; max-height: 100%"
        :src="dialogImageUrl"
        alt=""
      />
    </el-dialog> -->
    <image-viewer
      :zIndex="3333"
      :initialIndex="0"
      v-if="dialogVisible"
      :on-close="() => (dialogVisible = false)"
      :url-list="dialogImageUrl"
    />
  </div>
</template>
<script>
import { getObsPolicy, getObsUrlNoCheck } from "@/api/upload.js";
import _axios from "@/plugins/axios.js";
import axios from "axios";
import { isImage, isOnlineUrl } from "@/utils/index";
import { isEqual } from "lodash";
import FileList from "./FileList.vue";
import ImageViewer from "../../../node_modules/element-ui/packages/image/src/image-viewer.vue";
export default {
  components: { FileList, ImageViewer },
  props: {
    limit: Number,
    value: [Array, String],
    isEdit: Boolean,
  },
  data() {
    return {
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: "",
      cancelObsPolicy: null,
      cancelObsUpload: null,
      accept:
        "application/x-rar-compressed,.zip,application/zip,application/x-zip,application/octet-stream,application/x-zip-compressed,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/jpeg,image/png,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
  },
  watch: {
    value: {
      async handler(newVal, oldVal) {
        if (newVal) {
          if (!isEqual(newVal, oldVal)) {
            if (Array.isArray(newVal)) {
              const arr = this.filterEmptyValues(newVal);
              for (let i = 0; i < arr.length; i++) {
                arr[i].url = await this.getObsUrlDis(arr[i].name, arr[i].url);
              }
              this.fileList = arr;
            } else {
              if (newVal.url) {
                const url = await this.getObsUrlDis(newVal.name, newVal.url);
                this.fileList = [{ name: newVal.name, url }];
              } else {
                this.fileList = [];
              }
            }
          }
        } else {
          this.fileList = [];
        }
      },
      deep: true,
      immediate: true,
    },
    fileList: {
      handler: function (val) {
        if (val) {
          this.$emit("fileChange", val);
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.handleAbort();
  },
  methods: {
    filterEmptyValues(arr) {
      return arr.filter((item) => item.name !== "" && item.url !== "");
    },
    // 资源管理获取obs直传信息
    getObsPolicyOtions(file) {
      const params = {
        fileName: file.name,
        xObsAcl: "private",
        source: "front",
      };
      return getObsPolicy(params, {
        cancelToken: new axios.CancelToken((c) => {
          this.cancelObsPolicy = c;
        }),
      }).then((res) => {
        if (res.success) {
          return res.data;
        } else {
          return false;
        }
      });
    },
    // 获取下载授权链接
    async getObsUrlDis(name, url) {
      let reUrl = url;
      if (!isOnlineUrl(reUrl)) {
        if (reUrl) {
          const params = {
            objectName: reUrl,
            displayName: name,
            isDownload: 0,
          };
          const res = await getObsUrlNoCheck(params);
          reUrl = res.data;
        }
      }
      return reUrl;
    },
    submitUpload(option) {
      // eslint-disable-next-line no-async-promise-executor
      const httpRequestPromise = new Promise(async (resolve, reject) => {
        const obsParams = await this.getObsPolicyOtions(option.file);
        const { host, key, policy, accessKeyId, signature } = obsParams;
        // const fileUrl = host + "/" + key;
        let formData = new FormData();
        formData.append("key", key);
        formData.append("x-obs-acl", "private");
        formData.append("content-type", "multipart/form-data");
        formData.append("policy", policy);
        formData.append("signature", signature);
        formData.append("AccessKeyId", accessKeyId);
        formData.append("file", option.file);

        return _axios
          .post(host, formData, {
            cancelToken: new axios.CancelToken((c) => {
              this.cancelObsUpload = c;
            }),
            onUploadProgress: (progressEvent) => {
              option.file.status = "uploading";
              let complete =
                ((progressEvent.loaded / progressEvent.total) * 100) | 0;
              option.onProgress({ percent: complete }); // 触发el-upload组件的onProgress方法
            },
          })
          .then((res) => {
            resolve({ url: URL.createObjectURL(option.file), key });
            option.file.status = "success";
            return { url: URL.createObjectURL(option.file), key };
          })
          .catch((err) => {
            option.file.status = "fail";
            console.log("catch ===== ", err);
          });
      });

      // 添加abort方法 兼容el-upload组件的abort方法调用
      httpRequestPromise.abort = () => {
        this.handleAbort();
      };
      return httpRequestPromise;
    },
    handleSuccess(response, file, fileList) {
      file.url = response.url;
      file.key = response.key;
      this.fileList = fileList;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handlePreviewList(file) {
      // 接口回填 仅 查看preview
      this.preview(file);
    },
    handlePreview(file) {
      // el-upload preview功能
      this.preview(file);
    },
    preview(file) {
      console.log("isOnlineUrl ==== ", isOnlineUrl(file.url), file);
      const url = isOnlineUrl(file.url)
        ? file.url
        : URL.createObjectURL(file.raw);

      if (isImage(file.raw || file.url)) {
        this.dialogImageUrl = [file.url];
        this.dialogVisible = true;
      } else {
        let a = document.createElement("a");
        a.setAttribute("target", "_blank");
        a.setAttribute("download", file.name);
        a.setAttribute("href", url);
        a.click();
      }
    },
    beforeUpload(file, fileList) {
      if (!this.accept.includes(file.type)) {
        this.$message.error("文件格式不正确，请检查后重新上传");
        return Promise.reject(); // 正确的终止方式
      } else if (file.size > 5 * 1024 * 1024) {
        this.$message.warning("文件最大5M，请重新选择");
        return Promise.reject(); // 正确的终止方式
      }
      return true;
    },
    handleAbort() {
      this.cancelObsPolicy && this.cancelObsPolicy();
      this.cancelObsUpload && this.cancelObsUpload();
    },
  },
};
</script>
<style lang="less" scoped>
.upload-file {
  width: 100%;
  .el-upload-list__item.is-success:focus:not(:hover) {
    display: none !important;
  }
  .el-upload__tip {
    line-height: 18px;
    margin-top: 4px;
  }
}
</style>
