<template>
  <!-- 分页组件 -->
  <div class="pagination">
    <el-pagination
      :current-page.sync="params.page"
      :page-size="params.limit"
      :total="total"
      background
      layout="prev, pager, next"
      @size-change="sizeChange"
    >
    </el-pagination>
    <span class="el-pagi-right el-pagination__total"
      >{{ $t("el.pagination.totalname") }} {{ params.pages }}
      {{ $t("el.pagination.pages") }} {{ total }}
      {{ $t("el.pagination.items") }}
    </span>
  </div>
</template>

<script>
export default {
  name: "XmPagination",
  props: {
    // 总记录数
    total: {
      type: Number,
      default: 0,
    },
    // 分页参数
    params: {
      type: Object,
      default: () => ({
        limit: 10,
        offset: 0,
        page: 1,
      }),
    },
  },
  data() {
    return {};
  },
  watch: {
    // 监听页数
    "params.page"(newVal, oldVal) {
      // 改变参数
      var params = { ...this.params };
      params.offset = params.limit * (params.page - 1);
      this.$emit("params-change", params);
    },
  },
  methods: {
    /**
     * 分页数改变
     */
    sizeChange(size) {
      var params = { ...this.params };
      params.limit = size;
      this.$emit("params-change", params);
    },
  },
};
</script>

<style lang="less" scoped>
.pagination {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  .el-pagi-right {
  }
}
</style>
