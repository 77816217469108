<template>
  <div class="gpt-contain">
    <el-image
      v-show="!showChatDialog"
      ref="elImage"
      v-drag="dragData"
      :class="currentImgStyle"
      :src="youImg"
    >
      <img :src="youImg" slot="placeholder" class="el-image__placeholder" />
    </el-image>
    <div
      v-show="!isHidden && !isMove"
      ref="chatTips"
      :class="direction === 'right' ? 'bg-chat' : 'bg-chat-left'"
    >
      <el-image class="icon-chat" :src="iconChatClick"></el-image>
      <span class="chat-tips">{{ $t("el.chatgpt.ClickConversation") }}</span>
    </div>
    <ChatgptDialog
      :showChatDialog="showChatDialog"
      :direction="direction"
      @setChatDialogVisible="(isShow) => (showChatDialog = isShow)"
    ></ChatgptDialog>
  </div>
</template>

<script>
import drag from "@/directive/drag/index";
import ChatgptDialog from "@/components/biz-components/ChatgptDialog/index.vue";
export default {
  data() {
    return {
      youImg: require("@/assets/images/icon/icon-youyou-right.png"),
      currentImgStyle: "right-style",
      isHidden: true, // 是否是隐藏状态
      direction: "right", // 默认左右位置
      isDragging: false, // 添加一个标志位来跟踪图标是否被移动
      iconChatClick: require("@/assets/images/icon/icon-chat-click.png"),
      isMove: false,
      imgTimeOut: null,
      showChatDialog: false, // 是否显示 showChatDialog
    };
  },
  directives: {
    drag,
  },
  components: {
    ChatgptDialog,
  },
  computed: {
    dragData() {
      const defaultDragData = {
        set: this.updateElementPosition,
        onClick: this.onHandleYouClick,
        onMove: this.onMove,
        data: {
          direction: "all",
          scope: "screen", // screen 屏幕范围，none 不限制
          isDragBack: true,
        },
        leftStyle: "left-style",
        rightStyle: "right-style",
      };
      // const dragDataWhenHidden = {
      //   set: this.updateElementPosition,
      //   onClick: this.onHandleYouClick,
      //   onMove: this.onMove,
      //   data: {
      //     direction: "all",
      //     scope: "screen", // screen 屏幕范围，none 不限制
      //     isDragBack: true,
      //   },
      //   leftStyle: "left-style",
      //   rightStyle: "right-style",
      // };
      return defaultDragData;
    },
  },

  created() {
    this.leftStyle = "left-style"; // 左边样式
    this.rightStyle = "right-style"; // 右边样式
    this.youImgGifRight = require("@/assets/images/icon/icon-youyou-gif.gif");
    this.youImgGifLeft = require("@/assets/images/icon/icon-youyou-left.gif");
    this.youImgLeft = require("@/assets/images/icon/icon-youyou-left.png");
    this.youImgRight = require("@/assets/images/icon/icon-youyou-right.png");
  },
  watch: {
    isDragging: {
      handler: function (val) {
        console.log("watch=====", val);
        if (val) {
          clearTimeout(this.imgTimeOut);
          const elImage = this.$refs.elImage;
          if (elImage) {
            if (!elImage.$el.className.includes("current-image")) {
              elImage.$el.className = elImage.$el.className + " current-image ";
            }
            // console.log("current-image=====", elImage.$el.className);
            if (this.direction === "right") {
              this.youImg = this.youImgGifRight;
            } else {
              this.youImg = this.youImgGifLeft;
            }
          }
        } else {
          let windowWidth = window.innerWidth;
          const elImage = this.$refs.elImage;
          let left = elImage.$el.offsetLeft;
          if (left > windowWidth / 2) {
            this.direction = "right";
          } else {
            this.direction = "left";
          }
          console.log("(this.direction===", this.direction);
          this.changeSideImg();
        }
      },
    },
  },
  methods: {
    // 移动图标结束回调
    updateElementPosition(direction) {
      // 在这里编写您希望执行的操作，根据传入的 direction 参数进行不同的处理
      console.log("Set method called with direction:", direction);
      // 根据拖拽释放位置进行元素位置更新的代码
      console.log("Element position updated with direction:", direction);
      console.log(this.isHidden, 1111);
      this.isDragging = false;
      if (!this.isHidden) {
        this.direction = direction;
        // 获取 <el-image> 元素
        this.isHidden = true;
        this.changeSideImg();

        // 如果 <el-image> 元素是异步加载的，可能需要使用 $nextTick 等待更新后再进行操作
        // this.$nextTick(() => {
        // 在这里执行在更新后对 <el-image> 进行的其他操作
        this.isMove = false;
        // });
      }
    },
    // logo 点击事件
    onHandleYouClick() {
      clearTimeout(this.imgTimeOut);
      this.$nextTick(() => {
        console.log("this.isHidden", this.isHidden, 888);
        if (this.isHidden) {
          const elImage = this.$refs.elImage;
          this.isMove = false;
          if (elImage) {
            elImage.$el.className = elImage.$el.className + " current-image";
            if (this.direction === "right") {
              this.youImg = this.youImgGifRight;
              elImage.$el.style.left = "auto";
              elImage.$el.style.right = "30px";
            } else {
              this.youImg = this.youImgGifLeft;
              elImage.$el.style.right = "auto";
              elImage.$el.style.left = "30px";
            }
            this.$refs.chatTips.style.top = `calc(${this.$refs.elImage.$el.style.top} + 40px)`;
          }
          this.isHidden = false;
          // 如果没有点击，4秒之后图片贴边
          this.imgTimeOut = setTimeout(() => {
            // 重新计算direction
            let windowWidth = window.innerWidth;
            let left = elImage.$el.offsetLeft;
            if (left > windowWidth / 2) {
              this.direction = "right";
            } else {
              this.direction = "left";
            }
            this.updateElementPosition(this.direction);
          }, 4000);
        } else {
          // 弹出chatgpt 框
          this.showChatDialog = true;
          this.updateElementPosition(this.direction);
        }
        this.isDragging = false;
      });
    },
    onMove() {
      // move 监听
      this.isMove = true;
      this.isDragging = true;
    },
    // 优优靠边效果
    changeSideImg() {
      const elImage = this.$refs.elImage;
      elImage.$el.className = elImage.$el.className.replace(
        "current-image",
        ""
      );
      // 更新 <el-image> 的 src 属性
      if (this.direction === "left") {
        this.currentImgStyle = this.leftStyle; // 应用左边样式
        this.youImg = this.youImgLeft;
        elImage.$el.style.right = "auto";
        elImage.$el.style.left = "0";
      } else if (this.direction === "right") {
        this.currentImgStyle = this.rightStyle; // 应用右边样式
        this.youImg = this.youImgRight;
        elImage.$el.style.left = "auto";
        elImage.$el.style.right = "0";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.gpt-contain {
  z-index: 100;

  .right-style {
    position: fixed;
    z-index: 100;
    right: 0;
    left: auto;
    top: 68%;
    width: 70px;
    height: 112px;
    transform: rotate(-45deg) translateX(43px);
    cursor: pointer;
  }
  .left-style {
    position: fixed;
    z-index: 100;
    right: auto;
    left: 0;
    top: 68%;
    width: 70px;
    height: 112px;
    transform: rotate(45deg) translateX(-43px);
    cursor: pointer;
  }
  .current-image {
    transform: rotate(0deg) translateX(0px);
    transition: transform 1s;
  }
  .el-image__placeholder {
    background: transparent;
  }
  .bg-chat {
    width: 157px;
    height: 48px;
    position: fixed;
    z-index: 100;
    right: 104px;
    left: auto;
    top: calc(68% + 40px);
    background: url("../../../assets/images/icon/bg-chat-tips-right.png")
      no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-top: 9px;
    padding-left: 24px;
    .icon-chat {
      width: 20px;
      height: 20px;
    }
    .chat-tips {
      width: fit-content;
      max-width: 100px;

      font-size: 12px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
      margin-left: 11px;
      vertical-align: super;
    }
    .chat-tips-left {
      width: fit-content;
      font-size: 12px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
      margin-left: 11px;
      vertical-align: super;
    }
  }
  .bg-chat-left {
    width: 157px;
    height: 48px;
    position: fixed;
    z-index: 100;
    right: auto;
    left: 104px;
    top: calc(68% + 40px);
    background: url("../../../assets/images/icon/bg-chat-tips-left.png")
      no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-top: 9px;
    padding-left: 19px;
    .icon-chat {
      width: 20px;
      height: 20px;
    }
    .chat-tips {
      width: fit-content;
      font-size: 12px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
      margin-left: 11px;
      vertical-align: super;
    }
  }
}
</style>
