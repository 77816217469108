<template>
  <!-- 动态表单 start -->
  <el-form ref="form" v-bind="formOptions" :model="formData" v-on="$listeners">
    <!-- 循环输出表单项 start -->
    <template v-for="(item, index) in formItems">
      <el-form-item
        v-if="item.itemType && !item.hidden"
        :key="index"
        :prop="item.prop"
        :label="
          item.label +
          (item.hiddenColon || !item.label ? '' : $t('el.common.colon'))
        "
        :label-width="item.labelWidth"
        :style="item.formStyle"
      >
        <!-- 输入框 start -->
        <template v-if="item.itemType === 'input'">
          <el-input
            v-model="formData[item.prop]"
            :size="item.size"
            v-bind="item"
            :style="item.itemStyle || 'width: 200px;'"
            class="filter-item"
          />
        </template>
        <template v-else-if="item.itemType === 'inputNumber'">
          <el-input-number
            v-model="formData[item.prop]"
            type="number"
            @keyup.native="number($event)"
            :size="item.size"
            v-bind="item"
            :style="item.itemStyle || 'width: 200px;'"
          ></el-input-number>
        </template>
        <!-- 输入框 end -->
        <!-- 文字 start -->
        <template v-else-if="item.itemType === 'text'">
          <span :style="item.itemStyle || 'width: 200px;'">
            <template v-if="item.type === 'time'">
              {{ getFormatTime(formData[item.prop]) }}
            </template>
            <template v-else>
              <span>
                <span>{{ formData[item.prop] }}</span>
                <span v-if="item.suffix" class="xm-form-text-suffix">{{
                  item.suffix
                }}</span>
              </span>
            </template>
          </span>
        </template>
        <!-- 文字 end -->
        <!-- 带搜索的输入框 start -->
        <template v-else-if="item.itemType === 'inputSearch'">
          <el-input
            :placeholder="item.placeholder"
            :size="item.size"
            :style="item.itemStyle"
            v-model="formData[item.prop]"
          >
            <template slot="suffix">
              <i @click="$parent.query()" class="el-icon-search"></i>
            </template>
          </el-input>
        </template>
        <!-- 输入框 end -->
        <!-- 金额类型的输入框 start -->
        <template v-else-if="item.itemType === 'inputMoney'">
          <el-input-number
            class="xm-form-item-input-money"
            :placeholder="item.placeholder"
            :controls="false"
            :size="item.size"
            :style="item.itemStyle"
            v-model="formData[item.prop]"
            v-bind="item"
          >
          </el-input-number>
          <span class="xm-form-item-input-money-suffix">元</span>
        </template>
        <!-- 金额类型的输入框 end -->
        <!-- cascader start -->
        <template v-else-if="item.itemType === 'cascader'">
          <el-cascader
            :placeholder="item.placeholder"
            :options="item.options"
            :show-all-levels="item.showAllLevels || false"
            v-bind="item"
            :size="item.size"
            filterable
            :collapse-tags="item.collapseTags || true"
            v-model="formData[item.prop]"
            :style="item.itemStyle"
          ></el-cascader>
        </template>
        <!-- cascader end -->
        <!-- 日期 end -->
        <!-- 下拉框 start -->
        <template v-else-if="item.itemType === 'select'">
          <el-select
            :size="item.size"
            v-model="formData[item.prop]"
            v-bind="{ 'popper-append-to-body': false, ...item }"
            :style="item.itemStyle || 'width: 200px;'"
            class="filter-item"
            popper-class="select"
            @change="(...defaultArgs) => onSelectChange(item, ...defaultArgs)"
          >
            <template v-if="item.options && item.options.length">
              <el-option
                v-for="(option, i) in item.options"
                v-bind="option"
                :key="i"
              />
            </template>
          </el-select>
        </template>
        <template v-else-if="item.itemType === 'selectMulti'">
          <el-select
            v-for="(item2, index2) in item.multi"
            :key="index2"
            :size="item2.size || item.size"
            v-model="formData[item.prop][item2.prop]"
            v-bind="item2 || item"
            :style="item2.itemStyle || item.itemStyle || 'width: 200px;'"
            class="filter-item filter-item-select-multi"
            :popper-append-to-body="false"
            popper-class="select"
          >
            <template v-if="item2.options && item2.options.length">
              <el-option
                v-for="(option, i) in item2.options"
                v-bind="option"
                :key="i"
              />
            </template>
            <template v-else>
              <div>
                {{ item2.options.length }}
              </div>
            </template>
          </el-select>
        </template>
        <!-- 下拉框 end -->
        <!-- 日期 start -->
        <template v-else-if="item.itemType === 'date'">
          <el-date-picker
            :size="item.size"
            v-model="formData[item.prop]"
            v-bind="item"
            type="date"
          />
        </template>
        <!-- 日期区间 start 日期时间区间-->
        <template
          v-else-if="
            item.itemType === 'daterange' || item.itemType === 'datetimerange'
          "
        >
          <el-date-picker
            v-model="formData[item.prop]"
            v-bind="item"
            :size="item.size"
            :start-placeholder="
              item['start-placeholder'] || $t('el.common.startDate')
            "
            :end-placeholder="
              item['end-placeholder'] || $t('el.common.endDate')
            "
            :type="item.itemType"
            @change="(date) => changeData(date, item.prop1, item.prop2)"
          />
        </template>
        <!-- 日期区间 end 日期时间区间-->
        <!-- 时间区间 start -->
        <template v-else-if="item.itemType === 'timerange'">
          <el-time-picker
            v-if="isShowPicker"
            v-model="formData[item.prop]"
            v-bind="item"
            arrow-control
            :size="item.size"
            is-range
            :range-separator="$t('el.common.to')"
            :style="item.width ? `width: ${item.width}px;` : 'width: 200px;'"
            :start-placeholder="$t('el.common.startTime')"
            :end-placeholder="$t('el.common.endTime')"
            placeholder="请选择时间范围"
            @change="(date) => changeData(date, item.prop1, item.prop2)"
            @blur="blurTimePicker"
            @focus="focusTimePicker(item)"
          />
        </template>
        <!-- 时间区间 end -->
        <!-- 数据字典下拉 start -->
        <template v-else-if="item.itemType === 'dict'">
          <xm-dict-select
            :size="item.size"
            v-model="formData[item.prop]"
            v-bind="item"
            :dict-type="item.dictName"
            style="width: 200px"
            class="filter-item"
          />
        </template>
        <!-- 数据字典下拉 end -->
        <!-- 二级数据字典下拉 start -->
        <template v-else-if="item.itemType === 'dictLinkage'">
          <xm-dict-select
            :size="item.size"
            v-model="formData[item.prop]"
            v-bind="item"
            :dict-type="
              formData[item.parentProp] === ''
                ? ''
                : `${item.dictName},${formData[item.parentProp]}`
            "
            style="width: 200px"
            class="filter-item"
          />
        </template>
        <!-- 二级数据字典下拉 end -->
        <!-- 单选框 start -->
        <template v-else-if="item.itemType === 'radio'">
          <el-radio
            v-for="(item2, index2) in item.options"
            :key="index2"
            v-model="formData[item.prop]"
            v-bind="item2"
            class="filter-item filter-item-radio"
          >
            <span class="filter-item-radio-title">{{ item2.title }}</span>
          </el-radio>
        </template>
        <!-- 单选框 end -->
        <!-- 上传功能 start -->
        <template v-else-if="item.itemType === 'upload'">
          <upload-file
            :value="formData[item.prop]"
            v-bind="item"
            :style="item.itemStyle || 'width: 200px;'"
            @fileChange="item.fileChange"
          />
        </template>
        <!-- 上传功能 end -->
        <!-- 数值范围 start -->
        <template v-else-if="item.itemType === 'numberRange'">
          <div class="numberRange">
            <el-input
              v-model="formData[item.prop1]"
              :size="item.size"
              v-bind="item"
              maxlength="3"
              class="numberRange-input"
              @input="(val) => changeRange(val, item.prop1)"
              @blur="blurRange"
              :style="item.itemStyle || 'width: 70px;'"
            ></el-input>
            <span>至</span>
            <el-input
              v-model="formData[item.prop2]"
              :size="item.size"
              v-bind="item"
              maxlength="3"
              class="numberRange-input"
              @input="(val) => changeRange(val, item.prop2)"
              @blur="blurRange"
              :style="item.itemStyle || 'width: 70px;'"
            ></el-input>

            <span>分钟</span>
          </div>
        </template>
        <!-- 数值范围 end -->
      </el-form-item>
    </template>
    <!-- 循环输出表单项 end -->
    <!-- 表单按钮 start -->
    <slot name="formBtn"></slot>
    <!-- 表单按钮 end -->
  </el-form>
  <!-- 动态表单 end -->
</template>

<script>
import moment from "moment";
import UploadFile from "../UploadFile/index.vue";
import { getFormatTime } from "@/utils";
export default {
  name: "XmForm",
  props: {
    /**
     * 表单项配置
     */
    formItems: {
      type: Array,
      default: () => [],
    },
    /**
     * 表单配置属性
     */
    formOptions: {
      type: Object,
      default: () => {},
    },
    /**
     * 表单数据
     */
    formData: {
      type: Object,
      default: () => {},
    },
  },
  components: { UploadFile },
  data() {
    return {
      getFormatTime: getFormatTime,
      isShowPicker: true,
      currentItem: null,
    };
  },
  watch: {
    "formData.date": {
      handler(val, old) {
        // console.log("22222222", val);
        if (val !== null && val !== undefined) {
          if (val.length === 2) {
            let valueFormat = this.currentItem["value-format"];
            if (valueFormat) {
              if (!val[0]) {
                this.$set(this.formData, "date", [
                  moment().format(valueFormat),
                  moment(val[1]).format(valueFormat),
                ]);
              } else if (!val[1]) {
                this.$set(this.formData, "date", [
                  moment(val[0]).format(valueFormat),
                  moment().format(valueFormat),
                ]);
              }
            } else {
              if (!val[0]) {
                val[0] = new Date();
              } else if (!val[1]) {
                val[1] = new Date();
              }
            }
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    /**
     * 修改日期
     */
    changeData(data, prop1, prop2) {
      this.isShowPicker = false;
      if (data && data.length >= 2) {
        console.log(this.formData);
        delete this.formData.undefined;
        this.formData[prop1] = data[0];
        this.formData[prop2] = data[1];
        if (prop2 === "endTime") {
          this.formData[prop2] = data[1].replace("00:00:00", "23:59:59");
        }
      } else {
        if (prop1) {
          this.formData[prop1] = "";
        }
        if (prop2) {
          this.formData[prop2] = "";
        }
        if (data === null) {
          this.$nextTick(() => {
            this.isShowPicker = true;
          });
        } else {
          if (data.length !== 2) return;
          this.formData[prop1] = data[0];
          this.formData[prop2] = data[1];
        }
      }
    },
    changeRange(value, prop) {
      let val;
      if (value === "") val = value;
      else val = value * 1;
      if (typeof val === "number" && Number.isInteger(val)) {
        if (val > 100) {
          this.formData[prop] = 100;
        } else {
          this.formData[prop] = val;
        }
      } else this.formData[prop] = "";
    },
    blurRange() {},
    // select 选择
    onSelectChange(item, id) {
      // let selectedWorkName = {};
      console.log("id", id);
      if (!item.multiple) {
        let selectedWorkName = item.options.find((obj) => {
          return obj.value === id;
        });
        console.log("selectedWorkName", selectedWorkName);
        console.log("this.formData[item.prop]", this.formData[item.prop]);
        if (selectedWorkName && item.selectLabel) {
          this.formData[item.selectLabel] = selectedWorkName.label;
        }
      } else {
        if (item.ref) {
          console.log("form", this.$refs[item.ref]);
          this.$nextTick(() => {
            if (this.$refs[item.ref][0].$el.outerText) {
              this.formData[item.selectLabel] =
                this.$refs[item.ref][0].$el.outerText.split("\n");
            }
            console.log(
              " this.formData[item.selectLabel]",
              this.formData[item.selectLabel]
            );
          });
        }
      }
      this.$emit("onSelectChange", item, id);
    },
    /**
     * 为解决时间选择器初始化问题，
     * 失焦的时候重新渲染组件
     */
    blurTimePicker() {
      this.$nextTick(() => {
        this.isShowPicker = true;
      });
    },
    /**
     * 为解决判断value-format问题，
     *
     */
    focusTimePicker(item) {
      // console.log("item");
      this.currentItem = item;
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].resetFields();
        }
        this.$emit("resetForm");
      });
    },
    /**
     * 清除校验
     */
    clearValidate() {
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    number(e) {
      // 禁用键盘
      var keynum = window.event ? e.keyCode : e.which;
      var keychar = String.fromCharCode(keynum);
      if (
        keynum === 189 ||
        keynum === 190 ||
        keynum === 110 ||
        keynum === 109
      ) {
        this.$message.warning(this.$t("el.common.NoDecimalsnegativeNumbers"));
        e.target.value = "";
      }
    },
  },
  mounted() {
    this.formItems &&
      this.formItems.forEach((element) => {
        if (element.itemType === "radio") {
          this.formData[element.prop] = element.options[0].label;
        }
        if (element.itemType === "inputMoney") {
          // 可以让input-number的初始值改为空，而不是0
          if (element.isSubmit && !this.formData[element.prop]) {
            this.formData[element.prop] = undefined;
          }
        }
      });
  },
};
</script>

<style lang="less" scoped>
.numberRange {
  margin-top: 4px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  padding: 0 15px 0 0;
  display: flex;
  align-items: center;
  .numberRange-input {
    height: 26px;
    line-height: 26px !important;
  }
  span {
    height: 26px;
    line-height: 26px;
    color: #606266;
  }
  /deep/ .el-input__inner {
    height: 26px;
    border: none;
    text-align: center;
  }
}
.filter-item-select-multi {
  margin-left: 10px;
  &:first-child {
    margin-left: 0px;
  }
}
.filter-item-radio {
  /deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: #5a53f5;
    background: #5a53f5;
  }
  .filter-item-radio-title {
    color: #5a53f5;
  }
}

.xm-form-item-input-money {
  /deep/ input {
    text-align: left;
    padding-right: 24px !important;
  }
}
.xm-form-item-input-money-suffix {
  margin-left: -20px;
  z-index: 99;
  color: #333;
  font-size: 12px;
  line-height: 32px;
}

.xm-form-text-suffix {
  display: inline-block;
  margin-left: 4px;
}
</style>
