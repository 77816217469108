/**
 * 备授课路由
 */
export default {
  path: "/hdClassroomWps/:type",
  component: () =>
    import(
      /* webpackChunkName: "hdClassroom" */ "@/views/hdClassroomWps/index.vue"
    ),
  name: "hdClassroomWps",
  hidden: false,
  props: (route) => ({
    type: route.params.type,
    id: route.query.id,
    courseId: route.query.courseId,
    originTenantId: route.query.originTenantId,
    originTenantType: route.query.originTenantType,
  }),
  meta: {
    title: "el.routerName.classRoom",
    icon: "",
    authority: ["third"],
    keepAlive: false,
  },
};
