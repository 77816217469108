import axios from "@/plugins/axios.js";

/**
 * 登录
 * @param {*} data 数据
 * @return {Promise}
 */
export function toLogin(data) {
  return axios.post("/login/toLogin", data);
}

/**
 * 登出
 * @return {Promise}
 */
export function logout() {
  return axios.get("/login/logout");
}
