import Vue from "vue";
import ElementUI from "element-ui";
// 1、引入和使用vue-i18n插件
import VueI18n from "vue-i18n";
import zhLocale from "../assets/i18n/zh";
import enLocale from "../assets/i18n/en";
Vue.use(VueI18n);
// // 最后 export default，这一步肯定要写的。
const i18n = new VueI18n({
  locale: "zh_CN", // set locale 默认显示中文
  messages: {
    zh_CN: zhLocale,
    en_US: enLocale,
  }, // set locale messages
});

// 5、重点就是这句话！！elementUI使用定制的i18n统一管理
// ElementLocale.i18n((key, value) => i18n.t(key, value))
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
});

// 6、在上面的main.js文件中把i18n挂载到了根实例上。
export default i18n;
