import bskPermission from "./bskPermission";

const install = function (Vue) {
    Vue.directive("check-bsk-permission", bskPermission);
};

if (window.Vue) {
    window["bskPermission"] = bskPermission;
    Vue.use(install); // eslint-disable-line
}

bskPermission.install = install;
export default bskPermission;