import axios from "@/plugins/axios.js";

/**
 * 开始备课
 * @param {*} data 备课数据
 */
export function startPrepare(data) {
  return axios.post("/prepareLessons/startPrepare", data, {
    baseURL: "/aiykt-gateway/data-point",
  });
}

/**
 * 保持备课
 * @param {*} params 参数
 */
export function keepPrepare(params) {
  return axios.get("/prepareLessons/keepPrepare", {
    baseURL: "/aiykt-gateway/data-point",
    params,
  });
}

/**
 * 结束备课
 * @param {*} data 参数
 */
export function finishPrepare(data) {
  return axios.post("/prepareLessons/finishPrepare", data, {
    baseURL: "/aiykt-gateway/data-point",
    params: data,
  });
}
