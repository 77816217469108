import {
  getBskRank,
  exportBskRank,
  getPlatFormAlData,
  getAreaAlData,
  getSchoolAlData,
  getPlatJgAlData,
  getGradePropertyData,
  getSubjectPropertyData,
  getSubjectHeat,
  getJsonCodeData,
} from "@/api/screen/index";

export default {
  state: {
    propertyGradeData: [],
    propertySubjectData: [],
  },
  mutations: {
    GET_GRADE_DATA(state, value) {
      state.propertyGradeData = value;
    },
    GET_SUBJECT_DATA(state, value) {
      state.propertySubjectData = value;
    },
  },
  actions: {
    // 备授课时长排行
    getBskRank({ commit }, data) {
      return new Promise((resolve, reject) => {
        getBskRank(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 备授课时长导出
    exportBskRank({ commit }, data) {
      return new Promise((resolve, reject) => {
        exportBskRank(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 整体统计-平台
    getPlatFormAlData({ commit }, data) {
      return new Promise((resolve, reject) => {
        getPlatFormAlData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 整体统计-区域
    getAreaAlData({ commit }, data) {
      return new Promise((resolve, reject) => {
        getAreaAlData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 整体统计-学校
    getSchoolAlData({ commit }, data) {
      return new Promise((resolve, reject) => {
        getSchoolAlData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 整体统计-机构
    getPlatJgAlData({ commit }, data) {
      return new Promise((resolve, reject) => {
        getPlatJgAlData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 年级下拉框数据
    getGradePropertyData({ commit }, data) {
      return new Promise((resolve, reject) => {
        getGradePropertyData(data)
          .then((res) => {
            commit("GET_GRADE_DATA", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 科目下拉框数据
    getSubjectPropertyData({ commit }, data) {
      return new Promise((resolve, reject) => {
        getSubjectPropertyData(data)
          .then((res) => {
            commit("GET_SUBJECT_DATA", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 科目热度统计
    getSubjectHeat({ commit }, data) {
      return new Promise((resolve, reject) => {
        getSubjectHeat(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取区的json文件
    getJsonCodeData({ commit }, data) {
      return new Promise((resolve, reject) => {
        getJsonCodeData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
