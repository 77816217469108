var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"custom-class":[
      _vm.isExpandDialog ? 'chat-dialog-expand' : 'chat-dialog',
      'chat-dialog-content',
      !_vm.isExpandDialog
        ? _vm.direction === 'left'
          ? 'dialog-left'
          : 'dialog-right'
        : '',
    ].join(' '),"close-on-press-escape":false,"show-close":false,"close-on-click-modal":false,"modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"t-l"},[_c('div',{staticClass:"flex flex-m"},[_c('span',{staticClass:"chat-dialog-title"},[_vm._v(_vm._s(_vm.$t("el.chatgpt.chatgptTitle")))]),_c('span',{staticClass:"chat-dialog-version"},[_vm._v(_vm._s(_vm.$t("el.chatgpt.InternalTestVersion")))]),_c('el-image',{staticClass:"icon-max",attrs:{"src":_vm.isExpandDialog ? _vm.icoSmaller : _vm.iconMaximize},on:{"click":_vm.onExpandDialog}}),_c('el-image',{staticClass:"icon-chat-close",attrs:{"src":_vm.iconChatClose},on:{"click":_vm.onCloseDialog}})],1),_c('div',{staticClass:"point-desc flex flex-m"},[_vm._v(" "+_vm._s(_vm.$t("el.chatgpt.NumberResourcePoints"))+" "+_vm._s(_vm.resourcePoint)+" "),_c('el-tooltip',{attrs:{"effect":"dark","content":_vm.chatTips,"placement":"top","popper-class":"chat-tips","enterable":true}},[_c('el-image',{staticClass:"icon-chat-desc",attrs:{"src":_vm.iconChatDesc}})],1)],1),_c('div',{staticClass:"dash-line",staticStyle:{"width":"calc(100% + 28px)"}}),_c('el-scrollbar',{ref:"myScrollbar",staticClass:"message-content"},[_c('ChatMessage',{attrs:{"isExpandDialog":_vm.isExpandDialog,"messageList":_vm.messageList,"showMoreBtn":_vm.showMoreBtn},on:{"default-message-click":_vm.defaultMessageClick,"getMoreMessage":_vm.getMoreMessage,"onMessageControl":_vm.onMessageControl}})],1),_c('div',{staticClass:"dash-line",staticStyle:{"width":"calc(100% + 28px)"}}),_c('div',{staticClass:"input-content t-c",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"flex flex-m input-text"},[_c('el-input',{attrs:{"disabled":_vm.sendInputDisabled,"type":"textarea","autosize":{ minRows: 1, maxRows: 3 },"placeholder":_vm.chatInputPlaceholder},nativeOn:{"keydown":function($event){return ((...defaultArgs) =>
              _vm.returnKeySendMessage(_vm.message, 1, ...defaultArgs)).apply(null, arguments)}},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('el-button',{staticClass:"btn-chat-send",attrs:{"slot":"append","disabled":_vm.sendDisabled},on:{"click":function($event){return _vm.toSendMessage(_vm.message, 1)}},slot:"append"},[_vm._v(" "+_vm._s(_vm.$t("el.chatgpt.Send"))+" ")])],1),_c('div',{staticClass:"chat-dialog-desc"},[_vm._v(" "+_vm._s(_vm.$t("el.chatgpt.chatDialogTips"))+" ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }