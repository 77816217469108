/**
 * 教务管理-教室管理
 */
import axios from "@/plugins/axios.js";

/**
 * 获取创建人
 * @return {Promise}
 */
export function getCreatorData(schoolCode) {
  if (schoolCode) {
    return axios.get(`/planBase/getCreator/${schoolCode}`);
  } else {
    return axios.get("/planBase/getCreator");
  }
}

/**
 * 排课列表 数据
 * @return {Promise}
 */
export function getPlanCourseList(params) {
  return axios.get("/planBase/page", { params });
}

/**
 * 删除排课
 * @return {Promise}
 */
export function deletePlanCourseData(id) {
  return axios.get(`/planBase/delete/${id}`);
}

/**
 * 排课管理选择课程
 * @param {}
 * @returns
 */
export function planTeachingCourse(params) {
  return axios.get(`/planTeaching/course`, { params });
}

/**
 * 排课管理选择老师
 * @param {}
 * @returns
 */
export function planTeachingTeacher(params) {
  return axios.get(`/planTeaching/teacher`, { params });
}

/**
 * 排课管理选择班级
 * @param {}
 * @returns
 */
export function planTeachingClass(params) {
  return axios.get(`/planTeaching/class`, { params });
}
/**
 * 获取预览课表
 * @param {*} params
 * @returns
 */
export function getCourseTablePreview(params) {
  return axios.get("/courseTable/preview", { params });
}
/**
 * 获取我的课表
 * @param {*} params
 * @returns
 */
export function getMyCourseTable(params) {
  return axios.get("/import/course/myCourseTable", {
    params,
  });
}
/**
 * 完成课表
 * @param {*} params
 * @returns
 */
export function courseTableComplete(planId) {
  return axios.get(`/courseTable/complete/${planId}`);
}
/**
 * 获取查看课表
 * @param {*} params
 * @returns
 */
export function getCourseTableShow(params) {
  return axios.get("/courseTable/show", { params });
}

/**
 * 新增排课
 * @return {Promise}
 */
export function addPlanCourseData() {
  return axios.post("/planBase/init");
}

/**
 * 获取排课计划列表
 * @return {Promise}
 */
export function getPlanTeachingList(planId) {
  return axios.get(`/planTeaching/list/${planId}`);
}

/**
 * 获取课程下拉框列表
 * @return {Promise}
 */
export function getCourseOptions(params) {
  return axios.get(`/planTeaching/planCourse`, {
    params,
  });
}

/**
 * 获取班级下拉框列表
 * @return {Promise}
 */
export function getClassOptions(data) {
  return axios.get(`/classInfo/list/${data.courseId}`);
}

/**
 * 获取授课老师下拉框列表
 * @return {Promise}
 */
export function getTeacherOptions(data) {
  return axios.post("/planBase/getTecher", data);
}

/**
 * 授课计划
 * 点击添加按钮
 * @return {Promise}
 */
export function addNewPlanData(data) {
  return axios.post("/planTeaching/init", data);
}

// 删除某条授课计划
export function deletePlanData(id) {
  return axios.get(`/planTeaching/delete/${id}`);
}

// 授课计划手动保存
export function updatePlanData(data) {
  return axios.post("/planTeaching/updateSave", data);
}

// 授课计划自动保存
export function autoUpdatePlanData(data) {
  return axios.post("/planTeaching/autoUpdate", data);
}

// 生成课表
export function createTeachingSchedule(planId) {
  return axios.post(`/planTeaching/generate/${planId}`);
}

// 获取教务管理-总课表-老师下拉框数据
export function getSchoolStaffList(params) {
  return axios.get("/staffAuth/teacher", {
    params,
  });
}

// 获取总课表
export function getGeneralCourseTable(params) {
  return axios.get("/courseTable/general", {
    params,
  });
}

// 获取总课表 - 导入课表管理
export function getGeneralImportCourse(params) {
  return axios.get("/import/course/general", {
    params,
  });
}

// 导出总课表
export function exportCourseTable(params) {
  return axios({
    method: "GET",
    url: "/courseTable/export",
    params: params,
    responseType: "blob",
  });
  // return axios.get("/courseTable/export",params,{responseType: 'blob'})
}

// 导出总课表 - 导入课表管理
export function exportImportCourse(params) {
  return axios({
    method: "POST",
    url: "/import/course/export",
    params: params,
    responseType: "blob",
  });
}

// 我的-课程班级
export function getMyCourseClassList(params) {
  return axios.get("/classInfo/page", {
    params,
  });
}

// 导出预览课课表
export function exportPlanTable(params) {
  return axios({
    method: "GET",
    url: "/courseTable/planExport",
    params: params,
    responseType: "blob",
  });
  // return axios.get("/courseTable/export",params,{responseType: 'blob'})
}

// 获取总课表课程下拉框数据
export function getTotalTableCourseOptions(params) {
  return axios.get("/courseTable/course", {
    params,
  });
}

// 获取导入课表管理课程下拉框数据
export function getTableCourseOptions(params) {
  return axios.get("/import/course/schoolCourse", {
    params,
  });
}

// 获取总课表班级下拉框数据
export function getTotalTableClassOptions(params) {
  return axios.get("/courseTable/class", {
    params,
  });
}

// 获取总课表老师下拉框数据
export function getTotalTableTeacherOptions(params) {
  return axios.get("/courseTable/teacher", {
    params,
  });
}

// 获取总课表教室下拉框数据 cfd;ree4
export function getTotalTableClassroomOptions(params) {
  return axios.get("/courseTable/classroom", {
    params,
  });
}
/**
 * 老师考勤-获取列表
 * 所在学校/学部 schoolCode
 * 课程名称 courseName
 * 教学班 classId
 * 上课时间 startDate endDate
 * 姓名 staffName
 * 手机号码 telephone
 * 出勤状态 status 0正常 1异常
 * */
export function teacherAttendancePage(data) {
  return axios.get("/teacher/attendance/page", { params: data });
}

// 老师考勤-批量修改考勤
export function updateAttendance(data) {
  return axios.post("/teacher/attendance/updateAttendance/all", data);
}

// 老师考勤-修改考勤
export function updateAttendanceById(data) {
  return axios.post(
    `/teacher/attendance/updateAttendance/${data.teacherAttendanceId}`,
    data
  );
}
/**
 * 学生考勤-获取列表
 * 所在学校/学部 schoolCode
 * 课程名称 courseName
 * 教学班 classId
 * 上课时间 startDate endDate
 * 姓名 studentName
 * 学号 studentNumber
 * 出勤状态 status 0正常 1异常
 * */
export function studentAttendancePage(data) {
  return axios.get("/student/attendance/page", { params: data });
}

// 学生考勤-批量修改考勤
export function updateStudentAttendance(data) {
  return axios.post("/student/attendance/updateAttendance/all", data);
}

// 学生考勤-修改考勤
export function updateStudentAttendanceById(data) {
  return axios.post("/student/attendance/updateAttendance/one", data);
}

// 老师考勤-获取老师列表
export function fetchTeacherList(data) {
  return axios.get(`/courseTable/teacherList/${data.courseId}`, {
    params: data,
  });
}

// 判断课次是否启用
export function courseScheduleIsOpen(importCourseId) {
  return axios.get(`/import/course/checkCourseStatus/${importCourseId}`);
}

// 判断课程班是否被删除
export function getCourseClassDeleteStatus(classId) {
  return axios.get(`/courseClass/check/${classId}`);
}
