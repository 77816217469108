<template>
  <div
    id="app"
    :class="$i18n.locale === languageList[1].name ? 'en-status' : 'zh-status'"
  >
    <chatgpt-board
      v-if="isChildPageLoaded === 1 && gtpOpen === 0 && isBkRouter"
    ></chatgpt-board>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <!-- 其他设备登录提示 -->
    <template v-if="otherDeviceLogin">
      <div class="tip-toast">
        <div class="tip-toast__content">您的账号已在其他设备登录</div>
      </div>
      <div class="tip-toast-mask"></div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getCookieLanguage, setCookieLanguage } from "./core/auth.js";
import chatgptBoard from "./components/biz-components/ChatGptBoard";
export default {
  name: "App",
  data() {
    return {
      languageList: [],
    };
  },
  components: {
    chatgptBoard,
  },
  mounted() {
    window.addEventListener(
      "storage",
      (event) => this.handlerTokenCompare(event),
      false
    );
  },
  beforeDestroy() {
    window.removeEventListener("storage", (event) =>
      this.handlerTokenCompare(event)
    );
  },
  created() {
    // 在页面加载时读取localStorage里的状态信息
    // localStorage.getItem("userMsg") &&
    //   this.$store.replaceState(
    //     Object.assign(
    //       this.$store.state,
    //       JSON.parse(localStorage.getItem("userMsg"))
    //     )
    //   );
    // 设置语言环境
    this.setLanguage();
    /**
     * 在页面刷新时将vuex里的信息保存到localStorage里
     */
    // window.addEventListener("beforeunload", () => {
    //   localStorage.setItem("userMsg", JSON.stringify(this.$store.state));
    // });
  },
  computed: {
    ...mapState({
      otherDeviceLogin: (state) => state.otherDeviceLogin, // 已在其他设备登录
      gtpOpen: (state) => state.user.gtpOpen,
      isChildPageLoaded: (state) => state.user.isChildPageLoaded,
    }),
    isBkRouter() {
      return (
        [
          "teaching",
          "teaching1",
          "hdTeaching",
          "statisticalBoard",
          "PreviewWpsDialog",
        ].indexOf(this.$route.name) === -1
      );
    },
  },
  methods: {
    setLanguage() {
      let languageList = this.$D("LANGUAGE", "languageList");
      this.languageList = languageList;
      let language = getCookieLanguage() || localStorage.getItem("language");
      let item;
      if (language) {
        item = languageList.find((item) => item.name === language);
      }
      if (item) {
        this.$i18n.locale = language;
      } else {
        let lang = navigator.language || navigator.userLanguage;
        lang = lang.substr(0, 2); // 也可以不用截取
        console.log("lang", lang);
        if (lang === "en") {
          // 英文
          this.$i18n.locale = languageList[1].name;
        } else if (lang === "zh") {
          // 中文
          this.$i18n.locale = languageList[0].name;
        } else {
          this.$i18n.locale = languageList[1].name;
        }
      }
      setCookieLanguage(this.$i18n.locale);
    },
    // token监听 租户已切换，刷新页面
    handlerTokenCompare(event) {
      if (event.key === "Saas_Token") {
        const newToken = event.newValue;
        localStorage.removeItem("hasCancelBrowserPrompt");
        location.reload();
      }
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  height: 100%;
}
/deep/ .el-scrollbar.el-scrollbar_x .el-scrollbar__wrap {
  overflow-x: auto;
}
.tip-toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 412px;
  height: 111px;
  background: rgba(29, 29, 29, 0.92);
  border-radius: 12px;
  margin-top: -59.5px;
  &__content {
    font-size: 23px;
    color: rgba(255, 255, 255, 1);
    line-height: 45px;
    letter-spacing: 2px;
    padding-top: 33px;
    text-align: center;
  }
}
.tip-toast-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
<style>
.fa-icon {
  display: inline-block;
  fill: currentColor;
  overflow: visible;
  vertical-align: -0.125em;
}
</style>
