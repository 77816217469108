/**
 * 看板接口文件
 */
import axios from "@/plugins/axios.js";

/**
 * 看板下拉框 1 平台 2 区域 3 学校
 * @return {Promise}
 */
export function getBoardSelect(data) {
  return axios.get(`/statistics/kanban/${data}`);
}
/**
 * 获取切换看板配置
 */
export function getBoardOptions(data) {
  return axios.get("/statistics/options");
}
/**
 * 看板学校数据
 */
export function getAreaSchoolList(data) {
  return axios.get(`/school/schoolSelector?addressCode=${data}`);
}

/**
 * 人均使用时长
 */
export function getAvgTimes(data) {
  return axios.get("/statistics/avg", {
    params: data,
  });
}

/**
 * 获取学生每天人均上课时长
 */
export function getDayClassTimes(data) {
  return axios.get("/duration/page", {
    params: data,
  });
}
/**
 * 人均时长数据
 */
export function getDurationTimeTotal(data) {
  return axios.get("/duration/capita", {
    params: data,
  });
}

/**
 * 导出列表数据
 */
export function exportTableData(url, data) {
  let a = process.env.VUE_APP_BASE_API + `${url}?` + parseParam(data);
  window.location.href = a;
}

// json转url参数
export function parseParam(param, key) {
  let paramStr = "";
  if (
    typeof param === "string" ||
    typeof param === "number" ||
    typeof param === "boolean"
  ) {
    return "&" + key + "=" + encodeURIComponent(param);
  } else {
    let data = null;
    if (key) {
      data = param ? param[key] : "";
    } else {
      data = param;
    }
    for (let keyTemp in data) {
      let dataTemp = data[keyTemp];
      paramStr += parseParam(dataTemp, keyTemp);
    }
  }
  return paramStr.substr(1);
}
