/**
 * 使用情况分析接口文件
 */
import axios from "@/plugins/axios.js";

/**
 * 年级列表
 * @return {Promise}
 */
export function getGradeList() {
  return axios.get("/bi/detailUse/gradeList");
}

/**
 * 科目列表
 * @return {Promise}
 */
export function getSubjectList() {
  return axios.get("/bi/detailUse/subjectList");
}

/**
 * 老师列表
 * @return {Promise}
 */
export function getTeacherList(data) {
  return axios.get("/bi/detailUse/teacherList", {
    params: data,
  });
}

/**
 * 教师备课授课时长对比
 * @return {Promise}
 */
export function teacherCompare(data) {
  return axios.get("/bi/detailUse/teacher/compare", {
    params: data,
  });
}
/**
 * 课程列表
 * @return {Promise}
 */
export function queryCourse(data) {
  return axios.get("/courseManage/queryCourse", {
    params: data,
  });
}
/**
 * 知识点授课使用率
 * @return {Promise}
 */
export function useRate(data) {
  return axios.get("/bi/detailUse/teacher/konwledge/useRate", {
    params: data,
  });
}

/**
 * 跨校共享记录表格
 * @return {Promise}
 */
export function useShareDate(data) {
  return axios.get("/res/platform/page", {
    params: data,
  });
}
/**
 * 跨校共享记录表格内容导出
 */
export function platformExport(data) {
  return axios.post("/res/platform/export", data);
}
/**
 * 资源使用分析表格
 */
export function statisticsPage(data) {
  return axios.get("/resource/statistics/page", {
    params: data,
  });
}
/**
 * 资源使用分析导出表格
 */
export function statisticsExport(data) {
  return axios.post("/resource/statistics/export", data);
}

/**
 * 下载、授课操作记录
 * @param {*} resourceId 资源id
 * @param {*} operateType 2-授课 3-下载
 */
export function addDownloadLikeRecord(resourceId, operateType) {
  return axios.post(
    `/resourceDownloadLikeRecord/record/${resourceId}/${operateType}`
  );
}
/**
 * 获取文件版本号
 * @param {*} resourceId 资源id
 */
export function getVersion(data) {
  return axios.get("/resourceManage/getVersion", {
    params: data,
  });
}
