import axios from "@/plugins/axios.js";

/**
 * 文件加水印
 * @param {*} params 数据
 */
export function uploadWaterMarkDoc(params) {
  return axios.get("/file/uploadWaterMarkDoc", {
    params,
    baseURL: "/fast-file",
  });
}

// 资源管理获取oss直传信息
export function getOssPolicy(params) {
  return axios.get("/oss/policy", { params });
}

// 获取obs上传参数
export function getObsPolicy(params, config) {
  return axios.get("/obs/info/policy", { params, ...(config || {}) });
}

// 获取下载授权链接-需要校验资源
export function getObsUrl(params) {
  return axios.get("/obs/info/download", { params });
}

// 获取下载授权链接-无需校验资源
export function getObsUrlNoCheck(params) {
  return axios.get("/obs/info/getDownloadUrl", { params });
}
