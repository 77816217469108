/**
 * 上课时段管理接口文件
 */
import axios from "@/plugins/axios.js";

/**
 * 列表
 * @return {Promise}
 */
export function getClassTimePage(data) {
  return axios.get("/period/page", {
    params: data,
  });
}
/**
 * 添加上课时段
 */
export function addPeriod(data) {
  return axios.post("/period/add", data);
}
/**
 * 编辑上课时段
 * @param {*} data
 * @returns
 */
export function updatePeriod(data) {
  return axios.post("/period/update", data);
}

/**
 * 删除上课时段
 * @param {*} data
 * @returns
 */
export function deletePeriod(data) {
  return axios.get(`/period/delete/${data}`);
}

/**
 *
 * @param {*} data
 * @returns
 */
export function getSchoolList() {
  return axios.get("/period/getSchoolList");
}
