import Vue from "vue";
import Vuex from "vuex";
import { removeToken, setToken, getToken } from "@/core/auth";
import user from "./modules/user";
import dict from "./modules/dict";
import prepare from "./modules/prepare";
import attendClass from "./modules/attendClass";
import educationManage from "./modules/educationManage";
import classManage from "./modules/classManage";
import board from "./modules/board";
import screen from "./modules/screen";
import userManage from "./modules/userManage";
import Cookies from "js-cookie";
const TeachId = "teachId";
import securityConfig from "./modules/security";
import i18n from "@/tools/i18n.js";
const { pathToRegexp } = require("path-to-regexp");
import { logout } from "@/api/login";
import { courseAuthId } from "@/utils/auth";

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        title: process.env.VUE_APP_TITLE,
        token: getToken(), // 令牌
        // 密码校验配置
        securityConfig: (state) => state.security,
        teachId:
            (navigator.cookieEnabled &&
                (Cookies.get(TeachId) || window.sessionStorage.getItem(TeachId))) ||
            [], // 授课记录id
        hasUserInfo: false, // 是否有用户信息
        otherDeviceLogin: false, // 账号已在其他设备登录
        isInnerNetwork: false, // 是否可以访问内部网络
        documentHidden: document.hidden, // 窗口是否隐藏
        windowFocus: document.hasFocus(), // 窗口是否获取焦点
        formCompare: {
            grade: "",
            subject: "",
        }, // 选择对比范围
        otherParams: [7], // 其他参数
        schoolCode: "", // 对比学校
        course: "", // 对比课程
    },
    getters: {
        staffCode: (state) => state.user.staffCode, // 工号
        staffName: (state) => state.user.staffName, // 姓名
        browserVersionIsHigh: (state) => state.user.browserVersionIsHigh, // 浏览器是否高版本
        browserInfo: (state) => state.user.browserInfo, // 浏览器信息
        userType: (state) => state.user.userType, // 机构角色权限
        gender: (state) => state.user.gender, // 性别
        eduAuth: (state) => state.user.eduAuth, // 权限
        regulator: (state) => state.user.regulator, // 监管权限
        reviewAuth: (state) => state.user.reviewAuth, // 权限
        shardUploadUrl: (state) =>
            (state.isInnerNetwork ? process.env.VUE_APP_INNER_HOST : "") +
            process.env.VUE_APP_UPLOAD_PART_URL, // 分片上传地址
        shardUploadCopyUrl: (state) =>
            (state.isInnerNetwork ? process.env.VUE_APP_INNER_HOST : "") +
            process.env.VUE_APP_UPLOAD_PART_COPY_URL, // 分片上传地址（拷贝）
        downloadUrl: (state) =>
            (state.isInnerNetwork ? process.env.VUE_APP_INNER_HOST : "") +
            "/obs/info/download", // 文件下载地址
        attendSchoolList: (state) => state.attendClass.attendSchoolList,
        creatorList: (state) => state.educationManage.creatorList,
        previewCourseList: (state) => state.classManage.previewCourseList,
        previewClassList: (state) => state.classManage.previewClassList,
        previewTeacherList: (state) => state.classManage.previewTeacherList,
        courseOptions: (state) => state.educationManage.courseOptions,
        classOptions: (state) => state.educationManage.classOptions,
        teacherOptions: (state) => state.educationManage.teacherOptions,
        planStep: (state) => state.educationManage.planStep,
        planObj: (state) => state.educationManage.planObj,
        boardOptionsList: (state) => {
            // 看板radio 选项
            let a = [];
            state.board.boardOptionsList.forEach((element) => {
                let item = {
                    value: element.kanbanType,
                };
                if (element.kanbanType === 1) {
                    item.name = i18n.t("el.board.platformPanel");
                } else if (element.kanbanType === 2) {
                    item.name = i18n.t("el.board.AreaPanel");
                } else if (element.kanbanType === 3) {
                    item.name = i18n.t("el.board.SchoolPanel");
                }
                a.push(item);
            });
            return a;
        },
        boardAddressList: (state) => {
            // 看板学校下拉数据
            let a = [];
            let item = state.board.boardOptionsList.find(
                (item) => item.kanbanType === 3
            );
            if (item) {
                a = item.dataList;
            }
            convertTree(a);
            // a.forEach((element) => {
            //   element.value = element.addressCode;
            //   element.label = element.addressName;
            // });
            return a;
        },
        boardAreaList: (state) => {
            // 看板区域下拉数据
            let a = [];
            let item = state.board.boardOptionsList.find(
                (item) => item.kanbanType === 2
            );
            if (item) {
                a = item.dataList;
            }
            convertTree(a);
            // a.forEach((element) => {
            //   element.value = element.addressCode;
            //   element.label = element.addressName;
            // });
            return a;
        },
        lookBoardInfo: (state) => state.board.lookBoardInfo, // 看板选择后存储的信息
        averageTimedata: (state) => {
            // 平均使用时长数据
            let cdata = {
                category: [],
            };
            // cdata.category = state.board.avgTimesList.map(
            //   (item) => item.statisticDate
            // );
            if (state.board.avgTimesList && state.board.avgTimesList.length > 0) {
                if (
                    state.board.avgTimesList[0].statisticDate.substring(0, 4) ==
                    state.board.avgTimesList[
                        state.board.avgTimesList.length - 1
                    ].statisticDate.substring(0, 4)
                ) {
                    cdata.category = state.board.avgTimesList.map((item) => {
                        return item.statisticDate.substr(5);
                    });
                } else {
                    cdata.category = state.board.avgTimesList.map((item) => {
                        return item.statisticDate;
                    });
                }
                cdata.bkAvgData = state.board.avgTimesList.map(
                    (item) => item.bkAvgTime
                );
                cdata.skAvgData = state.board.avgTimesList.map(
                    (item) => item.skAvgTime
                );
            }

            console.log("cdata", cdata);
            return cdata;
        },
        canAccess: (state) => state.user.canAccess, // 数据分析权限
        isHiddenSign: (state) => state.user.isHiddenSign, // 大华，是否隐藏标志
        isThirdSign: (state) => state.user.isThirdSign, // 是否第三方
        propertyGradeData: (state) => state.screen.propertyGradeData,
        propertySubjectData: (state) => state.screen.propertySubjectData,
        dayClassTimeList: (state) => {
            // 学生平均使用时长信息
            let max = 0;
            state.board.dayClassTimeList.forEach((element) => {
                max = max > element.time ? max : element.time;
            });
            state.board.dayClassTimeList.forEach((element) => {
                if (max === 0) {
                    element.scale = 0;
                } else {
                    element.scale = (element.time / max).toFixed(2);
                }
            });
            return state.board.dayClassTimeList;
        },
        indexImageList: (state) => {
            // 学生平均使用时长信息
            return [
                {
                    titleIcon: require("@/assets/images/icon/icon-course-title-1.png"),
                },
                {
                    titleIcon: require("@/assets/images/icon/icon-course-title-2.png"),
                },

                {
                    titleIcon: require("@/assets/images/icon/icon-course-title-3.png"),
                },
                {
                    titleIcon: require("@/assets/images/icon/icon-course-title-4.png"),
                },
                {
                    titleIcon: require("@/assets/images/icon/icon-course-title-5.png"),
                },
                {
                    titleIcon: require("@/assets/images/icon/icon-course-title-6.png"),
                },
                {
                    titleIcon: require("@/assets/images/icon/icon-course-title-7.png"),
                },
                {
                    titleIcon: require("@/assets/images/icon/icon-course-title-8.png"),
                },
                {
                    titleIcon: require("@/assets/images/icon/icon-course-title-9.png"),
                },
            ];
        },

        getGradeListData: (state) => {
            return [
                {
                    id: "-1",
                    cnName: "全部",
                },
                ...state.userManage.gradeList,
            ];
        },
        getAddGradeListData: (state) => {
            return [
                {
                    id: "-1",
                    cnName: "全部",
                },
                ...state.userManage.addGradeList,
            ];
        },
        // 学校/学部 tree 带不限定
        getSchoolTreeList: (state) => {
            getSchoolTreeData(3, state.userManage.schoolTreeList);
            let schoolTreeList = JSON.parse(
                JSON.stringify(state.userManage.schoolTreeList)
            );
            schoolTreeList.unshift({
                parentCode: "-1",
                schoolCode: -1,
                schoolLevel: 1,
                schoolName: "不限定",
            });
            return schoolTreeList;
        },
        // 学校/学部 tree
        getSchoolTreeOption: (state) => {
            getSchoolTreeData(3, state.userManage.schoolTreeList);
            return state.userManage.schoolTreeList;
        },
        // 年级课程列表数据
        getCourseAuthSubjectList: (state) => {
            return (authCourseIds, initCourseId, type) => {
                const prop =
                    type === "add" ? "addCourseAuthSubjectList" : "courseAuthSubjectList";
                state.userManage[prop].forEach((element) => {
                    let checkedCourseList = [];
                    element.courseAuthInfoList &&
                        element.courseAuthInfoList.forEach((ele) => {
                            let item =
                                authCourseIds &&
                                authCourseIds.length > 0 &&
                                authCourseIds.find((item) => {
                                    if (typeof item === "string") {
                                        return item === ele._uniqueId;
                                    } else {
                                        return courseAuthId(item) === ele._uniqueId;
                                    }
                                });
                            if (item) {
                                checkedCourseList.push(ele._uniqueId);
                            }
                            // 设置初始数据显示标记
                            let obj =
                                initCourseId &&
                                initCourseId.length > 0 &&
                                initCourseId.find((obj) => {
                                    if (typeof obj === "string") {
                                        return obj === ele._uniqueId;
                                    } else {
                                        return obj._uniqueId === ele._uniqueId;
                                    }
                                });
                            if (obj) {
                                ele.initChose = true; // 是初始默认数据选中时，设置标记
                            }
                        });
                    element.checkAll =
                        checkedCourseList.length === element.courseAuthInfoList.length;
                    element.isIndeterminate =
                        checkedCourseList.length > 0 &&
                        checkedCourseList.length < element.courseAuthInfoList.length;
                    element.checkedCourseList = checkedCourseList;
                });
                return state.userManage[prop];
            };
        },
        // 年级课程列表总数
        getCourseAuthSubjectTotal: (state) => {
            return state.userManage.courseAuthSubjectTotal;
        },
        // 用户授权可访问菜单路由
        authMenu: (state) => {
            return state.user.authMenu;
        },
        // 动态路由
        dynamicAuthRegular: (state) => {
            let arr = [];
            for (let i of state.user.authMenu.keys()) {
                if (i.includes("/:")) {
                    arr.push(pathToRegexp(i));
                }
            }
            return arr;
        },
        // 字典 学年下拉
        schoolYearOptions: (state) => {
            return state.dict.SCHOOL_YEAR.map((item) => ({
                label: item.dictionaryText,
                value: item.dictionaryKey,
            }));
        },
        // 字典 学期下拉
        termOptions(state) {
            return state.dict.TERM.map((item) => ({
                label: item.dictionaryText,
                value: item.dictionaryKey,
            }));
        },
    },
    mutations: {
        /**
         * 设置授课记录ID
         * @param {*} state 状态
         * @param {string}
         */
        setTeachId(state, teachId) {
            let teachIds = state.teachId;
            if (state.teachId && state.teachId.length) {
                teachIds = JSON.parse(state.teachId);
            }
            teachIds.push(teachId);
            state.teachId = teachIds;
            window.sessionStorage.setItem(TeachId, teachIds);
            return Cookies.set(TeachId, teachIds);
        },
        /**
         * 删除记录ID
         * @param {*} state 状态
         * @param {string}
         */
        delTeachId(state, resourceId) {
            let teachIds = state.teachId;
            if (state.teachId) {
                if (Array.isArray(state.teachId)) {
                    teachIds = state.teachId;
                } else {
                    teachIds = JSON.parse(state.teachId);
                }
            }
            // console.log(teachIds, 'teachIds')
            teachIds.forEach((item, index) => {
                for (let key in item) {
                    // console.log(resourceId, key)
                    if (key == resourceId) {
                        teachIds.splice(index, 1);
                    }
                }
            });
            state.teachId = teachIds;
            if (!state.teachId.length) {
                window.sessionStorage.removeItem(TeachId);
                Cookies.remove(TeachId);
            } else {
                window.sessionStorage.setItem(TeachId, teachIds);
                return Cookies.set(TeachId, teachIds);
            }
        },
        delAllTeachId(state) {
            state.teachId = [];
            window.sessionStorage.setItem(TeachId, []);
            return Cookies.set(TeachId, []);
        },
        /**
         * 设置令牌
         * @param {*} state 状态
         * @param {string} token 令牌
         */
        setToken(state, token) {
            state.token = token;
            setToken(token);
        },
        /**
         * 删除令牌
         * @param {*} state 状态
         */
        removeToken(state) {
            state.token = "";
            removeToken();
        },
        /**
         * 标记以获取用户信息
         * @param {*} state 状态
         * @param {Boolean} value 值
         */
        setHasUserInfo(state, value) {
            state.hasUserInfo = value;
        },
        /**
         * 设置是否是内部网络
         * @param {*} state 状态
         * @param {*} value 之
         */
        setIsInnerNetwork(state, value) {
            return (state.isInnerNetwork = !!value);
        },
        /**
         * 设置当前窗口是否隐藏
         * @param {*} state 状态
         * @param {*} value 值
         */
        setDocumentHidden(state, value) {
            // document.title = value ? '我隐藏了' : '看见我啦';
            state.documentHidden = value;
        },
        /**
         * 设置当前窗口是否获取焦点
         * @param {*} state 状态
         * @param {*} value 值
         */
        setWindowFocus(state, value) {
            // document.title = value ? '获取焦点' : '失去焦点';
            state.windowFocus = value;
        },
        /**
         * 设置对比范围
         * @param {*} state 状态
         * @param {*} params
         * @param {string} grade 年级
         * @param {string} subject 科目
         */
        setFormCompare(state, params) {
            state.formCompare.grade = params.grade;
            state.formCompare.subject = params.subject;
            state.formCompare.teacher = params.teacher;
        },
        /**
         * 设置对比时间
         * @param {*} state 状态
         * @param {*} otherParams
         */
        setOtherParams(state, otherParams) {
            state.otherParams = otherParams;
        },
        /**
         * 设置对比学校
         * @param {*} state 状态
         * @param {*} schoolCode
         */
        setChangeSchool(state, schoolCode) {
            state.schoolCode = schoolCode;
        },
        /**
         * 设置对比课程
         * @param {*} state 状态
         * @param {*} course
         */
        setCourse(state, course) {
            state.course = course;
        },
    },
    actions: {
        /**
         * 设置授课记录ID
         * @param {*} state 状态
         * @param {string}
         */
        setTeachId({ commit, state }, teachIds) {
            commit("setTeachId", teachIds);
        },
        /**
         * 前端退出
         * @param {Function} param0 提交
         */
        async logout({ commit }) {
            await logout();
            commit("removeToken");
        },
        /**
         * 强制登出
         */
        forceLogout({ commit, state }) {
            state.otherDeviceLogin = true;
            commit("removeToken");
        },
        /**
         * 加载数据
         */
        async loadData({ state, dispatch }) {
            return new Promise((resolve, reject) => {
                try {
                    // 优先调用接口，保证后端用户数据初始化
                    dispatch("loadUserInfo").then((res) => {
                        if (res.tenantType) {
                            if (
                                res.tenantType !== "2" &&
                                process.env.NODE_ENV !== "development"
                            ) {
                                // 如果token切换成非学校端，则页面跳转到admin端
                                window.location.href = window.baseConfig.VUE_APP_AI_ADMIN;
                                return;
                            }
                            Promise.all([
                                dispatch("loadBaseData"), // 字典
                                dispatch("loadSaasBase"),
                                dispatch("loadAuthMenu"), // 用户授权菜单
                                dispatch("loadAuthBtn"), // 用户授权按钮
                            ]).then(() => {
                                resolve();
                            });
                        } else {
                            // 无权限
                            resolve();
                        }
                    });
                } catch (error) {
                    reject(error);
                }
            });
        },
        // 数据看板 暂时只需要获取用户基本信息以及菜单权限
        loadBoardData({ state, dispatch }) {
            return Promise.all([
                dispatch("loadUserInfo"), // 用户基本信息
                dispatch("loadAuthMenu"), // 用户授权菜单
            ]);
        },
    },
    modules: {
        user,
        dict,
        prepare,
        attendClass,
        educationManage,
        classManage,
        screen,
        board,
        userManage,
        securityConfig,
    },
});
function getSchoolTreeData(num, data, ele) {
    if (data && data.length > 0) {
        data.forEach((element) => {
            let data = element.children;
            getSchoolTreeData(num, data, element);
        });
    } else {
        // if (ele && ele.schoolLevel < num) {
        //   ele.disabled = true;
        // }
    }
}
function convertTree(data) {
    data.forEach((element) => {
        element.label = element.addressName;
        element.value = element.addressCode;

        if (element.children && element.children.length > 0) {
            convertTree(element.children);
        } else {
            // if (
            //   element.level === 3 ||
            //   element.level === 2 ||
            //   element.level === 1
            // ) {
            //   element.disabled = true;
            // }
        }
        // if (element.level === 4) {
        //   if (element.enable) {
        //     element.disabled = true;
        //   } else {
        //     element.disabled = false;
        //   }
        // }
    });
}

// 重置state数据
export function clearState(state, initData) {
    Object.keys(state).forEach((key) => {
        if (!initData[key]) {
            delete state[key];
        }
    });
    Object.keys(initData).forEach((key) => {
        // state[key] = initData[key];
        // 为了数据响应式
        Vue.set(state, key, initData[key]);
    });
}
