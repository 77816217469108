/**
 * 排课管理-课程管理
 */
import axios from "@/plugins/axios.js";

// 根据树选择的筛选条件 获取课程列表
export function getCourseList(params) {
  return axios.get("/courseManage/queryManageCourse", { params });
}
// 根据树选择的筛选条件 获取课程列表（选课管理的添加课程）
export function getCourseChooseList(params) {
  return axios.get("/courseManage/choose/optional/courses", { params });
}

/**
 * 查询年级 科目
 * @return {Promise}
 */
export function getTermList() {
  return axios.get("/courseProperty/listChild/termId");
}
