<template>
  <!-- 查询表单、列表、分页高度封装插件 -->
  <div>
    <!-- 查询表单 start -->
    <xm-form
      v-if="!hideForm"
      ref="form"
      :form-items="formItems"
      :form-options="newFormOptions"
      :form-data="queryParams"
      @keyup.enter.native="query"
      @submit.native.prevent
    >
      <el-form-item slot="formBtn">
        <template
          v-if="
            newFormOptions &&
            !newFormOptions.hiddenDefaultFormBtn &&
            !newFormOptions.newDefaultFormBtn
          "
        >
          <el-button
            :loading="loading"
            icon="el-icon-search"
            type="primary"
            @click="query"
            >{{ $t("el.common.search") }}</el-button
          >
          <el-button
            :loading="loading"
            icon="el-icon-refresh"
            type="primary"
            @click="resetForm"
            >{{ $t("el.common.reset") }}</el-button
          >
        </template>
        <template v-if="newFormOptions && newFormOptions.newDefaultFormBtn">
          <el-button
            class="new-default-form-btn"
            :loading="loading"
            type="primary"
            size="small"
            @click="query"
            >{{ $t("el.common.search") }}</el-button
          >
          <el-button
            class="new-default-form-btn"
            :loading="loading"
            size="small"
            @click="resetForm"
            >{{ $t("el.common.reset") }}</el-button
          >
        </template>
        <slot name="formBtn" />
      </el-form-item>
    </xm-form>
    <!-- 查询表单 end -->
    <el-row style="margin-bottom: 10px">
      <slot name="formBtn1" />
    </el-row>
    <!-- 列表 start -->
    <div class="xm-table">
      <el-table
        ref="table"
        v-loading="loading"
        v-bind="newTableOptions"
        :header-cell-class-name="
          newTableOptions.customCellStyle
            ? newTableOptions.headerStyle
            : headerStyle
        "
        :cell-class-name="
          newTableOptions.customCellStyle
            ? newTableOptions.cellStyle
            : cellStyle
        "
        :data="data"
        :row-key="rowKey"
        @selection-change="setSelections"
        @select="(selections, row) => emitEvent('select', selections, row)"
        @select-all="(selections) => emitEvent('select-all', selections)"
        @cell-mouse-enter="
          (row, column, cell, event) =>
            emitEvent('cell-mouse-enter', row, column, cell, event)
        "
        @cell-mouse-leave="
          (row, column, cell, event) =>
            emitEvent('cell-mouse-leave', row, column, cell, event)
        "
        @cell-click="
          (row, column, cell, event) =>
            emitEvent('cell-click	', row, column, cell, event)
        "
        @cell-dblclick="
          (row, column, cell, event) =>
            emitEvent('cell-dblclick', row, column, cell, event)
        "
        @row-click="
          (row, event, column) => emitEvent('row-click', row, event, column)
        "
        @row-contextmenu="
          (row, event) => emitEvent('row-contextmenu', row, event)
        "
        @row-dblclick="(row, event) => emitEvent('row-dblclick', row, event)"
        @header-click="
          (column, event) => emitEvent('header-click', column, event)
        "
        @sort-change="
          ({ column, prop, order }) =>
            emitEvent('sort-change', { column, prop, order })
        "
        @filter-change="(filters) => emitEvent('filter-change', filters)"
        @current-change="
          (currentRow, oldCurrentRow) =>
            emitEvent('current-change', currentRow, oldCurrentRow)
        "
        @header-dragend="
          (newWidth, oldWidth, column, event) =>
            emitEvent('header-dragend', newWidth, oldWidth, column, event)
        "
        @expand-change="
          (row, expandedRows) => emitEvent('expand-change', row, expandedRows)
        "
      >
        <slot name="table-prepend" />
        <template slot="empty">
          <div style="padding-top: 111px; line-height: 0">
            <img
              v-if="!sreenEmpty"
              src="@/assets/images/pic_empty_people@2x.png"
              alt=""
              style="width: 270px"
            />
            <img
              v-else
              src="@/assets/images/no-data.png"
              alt=""
              style="width: 270px"
            />
          </div>
          <div
            style="
              color: rgba(100, 108, 149, 0.25);
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 1px;
              padding-bottom: 120px;
            "
          >
            {{ noEmptyText ? $t(noEmptyText) : $t("el.common.noResources") }}
            <p class="plan-btn-add" v-if="showPlanBtn">
              <el-button
                @click="toAddPlan"
                type="primary"
                size="small"
                class="mt20"
                >去排课</el-button
              >
            </p>
          </div>
        </template>
        <!-- 渲染列 start -->
        <template v-for="(column, index) in columns">
          <el-table-column
            :show-overflow-tooltip="
              column.showtooltip === 'false' ? false : true
            "
            v-if="!column.type"
            v-bind="column"
            :key="index"
          >
            <template slot="header" slot-scope="scope">
              <span>{{ scope.column.label }}</span>
              <el-tooltip
                v-if="column.tip"
                class="xm-table-column-header-tip"
                effect="dark"
                :content="column.tip"
                placement="top"
              >
                <i class="el-icon-question" />
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <!-- 过滤器 start -->
              <template v-if="column.filter">
                {{ Vue.filter(column.filter)(scope.row[column.prop]) }}
              </template>
              <!-- 过滤器 end -->

              <template v-else-if="column.lineRule">
                <tooltip-over
                  v-if="column.singleAble"
                  :content="
                    column.formatter
                      ? column.formatter(
                          scope.row,
                          scope.column,
                          scope.row[column.prop],
                          scope.$index
                        )
                      : scope.row[column.prop]
                  "
                  :singleAble="true"
                ></tooltip-over>
                <!-- 超出悬浮，不超出不悬浮 -->
                <tooltip-over
                  v-else
                  :content="
                    column.formatter
                      ? column.formatter(
                          scope.row,
                          scope.column,
                          scope.row[column.prop],
                          scope.$index
                        )
                      : scope.row[column.prop]
                  "
                  class="class-menu-name"
                  :singleAble="false"
                  :overFlowNum="column.overFlowNum || 0"
                  :computedLineHeight="
                    column.computedLineHeight
                      ? column.computedLineHeight
                      : false
                  "
                  :spanWidth="column.spanWidth ? column.spanWidth : 0"
                  :refName="'tooltipOver' + column.prop"
                ></tooltip-over>
              </template>

              <!-- 使用slot插入 start -->
              <slot
                v-else-if="column.slotName"
                v-bind="scope"
                :name="column.slotName"
              />
              <!-- 使用slot插入 end -->
              <!-- 格式化文本 start -->
              <template v-else-if="column.formatter">
                {{
                  column.formatter(
                    scope.row,
                    scope.column,
                    scope.row[column.prop],
                    scope.$index
                  )
                }}
              </template>
              <!-- 格式化文本 end -->
              <!-- 字典翻译 start -->
              <template v-else-if="column.dictName">
                {{ scope.row[column.prop] | dict(column.dictName) }}
              </template>
              <!-- 字典翻译 end -->
              <!-- 展示时间 YYYY/MM/DD HH:mm:ss start -->
              <template v-else-if="column.showType === 'time'">
                {{ getFormatTime(scope.row[column.prop]) }}
              </template>
              <!-- 展示时间 YYYY/MM/DD HH:mm:ss end -->
              <!-- 判空 start -->
              <template
                v-else-if="isNullOrUnDefOrEmpty(scope.row[column.prop])"
              >
                <span>-</span>
              </template>
              <!-- 判空 end -->
              <!-- 默认显示值 start -->
              <template v-else>
                {{ scope.row[column.prop] }}
              </template>
              <!-- 默认显示值 end -->
            </template>
          </el-table-column>
          <!-- 操作按钮 start -->
          <el-table-column
            v-else-if="column.type === 'handle'"
            v-bind="column"
            :key="index"
          >
            <template slot-scope="scope">
              <template v-for="(btn, index) in column.btns">
                <slot v-if="btn.slotName" :name="btn.slotName" v-bind="scope">
                  <el-button
                    :key="index"
                    v-bind="btn"
                    @click="
                      emitEvent(
                        btn.method,
                        scope.row,
                        scope.column,
                        scope.row[column.prop],
                        scope.$index
                      )
                    "
                  >
                    {{ btn.label }}
                  </el-button>
                </slot>
                <el-button
                  v-else
                  :key="index"
                  v-bind="btn"
                  @click="
                    emitEvent(
                      btn.method,
                      scope.row,
                      scope.column,
                      scope.row[column.prop],
                      scope.$index
                    )
                  "
                >
                  {{ btn.label }}
                </el-button>
              </template>
            </template>
          </el-table-column>
          <!-- 操作按钮 end -->
          <el-table-column
            v-else
            :key="index"
            v-bind="column"
          ></el-table-column>
        </template>
        <!-- 渲染列 end -->
        <!-- 其他列 start -->
        <slot name="table-append" />
        <!-- 其他列 end -->
      </el-table>
    </div>

    <!-- 列表 end -->
    <!-- 分页 start -->
    <div class="teachManage-page">
      <el-pagination
        v-if="showPagination"
        v-bind="pagingOptions"
        layout="prev, pager, next"
        :current-page.sync="pagingParams.page"
        :page-sizes="pageSizes"
        :page-size="pagingParams.limit"
        :total="pagingParams.total"
        @size-change="setLimit"
      />
      <span class="el-pagination__total" v-if="showTotal"
        >{{ $t("el.common.totalname") }}
        {{ Math.ceil(pagingParams.total / pagingParams.limit) }}
        {{ $t("el.common.pages") }} {{ pagingParams.total }}
        {{ $t("el.common.items") }}
      </span>
    </div>
    <!-- 分页 end -->
  </div>
</template>

<script>
import Vue from "vue";
import XmForm from "./xm-form";
import tooltipOver from "../biz-components/tooltipOver/index.vue";
import { isNullOrUnDefOrEmpty } from "@/utils/is";
import { getFormatTime } from "@/utils";
function checkType(value, arr) {
  var res = arr.indexOf(("" + value).toLowerCase()) !== -1;
  !res && console.error(`没有该${value}值，请配置${arr.join("、")}其中一项`);
  return res;
}
export default {
  name: "XmTable",
  components: {
    XmForm,
    tooltipOver,
  },
  props: {
    /**
     * 列表数据类型
     * remote: 远程数据
     * local: 本地数据
     */
    type: {
      type: String,
      default: "remote",
      validator(value) {
        return checkType(value, ["remote", "local"]);
      },
    },
    /**
     * 列表请求地址
     */
    url: {
      type: String,
      default: null,
    },
    /**
     * 请求方法
     * 允许的请求类型：get、post
     */
    method: {
      type: String,
      default: "get",
      validator(value) {
        return checkType(value, ["get", "post"]);
      },
    },
    /**
     * 获取数据方法
     */
    fatchMethod: {
      type: Function,
      default: null,
    },
    /**
     * 参数处理
     */
    paramsHandle: {
      type: Function,
      default: null,
    },
    /**
     * 表格数据
     */
    tableData: {
      type: Array,
      default: null,
    },
    /**
     * 数据处理
     */
    dataHandle: {
      type: Function,
      default: null,
    },
    /**
     * 表单配置项
     */
    formItems: {
      type: Array,
      default: () => [],
    },
    /**
     * 列配置
     * label: 列名称
     * prop: 显示的字段名
     * 以及：el-table-column的属性
     *
     * 以下扩展属性：
     * filter: 使用过滤器显示数据
     * slotName: 使用指定的插槽
     * formatter: 格式化数据函数（value, row, index, column）
     */
    columns: {
      type: Array,
      default: () => [],
    },
    /**
     * 表格配置属性
     */
    tableOptions: {
      type: Object,
      default: () => {},
    },
    noEmptyText: {
      type: String,
      default: "",
    },
    /**
     * 表配置属性
     */
    formOptions: {
      type: Object,
      default: () => {},
    },
    /**
     * 分页配置属性
     */
    pagingOptions: {
      type: Object,
      default: () => {},
    },
    /**
     * 自动加载
     */
    autoLoad: {
      type: Boolean,
      default: true,
    },
    /**
     * 是否展示去排课按钮
     */
    showPlanBtn: {
      type: Boolean,
      default: false,
    },
    /**
     * 默认当前页条数
     */
    defaultLimit: {
      type: Number,
      default: 10,
    },
    /**
     * 默认当前页
     */
    defaultPage: {
      type: Number,
      default: 1,
    },
    /**
     * 当前页条数选项
     */
    pageSizes: {
      type: Array,
      default: () => [10, 50, 100, 500, 1000],
    },
    /**
     * 是否显示分页组件
     */
    showPagination: {
      type: Boolean,
      default: true,
    },
    /**
     * 是否显示total
     */
    showTotal: {
      type: Boolean,
      default: true,
    },
    /**
     * 是否隐藏查询表单
     */
    hideForm: {
      type: Boolean,
      default: false,
    },
    // 是否是数据大屏的缺省图
    sreenEmpty: {
      type: Boolean,
      default: false,
    },
    rowKey: {
      type: Function,
      default: null,
    },
  },
  data() {
    var queryParams = {};
    this.formItems &&
      this.formItems.forEach((item) => {
        queryParams[item.prop] = "";
      });
    return {
      Vue, // Vue
      isNullOrUnDefOrEmpty: isNullOrUnDefOrEmpty,
      getFormatTime: getFormatTime,
      loading: false, // 列表加载状态
      data: [], // 列表数据
      pagingParams: {
        // 分页参数
        total: 0, // 数据总数
        limit: this.defaultLimit, // 单页记录数
        offset: 0, // 偏移量
        page: this.defaultPage, // 当前页数
      },
      queryParams, // 查询参数
      selections: [], // 选中行
      nowQueryParams: {}, // 当前查询参数
      notResetFields: {}, // 不重置项
    };
  },
  computed: {
    /**
     * 继承表单配置
     */
    newFormOptions() {
      return {
        inline: true,
        ...this.formOptions,
      };
    },
    /**
     * 继承表格配置
     */
    newTableOptions() {
      return {
        border: true,
        ...this.tableOptions,
      };
    },
    /**
     * 是否有查询表单
     */
    hasQueryForm() {
      return this.formItems && this.formItems.length;
    },
    computedQueryParams() {
      return JSON.parse(JSON.stringify(this.queryParams));
    },
  },
  watch: {
    /**
     * 如果本地获取自动更新，传入的数据
     */
    tableData(newVal, oldVal) {
      if (this.type.toLowerCase() === "local") this.data = newVal;
    },
    /**
     * 监听page
     */
    "pagingParams.page"(newVal, oldVal) {
      this.setPage(newVal);
    },
    /**
     * 保存不重置项
     */
    formItems: {
      handler(newVal, oldVal) {
        this.notResetFields = {};
        newVal &&
          newVal.forEach((item) => {
            if (item.notReset) this.notResetFields[item.prop] = true;
            // 设置 defaultParam 时 根据参数判断，不设置时 select 组件默认选中第一个
            if (item.itemType === "select" && item.selectDefault) {
              if (item.options.length !== 0) {
                let hasDefault = false;
                item.options.forEach((element) => {
                  if (item.defaultParam && element[item.defaultParam]) {
                    this.queryParams[item.prop] = element[item.prop];
                    hasDefault = true;
                  }
                });
                if (!hasDefault) {
                  this.queryParams[item.prop] = item.options[0][item.prop];
                }
              }
            }
          });
      },
      immediate: true,
    },
    computedQueryParams: {
      handler(newVal, oldVal) {
        this.$emit("changeQueryParams", newVal, oldVal);
      },
      deep: true,
    },
  },
  created() {
    // 判断是否要自动加载数据
    this.autoLoad && this.fatchListData();
  },
  methods: {
    // 点击去排课，跳转页面
    toAddPlan() {
      this.$store.dispatch("addPlanCourseData").then((res) => {
        if (res.success) {
          this.$router.push({
            name: "planCourseDetails",
            query: { id: res.data },
          });
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    headerStyle({ row, rowIndex }) {
      return "bskHeader";
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "bskCell";
    },
    query() {
      this.pagingParams.page = 1;
      this.fatchListData();
    },
    /**
     * 更新limit
     * @param {Number} limit 偏移量
     */
    setLimit(limit) {
      this.pagingParams.limit = limit;
      this.fatchListData();
      this.emitEvent("size-change", limit);
    },
    /**
     * 更新当前页
     * @param {Number} page 页数
     */
    setPage(page) {
      this.pagingParams.page = page;
      this.fatchListData();
      this.emitEvent("current-change", page);
    },
    /**
     * 更新选中项
     * @param {Array} selections 选中项数组
     */
    setSelections(selections) {
      this.selections = selections;
      this.emitEvent("selection-change", selections);
    },
    /**
     * 触发事件
     * @param {String} eventName 事件名
     * @param {*} ... 其他参数
     */
    emitEvent(eventName) {
      // 其他参数
      var args = [].slice.call(arguments, 1);
      this.$emit(eventName, ...args);
    },
    /**
     * 获取查询参数
     */
    getQueryParams(isForm) {
      let params;
      if (isForm) {
        params = {
          ...this.queryParams,
        };
      } else {
        params = {
          ...this.pagingParams,
          ...this.queryParams,
        };
      }
      // 处理分页参数
      params["offset"] =
        // eslint-disable-next-line no-prototype-builtins
        params.hasOwnProperty("page") && params.hasOwnProperty("limit")
          ? (+params.page - 1) * +params.limit || 0
          : 0;
      // 执行参数处理方法
      if (this.paramsHandle) params = this.paramsHandle(params);
      this.nowQueryParams = params;
      return params;
    },
    /**
     * 获取列表数据
     */
    fatchListData() {
      switch (this.type.toLowerCase()) {
        case "remote":
          this.fatchRemoteData();
          break;
        case "local":
          this.fatchLocalData();
          break;
      }
    },
    /**
     * 获取远程数据
     */
    fatchRemoteData() {
      // 预防重复获取
      if (this.loading) return;
      // 异步对象
      var promise;
      var params = this.getQueryParams();
      // 如果offset大于total,依靠分页组件有效最大值触发请求
      if (params.offset > params.total) return;
      this.loading = true;
      // 优先使用传入的获取数据方法
      if (this.fatchMethod) {
        promise = this.fatchMethod(params);
      } else {
        switch (this.method.toLowerCase()) {
          case "get":
            promise = this.$axios.get(this.url, {
              params,
            });
            break;
          case "post":
            promise = this.$axios.post(this.url, params);
            break;
        }
      }
      // 处理数据
      if (!promise) return;
      promise
        .then((data) => {
          // 数据处理方法
          if (this.dataHandle) data = this.dataHandle(data);
          data = data.data;
          console.log(data);
          if (Array.isArray(data.records)) {
            this.data = data.records;
            this.pagingParams.total = data.total || 0;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    /**
     * 获取本地数据
     */
    fatchLocalData() {
      this.data = this.tableData;
    },
    /**
     * 获取列表数据
     */
    getNowDataList() {
      return this.data;
    },
    /**
     * 获取当前查询参数
     */
    getNowQueryParams() {
      return this.nowQueryParams;
    },
    /**
     * 设置表单值
     */
    setFormValue(key, value) {
      this.queryParams[key] = value;
    },
    /**
     * 获取表单值
     */
    getFormValue(key) {
      return this.queryParams[key];
    },
    /**
     * 重置表单
     */
    resetForm() {
      // $nextTick(() => $refs['form'].resetForm())
      // 重置表单
      Object.keys(this.queryParams).forEach((key) => {
        if (this.notResetFields[key]) return;
        this.queryParams[key] = "";
      });
      this.emitEvent("reset-form", this.queryParams);
      // 重置分页
      this.pagingParams.limit = this.defaultLimit;
      this.pagingParams.page = this.defaultPage;
      if (this.pagingParams.page === this.defaultPage) {
        this.pagingParams.page = this.defaultPage;
        this.fatchListData();
      } else {
        this.pagingParams.page = this.defaultPage;
      }
    },
    /**
     * 计算是一行还是两行
     * 设置classname
     */
    computedTwolineClass(text) {
      if (text.length > 9) {
        return "";
      } else {
        return "more-line-height";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.xm-table {
  padding: 0;
  /deep/ .el-table__empty-block {
    width: 100% !important;
  }
  /deep/.bskHeader {
    background-color: #f5f6f7 !important;
    color: #323232;
    text-align: center !important;
    font-size: 14px;
    // font-family: "MicrosoftYaHei";
    font-weight: normal;
    height: 50px;
    padding: 0;
    .cell {
      white-space: pre-line;
    }
  }
  /deep/.bskCell {
    text-align: center !important;
    color: #707276;
    font-size: 14px;
    height: 46px;
    padding: 0;
    .cell {
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      white-space: nowrap;
      display: block;
      div {
        width: 100%;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        white-space: nowrap;
        display: block;
      }
    }
  }

  /deep/.bskCell-2 {
    // 单元格2行样式
    .cell {
      word-break: break-all;
      white-space: normal;
      display: -webkit-box;
    }
  }
  /deep/ .el-table-column--selection {
    &:first-child {
      .cell {
        padding-left: unset;
        padding-right: unset;
      }
    }
  }
  /deep/.el-button--text {
    border: 0;
    color: @themeBlue;
    a {
      color: @themeBlue;
    }
  }
  /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background: #f1f6ff;
  }
}

/deep/ .el-pager li {
  height: 30px;
  line-height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: 50%;
  padding: 0 0px;
  margin: 0 5px;
  font-size: 13px;
  // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
  font-weight: 400;
  color: rgba(40, 40, 40, 1);
}
/deep/ .el-pager li.active {
  background: @themeBlue;
  color: #fff;
  font-size: 13px;
  // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
}
/deep/.el-pagination__total {
  font-size: 12px;
  // font-family: "MicrosoftYaHei";
  color: rgba(40, 40, 40, 1);
}
/deep/.el-pagination button {
  height: 30px;
  line-height: 30px;
}
/deep/ .el-pagination button:hover {
  color: @themeBlue;
}
.teachManage-page {
  display: flex;
  align-items: center;
  margin: 30px auto 0;
  text-align: center;
  justify-content: center;
}
.class-menu-name {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
  white-space: normal !important;
  /*! autoprefixer: off */
}
.plan-btn-add {
  margin: 0;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #5a53f5;
  border-color: #5a53f5;
}
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #5a53f5;
  border-color: #5a53f5;
}
</style>
<style lang="less">
.el-tooltip__popper {
  max-width: 50%;
}
.new-default-form-btn {
  padding: 9px 12px;
  border-radius: 5px;
  font-size: 12px !important;
}
.new-default-form-btn.el-button--default {
  &:hover {
    background: #fff;
    color: @themeBlue;
  }
}
.xm-table-column-header-tip {
  display: inline-block;
  margin-left: 4px;
}
</style>
