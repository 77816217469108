import Vue from "vue";
import * as filters from "./index";
/**
 * 安装过滤器
 */
const install = function (Vue) {
  var keys = Object.keys(filters);
  keys.forEach((key) => {
    Vue.filter(key, filters[key]);
  });
};

if (window.Vue) {
  window["filters"] = filters;
  Vue.use(install); // eslint-disable-line
} else {
  Vue.use(install);
}

export default {
  install,
};
