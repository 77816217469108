import { Message, MessageBox } from "element-ui";
import i18n from "@/tools/i18n.js";
/**
 * 对canvas特性检测
 * @param {*}
 */

export function canvasSupport() {
  return !!document.createElement("canvas").getContext;
}

function canvasApp() {
  if (!canvasSupport()) {
    Message({
      message: i18n.t("el.common.notSupportCanvas"),
      type: "warning",
    });
    return;
  } else {
    // Message({
    //   message: '支持canvas',
    //   type: 'warning'
    // })
  }
}
canvasApp();

checkVideo();

export function checkVideo() {
  if (document.createElement("video").canPlayType) {
    var vidTest = document.createElement("video");
    var oggTest = vidTest.canPlayType('video/ogg; codecs="theora, vorbis"');
    if (!oggTest) {
      var h264Test = vidTest.canPlayType(
        'video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
      );
      if (!h264Test) {
        Message({
          message: i18n.t("el.common.notSupportVideoPlay"),
          type: "warning",
        });
      } else {
        if (h264Test === "probably") {
          // Message({
          //   message: '当前浏览器支持视频播放',
          //   type: 'success'
          // })
        } else {
          Message({
            message: i18n.t("el.common.supportPartVideoPlay"),
            type: "warning",
          });
        }
      }
    } else {
      if (oggTest === "probably") {
        // Message({
        //   message: '当前浏览器支持视频播放',
        //   type: 'success'
        // })
      } else {
        Message({
          message: i18n.t("el.common.supportPartVideoPlay"),
          type: "warning",
        });
      }
    }
  } else {
    Message({
      message: i18n.t("el.common.notSupportVideoPlay"),
      type: "warning",
    });
  }
}
