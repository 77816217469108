/* eslint-disable */
import "./drag.css";
// 拖拽指令
export default {
    /**
     * 绑定
     * @param {HTMLElement} el 节点
     * @param {*} binding
     */
    bind(el, binding, vnode) {
        // 获取绑定值中的 data 和 set 方法
        el._valueData = binding.value ? binding.value.data : null;
        let setMethod = binding.value ? binding.value.set : null; // 获取 set 方法
        let onClick = binding.value ? binding.value.onClick : null; // 获取 set 方法
        let onMove = binding.value ? binding.value.onMove : null; // 获取 move 回调 方法
        // let isMouseMove = false; // 添加变量表示鼠标是否移动
        let clientOffset = {};
        // 获取当前元素和是否是父元素的标志
        var odiv = el;
        var isParent = !!(vnode.data.attrs && vnode.data.attrs["drag-parent"]);
        // 添加 CSS 类名
        el.className =
            el.className + " v-drag " + (isParent ? "v-drag--parent" : "");
        odiv.onmousedown = (e) => {
            e.preventDefault();
            // 算出鼠标相对元素的位置
            let disX = e.clientX - odiv.offsetLeft;
            let disY = e.clientY - odiv.offsetTop;
            var parent = isParent ? el.parentNode : document;

            clientOffset.clientX = e.clientX;
            clientOffset.clientY = e.clientY;

            // 创建阴影元素以防止其他元素的移动事件干扰
            var shade = document.createElement("div");
            shade.className =
                "v-drag-shade " + (isParent ? "v-drag-shade--parent" : "");
            var shadeWrap = isParent ? parent : document.body;
            shadeWrap.appendChild(shade);

            function onmousemove(e) {
                // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                // isMouseMove = true; // 鼠标按下时设置为 true
                if (
                    Math.abs(clientOffset.clientX - e.clientX) > 3 ||
                    Math.abs(clientOffset.clientY - e.clientY) > 3
                ) {
                    onMove && onMove(); // move 回调
                }
                let left = e.clientX - disX;
                let top = e.clientY - disY;
                let valueData = el._valueData;
                let clientWidth = (isParent ? parent : document.documentElement)
                    .clientWidth;
                let clientHeight = (isParent ? parent : document.documentElement)
                    .clientHeight;
                let elWidth = el.offsetWidth;
                let elHeight = el.offsetHeight;
                let maxLeft = clientWidth - elWidth;
                let maxTop = clientHeight - elHeight;
                // console.log("clientWidth======", clientWidth);
                // console.log("elHeight======", elHeight);

                // 根据范围限制元素的移动
                if (valueData && valueData.scope === "none") {
                    // 不限制移动范围
                } else if (valueData && valueData.scope === "screen") {
                    // 在屏幕范围内移动
                    maxLeft = clientWidth;
                    maxTop = clientHeight;
                    // 在屏幕范围之内
                    if (left > maxLeft) {
                        left = maxLeft;
                    }
                    if (left < 0) {
                        left = 0;
                    }
                    if (top > maxTop) {
                        top = maxTop;
                    }
                    if (top < 0) {
                        top = 0;
                    }
                } else {
                    maxLeft = clientWidth - elWidth;
                    maxTop = clientHeight - elHeight;
                    // 默认的移动范围
                    if (left > maxLeft) {
                        left = maxLeft;
                    }
                    if (left < 0) {
                        left = 0;
                    }
                    if (top > maxTop) {
                        top = maxTop;
                    }
                    if (top < 0) {
                        top = 0;
                    }
                }
                // 移动当前元素
                if (valueData && valueData.direction === "all") {
                    odiv.style.left = left + "px"; // 注释掉，使其不能左右移动
                    odiv.style.top = top + "px";
                } else if (valueData && valueData.direction === "x") {
                    odiv.style.left = left + "px"; // 左右移动
                } else {
                    odiv.style.top = top + "px";
                }
            }
            function onmouseup(event) {
                let windowWidth = window.innerWidth;
                let valueData = el._valueData;
                let clientX = event.clientX;
                let clientY = event.clientY;
                if (
                    Math.abs(clientX - clientOffset.clientX) <= 3 &&
                    Math.abs(clientY - clientOffset.clientY) <= 3
                ) {
                    // 这里触发点击事件，
                    onClick && onClick();
                } else {
                    if (valueData && valueData.isDragBack) {
                        let left = odiv.style.left.split("p")[0];
                        if (left > windowWidth / 2) {
                            odiv.style.left = "auto";
                            odiv.style.right = "0";
                            // 调用 set 方法
                            if (setMethod) {
                                setMethod("right");
                            }
                        } else {
                            // 拖拽释放在窗口宽度的一半以下，调用 set 方法并传递 "left" 参数
                            odiv.style.left = "0";
                            odiv.style.right = "auto";

                            if (setMethod) {
                                setMethod("left");
                            }
                        }
                    }
                }

                // 移除阴影元素和事件监听器
                shadeWrap.removeChild(shade);
                parent.removeEventListener("mousemove", onmousemove);
                parent.removeEventListener("mouseup", onmouseup);
            }
            // 添加鼠标移动和释放事件的监听器
            parent.addEventListener("mousemove", onmousemove);
            parent.addEventListener("mouseup", onmouseup);
        };
    },
    update(el, binding, vnode) {
        el._valueData = binding.value ? binding.value.data : null;

        // 更新指令内部的值
        // ...
        // 其他逻辑
        // ...
    },
};
