/* eslint-disable */
import i18n from "@/tools/i18n.js";
import axios from "@/plugins/axios.js";
import { MessageBox } from "element-ui";
import store from "@/store";
import moment from "moment";

export function initBrowserPrompt() {
    store.dispatch("getBrowserInfo");
    if (store.state.user.browserVersionIsHigh === 0) {
        if (~~localStorage.getItem("hasCancelBrowserPrompt") !== 1) {
            MessageBox.confirm(
                i18n.t("el.home.browserUpdateDesc"),
                i18n.t("el.common.reminder"),
                {
                    type: "warning",
                    showClose: false,
                    closeOnPressEscape: false,
                    closeOnClickModal: false,
                    confirmButtonText: i18n.t("el.common.goUpgrade"),
                    cancelButtonText: i18n.t("el.common.cancel"),
                }
            )
                .then(() => {
                    localStorage.setItem("hasCancelBrowserPrompt", 1);
                    switch (store.state.user.browserInfo) {
                        case "Edge":
                            // window.location.href = "edge://version/";
                            window.open(
                                "https://www.microsoft.com/zh-cn/edge/download?form=MT00A8",
                                "_blank"
                            );
                            break;
                        case "Firefox":
                            // window.location.href = "about:support";
                            window.open(
                                "https://www.firefox.com.cn/download/#product-desktop-release",
                                "_blank"
                            );
                            break;
                        case "Chrome":
                            window.open("https://www.google.cn/intl/en-US/chrome/", "_blank");
                            // window.alert(
                            //   "请手动打开 Chrome 设置 -> 关于 Chrome 页面。\n在 Chrome 中，您可以点击右上角的菜单图标并选择 '设置'，选择左下角 '关于 Chrome' 更新版本"
                            // );
                            break;
                        case "Safari":
                            // window.location.href = "chrome://version/";
                            window.open("https://www.apple.com.cn/safari/", "_blank");
                            break;

                        default:
                            break;
                    }
                })
                .catch(() => {
                    localStorage.setItem("hasCancelBrowserPrompt", 1);
                });
        }
    }
}

/**
 * 下载文件
 * @param {*} url 请求地址
 * @param {*} sendData 请求参数
 */
export function download(url, sendData, { method } = {}, fastFile) {
    // let downloadUrl=process.env.VUE_APP_BASE_API+ url
    // window.open(downloadUrl);
    // 改造oss后判断传入的路径是否为oss的路径走这个下载
    var config = {
        headers: {},
    };
    let language = "zh";
    if (i18n.locale === "en_US") {
        config.headers["Accept-Language"] = "en";
        language = "en";
    } else {
        config.headers["Accept-Language"] = "zh";
        language = "zh";
    }
    if (url === "/obs/info/download") {
        let fileLength = sendData.displayName.lastIndexOf(".");
        let fileType = sendData.displayName.substring(fileLength + 1);
        // 如果是视频，如果返回的文件名地址没有包含 vod_video
        if (
            ["mp4", "MP4"].indexOf(fileType) > -1 ||
            ["mov", "MOV"].indexOf(fileType) > -1
        ) {
            const OBSDIR = process.env.VUE_APP_OBS_VOD_VIDEO_DIR;
            if (!sendData.objectName.includes(OBSDIR)) {
                let prefix = OBSDIR;
                if (!sendData.objectName.startsWith("/")) {
                    prefix = `${OBSDIR}/`;
                }
                sendData.objectName = prefix + sendData.objectName;
            }
        }
        return axios
            .get(
                url,
                { params: sendData },
                {
                    ...config,
                }
            )
            .then((res) => {
                if (
                    fileType === "mp4" ||
                    fileType === "MP4" ||
                    fileType === "mov" ||
                    fileType === "MOV"
                ) {
                    // let downurl =
                    //   res.data.downloadUrl +
                    //   "?response-content-disposition=attachment;filename=" +
                    //   res.data.fileName;
                    //   if (downurl.indexOf("http://") > -1) {
                    //     downurl = downurl.replace("http:", "//");
                    //   }

                    //   if (downurl.indexOf("https://") > -1) {
                    //     downurl = downurl.replace("https:", "//");
                    //   }

                    // 更改为obs, 返回的地址即下载地址
                    const downurl = res.data;

                    MessageBox.alert(
                        `请确保使用浏览器下载，如果使用第三方软件将无法下载！`,
                        i18n.t("el.common.reminder"),
                        {
                            center: true,
                            type: "warning",
                            confirmButtonText: "确定",
                        }
                    ).then(() => {
                        let a = document.createElement("a");
                        // a.setAttribute('download', res.data.fileName);
                        console.log("downurl", downurl);
                        a.setAttribute("href", downurl);
                        a.click();
                    });
                } else {
                    windowOpen(res.data);
                }
                //  window.open(res.data)
                // let fileLength = sendData.displayName.lastIndexOf('.');
                // let fileType = sendData.displayName.substring(fileLength + 1);
                // if (fileType === 'mp4' || fileType === 'MP4') {
                //   var request = new XMLHttpRequest();
                //   request.responseType = 'blob';
                //   let fileUrl = res.data; // 文件路径
                //   request.open('GET', fileUrl);
                //   request.onload = function() {
                //     var url = window.URL.createObjectURL(this.response);
                //     var a = document.createElement('a');
                //     document.body.appendChild(a);
                //     a.href = url;
                //     a.download = '下载文件test';
                //     a.click();
                //   };
                //   request.send();
                // } else {
                //   window.open(res.data)
                // }
            });
    } else {
        sendData = sendData || {};
        // 创建表单
        var form = document.createElement("form");
        form.target = "_blank";
        form.method = method || "POST";
        (form.action = (fastFile || process.env.VUE_APP_BASE_API) + url),
            console.log(form.action);
        form.style = "display: none"; // 隐藏
        // 表单元素
        var createInput = function (key, value) {
            var input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = value;
            return input;
        };
        // 循环添加参数元素
        if (typeof sendData === "object") {
            for (const key in sendData) {
                const value = sendData[key];
                if (Array.isArray(value)) {
                    value.forEach((item) => {
                        form.appendChild(createInput(key, item));
                    });
                } else {
                    form.appendChild(createInput(key, value));
                }
            }
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    // 下面的为以前的form表单保留
}

/**
 * 下载导出
 * @param {} params
 * @returns
 */
export function exportDownCommon(method, params, url, fileName, responseType) {
    if (method === "post" || method === "POST") {
        return axios({
            method: method,
            url: url,
            data: params,
            responseType: responseType || "blob",
        }).then((res) => {
            let url = window.URL.createObjectURL(res);
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;

            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    } else {
        return axios({
            method: method || "GET",
            url: url,
            params: params,
            responseType: responseType || "blob",
        }).then((res) => {
            console.log("res.status", res);
            let url = window.URL.createObjectURL(res);
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;

            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }
}

/**
 * 验证
 * @param {*} path 验证
 */
export function check(e, l, g) {
    var c = Object.prototype.toString.call(l) === "[object Array]";
    if (!g) {
        for (var f in e) {
            if (typeof e[f] === "string") {
                e[f] = e[f].replace(/^\s+|\s+$/g, "");
            }
        }
    }
    for (var f in l) {
        var b = l[f];
        var k = c ? b["key"] : f;
        if (!k) {
            continue;
        }
        if (b.type && b.type.toLowerCase() == "array") {
            if (b.require && e[k] && e[k].length < 1) {
                return b.msg;
            }
            for (var d = 0; d < e[k].length; d++) {
                var h = e[k][d];
                if (!(!b.require && !h)) {
                    if (typeof h === "object") {
                        var a = this.check(h, b.child || [], g);
                        if (a !== 1) {
                            return a;
                        }
                    } else {
                        if (b.reg && h && !h.toString().match(b.reg)) {
                            return b.regMsg;
                        }
                    }
                }
            }
            continue;
        }
        !b.reg && b.require === undefined && (b.require = 1);
        if (b.require) {
            if (
                e[k] === undefined ||
                e[k] === null ||
                !e[k].toString().replace(/^\s+|\s+$/g, "")
            ) {
                return b.msg;
            }
        }
        if (!b.require && !e[k]) {
        } else {
            if (b.reg && e[k] && !e[k].toString().match(b.reg)) {
                return b.regMsg;
            }
        }
    }
    return 1;
}

export function mergeDefault() {
    var f = {};
    var m = arguments.length;
    var o = arguments[m - 1];
    var b = 0;
    var k;
    var h;
    var d;
    var g;
    if (typeof o === "boolean") {
        b++;
        h = o;
    }
    if (Object.prototype.toString.call(arguments[0]) === "[object Array]") {
        d = true;
        g = arguments[0];
        k = arguments[1];
    } else {
        k = arguments[0] || {};
    }
    for (var c = 0; c < m - b; c++) {
        var a = arguments[c];
        for (var e in d ? g : k) {
            var n = d ? g[e] : e;
            if (a && a[n] !== undefined) {
                h ? (f[n] = a[n]) : (k[n] = a[n]);
            } else {
                h && c == 0 && (f[n] = k[n]);
            }
        }
    }
    return h ? f : k;
}

/**
 * 获取文件地址后缀名
 * @param {string} url url地址
 */
export function getExtension(url) {
    if (!url || typeof url !== "string") return "";
    var res = /\.([0-9a-z]+)(?:[\?#]|$)/i.exec(url);
    if (!res) return "";
    res = res[1] + "";
    return res.toLowerCase();
}

/**
 * 匹配后缀名
 * @param {*} matchSufiixs 匹配的后缀名列表
 * @param {*} suffix 后缀名
 */
export function matchSuffix(matchSufiixs, suffix) {
    if (typeof suffix !== "string") return false;
    if (!Array.isArray(matchSufiixs)) matchSufiixs = [matchSufiixs];
    return matchSufiixs.indexOf(suffix.toLowerCase()) !== -1;
}

/**
 * 检测浏览器内核--返回的是两个key， name： 浏览器内核的名称-- - version： 浏览器的版本号
 */
export function getBroswer() {
    var browser = {
        versions: (function () {
            var u = navigator.userAgent;
            // app = navigator.appVersion
            return {
                trident: u.indexOf("Trident") > -1, // IE内核
                presto: u.indexOf("Presto") > -1, // opera内核
                webKit: u.indexOf("AppleWebKit") > -1, // 苹果、谷歌内核
                gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") === -1, // 火狐内核
                mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
                ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
                android: u.indexOf("Android") > -1 || u.indexOf("Adr") > -1, // android终端
                iPhone: u.indexOf("iPhone") > -1, // 是否为iPhone或者QQHD浏览器
                iPad: u.indexOf("iPad") > -1, // 是否iPad
                webApp: u.indexOf("Safari") === -1, // 是否web应该程序，没有头部与底部
                weixin: u.indexOf("MicroMessenger") > -1, // 是否微信 （2015-01-22新增）
                qq: u.match(/\sQQ/i) === " qq", // 是否QQ
            };
        })(),
        language: (navigator.browserLanguage || navigator.language).toLowerCase(),
    };
    return browser;
}

/**
 * 打开新窗口
 * 为新窗口链接添加时间戳 ps：为了防止浏览器缓存影响页面
 * @param {*} path 页面路径
 */
export function openWindow(path) {
    const Timestamp = new Date().getTime();
    const flag = path.indexOf("=") > 0;
    let TimestampStr = "";
    if (flag) {
        TimestampStr = "&Timestamp=" + Timestamp;
    } else {
        TimestampStr = "?Timestamp=" + Timestamp;
    }
    // if(type){
    //   window.open(process.env.BASE_URL + path + TimestampStr, '_self', 'noopener');
    // }else{
    windowOpen(process.env.VUE_APP_BASE_URL + path + TimestampStr, "_blank", "noopener");
    // }
}
// 转化对象键值
export function treeDataChangeField(arrData, settings) {
    // 对象数组或对象字段key修改
    settings = settings || {};
    settings.refer = settings.refer === undefined ? 1 : settings.refer;
    var data;
    if (Object.prototype.toString.call(arrData) === "[object Array]") {
        // 数组
        data = [];
        for (var i in arrData) {
            data.push(this.treeDataChangeField(arrData[i], settings));
        }
    } else if (arrData && typeof arrData === "object") {
        // 对象
        var opt = settings.data;
        data = {};
        for (var i in settings.refer ? arrData : opt) {
            if (arrData[i] !== undefined) {
                settings.contain && (data[i] = arrData[i]); // 包含原数据所有字段，可能会被覆盖
                if (typeof opt[i] === "function") {
                    var obj = opt[i](i, arrData[i], arrData);
                    if (Object.prototype.toString.call(obj) === "[object Array]") {
                        data[obj[0]] = obj[1];
                    } else if (obj && typeof obj === "object") {
                        for (var j in obj) {
                            data[j] = obj[j];
                        }
                    }
                } else {
                    var isArr =
                        Object.prototype.toString.call(settings.treeNodes) ===
                        "[object Array]";
                    data[opt[i] ? opt[i] : i] = (
                        isArr
                            ? settings.treeNodes.indexOf(i) >= 0
                            : !settings.treeNodes || settings.treeNodes === i
                    )
                        ? this.treeDataChangeField(arrData[i], settings)
                        : arrData[i];
                }
            }
        }
    } else {
        data = arrData;
    }
    return data;
}

/**
 * 生成快捷日期范围
 * @param {number} days = 7 近期天数
 * @returns {Array}
 */
export function genQuickDateRange(days = 7) {
    let startDate = new Date();
    let endDate = new Date();
    startDate.setDate(startDate.getDate() - (days - 1));
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 0);
    return [startDate, endDate];
}

/**
 * 生成传入n 的周次的 前几天
 * n = 0为 当周第一天
 * n = 1 为 当周第一天的前1天(即为上周的最后一天)
 * n = 7 为 当周第一天的前7天(即为上周的第一天)
 * n = -6 为 当周第一天的后7天(即为当周的最后一天)
 * @param {number} days = 7 近期天数
 * @returns {Array}
 */
export function getWeekDate(n) {
    var now = new Date();
    var year = now.getFullYear();
    // 因为月份是从0开始的,所以获取这个月的月份数要加1才行
    var month = now.getMonth() + 1;
    var date = now.getDate();
    var day = now.getDay();
    // 判断是否为周日,如果不是的话,就让今天的day-1(例如星期二就是2-1)
    if (day !== 0) {
        n = n + (day - 1);
    } else {
        n = n + (day + 6);
    }
    if (day) {
        // 这个判断是为了解决跨年的问题
        if (month > 1) {
            month = month;
        }
        // 这个判断是为了解决跨年的问题,月份是从0开始的
        else {
            year = year - 1;
            month = 12;
        }
    }
    now.setDate(now.getDate() - n);
    year = now.getFullYear();
    month = now.getMonth() + 1;
    date = now.getDate();
    let s =
        year +
        "/" +
        (month < 10 ? "0" + month : month) +
        "/" +
        (date < 10 ? "0" + date : date);
    return s;
}

/**
 * 获取两个日期之间的所有日期
 * sdate: 开始日期
 * edate: 结束日期
 * type: 如果需要获得 '-' 拼接的数据，sdate与edate也为 '-' 拼接的格式; '/' 同理
 * @param {*} date
 * @returns
 */
export function getdiffdate(sdate, edate, type) {
    // 初始化日期列表，数组
    var diffdate = new Array();
    var i = 0;
    // 开始日期小于等于结束日期,并循环
    while (sdate <= edate) {
        diffdate[i] = sdate;

        // 获取开始日期时间戳
        var sdate_ts = new Date(sdate).getTime();

        // 增加一天时间戳后的日期
        var next_date = sdate_ts + 24 * 60 * 60 * 1000;

        // 拼接年月日，这里的月份会返回（0-11），所以要+1
        var next_dates_y = new Date(next_date).getFullYear() + type;
        var next_dates_m =
            new Date(next_date).getMonth() + 1 < 10
                ? "0" + (new Date(next_date).getMonth() + 1) + type
                : new Date(next_date).getMonth() + 1 + type;
        var next_dates_d =
            new Date(next_date).getDate() < 10
                ? "0" + new Date(next_date).getDate()
                : new Date(next_date).getDate();

        sdate = next_dates_y + next_dates_m + next_dates_d;

        // 增加数组key
        i++;
    }
    return diffdate;
}

/**
 * 根据传入的时间
 * 获取年月日
 * 补 0 操作
 * @param {*} date
 */
export function getFormatterDate(date) {
    if (date) {
        let year = date.getFullYear();
        let month = addZero(date.getMonth() + 1);
        let day = addZero(date.getDate());
        return year + "-" + month + "-" + day;
    } else {
        return "";
    }
}

// 获取时分秒
export function getFormatterTime(date) {
    let times = new Date(date);
    let hour = addZero(times.getHours());
    let minutes = addZero(times.getMinutes());
    let seconds = addZero(times.getSeconds());
    return hour + ":" + minutes + ":" + seconds;
}

// 日期 补0 操作
function addZero(num) {
    if (num < 10) {
        return `0${num}`;
    } else {
        return num;
    }
}

/**
 * 四舍五入
 * num: 数字
 * s: 保留几位
 */
export function toFixed5(num, s) {
    var times = Math.pow(10, s);
    var des = num * times + 0.5;
    des = parseInt(des, 10) / times;
    return des;
}
// 参数处理
export function handparams(param) {
    const params = JSON.parse(JSON.stringify(param));
    for (const i in params) {
        if (params[i] === "") {
            delete params[i];
        }
    }
    // console.log("data===" + JSON.stringify(params))
    return params;
}

export function random_string(len) {
    let length = len || 32;
    var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
    var maxPos = chars.length;
    var pwd = "";
    for (let index = 0; index < length; index++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}

export function get_suffix(filename) {
    let pos = filename.lastIndexOf(".");
    let suffix = "";
    if (pos != -1) {
        suffix = filename.substring(pos);
    }
    return suffix;
}

export function calculate_object_name(filename) {
    let suffix = get_suffix(filename);
    let g_object_name = random_string(18) + suffix;
    return g_object_name;
}

export function toChineseBig(num) {
    // 将接收到的num转换为字符串
    var strNum = String(num);
    // 定义单位
    // var unit = ['拾', '佰', '仟', '万', '拾', '佰', '仟', '亿', '拾', '佰', '仟']
    var unit = ["十", "百", "千", "万", "十", "百", "千", "亿", "十", "百", "千"];
    // 结果中放一个符号，用来解决最后的零去不掉的问题
    var result = ["@"];
    // 单位下标
    var unitNo = 0;
    // 从后往前遍历接收到的数据，省略结束条件
    for (let i = strNum.length - 1; ; i--) {
        // 调用转大写函数，将每一个数字转换成中文大写，一次放入一个到结果数组中
        result.unshift(numToChinese(strNum[i]));
        // 如果不大于0
        if (i <= 0) {
            // 结束循环
            break;
        }
        // 放入一个数字，放入一个单位
        result.unshift(unit[unitNo]);
        // 单位下标加1
        unitNo++;
    }
    // 将结果数组转换成字符串，并使用正则替换一些关键位置，让结果符合语法
    // return result.join('').replace(/(零[仟佰拾]){1,3}/g, '零').replace(/零{2,}/g, '零').replace(/零([万亿])/g, '$1').replace(/亿万/g, '亿').replace(/零*@/g, '')
    return result
        .join("")
        .replace(/(零[千百十]){1,3}/g, "零")
        .replace(/零{2,}/g, "零")
        .replace(/零([万亿])/g, "$1")
        .replace(/亿万/g, "亿")
        .replace(/零*@/g, "");

    function numToChinese(n) {
        // var chineseBigNum = '零壹贰叁肆伍陆柒捌玖'
        var chineseBigNum = "零一二三四五六七八九";
        return chineseBigNum[n];
    }
}
// 检查身份证号
export function checkIDCard(rule, value, callback) {
    if (value) {
        let num = value;
        num = num.toUpperCase(); // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
        if (!/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num)) {
            callback(new Error(i18n.t("el.infrastructure.idNumberError1")));
            return false;
        } // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
        // 下面分别分析出生日期和校验位
        let len, re;
        len = num.length;
        if (len == 15) {
            re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
            let arrSplit = num.match(re); // 检查生日日期是否正确
            let dtmBirth = new Date(
                "19" + arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]
            );
            let bGoodDay;
            bGoodDay =
                dtmBirth.getYear() == Number(arrSplit[2]) &&
                dtmBirth.getMonth() + 1 == Number(arrSplit[3]) &&
                dtmBirth.getDate() == Number(arrSplit[4]);
            if (!bGoodDay) {
                //  alert('输入的身份证号里出生日期不对！');
                callback(new Error(i18n.t("el.infrastructure.idNumberError2")));
                return false;
            } else {
                // 将15位身份证转成18位 //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
                let arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];

                let arrCh = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];

                let nTemp = 0;
                let i;
                num = num.substr(0, 6) + "19" + num.substr(6, num.length - 6);
                for (i = 0; i < 17; i++) {
                    nTemp += num.substr(i, 1) * arrInt[i];
                }
                num += arrCh[nTemp % 11];
                // return true;
            }
        }
        if (len == 18) {
            re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
            let arrSplit = num.match(re); // 检查生日日期是否正确
            let dtmBirth = new Date(
                arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]
            );
            let bGoodDay;
            bGoodDay =
                dtmBirth.getFullYear() == Number(arrSplit[2]) &&
                dtmBirth.getMonth() + 1 == Number(arrSplit[3]) &&
                dtmBirth.getDate() == Number(arrSplit[4]);
            if (!bGoodDay) {
                callback(new Error(i18n.t("el.infrastructure.idNumberError2")));
                return false;
            } else {
                // 检验18位身份证的校验码是否正确。 //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
                let valnum;
                let arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];

                let arrCh = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];

                let nTemp = 0;
                let i;
                for (i = 0; i < 17; i++) {
                    nTemp += num.substr(i, 1) * arrInt[i];
                }
                valnum = arrCh[nTemp % 11];
                if (valnum != num.substr(17, 1)) {
                    //  alert('18位身份证的校验码不正确！应该为：' + valnum);
                    callback(new Error(i18n.t("el.infrastructure.idNumberError1")));
                    return false;
                }
                // return true;
            }
        }
    }

    callback();
}

/**
 * 将列表数据转为多维数组
 * @param {*} list 处理的数据
 * @param {*} idKey 主键id
 * @param {*} parentKey 父组件id
 * @param {*} copy 是否要拷贝数据(默认true)
 */
export function list2tree(
    list = [],
    idKey = "id",
    parentKey = "parentId",
    copy = true
) {
    // 如果不是数组并且长度为0返回空数组
    if (!Array.isArray(list) || !list.length) return [];
    // 剔除，id等于父id的数据，以防死递归
    list = list.slice(0).filter((item) => item[idKey] !== item[parentKey]);
    // 找根数据
    var roots = [];
    var ids = list.map((value) => value[idKey]);
    list.forEach(function (item) {
        var id = item[idKey];
        var parentId = item[parentKey];
        if (id === parentId || ids.indexOf(parentId) === -1) {
            roots.push(copy ? Object.assign({}, item) : item);
        }
    });
    var fn = function (roots) {
        roots.forEach(function (value) {
            var res = [];
            list.forEach((item) => {
                if (item[parentKey] === value[idKey]) {
                    res.push(copy ? Object.assign({}, item) : item);
                }
            });
            if (res.length >= 1) {
                value.children = res;
                fn(value.children);
            }
        });
    };
    fn(roots);
    return roots;
}

// 判断文件格式是否为图片
export function isImage(file) {
    if (isOnlineUrl(file)) {
        // 支持的图片文件扩展名数组
        const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
        // 提取链接中的文件名
        const urlParts = file.split("/");
        const filename = urlParts[urlParts.length - 1];

        // 提取文件扩展名
        const extensionMatch = filename.match(/\.([^.?]+)(\?|$)/);
        const extension = extensionMatch ? extensionMatch[1].toLowerCase() : null;

        // 判断文件扩展名是否在支持的图片扩展名数组中
        return extension && imageExtensions.includes(extension);
    } else {
        const isJPEG = file.type === "image/jpeg";
        const isJPG = file.type === "image/jpg";
        const isPNG = file.type === "image/png";
        return isJPEG || isJPG || isPNG;
    }
}

// 判断是否以 http、https 或 ftp 开头，然后后面跟着 ://，接着是非空白字符和一些其他字符
export function isOnlineUrl(url) {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlRegex.test(url);
}

// 截取链接的文件名
export function getFileName(fileName) {
    const pattern = /([^/]+).jpeg/;
    let name = fileName;
    if (fileName) {
        if (isOnlineUrl(fileName)) {
            const match = pattern.exec(fileName);
            console.log(match);
            name = match[0];
        }
    }
    return name;
}

export function getFormatDate(date) {
    return moment(date).format("YYYY/MM/DD");
}

export function getFormatTime(date) {
    return moment(date).format("YYYY/MM/DD HH:mm:ss");
}

/**
 * 获取文件名称
 * url "https://obs-aiykt-dev.obs.cn-east-3.myhuaweicloud.com:443/1751224372940300288.png?AccessKeyId=XMB78KOA1G3LXBOHJLBR&Expires=1706586116&Signature=jKKUrRxWui16zny82DC5mgjC2rA%3D"
 * return 1751224372940300288.png
 */
export function extractFileNameFromURL(url) {
    // 将 URL 按斜杠切割成数组
    const urlParts = url.split("/");
    // 获取数组中的最后一个元素（即文件名部分）
    const filenameWithParams = urlParts[urlParts.length - 1];
    // 去除可能包含的查询参数部分
    const filename = filenameWithParams.split("?")[0];
    return filename;
}

export const timeToSeconds = (time) => {
    const zero = new Date("1999-11-11 00:00").getTime();
    return (new Date("1999-11-11 " + time).getTime() - zero) / 1000;
};

export const secondsToTime = (seconds) => {
    if (seconds == 86400) return "24:00";
    let date = new Date(
        new Date("1999-11-11 00:00:00").getTime() + seconds * 1000
    );

    return (
        date.getHours().toString().padStart(2, "0") +
        ":" +
        date.getMinutes().toString().padStart(2, "0")
    );
};
export const windowOpen = (url, target = "_blank") => {
    if (/mobile/i.test(navigator.userAgent)) {
        setTimeout(() => {
            window.open(url, target, "noopener");
        }, 0);
    } else {
        window.open(url, target, "noopener");
    }
};
