/**
 * 课程管理-班级管理接口文件
 */
import axios from "@/plugins/axios.js";

/**
 * 列表-层级平铺
 * @return {Promise}
 */
export function getClassInfoList(schoolCode, courseId) {
  return axios.get(`/classInfo/list/${courseId}`);
}
/**
 * 班级列表-按层级返回  去掉schoolCode
 * @return {Promise}
 */

export function getClassInfoListNew(courseId) {
  return axios.get(`/classInfo/newlist/${courseId}`);
}
/**
 * 添加班级
 */
export function addClassInfo(data) {
  return axios.post("/classInfo/add", data);
}
/**
 * 获取最近2年的学年
 */
export function getLast2SchoolYear(data) {
  return axios.get("/dictionary/selectDictionaryBySchoolYear", {
    params: data,
  });
}

/**
 * 调班操作中，获取当前学期的班级
 */
export function getSemesterClassList(data) {
  return axios.get(
    `/classInfo/changeClass/${data.schoolCode}/${data.courseId}`,
    {
      params: data,
    }
  );
}

/**
 * 编辑班级
 * @param {*} data
 * @returns
 */
export function updateClassInfo(data) {
  return axios.post("/classInfo/update", data);
}

/**
 * 删除班级
 * @param {*} data
 * @returns
 */
export function deleteClassInfo(data) {
  return axios.get(`/classInfo/delete/${data}`);
}
/**
 * 获取课程学生列表
 * @param {*} data
 * @returns
 */
export function getCourseClassPage(params) {
  return axios.get("/courseClass/page", {
    params,
  });
}
/**
 * 添加学生
 */
export function addStudentClass(data) {
  return axios.post("/courseClass/add", data);
}

/**
 * 调班
 * @param {*} data
 * @returns
 */
export function updateStudentClass(data) {
  return axios.post("/courseClass/update", data);
}

/**
 * 删除学生
 * @param {*} data
 * @returns
 */
export function deleteStudentClass(data) {
  return axios.get(`/courseClass/delete/${data}`);
}

/**
 * 根据学校编码获取树形列表
 * @param {*} data
 * @returns
 */
export function getCourseClassTree(data) {
  return axios.get(`/courseClass/getTree/${data.courseId}/${data.classId}`);
}

/**
 * 默认学生
 * @param {*} schoolCode
 * @param {*} courseId
 * @returns
 */
export function getSelectedStudentList(schoolCode, courseId, classId) {
  return axios.get(`/courseClass/getSelected/${courseId}/${classId}`);
}
