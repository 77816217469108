// 1、引入ElementUI组件的语言包
import zhCNLocale from "element-ui/lib/locale/lang/zh-CN";
// 2、引入一个Javascript对象深层( 递归) 合并库
import deepmerge from "deepmerge";
// 3、项目中自定义语言包
var zhCNM = {
    el: {
        home: {
            teachingPreparation: "备授课",
            teachingManage: "教务管理",
            billboard: "区域看板",
            backEndManagement: "管理 后台",
            welcomeAIClass: "欢迎登录AI云课堂！",
            myCenter: "个人中心",
            classManagement: "班级管理",
            logOut: "退出登录",
            backTop: "回顶部",
            noSuggestCourse: "暂无推荐课程",
            hello: "你好！",
            schoolDivision: "所在学校/学部：",
            mySchedule: "我的排课表",
            myCourses: "我的课程",
            updatePwd: "修改密码",
            browserUpdateDesc: "浏览器版本过低，为了保证使用效果，请升级浏览器",
        },
        courseDetails: {
            courseDetails: "课程详情",
            types: "类别：",
            details: "详情",
            content: "目录",
            total: "共",
            keyPoints: "知识点",
            prepareLessons: "备课",
            teachingLessons: "授课",
            toTeachingLessons: "去授课",
            Subject: "科目：",
            Grade: "年级：",
            Semester: "学期：",
            noPermissionCourse: "您暂无此课程权限",
            applicationCourse: "申请课程权限",
            noCourseId: "没有课程id",
            noPermissionCourseContact: "您暂无此课程权限，请联系学校教务",
            keyPointsName: "知识点名称",
        },
        myTimeTable: {
            weeklySchedule: "周课表",
            lastWeek: "上一周",
            nextWeek: "下一周",
            Courses: "课程：",
            Class: "班级：",
            Time: "时间：",
            Teacher: "老师：",
            Classroom: "教室：",
            View: "查看",
            timeDate: "时间/日期",
            stop: "课程已停用，无法授课",
        },
        routerName: {
            homePage: "首页",
            myCourse: "我的课程",
            courseList: "课程列表",
            courseDetails: "课程详情",
            myTimeTable: "我的课表",
            myCenter: "个人中心",
            classManage: "班级管理",
            studentManage: "学员管理",
            manageCourseClass: "课程班级",
            authTip: "权限提示",
            resourceTip: "资源提示",
            teaching: "授课",
            classRoom: "备授课",
        },
        common: {
            minDurationMustSmaller: "备课时长输入有误",
            goUpgrade: "去升级",
            totalname: "共",
            pages: "页",
            items: "条",
            confirm: "确定",
            cancel: "取消",
            centerName: "海亮教育",
            create: "新建",
            edit: "编辑",
            add: "添加",
            save: "保存",
            operate: "操作",
            pleaseEnter: "请输入",
            pleaseSelect: "请选择",
            TeachingClass: "授课班级",
            search: "查询",
            reset: "重置",
            delete: "删除",
            import: "导入",
            back: "返回",
            submit: "提交",
            refresh: "刷新",
            systemError: "系统异常",
            noResources: "暂无资源",
            knowledgeFirst: "请先选择知识点",
            learned: "已上",
            noData: "暂无数据",
            pageMissing: "页面丢失",
            isNoPageExist: "您访问的页面地址有误或该页面不存在",
            backHomePage: "返回首页",
            isNoResourceExist: "资源不存在",
            noPerson: "暂无人员数据",
            deleteConfirm: "删除确认",
            reminder: "提示",
            pageUp: "上一页",
            pageDown: "下一页",
            redirectTo: "跳转至",
            chooseGrade: "请选择年级",
            chooseSubject: "请选择科目",
            previousStep: "上一步",
            nextStep: "下一步",
            submitSuccessfully: "提交成功",
            cannotEmpty: "描述不能为空",
            reload: "重新加载",
            chooseKnowledge: "选择知识点",
            Monday: "周一",
            Tuesday: "周二",
            Wednesday: "周三",
            Thursday: "周四",
            Friday: "周五",
            Saturday: "周六",
            Sunday: "周日",
            week7: "周七",
            week8: "周八",
            week9: "周九",
            week10: "周十",
            week11: "周十一",
            week12: "周十二",
            week13: "周十三",
            week14: "周十四",
            copyRight: "海亮素质教育集团 保留所有版权 浙ICP备2022027462号-1",
            copyRightNum: "浙公网安备33010802013010号",
            language: "EN",
            supportPartVideoPlay: "当前浏览器支持部分视频播放",
            notSupportVideoPlay: "当前浏览器不支持视频播放",
            notSupportCanvas: "当前浏览器不支持canvas",
            overMaxFileSize: "文件大于",
            importErrorMsg: "导入数据存在校验错误,是否下载错误信息文件？",
            importError: "导入错误",
            onlyAdmit: "只允许上传",
            files: "文件",
            notFormElement: "绑定的元素不是文件上传表单元素",
            returnError: "服务器返回异常！",
            elementLoading: "拼命加载中",
            errorServer: "获取服务器信息失败！",
            mobilePreviewError: "建议您使用电脑浏览器访问以获得更佳体验。",
            errorWords: "错误",
            noIntroduction: "暂无介绍",
            supportVideoPlayNot: "此视频暂无法播放，请稍后再试",
            loggingOut: "退出登录中",
            view: "查看",
            download: "下载",
            to: "至",
            startTime: "开始时间",
            endTime: "结束时间",
            type: "类型",
            coursesArrangingManagement: "排课管理",
            CourseRecommendations: "课程推荐",
            infrastructure: "基础设置",
            schoolResourcesManagement: "学校资源管理",
            dataAnalysis: "数据分析",
            orderManagement: "订单管理",
            screening: "筛选",
            export: "导出",
            new: "新增",
            startDate: "开始日期",
            endDate: "结束日期",
            colon: "：",
            saveSuccessfully: "保存成功",
            saveFailed: "保存失败",
            prompt: "提示",
            enable: "启用",
            disable: "禁用",
            NoLogging: "暂无日志记录",
            NoRecord: "暂无记录",
            ExportSchedule: "导出排课任务课表",
            Saving: "正在保存中...",
            CheckFormCorrectly: "请检查是否正确填写表单",
            NoDecimalsnegativeNumbers: "禁止输入小数以及负数",
            ChangeClass: "调班",
            UploadsDownloads: "上传下载",
            UploadDownload: "上传、下载",
            resourceUse: "资源使用",
            AllowUpload: "允许上传",
            AllowDownload: "允许下载",
            AllowCopy: "允许复制",
            OnlyUpload: "仅上传",
            JustDownload: "仅下载",
            None: "无",
            FunctionalRole: "功能角色",
            AuthorizationRemind: "授权提醒",
            DelegateTeacher: "确定授权多个科目给该教师 ？",
            ProtectIntellectualProperty: "*为保护知识产权，请按实际需求授权",
            SelectAll: "全选",
            NoCourse: "暂无课程",
            SelectSchoolDivision: "请选择所在学校/学部",
            SelectSchoolDivision2: "请选择学校/学部",
            IncompleteCoursePermissions: "课程权限不完整：年级、科目权限需同时存在",
            importedWait: "名单导入中，请稍候",
            EnterStudentID: "请输入学生ID",
            Number: "编号",
            width90Auto: "90px",
            width90_120: "90px",
            width100_160: "100px",
            width120_220: "120px",
            width120_240: "120px",
            width220_340: "220px",
            widthP40_50: "40%",
            name: "名称",
            AutoSaving: "正在自动保存...",
            deleteData: "是否删除这条数据？",
            numberPeople: "人数",
            numberOf: "",
            open: "开启",
            Group: "集团",
            teacherName: "教师名称",
            Course: "课程",
            noLess10M: "不少于10分钟",
            noLess30M: "不少于30分钟",
            cumulativeDuration: "累计时长",
            minutes: "分钟",
            dataStatistics: "当日数据每小时统计一次",
            accumulate: "累计",
            averageDaily: "日均",
            closeDialog: "关闭窗口",
            unableLoad: "无法加载，不支持播放！",
            querySuccess: "修改成功",
            Custom: "自定义",
            Use: "使用",
            expire: "到期",
            ModifyExpiration: "修改到期",
            RemoveCourse: "移除课程",
            CancelEditing: "取消编辑",
            onTrial: "试用",
            No: "暂无",
            noResource: "无资源",
            noTaskData: "暂无选课任务",
            showDetail: "查看详情",
            source: "来源",
        },
        chatgpt: {
            chatgptTitle: "优优-教学小助手",
            InternalTestVersion: "内测版",
            NumberResourcePoints: "资源点数：",
            chatDialogTips:
                "*对话内容均由人工智能模型输出，其内容的准确性和完整性无法保证，不代表我们的态度或观点。",
            Send: "发送",
            Answering: "正在回答中…",
            ChatTips:
                "每人每日5000资源点数。提问/回答均会消耗资源点数，消耗规则基本上是：1个汉字消耗约3个资源点数，1个英文字母/标点符号/数字消耗约1个资源点数；实际消耗量会根据内容的类型和长度有所浮动。",
            UsageLimit: "今日资源点数使用已达上限",
            EnteKnow: "请输入想了解的内容",
            ClickConversation: "点我开始对话~",
            Regenerate: "重新生成",
            ReadMore: "查看更多",
            YouyouAssistant: "Hi～我是优优，你的智能教学小助手。",
            AskMeQuestions:
                "你可以通过「扮演的角色+场景描述+要做什么」这样方式向我提问，比如：",
            Example1:
                "你现在是一位小学教师，准备设计一个可以拓展学生思维的课堂活动大纲，可以适当使用一些教室里有的物品做为道具。",
            Example2:
                "你是一个参与课后服务活动的老师，现在课程结束了，需要写一篇课后服务课程总结，从实施、课程、老师辅导等角度具体描述。",
            Example3:
                "你是一个小学老师，明天要给学生上青少年党史课，现在你需要汇总一下中国共产党发展过程中的重大会议并说明会议的内容和影响。",
            limitSendMessage: "回答中，请稍候再发送消息",
        },
        axios: {
            serverError: "服务器返回异常",
            serverError401: "登录失效，请重新登录!",
            serverError406:
                "你的账号已在其他设备登录，当前设备登录失效，若非本人操作请联系管理员重置密码",
            logoutTip: "你已被登出，可以取消继续留在该页面，或者重新登录",
            confirmLogout: "确定登出",
            reLogin: "重新登录",
            pathNoexit: "请求地址不存在",
            serverError2: "服务器异常错误",
            netWorkError: "网络超时,请稍后重试！",
            initDataErrorLoad: "初始数据加载失败！",
            reLoadData: "重新加载数据",
            loginPage: "登录页面",
        },
        pagination: {
            totalname: "共",
            pages: "页",
            items: "条",
        },
        authDialog: {
            name: "姓名",
            applicationTitle: "申请课程权限，请按需联系本校教学处 / 所在教研组",
            subjects: "可授权科目",
            grades: "可授权年级",
            courseNames: "可授权课程",
            telephone: "电话",
        },
        courseList: {
            all: "全部",
            total: "共",
            totalcourses: "个课程",
            myCourses: "我的课程",
            allGrade: "全部年级",
            allSubject: "全部科目",
            application: "申请课程权限",
            moreFilters: "展开更多筛选项",
            lessFilters: "收起筛选",
            enterCourseName: "请输入课程名称",
            subject: "科目",
            grade: "年级",
            semester: "学期",
            totalCourses: "共**个课程",
            noPermission: "暂无权限",
            noCourses: "暂无课程，请重新筛选",
            deleteSchedulingTask: "是否要删除此排课任务",
            deleteSchedulingTask1:
                "是否要删除此排课任务，删除后此排课包含的未到上课时间的课程将全部删除",
            CourseExpirationTime: "课程到期时间",

            MaximumAuthorizedDate:
                "最大可授权日期：教务自身课程权限的到期时间，若选择多个课程，则为最早到期的时间",
            MaximumAuthorized: "最大可授权日期",
            selectOperate: "请选择要操作的课程",
            SelectedCourses: "已选课程",
            sureSelectedCourse: `确定要移除选择的 {0} 个课程`,
            ModifyExpirationTime: "修改到期时间",
            sureDeleteBatch: `确定要批量删除 {0} 个课程`,
            BatchPermissionsSucceeded: "批量配置权限成功",
            SuccessfullyDeletedBatch: "批量删除权限成功",
            allCourse: "全部课程",
            formalCourses: "正式课程",
            trialCourses: "试用课程",
            courseType: "课程类别",
            courseSource: "来源类型",
            courseSourceName: "来源名称",
            coursePrice: "课程价格(元)",
        },
        MyHome: {
            MyHomePage: "我的主页",
            classManage: "班级管理",
            courseClass: "课程班级",
            hello: "老师，您好",
            depart: "所在学校/学部",
            identity: "身份",
            normalUser: "身份：普通用户",
            myRecord: "我的记录",
            introduction: "说明",
            sumDays: "累计使用天数",
            day: "天",
            dataComputed: "当天数据最多延迟1小时",
            loginTips: "登录过平台即视为当天已使用",
            preparaHours: "累计备课时长",
            effectiveHours: "累计授课时长",
            hours: "小时",
            minutes: "分钟",
            uploadNum: "个人上传及编辑平台课件的数量",
            uploadAndCreate: "上传与再创作",
            materials: "个资料",
            teachingNum: "使用备课或授课过的集团资源数量",
            groupNumbers: "累计查看集团资源数",
            the: "个",
            likeNum: "由您分享至学校的资源，被点赞的次数",
            likeReceive: "收到的赞",
            recentUsage: "近期使用情况",
            prepareValidate: "当天数据最多延迟1小时且正在备课中的数据未计入。",
            today: "今日",
            todayTimes: "备课时长",
            lastedMonth: "近30日",
            compare: "环比",
            equal: "持平",
            effectiveValidate: "当天数据最多延迟1小时且正在授课中的数据未计入。",
            todayEffectiveHours: "授课时长",
            binding: "绑定",
            unBinding: "解绑",
            Bound: "已绑定",
            WeChat: "微信",
            BindWeChat: "绑定微信",
            toWeChatBind: "打开微信扫一扫即可绑定",
            Cancel: "取 消",
            Sure: "确 定",
            ChangeWechatbinding: "换绑微信",
            BindingSuccessful: "绑定成功！",
            UnbindSuccessfully: "解绑成功！",
            UnbindWeChat: "解绑微信",
            SureUnbindDesc: "解绑后将不能再使用该微信登录当前账号，是否确定解绑？",
        },
        courseClassManage: {
            classIntro: "班级-学生用于授课时进行随机提问、点名功能",
            studentManage: "学员管理",
            class: "班级名称",
            course: "所属课程",
            school: "所属学校",
            stuNumber: "学生人数",
            keywordPlaceholder: "请输入学生姓名/ID/手机号",
            placeholderWidth: "240px",
            addStudent: "添加学生",
            remove: "移除",
            studentID: "学生ID",
            studentNumber: "学号",
            schoolName: "所在学校",
            gradeName: "所属年级",
            schoolClassName: "行政班级",
            currentClass: "当前班级",
            createTime: "报名时间",
            studentRules: "请选择学生",
            classRules: "请选择班级",
            chooseStudent: "选择学生",
            chooseClass: "选择班级",
            chooseInputLength: "120px",
            addSuccess: "新增成功!",
            confirmThat: "确定将",
            confirmContext: "从此课程移除？",
            reminder: "提示",
            removeSuccess: "移除成功！",
            cancelRemove: "已取消删除",
            studentManageNbsp: "学员管理",
            studentName: "学生姓名",
            enterNameIDStudentID: "请输入学生姓名/ID/学号",
            allStudents: "全部学生",
            addClass: "添加班级",
            classMenuTitleWidth: "class-menu-title",
            determineName: "确定删除此班级，班级名称：",
            callInClass: "调入班级",
            mostStudentsNumber: "最大学生数",
            InClass: "所在班级",
            enterNameStudentID: "请输入学生姓名/学号",
        },
        teaching: {
            selectCourse: "选择课件",
            selectClass: "选择班级",
            selectClassTips: "用于随机提问、点名",
            selectNoClass: "未创建班级， 去创建",
            noClass: "暂无班级",
            fullScreen: "全屏",
            exitFullScreen: "退出全屏",
            exitClass: "退出授课",
            randomQuestion: "随机提问",
            start: "开始",
            pause: "暂停",
            rollCall: "点名",
            switchFile: "切换课件",
            courseResources: "课程资源",
            studentList: "学生名单",
            draw: "绘制",
            clearPage: "清除本页",
            undo: "撤销",
            recovery: "恢复",
            clear: "清除",
            startDraw: "开始绘制",
            endDraw: "结束绘制",
            clearSuccess: "清除成功",
            confirmExit: "确定退出",
            confirmExitSession: "确定结束授课吗？",
            logout: "退出登录",
            mine: "我的",
            add: "去添加",
            chooseCourseWare: "请选择课件",
            loadingData: "正在努力加载数据",
            noCreateClass: "未创建班级",
            noSelectClass: "未选择班级",
            gotoAdd: "去创建",
            exitSession: "结束授课",
            hideWindow: "隐藏窗口",
            showWindow: "显示窗口",
            startPng: "pic_button_start@2x.png",
            startPressPng: "pic_button_start_active@2x.png",
            pausePng: "pic_button_suspend@2x.png",
            pausePressPng: "pic_button_suspend_active@2x.png",
            noClassOrDelete: "班级不存在或已被删除",
            classNoStudent: "当前班级暂无学生",
            resourceUnsupport: "该资源不支持授课！",
            browserUnfullsreen: "您的浏览器不支持全屏",
            chooseBrowser: "请更换浏览器",
            pleaseChangeBrowser:
                "当前浏览器无法正常使用此功能，推荐使用：Google Chrome",
            looseParams: "缺少参数无法正常打开备课页面",
            dataLoadingFail: "数据加载失败",
            sessionFailQuestion: "此次授课未开始或已结束,请确认!",
        },
        classManage: {
            classFunction:
                "班级-学生用于授课时进行随机提问、点名功能；其中自建班级为老师自己创建的班级，授权班级为教务授权管理班级",
            className: "班级名称",
            classType: "班级类型",
            stuNumber: "学生人数",
            createTime: "创建日期",
            studentManage: "学员管理",
            newClass: "新增班级",
            addSuccess: "新增成功",
            deleteSuccess: "删除成功",
            editClass: "编辑班级",
            enterClassName: "请输入班级名称",
            deleteClassTips: "确定删除班级并同时删除班级学生？",
            editSuccess: "编辑成功",
            editGrade: "编辑年级",
            addGrade: "新增年级",
        },
        studentManage: {
            studentMag1: "",
            studentMag2: "班级学生管理",
            studentName: "学生姓名：",
            studentNameN: "学生姓名",
            downloadTemplate: "下载导入模板",
            createTime: "创建日期",
            addStudent: "新增学生",
            editStudent: "编辑学生",
            deleteTips: "删除后，授课随机提问等功能将无法抽取此学生，确认删除？",
            deleteSuccess: "删除成功",
            addStuTips: "当前学生姓名已存在，是否确认新增?",
            backModify: "返回修改",
            confirmAdd: "确认新增",
            importProm: "导入提示",
            hasSameStu: "表格中存在相同的学生姓名，是否继续导入?",
            continueImport: "继续导入",
            dontImport: "暂不导入",
            importSameStu: "表格中存在相同的学生姓名，是否继续导入?",
            addSuccess: "新增成功",
            importSuccess: "导入成功",
            enterStudentName: "请输入学生姓名",
            editStudentTips: "当前学生姓名已存在，是否确认更改？",
            conformChange: "确认更改",
            skipOrContinue: "以下学生已存在，是否跳过或继续导入：",
            skipRepeat: "跳过重复",
            editSuccess: "编辑成功",
            fileTypes: "只允许上传xls、xlsx文件",
        },
        classroomWps: {
            teacherInstructions: "师训",
            UnableToTeach: "没有课件无法授课，请上传课件后重试！",
            UnableToCompare: "没有课件无法对照，请上传课件后重试！",
            selectToStart: "请选择文件，再开始授课！",
            shift: "切换",
            courseware: "课件",
            lessonPlan: "教案",
            classmaterials: "课堂资料",
            preview: "课前预习",
            lessonVideo: "备课视频",
            classroomRecord: "课堂实录",
            worksheet: "学习单",
            homework: "作业",
            classMsg: "班级管理",
            groupResource: "集团资源",
            schoolResource: "学校资源",
            myResourse: "我的资源",
            switchTip: "点击这里可以切换课件",
            less: "收起",
            more: "展开",
            lists: "列表",
            viewAll: "查看全部",
            myModify: "我要修改",
            modify: "修改",
            like: "点赞",
            download: "下载",
            shareToSchool: "分享至学校",
            mores: "更多",
            rename: "重命名",
            reupload: "重新上传",
            supportingCourseware: "配套课件",
            supportingLessonPlan: "配套教案",
            uploadPPT: "上传个人课件",
            uploadLessonPlan: "上传个人教案",
            uploadMaterials: "上传个人课堂资料",
            uploadProfile: "上传个人资料",
            uploadResource: "上传个人资源",
            closeList: "收起列表",
            openList: "展开列表",
            uploading: "正在上传",
            uploaded: "已上传",
            uploadedSuccess: "文件上传成功",
            sameResourceTips: "在本章节已有同名的资源，请修改",
            renameCourseware: "重命名",
            coursewareName: "名称",
            noLessonPlan: "当前没有教案，无法分享至学校",
            noLessonPlanCourseware:
                "资源分享至学校时，需同时提交配套教案和课件，当前没有可提交的教案，请前往教案分类下添加！",
            print: "打印",
            creatorsCenter: "创作者中心",
            inviteMsg:
                "诚邀您加入我们的课程研发与创作，请详细描述您的想法或建议，支持图片粘贴",
            startTeaching: "开始授课",
            inFullScreen: "全屏备课",
            compareLessonPreparation: "对照备课",
            exitFullScreen: "退出全屏",
            ESCexitFullScreen: "按esc退出全屏",
            canotPreview: "该资源无法预览",
            usePlatform: "使用平台课件备课",
            usePersonal: "使用个人课件备课",
            sharetoSchool: "分享至学校， 可被本教研组教师查看使用",
            verification: "审核中",
            tobemodified: "待修改",
            passed: "已通过",
            backModify: "退回修改",
            verifyOpinion: "审核意见",
            shareSchool1: "分享至学校，可被本校",
            shareSchool2: "科目教师查看使用",
            confirmDeleteClass: "确定删除班级并同时删除班级学生？",
            verifier: "审核人",
            noVerifyOpinion: "暂无审核意见",
            gotIt: "知道了",
            howtoTitle: "如何使用集中备课呢？",
            sharetoSchoolinfo:
                "资源分享至学校时，需同时提交配套教案和课件，请从下列文件中选择该文件的配套资源：",
            sharetoSchoolTips:
                "资源分享至学校后，将无法撤回，请您仔细检查教案和课件哦",
            sharetoSchoolError: "当前没有课件，无法分享至学校！",
            sharetoSchoolneed:
                "资源分享至学校时，需同时提交配套教案和课件，当前没有可提交的课件，请前往课件分类下添加！",
            backtoedit: "是否确认退回修改？",
            backTips:
                "退回后，您可以对教案和课件进行修改。如需分享至学校，您需要再次提交。",
            noDownloadPermisssion: "暂无下载权限",
            noUploadPermisssion: "您的账号暂无上传权限，如有需要请找管理员申请！",
            cannotBatch: "浏览器不支持文件批量上传",
            uploadFail: "文件上传失败",
            cannotVideo: "暂不支持视频的上传",
            selectFile: "请选择文件",
            filenoContent: "文件无内容，请重新选择",
            turnPersonplan: "已为您转化成个人教案，您可对个人教案进行修改",
            turnPersonCourseware: "已为您转化成个人课件，您可对个人课件进行修改",
            turnPersonresource: "已为您转化成个人课堂资源",
            enterfileName: "请输入文件名称",
            fileRecall: "文件已撤回",
            thanksShare: "感谢您的分享！",
            togetherShare: "现在可以通知备课组其他教师一起查看了哦",
            cancelShare: "取消后， 备课组其他教师将无法查看  确认取消？",
            cancelsuccess: "取消分享成功",
            sametype: "只能上传同类型文件",
            cancelUpload: "取消上传",
            cancelPersonal: "删除后无法使用个人资源授课，确定删除？",
            deleteSuccess: "删除成功",
            notSupportPreview: "该类型资源暂不支持预览!",
            selectResourcesCompare: "请选择要对照的资源",
            currentResource: "当前资源",
            changeResource: "切换资源",
            sureComparison: "确定退出对照吗？",
            ExitControl: "退出对照",
            oldLinkTip:
                "该链接已失效，将自动返回至我的课程，请重新保存链接以免影响使用",
        },
        symbol: {
            period: "。",
            comma: "，",
            colon: "：",
            semicolon: "；",
            exdamation: "！",
            questionMark: "？",
        },
        schoolResourceManage: {
            schoolResourcesManagement: "学校资源管理",
            teacherName: "教师姓名",
            workNumber: "工号",
            school: "所在学校",
            subjects: "科目",
            grade: "年级",
            teacher: "教师",
            scienceBooks: "学科学段册别",
            chapter: "章节",
            resourceState: "资源状态",
            all: "全部",
            online: "上线",
            offline: "下线",
            sectionName: "章节名称",
            resourceName: "资源名称",
            operator: "操作人",
            operateTime: "操作时间",
            crossSchoolShared: "跨校共享",
            log: "日志",
            crossSchoolSharedWhether: "是否确认跨校共享？",
            crossSchoolSharedDesc:
                "跨校共享后，资源将会展示在“集团资源”中，对所有用户可见哦~",
            note: "备注",
            no: "无",
            audit: "审核",
            auditmanagement: "审核管理",
            auditStatus: "审核状态",
            toAudit: "待审核",
            returnToModify: "退回修改",
            approved: "审核通过",
            auditor: "审核人",
            auditDate: "审核日期",
            auditTime: "审核时间",
            auditRecords: "审核记录",
            submitAudit: "提交审核",
            approvedAndOnline: "审核通过并上线",
            shutDown: "关闭",
            crossSchoolSharingRecords: "跨校共享记录",
            crossSchoolSharedTime: "跨校共享日期",
            derivedForm: "导出表格",
            crossSchoolSharer: "跨校共享操作人",
            Highlight: "加精",
            CancelHighlight: "取消加精",
            AutoReturn: "自行退回",
        },
        dataAnalysis: {
            DepartmentName: "学部名称",
            dataAnalysis: "数据分析",
            today: "今日",
            nearly7days: "近7日",
            nearly30: "近30日",
            schoolOrDepartment: "学校/学部",
            unit: "单位",
            dateRangeDesc: "日期查询范围不能超过365天，请重新选择",
            day: "天",
            weeks: "周",
            month: "月",
            toTop: "回顶部",
            usageStateAnalysis: "使用情况分析",
            usageAnalysis: "使用率分析",
            usageAnalysisDesc: [
                "1、平台使用率=当天登录过平台的本校教师人数 / 学校当天的教师账号数",
                "2、备课使用率=当天有备课记录的教师人数 / 学校当天的教师账号数",
                "3、授课使用率=当天有授课记录的教师人数 / 学校当天的教师账号数",
                "注：平台授课、备课数据分别从2020年6月4日、2020年10月27日开始记录。当日数据每小时统计一次",
            ],
            platformUsage: "平台使用率",
            platformUseNum: "平台使用人数",
            preparingUsage: "备课使用率",
            preparingUseNum: "备课使用人数",
            teachingUtilization: "授课使用率",
            teachingUtilizationNum: "授课使用人数",
            usageTimeAnalysis: "人均使用时长分析",
            usageTimeAnalysisDesc: [
                "1、每天人均备课（授课）时长=当天学校备课（授课）时长之和 / 学校当天的教师账号数",
                "2、每周人均备课（授课）时长=本周每天人均备课（授课）时长之和",
                "3、每月人均备课（授课）时长=本月每天人均备课（授课）时长之和",
                "注：平台授课、备课数据分别从2020年6月4日、2020年10月27日开始记录。当日数据每小时统计一次。",
            ],
            perPreparationTime: "人均备课时长",
            perteachingTime: "人均授课时长",
            unuseSystemTeacher: "周期内未使用系统的老师名单",
            unuseSystemClientTeacher: "周期内未使用备课系统的老师名单",
            unuseSystemTeachingTeacher: "周期内未使用授课系统的老师名单",
            teachingDailyRacking: "教师备授课时长排行",
            teachingDailyRackingDesc: [
                "1、教师日均备课（授课）时长=统计周期内教师的有效备课（授课）时间 / 统计天数",
                "2、全校平均备课（授课）时长=统计周期内学校每天人均备课（授课）时长之和/统计天数",
                "注：平台授课、备课数据分别从2020年6月4日、2020年10月27日开始记录。当日数据每小时统计一次。",
            ],
            unfoldAllRecords: "展开全部名单",
            foldAllRecords: "收起全部名单",
            preparationTimeOnAverage: "全校平均备课时长",
            teachingTimeOnAverage: "全校平均授课时长",
            preparationDailyTime: "备课时长",
            teachingDailyTime: "授课时长",
            topTen: "排名前10",
            afterRanking10: "排名后10",
            preparationAnalysisEachGrade: "各年级-学科备课分析",
            preparationAnalysisEachGradeDesc: [
                `1、根据备课课程所属的年级、科目统计，如A老师备课了一年级语文、二年级语文，则分别统计在一年级语文、二年级语文。`,
                `注：平台授课、备课数据分别从2020年6月4日、2020年10月27日开始记录。当日数据每小时统计一次。`,
            ],
            preparingTime: "备课时间",
            teachingAnalysisEachGrade: "各年级-学科授课分析",
            teachingAnalysisEachGradeDesc: [
                `1、根据授课课程所属的年级、科目统计，如A老师授课了一年级语文、二年级语文，则分别统计在一年级语文、二年级语文。`,
                `注：平台授课、备课数据分别从2020年6月4日、2020年10月27日开始记录。当日数据每小时统计一次。`,
            ],
            teachingTime: "授课时间",
            chooseContrastRange: "选择对比范围",
            selectGrade: "请选择年级",
            selectSubject: "请选择科目",
            toContrast: "开始对比",
            selectContrastRange: "请选择对比范围",
            preparingTimeContrast: "备课时长对比",
            preparingTimeContrastDesc: [
                "1、统计教师在所查询的年级、科目课程的备课时长",
                "注：平台授课、备课数据分别从2020年6月4日、2020年10月27日开始记录。当日数据每小时统计一次。",
            ],
            preparationTime: "备课时长",
            TeachingTimeContrast: "授课时长对比",
            TeachingTimeContrastDesc: [
                "1、统计教师在所查询的年级、科目课程的授课时长",
                "注：平台授课、备课数据分别从2020年6月4日、2020年10月27日开始记录。当日数据每小时统计一次。",
            ],
            standardCourseware: "标准课件",
            standardCoursewareDerivative: "标准课件衍生",
            personalCourseware: "个人课件",
            personalCoursewareDerivative: "个人课件衍生",
            curriculumTeachingUtilization: "课程授课使用率对比",
            curriculumTeachingUtilizationDesc: [
                "1、课程授课使用率=选择的课程中，教师在统计时段内授课过的知识点数量/课程知识点总数",
                "注：平台授课、备课数据分别从2020年6月4日、2020年10月27日开始记录。当日数据每小时统计一次。",
            ],
            selectCoursesToAnalysis: "选择需分析的课程",
            thumbUpNumber: "点赞人数",
            downloadsNumber: "下载人数",
            downloads: "下载次数",
            classesNumber: "授课次数",
            citations: "引用次数",
            TheTotal: "总量",
            SchoolAverage: "全校日均线",
            DetailsOfCourse: "备授课时长详情",
        },
        order: {
            orderList: "订单列表",
            orderDetail: "订单详情",
            refundApplication: "退款申请",
            refundDetail: "退款详情",
            orderNo: "订单编号",
            createTime: "创建时间",
            courseName: "课程名称",
            shoukuanshanghu: "收款商户",
            registeredStudents: "报名学生",
            grade: "年级",
            class: "班级",
            userPhoneNumber: "用户手机号",
            dingdanjine: "订单金额",
            shifujine: "实付金额",
            tuikuanjine: "退款金额",
            shenqingtuikuanjine: "申请退款金额",
            shijituikuanjine: "实际退款金额",
            yuan: "(元)",
            orderStatus: "订单状态",
            settlement: "结算管理",
            settlementNumber: "结算单号",
            settlementTimeInterval: "结算时段",
            settlementObjType: "结算对象类型",
            settlementObj: "结算对象",
            settlementStatus: "结算状态",
            settlementObjName: "结算对象名称",
            orderTotalMoney: "订单总额",
            orderTotalRefund: "退款总额",
            settlementMoneyNeed: "需结算金额",
            settlementMoneyUsed: "实际打款金额",
            settlementSubmit: "提交结算",
            settlementRecord: "结算凭证",
            view: "查看订单",
            autoreject: "退款申请7天未处理将自动审核不通过",
            refundNo: "退款单号",
            applyTime: "申请时间",
            refundStatus: "退款状态",
            orderInfo: "订单信息",
            payBy: "支付方式",
            payTime: "支付时间",
            studentNo: "学生学号",
            courseInfo: "课程信息",
            orderItemNo: "子订单号",
            refundInfo: "退款信息",
            refundReason: "退款原因",
            refundTime: "退款时间",
            changeReason: "修改原因",
            failReason: "失败原因",
            refundAudit: "退款审核",
            auditResult: "审核结果",
            pass: "通过",
            noPass: "不通过",
            reason: "原因",
            passed: "已通过",
            noPassed: "已驳回",
            changeRefundAmount: "退款金额修改",
            emptyNotice: "存在未设置退款金额数据，请填写完整后提交",
            zeroNotice: "退款金额不可为0元，请修改退款金额",
        },
        planCourse: {
            totalSchedule: "总课表",
            TheClassSchedule: "班级课表",
            TheTeacherSchedule: "老师课表",
            TheClassroomSchedule: "教室课表",
            classSchedule: "排课列表",
            arrangeClass: "排课",
            courseManagement: "学校课程库",
            selectCourses: "选课管理",
            classManagement: "班级管理",
            school: "学校",
            course: "课程",
            selectSchool: "请选择学校",
            selectCourse: "请选择课程",
            courseClass: "课程班级",
            class: "班级",
            teacher: "老师",
            selectTeacher: "请选择老师",
            classroom: "教室",
            chooseClassroom: "请选择教室",
            periodTime: "时间段",
            to: "至",
            startTime: "开始时间",
            endTime: "结束时间",
            selectTimeRange: "请选择时间范围",
            noArrangement: "暂无安排",
            view: "查看",
            delete: "删除",
            courseName: "排课名称",
            enterScheduleName: "请输入排课名称",
            arrangingDate: "排课日期",
            creationDate: "创建日期",
            founder: "创建人",
            selectFounder: "请选择创建人",
            state: "状态",
            all: "全部",
            courseArranging: "排课中",
            completed: "已完成",
            operateWidth: "140px",
            scheduleView: "课表查看",
            schedulePreview: "课表预览",
            changeSetting: "修改设置",
            complete: "完成",
            allCourses: "所有课程",
            allTeachers: "所有老师",
            allClass: "所有班级",
            infrastructure: "基础设置",
            teachingPlan: "授课计划",
            numberWeek: "每周上课天数：",
            circulationRules: "循环规则：",
            oneWeekIterations: "单周重复",
            twoWeekIterations: "双周重复",
            classTime: "上课时段：",
            classTimeNo: "上课时段",
            deleteClassTime: "是否要删除此上课时段?",
            editTime: "编辑时段",
            pleaseSelectWeekDay: "请选择每周上课天数",
            selectTimeClass: "请选择上课时段",
            pleaseSelectRowCourse: "请选择待排课程",
            pleaseSelectTimePeriod: "请选择时间段",
            rowCourse: "待排课程：",
            choose: "选择",
            add: "添加",
            chooseTime: "选择时段",
            newPeriodTime: "新增时段",
            CourseName: "课程名称",
            expirationDate: "到期时间",
            instructor: "授课老师",
            generateSchedule: "生成课表",
            beingGeneratedSchedule: "正在生成课表中...",
            maximumNumberSection: "最大可排节数为",
            pleaseSelectCourse: "请先选择课程",
            confirmDelete: "是否确认删除",
            confirmDeleteCourse: "是否确认删除该课程",
            addTeachingPlan: "请添加授课计划",
            forAWeek: "每周节数",
            trainingSession: "每周节数为此课程班级每周排课的节数，",
            periodLength: "每节课程时长为选择课程时间段时长",
            pleaseFillWeek: "请填写每周节数",
            scheduleGenerateSuccess: "课表生成成功",
            returnsList: "返回列表",
            checkSchedule: "查看课表",
            teachingMaterialVersion: "教材版本",
            classNumber: "班级数量",
            curriculumPlaces: "课程名额",
            shifoushoufei: "是否收费",
            registrationNumber: "已报名人数",
            w100_160: "100px",
            SelectClass: "请选择课程班级",
            SelectState: "请选择状态",
            SelectcirculationRules: "请选择循环规则",
            SelectSchedulePeriod: "请选择排课时间段",
            enterNumberSessionsWeek: "请输入每周节数",
            NoStudentMatched: "未匹配到学生姓名",
            StudentIDNotMatched: "未匹配到学号",
            StudentIDExist: "此学生ID不存在",
            ContainsCharacters: "长度在 1 到 10 个字符",
            ContainsCharactersCustom: "长度在 1 到 {0} 个字符",
            CharactersCustom: "长度在 {0} 到 {1} 个字符",
            MoreThan0: "请填写大于0的数字",
            ChangeSuccessfully: "调班成功",
            MaximumNumberPeople: "请选择最多容纳人数",
            SelectRange: "请选择日期范围",
            totalScheduleByImport: "课表管理",
            totalScheduleByImportLoading: "课表导入中...",
            scheduleImportSuccess: "导入成功",
            importResult: "导入结果",
            importResultData: `共 {0} 条数据，导入成功  {1}  条，导入失败  {2}  条；请下载导入结果查看导入失败的部分，修改后重新导入`,
            showImportRecord: "关闭后可在顶部菜单【操作中心】-导入记录页面查看",
            downloadToView: "下载查看",
            affiliation: "所属机构",
            academicYearAndSemester: "学年学期",
            academicYearAndSemesterSelect: "请选择学年学期",
            academicYear: "学年",
            academicYearSelect: "请选择学年",
            semesterSelect: "请选择学期",
            fallSemester: "秋季学期",
            springSemester: "春季学期",
            summerVacation: "暑假",
            winterVacation: "寒假",
            teacherAttendance: "老师考勤",
            teachingClasses: "教学班",
            teachingTime: "上课时间",
            teachingDate: "上课日期",
            statusOfAttendance: "出勤状态",
            normal: "正常",
            absenteeism: "缺勤",
            syncedTip: "当日数据每小时更新一次（仅同步已结束课程）",
            bulkModification: "批量修改",
            modifyAttendance: "修改考勤",
            AutomaticMatching: "自动匹配",
            teacherTimeSchedules: "当前老师同一一时间段已排课，请重新选择",
            studentAttendance: "学生考勤",
            changeToNormal: "改为正常",
            changeToNormalConfim: "确定修改此学生出勤状态为正常",
            changedToAbsence: "改为缺勤",
            changedToAbsenceConfirm: "确定修改此学生出勤状态为缺勤",
            questionnaireManage: "问卷管理",
            questionnaireResult: "问卷结果",
            questionnaireName: "问卷名称",
            questionnaireDeleteConfirm: "确定要删除此问卷？",
            enterQuestionnaireName: "请输入问卷名称",
            inProgress: "进行中",
            ended: "已结束",
            viewResults: "查看结果",
            addQuestionnaire: "添加问卷",
            editQuestionnaire: "编辑问卷",
            Deadline: "截止时间",
            selectDeadline: "请选择截止时间",
            activityCourseType: "意向活动课程类型",
            requirementType: "意向需求类型",
            AddNewClass: "新增班次",
            other: "其他",
            otherIntentionalNeeds: "其他意向需求",
            courseSatisfaction: "课程满意度",
            satisfaction: "满意度",
            importedRecords: "导入记录",
            exportedRecords: "导出记录",
            operationCenter: "操作中心",
            downloadFile: "下载文件",
            serialNumber: "序号",
            fileName: '文件名称',
            ToDownload: '去下载',
            DataOverviewExportInformation: '文件正在导出，导出完成的文件请在顶部菜单【操作中心】进行下载',
            teacherUsageStatistics: '教师使用统计',
            DataRefreshPrompt: '该统计数据每天凌晨1点更新',
            deleteRecordTip: "删除记录时，对应导入数据将同步删除，请谨慎操作",
            importLocation: "导入位置",
            importFile: "导入文件名称",
            importTime: "导入时间",
            DownloadFailedData: "下载失败数据",
            deleteRecordConfirm:
                "删除此记录，对应导入的数据均会同步删除，确定要删除？",
            teacherName: "老师姓名",
            inGrade: "所在年级",
            viewQuesResult: "课程满意度查看",
            importing: "问卷导入中",
            teacherNameInput: "请输入老师姓名",
            selectFirst: "请先选择数据",
            selectData: "已选{0}条数据",
            // --------w---------
            selectCouresTaskName: "选课任务名称",
            openingAcademicYear: "开课学年",
            openingSemester: "开课学期",
            selectCourseRange: "选课范围",
            selectCourseDate: "选课时间",
            kechengtuikuan: "课程退款",
            yunxutuikuan: "允许退款",
            yunxushenqing: "允许申请",
            buketuikuan: "不可退款",
            numberOfCourses: "课程数量",
            academicYearSemester: "学年学期",
            optionalCourses: "可选课程",
            selectCourseButton: "选择课程",
            courseSource: "课程来源",
            coursePrice: "课程价格(元)",
            applyGrade: "适用年级",
            courseCategory: "课程类别",
            numberOfOptionalClasses: "可选班级数量",
            addSelectCourseTask: "新增选课任务",
            updateSelectCourseTask: "编辑选课任务",
            viewSelectCourseTask: "查看选课任务",
            registeredStudents: "报名学生",
            userPhoneNumber: "用户手机号",
            refundTime: "退课时间",
            courseNameText: "课程名称",
            classPlaceholder: "请先选择年级",
            deleteCourseSelectionTask: "是否删除当前选课任务",
            courseStateLabel: "课程状态",
            noChooseCourse: "暂无可选课程",
            isRemoveCourseListItem: "是否确定移除该课程？",
            releaseDate: "发放日期",
            deadline: "截止日期",
            notStart: "未开始",
            releaseTime: "发放时间",
            selectReleaseTime: "请选择发放时间",
            releaseRange: "发放范围",
            selectReleaseRange: "请选择发放范围",
            questionnairCanntDelete: "当前已有家长提交，问卷不可删除！",
            fillTime: "填写时间",
            evaluationManage: "评价管理",
            evaluationTimes: "评价次数",
            courseAverageScore: "课程平均得分",
            averageScore: "平均得分",
            evaluationDetail: "评价详情",
            score: "{0}分",
            evaluationTime: "评价时间",
            appraiser: "评价人",
            courseRating: "课程评分",
            evaluationContent: "评价内容",
            rollCallCantCancel: "已考勤学生不可取消考勤状态，如需修改请联系教务",
            confirmAttendance: "确认出勤",
            submitPerson: "提交人",
            submitTime: "提交时间",
            attendanceNumber: "已到 {0} 人，未到 {1} 人",
            rollCallSuccess: "点名成功",
            updateMidnight: "该统计数据每天0点更新",
            noRefundNotice: "注意：课程班级对应的学期结束后则不可再退款",
        },
        infrastructure: {
            teachersManagement: "教师管理",
            authorizationTeachers: "教师授权",
            TeacherBatchAuthorization: "教师批量授权",
            BatchConfiguration: "批量配置",
            selected: "已选",
            numberPeople: "人",
            BatchAdd: "批量添加",
            BatchDel: "批量删除",
            studentManagement: "学生管理",
            timeManagementClass: "上课时段管理",
            classroomManagement: "教室管理",
            teacherNameMobile: "教师姓名/手机号",
            mobilePhoneNumber: "手机号码",
            idNumber: "证件号",
            mobilePhone: "手机号：",
            functionalAuthority: "功能权限",
            educationalAdministrator: "教务管理-子管理员",
            averageUser: "普通用户",
            Regulator: "监管机构",
            coursePermissions: "课程权限",
            authorization: "授权",
            allowedModify: "主管理员权限不允许修改!",
            userInformation: "用户信息",
            teacherCannotModified:
                "此教师为“教务管理-子管理员”，所在学校/学部不能被修改",
            jurisdiction: "管辖范围",
            whetherOpenAudit: "是否开启审核权限：",
            allowUploadPersonalResources: "允许上传个人资源",
            allowDownloadResources: "允许下载资源",
            allowCopyResources: "允许复制文本",
            student: "学生",
            withinSystem: "若学生在系统内已存在学生ID，可",
            throughToAdd: "通过学生ID添加",
            notStudentID: "学生没有学生ID， ",
            createStudent: "去创建新学生",
            accordingIDMatch: "根据学生ID匹配",
            enterStudentID: "请输入学号",
            gradeName: "年级名称",
            enterGradeName: "请输入年级名称",
            creationTime: "创建时间",
            inClass: "在班",
            Transfer: "转校",
            dropSchool: "退学",
            outSchool: "休学",
            graduation: "毕业",
            numberCourse: "已报课程数",
            RegisteredCourses: "已报课程",
            enterCorrectName: "请输入正确的学生姓名",
            enterCorrectStudentID: "请输入正确的学号",
            choosefirstGrade: "请先选择年级",
            downloadTemplate: "下载模板",
            nameClass: "教室名称",
            canAccommodateNumber: "可容纳人数",
            accommodateNumber: "最多容纳人数",
            enterNumberStudents: "请输入最大学生数",
            enterNameClassroom: "请输入教室名称",
            newClassroom: "新增教室",
            editClassroom: "编辑教室",
            confirmDeleteClassroom: "是否确认删除此教室?",
            WhetherLogoffResources: "是否确认下线配套资源",
            resourcesNotVisible: "资源下线后，本校所有教师均不可见哦~",
            logoffReasonLeaveResources:
                "请输入下线理由，系统将会发送给资源作者，可以为空",
            WhetherOnlinedResources: "是否确认上线配套资源？",
            ResourceWillAvailable: "资源上线后，将对本校所有教师可见哦~",
            MarkedRegenerate: "标记为存在冲突课程，请修改设置后重新生成",
            EducationalAdministrators: "教务管理-主管理员",
            PermissionModifyInformation: "子管理员无权限修改，请联系主管理员处理",
            ReselectCourseDate: "过去的时间不可选择，请重新选择排课日期",
            CoincidesTimePperiod: "此时间段与已选时间段有时间重合，请重新选择",
            idNumberError1: "身份证号为18位，末尾的X请输入大写字母！",
            idNumberError2: "身份证号出生日期不对！",
            enterIdNumber: "请输入身份证号",
            IdNumber: "身份证号",
        },
        board: {
            selectPanel: "选择看板",
            platformPanel: "平台看板",
            AreaPanel: "区域看板",
            SchoolPanel: "学校看板",
            DataPanelHailiang: "海亮课后服务平台数据看板",
            PlatformDataPanel: "课后服务平台数据看板",
            TimeRange: "时间范围",
            Nearly7days: "近7天",
            Nearly30days: "近30天",
            lastModifyTime: "上次更新时间：",
            NumberTeachers: "参与老师数",
            NumberStudents: "参与学生数",
            TotalCourses: "课程数",
            TotalLessonPeriods: "课节数",
            TotalLessons: "课次数",
            AllKnowledgePoints: "系统内全部学科下各课程二级知识点数之和",
            AllStudentsTaken: "系统内全部学生已上过的课程节数之和",
            AreaNumber: "地区数",
            SchoolNumber: "学校数",
            usage: "使用情况",
            UsageTrendChart: "所选时间范围内，使用情况趋势图；",
            PlatformUsageDesc:
                "平台使用率 = 平台当天登录过平台的用户数 / 平台用户总数 ",
            PreparationUsageRateDesc:
                "备课使用率=平台当天有备课记录的教师（含教务）人数 / 平台系统当天的教师（含教务）账号数",
            TeachingUsageRateDesc:
                "授课使用率=平台当天有授课记录的教师（含教务）人数 / 平台系统当天的教师（含教务）账号数",
            UsageRateAreaDesc:
                "使用率 = 区域内当天登录过平台的用户数 / 区域内用户总数",
            PreparationUsageRateAreaDesc:
                "备课使用率=区域内当天有备课记录的教师（含教务）人数 / 区域内当天的教师（含教务）账号数",
            TeachingUsageRateAreaDesc:
                "授课使用率=区域内当天有授课记录的教师（含教务）人数 / 区域内当天的教师（含教务）账号数",
            UsageRateDesc: "使用率 = 本校内当天登录过平台的用户数 / 本校内用户总数",
            PreparationRateDesc:
                "备课使用率=本校当天有备课记录的教师（含教务）人数 / 本校当天的教师（含教务）账号数",
            TeachingUsageRateSchoolDesc:
                "授课使用率=本校当天有授课记录的教师（含教务）人数 / 本校当天的教师（含教务）账号数",
            AverageDurationUse: "人均使用时长",
            TrendChartTimeRange: "所选时间范围内，人均使用时长趋势图；",
            PerCapitaLessonPreparationTime:
                "人均备课时长=当天平台备课时长之和 / 平台当天的教师教务账号数",
            PerCapitaTeachingHours:
                "人均授课时长=当天平台授课时长之和 / 平台当天的教师教务账号数",
            PerCapitaLessonPreparationDuration:
                "人均备课时长=区域内当天平台备课时长之和 / 区域内当天的教师教务账号数",
            PerCapitaTeachingDuration:
                "人均授课时长=区域内当天平台授课时长之和 / 区域内当天的教师教务账号数",
            PerCapitaLessonDuration:
                "人均备课时长=当天本校备课时长之和 / 本校当天的教师教务账号数",
            PerCapitaTeachingDurationDesc:
                "人均授课时长=当天本校授课时长之和 / 本校当天的教师教务账号数",
            RankingLesson: "备授课时长排行",
            More: "更多",
            teachers: "老师",
            school: "学校",
            area: "区域",
            areaData: "地区",
            DurationTeaching: "授课时长",
            TotalDuration: "总时长",
            RankingTimeRange: "所选时间范围内，备授课时长排名",
            AreaLessonDuration: "地区备课时长 = 该地区下所有教师教务的备课时长之和",
            AreaTeachingDuration: "地区授课时长 = 该地区下所有用户的授课时长之和",
            TotalDurationTime: "总时长 = 地区备课时长 + 地区授课时长",
            SchoolPreparationDuration:
                "学校备课时长 = 该学校下所有教师教务的备课时长之和",
            SchoolTeachingDuration: "学校授课时长 = 该学校下所有用户的授课时长之和",
            TotalTeachingDuration: "总时长 = 学校备课时长 + 学校授课时长",
            LessonPreparationTeachers: "老师备课时长 = 教师教务的备课时长之和",
            TeachingDurationTeachers: "老师授课时长 = 老师教务的授课时长之和",
            TotalDurationTeacher: "总时长 = 老师备课时长 + 老师授课时长",
            ParticipationGrade: "各年级参与情况",
            ParticipationSchool: "各学校参与情况",
            ParticipationArea: "各地区参与情况",
            grade: "年级",
            NumberPrticipatingClasses: "参与班级数",
            StudentParticipationRate: "学生参与率",
            SchoolName: "学校名称",
            NumberPrticipatingSchools: "参与学校数",
            AreaName: "区域名称",
            AreaDataName: "地区名称",
            ParticipationRate: "参与率",
            Popularityisciplines: "各学科热度分布",
            PlatformPopularity: "选择时间范围内，平台内各学科热度值分布",
            PlatformPopularityValue:
                "热度值 = 学科下各课程备课和授课次数之和 / 全部学科下各课程备课和授课次数之和 * 100%",
            AreaPopularity: "选择时间范围内，区域内各学科热度值分布",
            AreaPopularityValue:
                "热度值 = 学科下各课程备课和授课次数之和 / 全部学科下各课程备课和授课次数之和 * 100%",
            SchoolPopularity: "选择时间范围内，学校内各学科热度值分布",
            SchoolPopularityValue:
                "热度值 = 学科下各课程备课和授课次数之和 / 全部学科下各课程备课和授课次数之和 * 100%",
            CoursePopularityRanking: "课程热度排行",
            Ranking: "排名",
            Popularity: "热度",
            PopularityValueRanking: "所选时间范围内，平台内课程热度值排行",
            PlatformValueDesc:
                "热度值 = 课程备课和授课次数之和 / 同年级全部课程备课和授课次数之和 * 100%",
            AreaPopularityValueRanking: "所选时间范围内，区域内开通的课程热度值排行",
            AreaValueDesc:
                "热度值 = 课程备课和授课次数之和 / 同年级全部课程备课和授课次数之和 * 100%",
            SchoolPopularityValueRanking: "所选时间范围内，本校内课程热度值排行",
            SchoolValueDesc:
                "热度值 = 课程备课和授课次数之和 / 同年级全部课程备课和授课次数之和 * 100%",
            TopFive: "前五名",
            LastFive: "后五名",
            DurationDayStudent: "学生人均每天上课时长",
            NumberCoursesPerson: "人均报名课程数",
            PerCapitaDuration: "人均每天上课时长",
            AverageRange: "所选时间范围内，学生人均每天上课时长；",
            AverageClassDuration:
                "学生每天平均上课时长 = 学生所在课程班级排课时长之和 / 排课天数",
            PlatformAverageNumberCourses:
                "人均报名课程数 = 系统内参与学生报名的课程总数 / 系统内参与学生数",
            PlatformClassDuration:
                "人均每天上课时长 = 系统内参与的学生每天平均上课时长总和 / 系统内参与学生数",
            PlatformDurationPerDay:
                "区域（学校）人均每天上课时长 = 区域（学校）参与的学生每天平均上课时长总和 / 区域（学校）内参与学生数",
            AreaAverageNumberCourses:
                "人均报名课程数 = 区域内参与学生报名的课程总数 / 区域内参与学生数",
            AreaClassDuration:
                "人均每天上课时长 = 区域内参与的学生每天平均上课时长总和 / 区域内参与学生数",
            AreaDurationPerDay:
                "年级（班级）人均每天上课时长 = 年级（班级）内参与的学生每天平均上课时长总和 / 年级（班级）内参与学生数",
            SchoolAverageNumberCourses:
                "人均报名课程数 = 本校内参与学生报名的课程总数 / 本校内参与学生数",
            SchoolClassDuration:
                "人均每天上课时长 = 本校内参与的学生每天平均上课时长总和 / 本校内参与学生数",
            SchoolDurationPerDay:
                "学校人均每天上课时长 = 学校参与的学生每天平均上课时长总和 / 学校内参与学生数",
            DurationPerCapita: "人均时长（分钟）",
            AccordingArea: "按区域",
            AccordingSchool: "按学校",
            AccordingGrade: "按年级",
            AccordingClass: "按班级",
            UsageRate: "使用率",
        },
        router: {
            DataPanel: "数据看板",
            TeachersComparativeAnalysis: "教师对比分析",
            teachingAnalysis: "备授课分析",
            TeachingRecord: "授课记录",
            PrepareRecord: "备课记录",
            dataOverview: "数据概览",
            ResourceUsageAnalysis: "资源使用分析",
        },
        forgotPwd: {
            SendingSMSMessage: "短信发送中",
            ObtainingCode: "获取验证码",
            EnterMobileNumber: "请输入手机号",
            MobileNumber: "手机号",
            EnterVerificationCode: "请输入验证码",
            VerificationCodes: "验证码",
            SetPassword: "设置密码",
            Charactersnumbers: "不少于8位，需包含字母（区分大小写）和数字",
            ConfirmPassword: "确认密码",
            ConfirmPasswordAgain: "再次确认密码",
            VerificationSent: "验证码已发送，请注意查收!",
            ResetSucceeded: "密码重置成功",
            passwordnotMeet: "密码输入不符合密码复杂度要求！",
            passwordDiff: "两次输入密码不一致!",
            enternewpassword: "输入新的密码",
            VerificationCode: "输入6位短信验证码",
            CorrectMobileNumber: "请输入正确的手机号",
            PasswordLength: "密码长度必须为",
            characters: "16个字符",
            PasswordNotEmpty: "密码不得为空",
            ContainUpperLowerLetters: "密码必须包含大小写英语字母",
            MustcontainNumbers: "密码必须包含数字",
            SpacesCharacters: "密码不得包含空格或中文字符",
            SpecialCharacters: "密码不得包含以下特殊字符",
        },
        saas: {
            permissionManage: "权限管理",
            addTeacher: "添加教师",
            downloadTemplate: "下载模板",
            batchImport: "批量导入",
            roleManage: "角色管理",
            authorizationRoles: "角色管理",
            menuAuth: "菜单授权",
            assigningUsers: "分配用户",
            chooseUser: "选择教职工",
            chooseMember: "选择成员",
            backToSaas: "返回云平台",
            pleaseChooseMember: "请选择成员",
            pleaseChooseRole: "请选择功能角色",
            role: {
                roleCode: "角色编码",
                roleName: "角色名称",
                roleType: "角色类型",
                roleStatus: "角色状态",
                roleDesc: "角色描述",
                roleDeleteConfirm: "确定要删除此角色？",
                roleNameRequired: "请输入角色名称",
                roleStatusRequired: "请选择角色状态",
                roleAdd: "角色新增",
                roleEdit: "角色编辑",
            },
        },
    },
};
// 4、项目中自定义语言包，与组件语言包合并
zhCNM = deepmerge(zhCNM, zhCNLocale, { clone: true });
export default zhCNM;
