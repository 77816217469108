import store from "@/store";
import Vue from "vue";

const hasPermisstion = (code) => {
  if (typeof code === "string") {
    code = [code];
  }
  if (Array.isArray(code)) {
    return code.every((c) => store.state.user.authBtn.includes(c));
  } else {
    console.warn("请传入code字符串或数组");
    return true;
  }
};

export default {
  bind(el, binding) {
    if (!hasPermisstion(binding.value)) {
      Vue.prototype.$nextTick(() => {
        el.parentNode?.removeChild(el);
      });
    }
  },
  update(el, binding) {
    if (!hasPermisstion(binding.value)) {
      Vue.prototype.$nextTick(() => {
        el.parentNode?.removeChild(el);
      });
    }
  },
};
