<template>
  <div class="file-list">
    <ul v-for="(file, index) in files" :key="index">
      <li
        @click="handleClick(file)"
        class="file-list-item el-upload-list__item is-success"
      >
        <a class="el-upload-list__item-name">
          <i class="el-icon-document"></i>
          <span class="file-list-item-name single-line">{{
            extractFileNameFromURL(file.name)
          }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import { extractFileNameFromURL } from "@/utils/index";
export default {
  data() {
    return {
      extractFileNameFromURL: extractFileNameFromURL,
    };
  },
  props: {
    files: Array,
  },
  methods: {
    handleClick(file) {
      this.$emit("handleClick", file);
    },
  },
};
</script>
<style lang="less" scoped>
.file-list {
  &-item {
    display: flex;
    &:hover {
      cursor: pointer;
    }
    &-name {
      flex: 1;
    }
  }
}
</style>
