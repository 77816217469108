// 挂在cookie库
import Vue from "vue";
import format from "date-fns/format";

// 挂载实例方法
Object.defineProperty(Vue.prototype, "$dateFormat", {
  value: format,
});

// 挂载Vue下
Object.defineProperty(Vue, "dateFormat", {
  value: format,
});

export default format;
