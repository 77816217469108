import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

let routes = [
    {
        path: "/",
        redirect: {
            name: "home",
        },
    },
    {
        path: "",
        name: "layout",
        component: () =>
            import(/* webpackChunkName: "layout" */ "../views/layout/index.vue"),
        children: [
            {
                path: "/home",
                name: "home",
                component: () =>
                    import(/* webpackChunkName: "home" */ "../views/home/index.vue"),
                meta: {
                    title: "el.routerName.homePage",
                    keepAlive: true,
                    // eduAuth: [0, 1, "0", "1"]
                },
            },
            {
                path: "/myCourses",
                component: () =>
                    import(
            /* webpackChunkName: "myCourses" */ "@/views/myCourseList/index.vue"
                    ),
                name: "myCourses",
                meta: {
                    title: "el.routerName.myCourse",
                    icon: "",
                    keepAlive: true,
                    eduAuth: [0, 1, "0", "1", "3", 3],
                },
            },
            {
                path: "/courseList",
                component: () =>
                    import(
            /* webpackChunkName: "courseList" */ "@/views/courseList/index.vue"
                    ),
                name: "courseList",
                meta: {
                    title: "el.routerName.courseList",
                    icon: "",
                    keepAlive: true,
                    eduAuth: [0, 1, "0", "1", "3", 3],
                },
            },
            {
                path: "/courseManage/courseDetails",
                component: () =>
                    import(
            /* webpackChunkName: "CourseDetails" */ "@/views/courseList/courseDetails.vue"
                    ),
                name: "CourseDetails",
                meta: {
                    title: "el.routerName.courseDetails",
                    icon: "",
                    keepAlive: false,
                    eduAuth: [0, 1, "0", "1", "3", 3],
                },
            },
            {
                path: "/courseManage/hdCourseDetails",
                component: () =>
                    import(
            /* webpackChunkName: "hdCourseDetails" */ "@/views/hdClassroomWps/course-details/courseDetails.vue"
                    ),
                name: "hdCourseDetails",
                meta: {
                    title: "el.routerName.courseDetails",
                    authority: ["third"],
                    icon: "",
                    keepAlive: false,
                    eduAuth: [0, 1, "0", "1", "3", 3],
                },
            },
            {
                path: "/home/myTimeTable",
                name: "myTimeTable",
                component: () =>
                    import(
            /* webpackChunkName: "home" */ "../views/home/myTimeTable.vue"
                    ),
                meta: {
                    title: "el.routerName.myTimeTable",
                },
            },
            {
                path: "",
                component: () =>
                    import(/* webpackChunkName: "my" */ "@/views/my/index.vue"),
                name: "my",
                children: [
                    // {
                    //   path: "/my",
                    //   name: "myCenter",
                    //   component: () =>
                    //     import(
                    //       /* webpackChunkName: "home" */ "../views/my/my-center/index.vue"
                    //     ),
                    //   meta: {
                    //     title: "el.routerName.myCenter",
                    //     icon: "",
                    //     keepAlive: false,
                    //   },
                    // },
                    {
                        path: "/manage/classManage",
                        component: () =>
                            import(
                /* webpackChunkName: "classManage" */ "@/views/manage/class-manage/index.vue"
                            ),
                        name: "classManage",
                        meta: {
                            title: "el.routerName.classManage",
                            icon: "",
                            keepAlive: false,
                        },
                    },
                    {
                        path: "/manage/studentManage",
                        component: () =>
                            import(
                /* webpackChunkName: "studentManage" */ "@/views/manage/student-manage/index.vue"
                            ),
                        name: "studentManage",
                        meta: {
                            title: "el.routerName.studentManage",
                            icon: "",
                            keepAlive: false,
                        },
                    },
                    {
                        path: "/manage/classStudentManage",
                        component: () =>
                            import(
                /* webpackChunkName: "classStudentManage" */ "@/views/manage/student-manage/classStudentManage.vue"
                            ),
                        name: "classStudentManage",
                        meta: {
                            title: "el.routerName.studentManage",
                            icon: "",
                            keepAlive: false,
                        },
                    },
                    {
                        path: "/manage/courseClass",
                        component: () =>
                            import(
                /* webpackChunkName: "classManage" */ "@/views/manage/course-class/index.vue"
                            ),
                        name: "manageCourseClass",
                        meta: {
                            title: "el.routerName.manageCourseClass",
                            icon: "",
                            keepAlive: false,
                        },
                    },
                    {
                        path: "/manage/courseClassStudent",
                        component: () =>
                            import(
                /* webpackChunkName: "classManage" */ "@/views/manage/course-class/student.vue"
                            ),
                        name: "manageCourseClassStudent",
                        meta: {
                            title: "el.courseClassManage.studentManageNbsp",
                            icon: "",
                            keepAlive: false,
                        },
                    },
                ],
            },
            // {
            //   path: '/manage/classManage',
            //   component: () => import(/* webpackChunkName: "classManage" */'@/views/manage/class-manage/index.vue'),
            //   name: 'classManage',
            //   meta: {
            //     title: '班级管理',
            //     icon: '',
            //     keepAlive: false
            //   }
            // },
            // {
            //   path: '/manage/studentManage',
            //   component: () => import(/* webpackChunkName: "studentManage" */'@/views/manage/student-manage/index.vue'),
            //   name: 'studentManage',
            //   meta: {
            //     title: '学员管理',
            //     icon: '',
            //     keepAlive: false
            //   }
            // },
            {
                path: "/board/statisticalBoard",
                component: () =>
                    import(
            /* webpackChunkName: "statisticalBoard" */ "@/views/board/statistical-board/index.vue"
                    ),
                name: "statisticalBoard",
                meta: {
                    title: "el.router.DataPanel",
                    icon: "",
                    keepAlive: false,
                    eduAuth: [0, 1, "0", "1", "3", 3],
                },
            },
            {
                path: "/manage/teachManage",
                component: () =>
                    import(
            /* webpackChunkName: "tabManage" */ "@/views/manage/tab-manage/index.vue"
                    ),
                name: "tabManage",
                children: [
                    {
                        path: "/manage/teacherManage",
                        component: () =>
                            import(
                /* webpackChunkName: "teachManage" */ "@/views/manage/teach-manage/index.vue"
                            ),
                        name: "teachManage",
                        meta: {
                            title: "el.saas.permissionManage",
                            icon: "",
                            keepAlive: true,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/courseRecommendations",
                        component: () =>
                            import(
                /* webpackChunkName: "courseRecommendations" */ "@/views/manage/teach-manage/courseRecommendations.vue"
                            ),
                        name: "courseRecommendations",
                        meta: {
                            title: "el.common.CourseRecommendations",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/attendClassTime",
                        component: () =>
                            import(
                /* webpackChunkName: "teachManage" */ "@/views/manage/attend-class/index.vue"
                            ),
                        name: "attendClassTime",
                        meta: {
                            title: "el.infrastructure.timeManagementClass",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/authorize",
                        component: () =>
                            import(
                /* webpackChunkName: "authorize" */ "@/views/manage/teach-manage/authorize.vue"
                            ),
                        name: "authorize",
                        meta: {
                            title: "el.infrastructure.authorizationTeachers",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/authorize/batchUserPermission",
                        name: "batchUserPermission",
                        meta: {
                            title: "批量权限配置",
                            keepAlive: false,
                        },
                        component: () =>
                            import(
                                /* webpackChunkName: "batchUserPermission" */
                                "@/views/manage/teach-manage/batch-user-permission.vue"
                            ),
                    },
                    {
                        path: "/manage/compared",
                        component: () =>
                            import(
                /* webpackChunkName: "compared" */ "@/views/manage/analysis/compared"
                            ),
                        name: "compared",
                        meta: {
                            title: "el.router.TeachersComparativeAnalysis",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/teachingRecord",
                        component: () =>
                            import(
                /* webpackChunkName: "teachingRecord" */ "@/views/manage/analysis/teachingRecord"
                            ),
                        name: "teachingRecord",
                        meta: {
                            title: "el.router.TeachingRecord",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/dataOverview",
                        component: () =>
                            import(
                /* webpackChunkName: "prepareRecord" */ "@/views/manage/analysis/dataOverview"
                            ),
                        name: "dataOverview",
                        meta: {
                            title: "el.router.dataOverview",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/prepareRecord",
                        component: () =>
                            import(
                /* webpackChunkName: "prepareRecord" */ "@/views/manage/analysis/prepareRecord"
                            ),
                        name: "prepareRecord",
                        meta: {
                            title: "el.router.PrepareRecord",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/course",
                        component: () =>
                            import(
                /* webpackChunkName: "course" */ "@/views/manage/analysis/course"
                            ),
                        name: "course",
                        meta: {
                            title: "el.router.teachingAnalysis",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/usage",
                        component: () =>
                            import(
                /* webpackChunkName: "usage" */ "@/views/manage/analysis/usage"
                            ),
                        name: "usage",
                        meta: {
                            title: "使用情况分析",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/auditManage",
                        component: () =>
                            import(
                /* webpackChunkName: "auditManage" */ "@/views/manage/audit-manage/index.vue"
                            ),
                        name: "auditManage",
                        meta: {
                            title: "审核管理",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/resourceManage",
                        component: () =>
                            import(
                /* webpackChunkName: "resourceManage" */ "@/views/manage/resource-manage/index.vue"
                            ),
                        name: "resourceManage",
                        meta: {
                            title: "el.common.schoolResourcesManagement",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/shareRecord",
                        component: () =>
                            import(
                /* webpackChunkName: "shareRecord" */ "@/views/manage/share-record/index.vue"
                            ),
                        name: "shareRecord",
                        meta: {
                            title: "el.schoolResourceManage.crossSchoolSharingRecords",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/rescourceAnalyze",
                        component: () =>
                            import(
                /* webpackChunkName: "rescourceAnalyze" */ "@/views/manage/rescource-analyze/index.vue"
                            ),
                        name: "rescourceAnalyze",
                        meta: {
                            title: "el.router.ResourceUsageAnalysis",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/orderList",
                        component: () =>
                            import(
                /* webpackChunkName: "orderList" */ "@/views/manage/order-manage/orderList.vue"
                            ),
                        name: "orderList",
                        meta: {
                            title: "el.order.orderList",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/orderDetail",
                        component: () =>
                            import(
                /* webpackChunkName: "orderDetail" */ "@/views/manage/order-manage/orderDetail.vue"
                            ),
                        name: "orderDetail",
                        meta: {
                            title: "el.order.orderDetail",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/refundApplication",
                        component: () =>
                            import(
                /* webpackChunkName: "refundApplication" */ "@/views/manage/order-manage/refundApplication.vue"
                            ),
                        name: "refundApplication",
                        meta: {
                            title: "el.order.refundApplication",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/refundDetail",
                        component: () =>
                            import(
                /* webpackChunkName: "refundDetail" */ "@/views/manage/order-manage/refundDetail.vue"
                            ),
                        name: "refundDetail",
                        meta: {
                            title: "el.order.refundDetail",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/settlement",
                        component: () =>
                            import(
                /* webpackChunkName: "refundApplication" */ "@/views/manage/order-manage/settlement/index.vue"
                            ),
                        name: "settlement",
                        meta: {
                            title: "el.order.settlement",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/manage/base/classroomSetting",
                        component: () =>
                            import(
                /* webpackChunkName: "rescourceAnalyze" */ "@/views/manage/classroom-setting/index.vue"
                            ),
                        name: "classroomSetting",
                        meta: {
                            title: "el.infrastructure.classroomManagement",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/classroomSetting/schedule",
                        name: "classroomSchedule",
                        component: () =>
                            import(
                /* webpackChunkName: "home" */ "@/views/manage/classroom-setting/schedule.vue"
                            ),
                        meta: {
                            title: "上课管理",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    // 学生管理隐藏
                    // {
                    //   path: "/manage/base/classStudent",
                    //   component: () =>
                    //     import(
                    //       /* webpackChunkName: "rescourceAnalyze" */ "@/views/manage/class-student/index"
                    //     ),
                    //   name: "classStudent",
                    //   meta: {
                    //     title: "el.infrastructure.studentManagement",
                    //     icon: "",
                    //     keepAlive: false,
                    //     eduAuth: [0, 1, "0", "1"],
                    //   },
                    // },
                    {
                        path: "/manage/base/reported",
                        component: () =>
                            import(
                /* webpackChunkName: "studentReported" */ "@/views/manage/class-student/reported"
                            ),
                        name: "classStudentReported",
                        meta: {
                            title: "el.infrastructure.RegisteredCourses",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/planCourseManage/courseManage",
                        component: () =>
                            import(
                /* webpackChunkName: "rescourceAnalyze" */ "@/views/manage/plan-course/courseManage.vue"
                            ),
                        name: "courseManage",
                        meta: {
                            title: "el.planCourse.courseManagement",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    // 选课管理
                    {
                        path: "/select-courses",
                        component: () =>
                            import(
                /* webpackChunkName: "rescourceAnalyze" */ "@/views/manage/select-courses/index.vue"
                            ),
                        name: "selectCourses",
                        meta: {
                            title: "el.planCourse.selectCourses",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    // 选课详情
                    {
                        path: "/select-courses/detail",
                        component: () =>
                            import(
                /* webpackChunkName: "rescourceAnalyze" */ "@/views/manage/select-courses/detail.vue"
                            ),
                        name: "selectCoursesDetail",
                        meta: {
                            title: "选课详情",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    // 课程管理
                    {
                        path: "/planCourseManage/teacherAttendance",
                        component: () =>
                            import(
                /* webpackChunkName: "rescourceAnalyze" */ "@/views/manage/plan-course/teacherAttendance.vue"
                            ),
                        name: "teacherAttendance",
                        meta: {
                            title: "el.planCourse.teacherAttendance",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/planCourseManage/studentAttendance",
                        component: () =>
                            import(
                /* webpackChunkName: "rescourceAnalyze" */ "@/views/manage/plan-course/teacherAttendance.vue"
                            ),
                        name: "studentAttendance",
                        meta: {
                            title: "el.planCourse.studentAttendance",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/planCourseManage/questionnaireManage",
                        component: () =>
                            import(
                /* webpackChunkName: "rescourceAnalyze" */ "@/views/manage/plan-course/questionnaireManage.vue"
                            ),
                        name: "questionnaireManage",
                        meta: {
                            title: "el.planCourse.questionnaireManage",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/planCourseManage/questionnaireResult",
                        component: () =>
                            import(
                /* webpackChunkName: "questionnaireResult" */ "@/views/manage/plan-course/questionnaireResult.vue"
                            ),
                        name: "questionnaireResult",
                        meta: {
                            title: "el.planCourse.questionnaireResult",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/planCourseManage/courseClassManage",
                        component: () =>
                            import(
                /* webpackChunkName: "teachManage" */ "@/views/manage/plan-course/courseClassManage.vue"
                            ),
                        name: "courseClassManage",
                        meta: {
                            title: "el.planCourse.classManagement",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/planCourseManage/totalCourseList",
                        component: () =>
                            import(
                /* webpackChunkName: "rescourceAnalyze" */ "@/views/manage/plan-course/totalCourseList.vue"
                            ),
                        name: "totalCourseList",
                        meta: {
                            title: "el.planCourse.totalSchedule",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/planCourseManage/totalCourseListByImport",
                        component: () =>
                            import(
                /* webpackChunkName: "rescourceAnalyze" */ "@/views/manage/plan-course/totalCourseList.vue"
                            ),
                        name: "totalCourseListByImport",
                        meta: {
                            title: "el.planCourse.totalScheduleByImport",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/planCourseManage/planCourseList",
                        component: () =>
                            import(
                /* webpackChunkName: "rescourceAnalyze" */ "@/views/manage/plan-course/planCourseList.vue"
                            ),
                        name: "planCourseList",
                        meta: {
                            title: "el.planCourse.classSchedule",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/planCourseManage/schoolTimetabelCheck",
                        component: () =>
                            import(
                /* webpackChunkName: "rescourceAnalyze" */ "@/views/manage/plan-course/schoolTimetabelCheck.vue"
                            ),
                        name: "schoolTimetabelCheck",
                        meta: {
                            title: "el.planCourse.scheduleView",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/planCourseManage/planCourseDetails",
                        component: () =>
                            import(
                /* webpackChunkName: "rescourceAnalyze" */ "@/views/manage/plan-course/planCourseDetails.vue"
                            ),
                        name: "planCourseDetails",
                        meta: {
                            title: "el.planCourse.arrangeClass",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/rolemanage",
                        component: () =>
                            import(
                /* webpackChunkName: "rolemanage" */ "@/views/manage/role-manage/index.vue"
                            ),
                        name: "roleManage",
                        meta: {
                            title: "el.saas.authorizationRoles",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/rolemanage/permission",
                        component: () =>
                            import(
                /* webpackChunkName: "rolemanage" */ "@/views/manage/role-manage/permission.vue"
                            ),
                        name: "rolePermission",
                        meta: {
                            title: "el.saas.menuAuth",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/rolemanage/allotUser",
                        component: () =>
                            import(
                /* webpackChunkName: "rolemanage" */ "@/views/manage/role-manage/allotUser.vue"
                            ),
                        name: "allotUser",
                        meta: {
                            title: "el.saas.assigningUsers",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/planCourseManage/evaluationManage",
                        component: () =>
                            import(
                /* webpackChunkName: "evaluationManage" */ "@/views/manage/plan-course/evaluationManage.vue"
                            ),
                        name: "evaluationManage",
                        meta: {
                            title: "el.planCourse.evaluationManage",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                    {
                        path: "/planCourseManage/evaluationDetail",
                        component: () =>
                            import(
                /* webpackChunkName: "evaluationManage" */ "@/views/manage/plan-course/evaluationDetail.vue"
                            ),
                        name: "evaluationDetail",
                        meta: {
                            title: "el.planCourse.evaluationDetail",
                            icon: "",
                            keepAlive: false,
                            eduAuth: [0, 1, "0", "1"],
                        },
                    },
                ],
            },
            // {
            //   path: '/manage/authorize',
            //   component: () =>
            //     import(
            //       /* webpackChunkName: "classManage" */ '@/views/manage/teach-manage/authorize.vue'
            //     ),
            //   name: 'authorize',
            //   meta: {
            //     title: '授权',
            //     icon: '',
            //     keepAlive: false
            //   }
            // },
            {
                path: "/tip/install",
                component: () =>
                    import(
                        /* webpackChunkName: "InstallTip" */
                        "@/views/tip/install.vue"
                    ),
                name: "InstallTip",
                meta: {
                    title: "插件安装提示",
                    icon: "",
                    keepAlive: false,
                },
            },
            // {
            //   path: '/error/404',
            //   name: 'not-found',
            //   component: () =>
            //     import( /* webpackChunkName: "not-found" */ '../views/error/404.vue')
            // }
        ],
    },
    {
        path: "/lookWps",
        component: () =>
            import(/* webpackChunkName: "lookWps" */ "@/views/lookWps/index.vue"),
        name: "lookWps",
        meta: {
            title: "查看文件",
        },
    },
    {
        path: "/error/404",
        name: "not-found",
        component: () =>
            import(/* webpackChunkName: "not-found" */ "../views/error/404.vue"),
    },
    {
        path: "/error/403",
        name: "not-authority",
        component: () =>
            import(/* webpackChunkName: "not-found" */ "../views/error/403.vue"),
    },
    {
        path: "/tip/auth",
        component: () =>
            import(
                /* webpackChunkName: "Auth" */
                "@/views/tip/auth.vue"
            ),
        name: "Auth",
        meta: {
            title: "el.routerName.authTip",
            icon: "",
            keepAlive: false,
        },
    },
    {
        path: "/tip/resource",
        component: () =>
            import(
                /* webpackChunkName: "Resource" */
                "@/views/tip/resource.vue"
            ),
        name: "Resource",
        meta: {
            title: "el.routerName.resourceTip",
            icon: "",
            keepAlive: false,
        },
    },
    {
        path: "/teaching",
        name: "teaching",
        meta: {
            title: "el.routerName.teaching",
        },
        component: () =>
            import(/* webpackChunkName: "teaching" */ "../views/teaching/index.vue"),
    },
    {
        path: "/PreviewWpsDialog",
        name: "PreviewWpsDialog",
        meta: {
            title: "el.routerName.teaching",
        },
        component: () =>
            import(
        /* webpackChunkName: "teachingWpsDialog" */ "../views/teaching/PreviewWpsDialog.vue"
            ),
    },
    {
        path: "/hdTeaching",
        name: "hdTeaching",
        meta: {
            title: "el.routerName.teaching",
            authority: ["third"],
        },
        component: () =>
            import(
        /* webpackChunkName: "hdTeaching" */ "../views/teaching/indexHd.vue"
            ),
    },
    {
        path: "/login",
        name: "login",
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/login.vue"),
    },
    {
        path: "/sso/auth",
        redirect: "/",
    },
    {
        path: "/third/auth",
    },
];

// 动态加载路由配置文件
const content = require.context(".", false, /\.js$/);
const views = content
    .keys()
    .filter((key) => {
        return key !== "./index.js";
    })
    .map(function (key) {
        return content(key).default;
    });
// 动态添加路由
routes = routes.concat(views);

routes.push({
    path: "*",
    redirect: "/error/404",
});

let router = new VueRouter({
    mode: "history",
    //   base: process.env.BASE_URL,
    base: process.env.VUE_APP_BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {
                x: 0,
                y: 0,
            };
        }
    },
});

// 获取原型对象上的push函数
const originalReplace = VueRouter.prototype.replace;
// 修改原型对象中的push方法
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch((err) => err);
};

const _routerNameToPathMap = new Map();
const loop = (tree, cb) => {
    tree.forEach((item) => {
        cb(item);
        if (item.children?.length) {
            loop(item.children, cb);
        }
    });
};
loop(routes, (item) => {
    if (item.name) {
        _routerNameToPathMap.set(item.name, item.path);
    }
});

// 路由name与path的映射map
export const routerNameToPathMap = _routerNameToPathMap;

// 根据路由name获取到路由的path
export const getPathByName = (name) => {
    return _routerNameToPathMap.get(name);
};

export default router;
