import store from "../store/index";
export function initState() {
  initFocusBlurState();
}

/**
 * 初始化当前焦点状态
 */
function initFocusBlurState() {
  store.commit("setDocumentHidden", document.hidden);
  // 监听页面的隐藏和显示
  document.addEventListener("visibilitychange", function () {
    store.commit("setDocumentHidden", document.hidden);
  });
  // 监听页面获取和失去焦点
  // window.addEventListener('focus', function () {
  //   // store.commit('setWindowFocus', true);
  //   console.log('window获取焦点');
  // });
  // window.addEventListener('blur', function () {
  //   // store.commit('setWindowFocus', false);
  //   console.log('window失去焦点');
  // });
  // document.addEventListener('focus', function () {
  //   // store.commit('setWindowFocus', true);
  //   console.log('document获取焦点');
  // });
  // document.addEventListener('blur', function () {
  //   // store.commit('setWindowFocus', false);
  //   console.log('document失去焦点');
  // });

  // window.addEventListener('focus', function () {
  //   // store.commit('setWindowFocus', true);
  //   console.log('window获取焦点');
  // }, true);
  // window.addEventListener('blur', function () {
  //   // store.commit('setWindowFocus', false);
  //   console.log('window失去焦点');
  // }, true);
  // document.addEventListener('focus', function () {
  //   // store.commit('setWindowFocus', true);
  //   console.log('document获取焦点');
  // }, true);
  // document.addEventListener('blur', function () {
  //   // store.commit('setWindowFocus', false);
  //   console.log('document失去焦点');
  // }, true);

  // window.onblur = function () {
  //   console.log('window失去焦点');
  // }

  // window.onfocus = function () {
  //   console.log('window获取焦点');
  // }

  // document.onblur = function () {
  //   console.log('document失去焦点');
  // }

  // document.onfocus = function () {
  //   console.log('document获取焦点');
  // }
  store.commit("setWindowFocus", document.hasFocus());
  /**
   * 轮询监听是否失去焦点
   */
  setInterval(function () {
    let focus = document.hasFocus();
    store.state.windowFocus !== focus && store.commit("setWindowFocus", focus);
  }, 200);
}
