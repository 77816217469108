<template>
  <!-- 字典下拉组件 -->
  <el-select ref="select" v-model="data" v-bind="$attrs" v-on="$listeners">
    <el-option
      v-for="(item, index) in getDictionary(dictType)"
      :key="index"
      :label="item.label"
      :value="item.key"
    />
  </el-select>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "XmDictSelect",
  components: {},
  props: {
    // 值传递
    value: {
      required: true,
    },
    // 字典类型
    dictType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getDictionary"]),
    // 双向绑定
    data: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    /**
     * 获取焦点
     */
    focus() {
      this.$nextTick().then(() => {
        this.$refs.select.focus();
      });
    },
    /**
     * 失去焦点
     */
    blur() {
      this.$nextTick().then(() => {
        this.$refs.select.blur();
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
