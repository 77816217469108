/**
 * 教务管理-教室管理
 */
import axios from "@/plugins/axios.js";
/**
 * 获取学校下拉框
 * @return {Promise}
 */
export function getManageSchoolList() {
  return axios.get("/staffAuth/school/selector");
}

// 获取教室列表
export function getClassroomList(params) {
  return axios.get("/classroom/list", {
    params,
  });
}

// 新增教室
export function addClassroom(data) {
  return axios.post("/classroom/add", data);
}

// 启禁用教室
export function updateClassStatus(data) {
  return axios.put(`/classroom/enable/${data.id}/${data.enable}`);
}

// 删除教室
export function deleteClassroom(id) {
  return axios.delete(`/classroom/delete/${id}`);
}

// 修改教室
export function updateClassroom(form) {
  return axios.put(`/classroom/update/${form.id}`, form.data);
}
