"use strict";

import Vue from "vue";
import axios from "axios";
import { Message, MessageBox } from "element-ui";
import { getToken, getThirdCode } from "@/core/auth";
import store from "@/store/index.js";
import i18n from "../../src/tools/i18n";

import JSONBig from "json-bigint";
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
    baseURL: process.env.VUE_APP_BASE_API || "",
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
    // 解决苹果浏览器接口数据缓存问题
    headers: {
        "Cache-Control": "no-cache",
    },
};

// axios.defaults.transformResponse = [
//   function (data) {
//     const json = JSONBig({
//       storeAsString: true,
//     });
//     return data && json.parse(data);
//   },
// ];

let show401 = false;

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        const token = getToken();
        const bussinessCode = getThirdCode();
        if (token) {
            // oss直传文件 不加token
            if (config.url.indexOf(".aliyuncs.com") === -1) {
                config.headers["Authorization"] = token; // 让每个请求携带自定义token 请根据实际情况自行修改
                bussinessCode && (config.headers["bussinessCode"] = bussinessCode); // 第三方传入的学校班级自定义 code
            }
        }
        console.log("this.$i18n.locale", i18n.locale);
        if (i18n.locale === "en_US") {
            config.headers["Accept-Language"] = "en";
        } else {
            config.headers["Accept-Language"] = "zh";
        }
        return config;
    },
    function (error) {
        // Do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    }
);
const json = JSONBig({
    storeAsString: true,
});
// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        // Do something with response data
        const res = response.data;

        // 请求成功
        if (res.status === 200 || response.status === 204 || res.code === 200) {
            if (response.config.JSONBig) {
                // 使用jsonbig解决长数值精度丢失问题 在接口中配置 { JSONBig:true }
                return response.data && json.parse(response.data);
            } else {
                return response.data;
            }
        }

        if (res && Object.prototype.toString.call(res) === "[object Object]") {
            res.message = res.message || res.msg;
        }

        if (res instanceof Blob) {
            return response.data;
        }

        if (res.code && (res.code === 401 || res.code === 406)) {
            if (!show401) {
                show401 = true;
                logOutTip(res);
            }
            return;
        }

        // 处理请求其他异常状态程序
        switch (res.status) {
            case 400:
                Message({
                    message: res.message || i18n.t("el.axios.serverError"),
                    type: "warning",
                    duration: 5 * 1000,
                });
                break;
            case 403:
                // 判断当前路由
                if (/^#\/login($|\?.*)/.test(location.hash)) {
                    // 当前是登录页面
                    Message({
                        message: res.message || i18n.t("el.axios.serverError"),
                        type: "warning",
                        duration: 5 * 1000,
                    });
                } else {
                    // 不是登录页面需要跳转到登录页
                    MessageBox.confirm(
                        i18n.t("el.axios.logoutTip"),
                        i18n.t("el.axios.confirmLogout"),
                        {
                            confirmButtonText: i18n.t("el.axios.reLogin"),
                            cancelButtonText: i18n.t("el.common.cancel"),
                            type: "warning",
                            closeOnClickModal: false,
                            closeOnPressEscape: false,
                        }
                    )
                        .then(() => {
                            let loginReturnUrl = sessionStorage.getItem("loginReturnUrl");
                            if (loginReturnUrl) {
                                window.location.href = loginReturnUrl;
                            } else {
                                store.dispatch("logout").then(() => {
                                    location.reload(); // 为了重新实例化vue-router对象 避免bug
                                });
                            }
                        })
                        .catch(() => { });
                }
                break;
            case 200302:
                location.href = res.data;
                break;
            default:
                Message({
                    message: res.message || i18n.t("el.axios.serverError"),
                    type: "error",
                    duration: 5 * 1000,
                });
                break;
        }
        return Promise.reject(res);
    },
    function (error) {
        // Do something with response error
        var defaultError = {
            status: 500,
            message: i18n.t("el.common.systemError"),
        };
        if (error.message && error.message.cancel === "cancel") {
            return Promise.reject(error.message.response || defaultError);
        }
        // 判断是否是网络异常请求
        if (error.response) {
            var { response } = error;
            let message =
                (response.data && response.data.message) || response.statusText;
            switch (response.status) {
                case 404:
                    message = message || i18n.t("el.axios.pathNoexit");
                    break;
                case 429:
                    message = message || i18n.t("el.axios.pathNoexit");
                    break;
                case 500:
                    message = message || i18n.t("el.axios.serverError2");
                    break;
            }
            Message({
                message,
                type: "error",
                duration: 5 * 1000,
            });
            if (response.status === 429) {
                return Promise.reject(error.response.data);
            }
            return Promise.reject(error.response || defaultError);
        } else {
            if (axios.isCancel(error)) {
                return Promise.reject("cancel");
            }
            let message = error.message;
            if (
                error.code === "ECONNABORTED" &&
                !error.message.indexOf("timeout") !== -1
            ) {
                message = i18n.t("el.axios.netWorkError");
            }
            Message({
                message,
                type: "error",
                duration: 5 * 1000,
            });
            return Promise.reject(error.response || defaultError);
        }
    }
);
var Plugin = {};
Plugin.install = function (Vue, options) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            },
        },
        $axios: {
            get() {
                return _axios;
            },
        },
    });
};

Vue.use(Plugin);

export default _axios;

export var CancelToken = axios.CancelToken;

// 登出｜重载 弹窗处理
export const logOutTip = (error) => {
    const isExpired = error?.code === 401; // 过期
    const isRemoteLogin = error?.code === 406; // 异地登录
    let message = "";
    if (isExpired) {
        message = i18n.t("el.axios.serverError401");
    } else if (isRemoteLogin) {
        message = i18n.t("el.axios.serverError406");
    } else {
        message = i18n.t("el.axios.initDataErrorLoad");
    }
    MessageBox.confirm(message, i18n.t("el.common.reminder"), {
        confirmButtonText: i18n.t("el.axios.loginPage"),
        cancelButtonText: i18n.t("el.axios.reLoadData"),
        showClose: false,
        showCancelButton: !isExpired && !isRemoteLogin,
        closeOnClickModal: false,
        type: "error",
    })
        .then(() => {
            show401 = false;
            store.dispatch("logout").finally(() => {
                window.localStorage.clear();
                window.sessionStorage.clear();
                window.location.href =
                    window.baseConfig.VUE_APP_SAAS_ADMIN_URL + "?saas_logout=ai-front";
            });
        })
        .catch(() => {
            window.location.reload();
            show401 = false;
        });
};
