import axios from "@/plugins/axios.js";
let prefix = "/account";
// 关闭弹窗 今日不在提示
export function closeQcCode() {
  return axios.get(`${prefix}/user/closeQcCode`, {
    baseURL: process.env.VUE_APP_SAAS_BASE_API,
  });
}

/**
 *  resource 场景来源类型 workbench、finishReady、finishTeach、teachManage、topTips
 */
// 获取二维码
export const getQrCode = (resource, refresh = 0) => {
  return axios.get(`/auth/wechat/getQrCode/${resource}/${refresh}`, {
    baseURL: process.env.VUE_APP_SAAS_BASE_API,
  });
};
