import axios from "@/plugins/axios.js";

/**
 * 教务管理列表
 * @return {data}
 */
export function getTableList(data) {
  return axios.get("/staffAuth/list", {
    params: data,
  });
}
/**
 * 教务管理列表新
 * @return {data}
 */
export function getTableListNew(data) {
  return axios.get("/staffAuth/new", {
    params: data,
  });
}
/**
 * 教务管理列表
 * @return {data}
 */
export function authDetail(data) {
  return axios.get("/staffAuth/authDetail", {
    params: data,
  });
}

/**
 * 教务管理-教师授权-获取明文身份证号
 * @return {data}
 */
export function detailForIdNumberAndMobile(data) {
  return axios.get("/staffAuth/detailForIdNumberAndMobile", {
    params: data,
  });
}

/**
 * 保存权限
 * @return {Promise}
 */
export function saveAuth(queryParams) {
  return axios.post("/staffAuth/saveAuth", queryParams);
}

/**
 * 学校列表-树形
 * @return {Promise}
 */
export function schoolTree() {
  return axios.get("/staffAuth/school/tree");
}

/**
 * 授课班级列表-树形
 * @return {Promise}
 */
export function classTree() {
  return axios.get(`/staffAuth/admin/class/tree`);
}
/**
 * 获取年级课程
 */
export function courseAuthList(queryParams) {
  return axios.get("/courseManage/courseAuthList", {
    params: queryParams,
  });
}

/**
 * 获取年级课程 新增编辑弹窗
 */
export function courseAuthListAdd(queryParams) {
  return axios.get("/courseManage/courseAuthList/add", {
    params: queryParams,
  });
}

/**
 * 获取年级
 */
export function getAuthGradeList(queryParams) {
  return axios.get("/courseManage/gradeList", {
    params: queryParams,
  });
}

/**
 * 批量编辑用户权限新
 */
export function editBatchUserAuthCopy(queryParams) {
  return axios.post("/userManage/userAuthBatch", queryParams);
}
/**
 * 批量编辑用户权限新新
 */
export function editBatchUserAuthCopyNew(queryParams) {
  return axios.post("/userManage/userAuthBatch/new", queryParams);
}
/**
 * 试用转正式
 */
export function updateCourseFormal(queryParams) {
  return axios.post(`/courseManage/trialCourseToFormal`, queryParams);
}
/**
 * 获取授课记录列表
 */
export function getTeachClassMerge(queryParams) {
  return axios.get("/teachClassMerge/page", {
    params: queryParams,
  });
}

/**
 * 个人中心授课记录列表
 */
export function getTeachClassPersonalMerge(queryParams) {
  return axios.get("/teachClassMerge/personal/page", {
    params: queryParams,
  });
}

/**
 * 个人中心授课班级
 */
export function getPersonalClassTree() {
  return axios.get("/teachClassMerge/personal/admin/class");
}
