/**
 * 创作者中心接口文件
 */
import axios from "@/plugins/axios.js";

/**
 * 创作者中心提交
 * @return {data}
 */
export function addCreatorCenter(data) {
  return axios.post("/creatorCenter/add", data);
}

/**
 * 创作者中心提交
 * @return {data}
 */
export function getImage(data) {
  return axios.get("/oss/", { params: data });
}
