import axios from "@/plugins/axios.js";

/**
 * 修改手机号
 * @param {g} data
 * @returns
 */
export function getChatTokenCount(data) {
  return axios.get(`/css/getTokenCount`, {
    params: data,
  });
}

/**
 * 发送聊天消息
 * @param {g} data
 * @returns
 */
export function sendChatMessage(data) {
  // http://172.21.27.249:18199/bskpt-front
  return axios.post("/ws/send/message", data);
}
/**
 * chatgpt 聊天记录
 * @param {*} data
 * @returns
 */
export function searchIndexInfo(data) {
  // http://172.21.27.249:18199/bskpt-front
  return axios.post("/css/searchIndexInfo", data);
}
