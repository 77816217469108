// 用户模块
// import Vue from 'vue';
import {
  getUserInfo,
  pluginChecked,
  getMenuAuth,
  getBtnAuth,
} from "@/api/user.js";
import { getQrCode } from "@/api/serviceQrcode/index.js";
import Vue from "vue";
import { clearState } from "../index.js";

// 初始化数据
const initState = () => ({
  staffCode: "", // 工号
  staffName: "", // 用户名
  schoolName: "", // 学校
  uploadAuth: false, // 是否有个人课件上传权限 废弃 使用接口判断
  checkPlugin: false, // 默认首次打开
  eduAuth: "", // 权限
  reviewAuth: "", // 审核权限
  firstLogin: 1, // 首次打开引导
  officeType: 1, // 是否是灰度用户 0-officeOL 1-wps
  regulator: undefined,
  canAccess:
    (navigator.cookieEnabled && sessionStorage.getItem("canAccess")) || false, // 是否有数据分析权限
  loginReturnUrl: "",
  isHiddenSign: false,
  isThirdSign: false,
  mobile: "",
  userType: "0", // 机构角色权限： 1角色用户，2机构用户 0 普通用户
  gtpOpen: null, // 是否开通小助手
  isChildPageLoaded: null,
  authMenu: null, // 当前用户可访问的路由
  authBtn: [], // 当前用户授权的按钮权限
  servieceCodeVisible: false, // 是否显示服务号二维码
  qrcodeUrl: "", // 服务号二维码链接
  isOverDue: false, // 二维码是否过期
  timer: null,
  browserInfo: "", // 当前浏览器信息
  browserVersionIsHigh: 1,
});

export default {
  state: initState(),
  getters: {
    /**
     * 是否需要引导
     */
    isNeedGuide: (state) => () => state.firstLogin === 0,

    getMobile(state) {
      return state.mobile;
    },
  },
  mutations: {
    /**
     * 设置用户信息
     * @param {Object} state 状态
     * @param {Object} data 用户信息
     */
    setUserInfo(state, data) {
      // clearState(state, initState());
      // 更新数据
      Object.assign(state, data);
      state.schoolName = data.tenantName;
      if (data.authority && data.authority.indexOf("isHide") > -1) {
        Vue.set(state, "isHiddenSign", true);
      } else {
        Vue.set(state, "isHiddenSign", false);
      }
      if (data.authority && data.authority.indexOf("third") > -1) {
        Vue.set(state, "isThirdSign", true);
      } else {
        Vue.set(state, "isThirdSign", false);
      }
      console.log("state.isHiddenSign", state.isHiddenSign);
      // localStorage.setItem("userMsg", JSON.stringify(store.state));
    },
    setChildPageLoaded(state, value) {
      state.isChildPageLoaded = value;
    },
    /**
     * 设置是否需要引导
     */
    setFirstLogin(state, value) {
      state.firstLogin = value;
    },
    // 是否有数据分析权限
    getCanAccess(state, value) {
      sessionStorage.setItem("canAccess", value);
      state.canAccess = value;
    },
    // 设置返回登录地址
    setReturnUrl(state, value) {
      sessionStorage.setItem("loginReturnUrl", value);
      state.loginReturnUrl = value;
    },
    // 设置授权路由map，后续取用更方便
    setAuthMenu(state, value) {
      const map = new Map();
      value.forEach((item) => {
        map.set(item.url, item);
      });
      state.authMenu = map;
    },
    // 设置授权按钮
    setAuthBtn(state, value) {
      state.authBtn = value;
    },
    setServieceCodeVisible(state, value) {
      state.servieceCodeVisible = value;
    },
    setQrcodeUrl(state, value) {
      state.qrcodeUrl = value;
    },
    setIsOverDue(state, value) {
      state.isOverDue = value;
    },
    // 浏览器是否是高版本
    setBrowserVersionIsHigh(state, value) {
      state.browserVersionIsHigh = value;
    },
    // 设置浏览器信息
    setBrowserInfo(state, value) {
      state.browserInfo = value;
    },
  },
  actions: {
    /**
     * 获取二维码内容
     * @param {*} param0 来源
     */
    fetchQrCode({ state, commit }, { source, refresh = 0 }) {
      return getQrCode(source, refresh).then(({ data }) => {
        if (data.qrCodeUrl) {
          commit("setIsOverDue", false);
          state.timer = setTimeout(() => {
            commit("setIsOverDue", true);
          }, 1000 * 60);
          commit("setQrcodeUrl", data.qrCodeUrl);
          commit("setServieceCodeVisible", true);
        }
      });
    },
    /**
     * 加载用户信息
     * @param {*} param0 提交
     */
    loadUserInfo({ state, commit }) {
      return getUserInfo().then(({ data }) => {
        localStorage.setItem(
          window.baseConfig?.VITE_APP_CTRLVIDEO_USER,
          JSON.stringify({
            tenantId: data?.tenantId,
            tenantType: data?.tenantType,
            tenantName: data?.tenantName,
            userId: data?.userId,
            userName: data?.staffName,
          })
        );
        localStorage.setItem(window.baseConfig?.VITE_APP_CTRLVIDEO_STORAGE_KEY, "");
        commit("getCanAccess", true); // TODO: 待定
        commit("setUserInfo", data);
        commit("setChangeSchool", data.tenantId, { root: true });
        return data;
      });
    },
    /**
     * 记录已检查
     * @param {*} param0 状态
     */
    recordChecked({ state }) {
      return pluginChecked().then(() => {
        state.checkPlugin = false;
      });
    },
    /**
     * 触发引导
     */
    triggerGuide({ state, commit }) {
      commit("setFirstLogin", 1);
    },

    /**
     * 用户授权菜单
     */
    loadAuthMenu({ state, commit }) {
      return getMenuAuth().then(({ data }) => {
        commit(
          "setAuthMenu",
          data.filter((item) => item.url !== "Menu_category")
        );
      });
    },

    /**
     * 用户授权按钮
     */
    loadAuthBtn({ commit }) {
      return getBtnAuth().then(({ data }) => {
        if (Array.isArray(data)) {
          data = data.filter((item) => !!item).map((item) => item.itfCode);
        } else {
          data = [];
        }
        commit("setAuthBtn", data);
      });
    },
    // 获取浏览器信息
    getBrowserInfo({ state, commit }) {
      // 获取userAgent信息
      const ua = navigator.userAgent.toLowerCase();
      let browserInfo = "";
      let browserVersionIsHigh = 1;
      // 判断是否为IE 浏览器，因为IE浏览器有独特的userAgent信息
      if (/msie|trident/.test(ua)) {
        // IE11 及以下版本
        if (/msie \d+/.test(ua)) {
          const ieVersion = parseInt(ua.match(/msie (\d+)/)[1]);
          console.log(`IE ${ieVersion}`);
          browserInfo = "IE";
          browserVersionIsHigh = 0;
        } else {
          console.log("IE 11或以上版本");
          browserInfo = "IE11+";
          browserVersionIsHigh = 1;
        }
      } else if (/edge\/(\d+)/.test(ua)) {
        // 判断是否为Edge 浏览器
        const edgeVersion = parseInt(ua.match(/edge\/(\d+)/)[1]);
        console.log(`Edge ${edgeVersion}`);
        browserInfo = "Edge";
        if (~~edgeVersion < 109) {
          browserVersionIsHigh = 0;
        }
      } else if (/firefox\/(\d+)/.test(ua)) {
        // 判断是否为firefox 浏览器
        const firefoxVersion = parseInt(ua.match(/firefox\/(\d+)/)[1]);
        console.log(`Firefox ${firefoxVersion}`);
        browserInfo = "Firefox";
        if (~~firefoxVersion < 107) {
          browserVersionIsHigh = 0;
        }
      } else if (/chrome\/(\d+)/.test(ua)) {
        // 判断是否为chrome 浏览器
        const chromeVersion = parseInt(ua.match(/chrome\/(\d+)/)[1]);
        console.log(`Chrome ${chromeVersion}`);
        browserInfo = "Chrome";
        if (~~chromeVersion < 80) {
          browserVersionIsHigh = 0;
        }
      } else if (/safari\/(\d+)/.test(ua)) {
        // 判断是否为Safari 浏览器
        const safariVersion = parseInt(ua.match(/safari\/(\d+)/)[1]);
        browserInfo = "Safari";
        console.log(`Safari ${safariVersion}`);
        if (~~safariVersion < 16) {
          browserVersionIsHigh = 0;
        }
      }

      commit("setBrowserInfo", browserInfo);
      commit("setBrowserVersionIsHigh", browserVersionIsHigh);
    },
  },
};
