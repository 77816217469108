<template>
  <div class="chat-message-contain">
    <div
      v-if="showMoreBtn"
      @click="getMoreMessage"
      class="chat-message-contain-more"
    >
      {{ $t("el.chatgpt.ReadMore") }}
    </div>
    <div v-for="(item, index) in messageList" :key="item.messageId">
      <div>
        <div class="flex chat-message-content">
          <el-image
            class="chat-avthor"
            :src="
              item.messageType === 0 || item.messageType === 2
                ? iconChatgpt
                : iconUser
            "
          ></el-image>
          <div
            v-if="item.messageType === 0 || item.messageType === 2"
            class="chat-answer"
            :style="{
              width: item.messageType === 0 ? 'calc(100% - 76px)' : '',
              maxWidth: item.messageType === 2 ? 'calc(100% - 110px)' : '',
            }"
          >
            <div v-if="item.messageType === 0">
              <div class="message-title">
                {{ $t("el.chatgpt.YouyouAssistant") }}<br />{{
                  $t("el.chatgpt.AskMeQuestions")
                }}
              </div>
              <div
                v-for="(obj, idx) in item.tipsList"
                :key="obj.id"
                style="width: 100%; position: relative; cursor: pointer"
                class="flex flex-m"
                @click="defaultMessageClick(obj, idx)"
              >
                <span class="message-item0">{{ obj.message }}</span>
                <el-image class="chat-arrow" :src="chatArrow"></el-image>
                <div
                  v-if="idx !== item.tipsList.length - 1"
                  style="position: absolute; bottom: 0"
                  class="white-line"
                ></div>
              </div>
            </div>
            <!-- 回答，要支持markdown -->
            <div v-else>
              <v-md-preview :text="item.message"></v-md-preview>
            </div>
          </div>
          <!-- 如果是 回答中 -->
          <!-- <el-image
            v-if="item.messageType === 2 && item.status === 1"
            class="icon-control"
            :src="iconStopGenerate"
            @click="onMessageControl(item, index, item.status)"
          ></el-image> -->
          <div v-if="item.messageType === 1" class="chat-question">
            <div class="message-question">{{ item.message }}</div>
          </div>
        </div>
        <div
          v-if="item.messageType === 2 && item.status === 1"
          class="flex flex-m btn-control"
          @click="onMessageControl(item, index, 1)"
          style="min-height: 14px"
        >
          <!-- <el-image class="icon-control" :src="iconStopGenerate"></el-image>
          停止生成 -->
        </div>
        <div
          v-else-if="
            item.messageType !== 0 &&
            messageList.length > 1 &&
            (index === messageList.length - 1 ||
              (index === messageList.length - 2 &&
                messageList[messageList.length - 1].messageType === 0))
          "
          class="flex flex-m btn-control"
          @click="onMessageControl(item, index, 0)"
        >
          <el-image class="icon-control" :src="iconReGenerate"></el-image>
          {{ $t("el.chatgpt.Regenerate") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Vue from "vue";
import VMdPreview from "@kangc/v-md-editor/lib/preview";
import "@kangc/v-md-editor/lib/style/preview.css";
import githubTheme from "@kangc/v-md-editor/lib/theme/github.js";
import "@kangc/v-md-editor/lib/theme/style/github.css";

// highlightjs
import hljs from "highlight.js";

VMdPreview.use(githubTheme, {
  Hljs: hljs,
});
Vue.use(VMdPreview);

export default {
  data() {
    return {
      iconChatgpt: require("@/assets/images/icon/icon-chat-youyou.png"),
      chatArrow: require("@/assets/images/icon/icon-chat-arrow.png"),
      iconUser:
        this.gender === 2
          ? require("@/assets/images/subjectType/pic_face_woman@2x.png")
          : require("@/assets/images/subjectType/pic_face_man@2x.png"),
      iconReGenerate: require("@/assets/images/icon/icon-re-generate.png"),
      iconStopGenerate: require("@/assets/images/icon/icon-stop-generate.png"),
    };
  },
  props: {
    // messageType: 0 初始消息， 1 问题  2 答案
    messageList: {
      type: Array,
      default: () => [],
    },
    showMoreBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["gender"]),
  },
  methods: {
    defaultMessageClick(item, index) {
      this.$emit("default-message-click", item, index);
    },
    onMessageControl(item, index, status) {
      this.$emit("onMessageControl", item, index, status);
    },
    // 查看更多
    getMoreMessage() {
      this.$emit("getMoreMessage");
    },
  },
};
</script>

<style lang="less" scoped>
.chat-message {
  &-contain {
    width: 100%;
    &-more {
      padding-top: 14px;
      text-align: center;
      font-size: 12px;
      color: #5a53f5;
      cursor: pointer;
    }

    .btn-control {
      width: fit-content;

      font-size: 12px;
      font-weight: 500;
      color: #b6b3bc;
      cursor: pointer;
      margin-left: 62px;
      margin-top: 6px;
    }

    .icon-control {
      width: 14px;
      height: 14px;
      margin-right: 2px;
    }
  }
  &-content {
    width: 100%;
    padding-top: 14px;
    position: relative;
    .chat-avthor {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .chat-answer {
      max-width: calc(100% - 76px);
      margin-left: 8px;
      padding-left: 14px;
      padding-right: 14px;

      background: rgba(90, 83, 245, 0.05);
      border-radius: 8px;
    }

    /deep/ .github-markdown-body {
      padding: 14px 0 0;
      font-size: 12px;

      font-weight: 400;
      color: #333333;
      line-height: 17px;
      word-wrap: break-word;
    }
    .chat-question {
      max-width: calc(100% - 76px);
      margin-left: 8px;
      padding-top: 12px;
      padding-right: 14px;
      padding-bottom: 12px;
      padding-left: 14px;
      background: #5a53f5;
      border-radius: 8px;
      .message-question {
        width: fit-content;

        font-size: 12px;

        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
      }
    }
    .message-title {
      width: fit-content;
      font-size: 12px;

      font-weight: 400;
      color: #333333;
      line-height: 17px;
      padding-top: 14px;
      margin-bottom: 14px;
    }
    .message-item0 {
      width: calc(100% - 22px);
      height: fit-content;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      line-height: 17px;
      padding-top: 14px;
      padding-bottom: 14px;
    }
    .chat-arrow {
      width: 12px;
      height: 12px;
      margin-left: auto;
    }
  }
}
.white-line {
  width: 100%;
  height: 2px;
  transform: scaleY(0.5);
  background: #ffffff;
}
</style>
