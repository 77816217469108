<template lang="html">
  <div class="editor">
    <div ref="toolbar" class="toolbar"></div>
    <div ref="editor" class="editor-text"></div>
    <p v-if="isShow" style="color: red; text-align: right">
      编辑内容不能超过{{ config.limitNum }}个字
    </p>
  </div>
</template>

<script>
import E from "wangeditor";
import { calculate_object_name } from "../../utils/index";
export default {
  name: "editoritem",
  data() {
    return {
      // uploadPath,
      editor: null,
      info_: null,
      isChange: false,
      isShow: false,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    isClear: {
      type: Boolean,
      default: false,
    },
    isDirectUpload: {
      type: Boolean,
      default: true,
    },
    showImg: {
      type: Boolean,
      default: false,
    },
    baseUpload: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
    config: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
  },
  watch: {
    isClear(val) {
      // 触发清除文本域内容
      if (val) {
        this.editor.txt.clear();
        this.info_ = null;
      }
    },
    value: function (value) {
      // if (value !== this.editor.txt.html()) {
      //   this.editor.txt.html(this.value);
      // }

      /**
       * 光标乱跳是因为watch监听的时候 文本内容被实时更新，那么想办法在文本输入的时候让监听无效即可，离开编辑状态 又生效，让文本内容可以保证保存的时候是正确的即可。
       */
      console.log("value", value);
      if (!this.isChange) {
        this.editor.txt.html(this.value);
      } else {
        if (value !== this.editor.txt.html()) {
          if (this.config.limitNum) {
            //          获取纯文字 在获取img 相加就可以了
            var temp = this.value.replace(/<\/?.+?>/g, "");
            var result = temp.replace(/ /g, ""); // result为获取冲文字得到后的内容
            //         获取img标签
            var re = /<img[^>]+>/g;
            var ImgA = this.value.match(re);
            let imgLen;
            if (ImgA) {
              imgLen = ImgA.length;
            }
            if (!ImgA) {
              imgLen = 0;
            }
            this.spanInfo = imgLen + result.length;
            if (this.spanInfo > this.config.limitNum) {
              this.isShow = true;
            } else {
              this.isShow = false;
            }
          }

          this.editor.txt.html(this.value);
        }
      }
      this.isChange = false;
    },
    // value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
  },
  async mounted() {
    this.seteditor();
    if (this.config.limitNum) {
      //          获取纯文字 在获取img 相加就可以了
      var temp = this.value.replace(/<\/?.+?>/g, "");
      var result = temp.replace(/ /g, ""); // result为获取冲文字得到后的内容
      //         获取img标签
      var re = /<img[^>]+>/g;
      var ImgA = this.value.match(re);
      let imgLen;
      if (ImgA) {
        imgLen = ImgA.length;
      }
      if (!ImgA) {
        imgLen = 0;
      }
      this.spanInfo = imgLen + result.length;
      if (this.spanInfo > this.config.limitNum) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    }

    this.editor.txt && this.editor.txt.html(this.value);
  },
  methods: {
    stopEditor() {
      this.editor.$textElem.attr("contenteditable", false);
    },
    async seteditor() {
      // http://192.168.2.125:8080/admin/storage/create
      this.editor = new E(this.$refs.toolbar, this.$refs.editor);
      this.config.uploadImgShowBase64 &&
        (this.editor.config.uploadImgShowBase64 =
          this.config.uploadImgShowBase64); // base 64 存储图片
      this.config.uploadImgServer &&
        (this.editor.config.uploadImgServer = this.config.uploadImgServer); // 配置服务器端地址
      this.config.uploadImgHeaders &&
        (this.editor.config.uploadImgHeaders = this.config.uploadImgHeaders); // 自定义 header
      this.editor.config.uploadFileName = this.config.uploadFileName || "file"; // 后端接受上传文件的参数名
      this.editor.config.uploadImgMaxSize =
        this.config.uploadImgMaxSize || 2 * 1024 * 1024; // 将图片大小限制为 2M
      this.editor.config.uploadImgMaxLength =
        this.config.uploadImgMaxLength || 1; // 限制一次最多上传 1 张图片
      this.editor.config.uploadImgTimeout =
        this.config.uploadImgTimeout || 3 * 60 * 1000; // 设置超时时间
      this.editor.config.showLinkImg = this.config.uploadImgTimeout;
      this.editor.config.placeholder = this.config.placeholder || "请输入正文";

      // await this.$store.dispatch('getUploadInfo');
      // this.baseUpload = this.$store.getters.baseUpload;
      if (this.isDirectUpload) {
        let that = this;
        this.editor.config.customUploadImg = function (
          resultFiles,
          insertImgFn
        ) {
          // resultFiles 是 input 中选中的文件列表
          // insertImgFn 是获取图片 url 后，插入到编辑器的方法
          // console.log('resultFiles', resultFiles);
          resultFiles.forEach((item) => {
            let fd = new FormData();
            let key =
              process.env.VUE_APP_OSS_IMG_DIR +
              calculate_object_name(item.name);
            // fd.append("key", that.baseUpload.dir + key);
            // fd.append("ossAccessKeyId", that.baseUpload.accessKeyId);
            // fd.append("policy", that.baseUpload.policy);
            // fd.append("signature", that.baseUpload.signature);
            // fd.append("success_action_status", "200");
            // fd.append("dir", that.baseUpload.dir);
            // fd.append("callback", that.baseUpload.callback);
            // fd.append("file", item);
            fd.append("key", key);
            fd.append("x-obs-acl", "public-read");
            fd.append("content-type", "multipart/form-data");
            fd.append("policy", that.baseUpload.policy);
            fd.append("signature", that.baseUpload.signature);
            fd.append("AccessKeyId", that.baseUpload.accessKeyId);
            fd.append("file", item);

            that
              .axios({
                url: that.baseUpload.host,
                method: "POST",
                data: fd,
              })
              .then((res) => {
                let data = {};
                data.url = that.baseUpload.url + "/" + key;
                // // data.url = res.data.resourceUrl;
                // data.url = process.env.VUE_APP_OSS_HOST + res.data;
                // // console.log("data.url", data.url);
                // // this.formData.fileList.push(res.data); //这里上传到指定的图片服务器，成功后会返回图片的url

                // // 上传图片，返回结果，将图片插入到编辑器中
                insertImgFn(data.url);
              })
              .catch(() => {
                that.$message.error(that.$t("el.classroomWps.uploadFail"));
              });
          });
        };
      }

      // 配置菜单
      this.editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "fontName", // 字体
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        // 'link', // 插入链接
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        // 'emoticon', // 表情
        // 'image', // 插入图片
        // 'table', // 表格
        // 'video', // 插入视频
        // 'code', // 插入代码
        "undo", // 撤销
        "redo", // 重复
        "fullscreen", // 全屏
      ];

      if (this.showImg) {
        this.editor.config.menus.push("image");
      }

      this.editor.config.uploadImgHooks = {
        fail: (xhr, editor, result) => {
          // 插入图片失败回调
        },
        success: (xhr, editor, result) => {
          // 图片上传成功回调
        },
        timeout: (xhr, editor) => {
          // 网络超时的回调
        },
        error: (xhr, editor) => {
          // 图片上传错误的回调
        },
        customInsert: (insertImg, result, editor) => {
          // 图片上传成功，插入图片的回调
          // result为上传图片成功的时候返回的数据，这里我打印了一下发现后台返回的是data：[{url:"路径的形式"},...]
          // insertImg()
          // 循环插入图片
          // for (let i = 0; i < 1; i++) {
          // console.log(result);
          let url = result.data.resourceUrl;
          insertImg(url);
          // }
        },
      };
      this.editor.config.onchange = (html) => {
        this.isChange = true;
        this.info_ = html; // 绑定当前逐渐地值

        if (this.config.limitNum) {
          //          获取纯文字 在获取img 相加就可以了
          var temp = this.info_.replace(/<\/?.+?>/g, "");
          var result = temp.replace(/ /g, ""); // result为获取冲文字得到后的内容
          //         获取img标签
          var re = /<img[^>]+>/g;
          var ImgA = this.info_.match(re);
          let imgLen;
          if (ImgA) {
            imgLen = ImgA.length;
          }
          if (!ImgA) {
            imgLen = 0;
          }
          this.spanInfo = imgLen + result.length;
          //        去掉除img以外的所有标签；
          // var regL = /<\/?(?!img)[a-z]+?[^>]*>/gi;
          // var z = this.info_.replace(regL, "");
          // this.info_ = z;

          if (this.spanInfo > this.config.limitNum) {
            this.isShow = true;
          } else {
            this.isShow = false;
          }
        }
        this.$emit("change", this.info_, this.spanInfo); // 将内容同步到父组件中
      };
      // 创建富文本编辑器
      this.editor.create();
    },
  },
};
</script>

<style lang="less">
.editor {
  /deep/ .w-e-menu {
    font-size: 14px;
    padding: 8px 11px;
  }
}
.toolbar {
  border: 1px solid #cdcdcd;
  background: #f1f1f1;
}
.editor-text {
  border: 1px solid #cdcdcd;
  height: 326px;
  border-top: 0;
  text-align: left;
  z-index: 2 !important;
}
/deep/ .w-e-text-container {
  z-index: 0 !important;
}
/deep/ .toolbar .w-e-menu {
  z-index: 2 !important;
}
</style>
