/**
 * 备授课路由
 */
export default {
  path: "/classroomWps/:type",
  component: () =>
    import(
      /* webpackChunkName: "classroom" */ "@/views/classroomWps/index.vue"
    ),
  name: "classroomWps",
  hidden: false,
  props: (route) => ({
    type: route.params.type,
    id: route.query.id,
    courseId: route.query.courseId,
    originTenantId: route.query.originTenantId,
    originTenantType: route.query.originTenantType,
  }),
  meta: {
    title: "el.routerName.classRoom",
    icon: "",
    keepAlive: false,
  },
};
