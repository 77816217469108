/**
 * 备课
 */
import axios from "@/plugins/axios.js";
import { Message } from "element-ui";

/**
 * 查询资源
 * - knowledgeId 知识点id
 * @param {*} type 类型
 * @param {*} params 查询参数
 */
export function queryResource(type, params) {
    return axios.get(`/resourceManage/queryResource/${type}`, {
        params,
    });
}

/**
 * 查看资源左侧tab 是否有 资源
 * @param {*} type
 * @param {*} params
 * @returns
 */
export function getResourceCount(params) {
    return axios.get(`/resourceManage/getResourceCount`, {
        params,
    });
}

/**
 * 复制ppt
 * - knowledgeId 知识点id
 * @param {*} type 类型
 * @param {*} params 查询参数
 */
export function copyResource(params) {
    return axios.post("resourceManage/copyResource", params);
}

/**
 * 查询知识点信息
 * - knowledgeId 知识点id
 * @param {*} params 参数
 */
export function queryKnowledgeInfo(params) {
    return axios.get("/knowledgeManage/knowledgeInfo", {
        params,
    });
}
/**
 * 上传前使用参数判断是不是存在一样的文件名
 * @param {*} data 数据
 */
export function checkResource(data, params = {}) {
    return axios.post("/resourceManage/checkForUpload", data, params);
}

/**
 * 
 * @param {*} data 
 * @param {*} params 
 * @returns 
 */
export function checkBSKAuth(params) {
    return axios.get("/resourceManage/check/bsk/auth", {
        params,
    });
}
/**
 * 添加个人资源
 * @param {*} data 数据
 */
export function addPersonResource(data, params = {}) {
    return axios.post("/resourceManage/addPersonResource", data, params);
}

/**
 * 查询知识点信息
 * - courseId 课程id
 * @param {*}} params 查询参数
 */
export function queryKnowledge(params) {
    return axios.get("/knowledgeManage/queryKnowledge", {
        params,
    });
}

/**
 * 获取课程信息
 * - courseId 课程id
 * @param {*} id 课程id
 */
export function queryCourseInfo(id) {
    return axios
        .get("/courseManage/queryCoursePage", {
            params: {
                id,
            },
        })
        .then((res) => {
            var data = res.data;
            if (Array.isArray(data.records) && data.records.length) {
                res.data = data.records[0] || {};
                return res;
            } else {
                Message.warning("当前课程不存在或已被删除");
                return Promise.reject(new Error("当前课程不存在或已被删除"));
            }
        });
}

/**
 * 删除个人资源
 * @param {*} data
 */
export function delPersonResource(data) {
    return axios.post("/resourceManage/delPersonResource", data);
}

/**
 * 检查资源是否有权限，是否被删除
 * @param {*} params 参数
 */
export function checkResourceAuth(params) {
    return axios.get("/resourceManage/checkResourceAuth", {
        params,
    });
}

/**
 * 重命名和拷贝资源
 * @param {*} data 数据
 */
export function reNameAndCopyResource(data) {
    return axios.post("/resourceManage/reNameAndCopyResource", data);
}

/**
 * 个人资源重命名
 * @param {*} data 数据
 */
export function reSetName(data) {
    return axios.post("/resourceManage/reSetName", data);
}

/**
 * 文件点赞/取消点赞
 * @param {*} resourceId 资源id
 */
export function like(resourceId) {
    return axios.post(`/resourceManage/like/${resourceId}`);
}

/**
 * 分享资源到学校资源
 * @param {*} resourceId 资源id
 */
export function share(data) {
    return axios.post("/resourceManage/personalShare", data);
}

/**
 * 取消分享资源
 * @param {*} resourceId 资源id
 */
export function unshare(resourceId) {
    return axios.post(`/resourceManage/unshare/${resourceId}`);
}

/**
 * 已使用首次登录
 */
export function firstLogin() {
    return axios.post("/login/firstLogin");
}

/**
 * 审核意见
 */

export function checkSuggest(data) {
    return axios.post("/pkgOprRecord/queryList", data);
}

/**
 * 重新上传
 */
export function setreuploadResource(resourceId, data) {
    return axios.post(`/resourceManage/reuploadResource/${resourceId}`, data);
}

/**
 *  获取可以配对的教案
 */
export function getCanRelateResource(type, data) {
    return axios.get(`/resourceManage/queryCanRelateResource/${type}`, {
        params: data,
    });
}

/**
 * 撤回修改
 */
export function getrecallCheck(data) {
    return axios.post("/resourceManage/recallCheck", data);
}
/**
    /**
 * 下载、授课操作记录
 * @param {*} resourceId 资源id
 * @param {*} operateType 2-授课 3-下载
 */
export function addDownloadLikeRecord(resourceId, operateType) {
    return axios.post(
        `/resourceDownloadLikeRecord/record/${resourceId}/${operateType}`
    );
}

/**
 * 获取URL/weboffice/url
 */
export function webofficeUrl(data) {
    return axios.get("/weboffice/url", { params: data });
}
/**
 * 获取 wps 参数
 */
export function getWPSParam(data) {
    return axios.get("/weboffice/getParam", { params: data });
}

/**
 * 获取videoUrl
 */
export function getVideoUrl(url) {
    return axios.get(`/oss/getDownloadUrl?objectName=${url}`, {});
}
export function getnewVideoUrl(url) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/obs/info/download?objectName=${url}`, {})
            .then((res) => {
                resolve(res.data.result);
                return res.data.result;
            })
            .catch((err) => {
                reject(err.data);
            });
        //  axios.get(host + String(method), {
        //         params: params
        //     }).then(res => {
        //         resolve(res.data.result);
        //         return res.data.result;
        //     }).catch(err => {
        //         reject(err.data)
        //     })
    });
}

// 资源管理获取oss直传信息
export function getOssPolicyV2(params) {
    return axios.get("/oss/policyV2", { params });
}

// 获取obs上传参数
export function getObsPolicy(params) {
    return axios.get("/obs/info/policy", { params });
}
/**
 * 查询提示是否出现过
 * @param {*} params 查询参数
 */
export function noticeCheck(params) {
    return axios.post("/notice/check", params);
}

// 根据courseId knowledgeId获取originTenantType originTenantId
export function getOriginTenant(params) {
    return axios.get("/getCourseSource", {
        params,
        baseURL: "/aiykt-gateway/bskpt-open",
    });
}
