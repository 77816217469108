import {
  getManageSchoolList,
  getClassroomList,
  addClassroom,
  updateClassStatus,
  deleteClassroom,
  updateClassroom,
} from "@/api/manage/classroom";

import {
  getGradeData,
  getClassData,
  addGradeData,
  deleteGradeData,
  editGradeData,
  addClassData,
  editClassData,
  deleteClassData,
  toGetStudentList,
  toGetStudentDetail,
  importStudentData,
  addSchoolStudentData,
  checkStudentCodeExist,
  getStudentReportedList,
  updateSchoolStudentData,
  deleteStudentData,
} from "@/api/manage/student-manage";

import {
  getCreatorData,
  getPlanCourseList,
  deletePlanCourseData,
  addPlanCourseData,
  getPlanTeachingList,
  getCourseOptions,
  getClassOptions,
  getTeacherOptions,
  addNewPlanData,
  deletePlanData,
  updatePlanData,
  autoUpdatePlanData,
  createTeachingSchedule,
  getSchoolStaffList,
  getGeneralCourseTable,
  getGeneralImportCourse,
  getMyCourseClassList,
  getTotalTableCourseOptions,
  getTableCourseOptions,
  getTotalTableClassOptions,
  getTotalTableTeacherOptions,
  getTotalTableClassroomOptions,
} from "@/api/manage/course-list";

export default {
  state: {
    creatorList: [],
    courseOptions: [],
    classOptions: [],
    teacherOptions: [],
    planStep:
      (navigator.cookieEnabled && sessionStorage.getItem("planStep")) || 0,
    planObj:
      (navigator.cookieEnabled && sessionStorage.getItem("planObj")) || {},
  },
  mutations: {
    SET_CREATOR_LIST(state, value) {
      state.creatorList = value;
    },
    SET_COURSE_OPTIONS(state, value) {
      state.courseOptions = value;
    },
    SET_CLASS_OPTIONS(state, value) {
      state.classOptions = value;
    },
    SET_TEACHER_OPTIONS(state, value) {
      state.teacherOptions = value;
    },
    SET_PLANDETAIL_STEP(state, value) {
      state.planStep = value;
      sessionStorage.setItem("planStep", value);
    },
    SET_GET_SCHOOLCODE_COUNT(state, value) {
      state.planObj = value;
      sessionStorage.setItem("planObj", value);
    },
  },
  actions: {
    /**
     * 获取归属学校列表+所属机构
     */
    getManageSchoolList({ commit }, data) {
      return new Promise((resolve, reject) => {
        getManageSchoolList(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取教室列表
    getClassroomList({ commit }, data) {
      return new Promise((resolve, reject) => {
        getClassroomList(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 新增教室
    addClassroom({ commit }, data) {
      return new Promise((resolve, reject) => {
        addClassroom(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 启禁用教室
    updateClassStatus({ commit }, data) {
      return new Promise((resolve, reject) => {
        updateClassStatus(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 删除教室
    deleteClassroom({ commit }, data) {
      return new Promise((resolve, reject) => {
        deleteClassroom(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 修改教室
    updateClassroom({ commit }, data) {
      return new Promise((resolve, reject) => {
        updateClassroom(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 根据学校code获取当前学校下的所有年级数据
    getGradeData({ commit }, data) {
      return new Promise((resolve, reject) => {
        getGradeData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取当前年级下的班级的数据
    getClassData({ commit }, data) {
      return new Promise((resolve, reject) => {
        getClassData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 新增当前学校下的年级
    addGradeData({ commit }, data) {
      return new Promise((resolve, reject) => {
        addGradeData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 删除年级
    deleteGradeData({ commit }, data) {
      return new Promise((resolve, reject) => {
        deleteGradeData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 编辑年级
    editGradeData({ commit }, data) {
      return new Promise((resolve, reject) => {
        editGradeData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 新增该年级下的班级
    addClassData({ commit }, data) {
      return new Promise((resolve, reject) => {
        addClassData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 编辑班级
    editClassData({ commit }, data) {
      return new Promise((resolve, reject) => {
        editClassData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 删除班级
    deleteClassData({ commit }, data) {
      return new Promise((resolve, reject) => {
        deleteClassData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 根据树选择的学校/年级/班级 获取学生列表数据
    toGetStudentList({ commit }, data) {
      return new Promise((resolve, reject) => {
        toGetStudentList(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 根据树选择的学校/年级/班级 学生列表数据 通过id获取详情
    toGetStudentDetail({ commit }, data) {
      return new Promise((resolve, reject) => {
        toGetStudentDetail(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 导入学生数据
    importStudentData({ commit }, data) {
      return new Promise((resolve, reject) => {
        importStudentData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 新增学生
    addSchoolStudentData({ commit }, data) {
      return new Promise((resolve, reject) => {
        addSchoolStudentData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 根据学生编码判断学生是否存在
    checkStudentCodeExist({ commit }, data) {
      return new Promise((resolve, reject) => {
        checkStudentCodeExist(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取学生的已报课程数据
    getStudentReportedList({ commit }, data) {
      return new Promise((resolve, reject) => {
        getStudentReportedList(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 编辑学生
    updateSchoolStudentData({ commit }, data) {
      return new Promise((resolve, reject) => {
        updateSchoolStudentData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 删除学生
    deleteStudentData({ commit }, data) {
      return new Promise((resolve, reject) => {
        deleteStudentData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取创建人
    getCreatorData({ commit }, data) {
      return new Promise((resolve, reject) => {
        getCreatorData(data)
          .then((res) => {
            let newList = res.data || [];
            console.log(newList);
            if (newList.length > 0) {
              newList.map((item) => {
                item.value = item.staffCode;
                item.label = item.staffName;
              });
            }
            commit("SET_CREATOR_LIST", newList);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 排课列表 数据
    getPlanCourseList({ commit }, data) {
      return new Promise((resolve, reject) => {
        getPlanCourseList(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 删除排课
    deletePlanCourseData({ commit }, data) {
      return new Promise((resolve, reject) => {
        deletePlanCourseData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 新增排课
    addPlanCourseData({ commit }, data) {
      return new Promise((resolve, reject) => {
        addPlanCourseData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取排课计划列表
    getPlanTeachingList({ commit }, data) {
      return new Promise((resolve, reject) => {
        getPlanTeachingList(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取课程下拉框列表
    getCourseOptions({ commit }, data) {
      return new Promise((resolve, reject) => {
        getCourseOptions(data)
          .then((res) => {
            // 键值对格式
            let list = res.data || [];
            if (list.length > 0) {
              list.map((item) => {
                item.label = item.courseName;
                item.value = item.courseId;
              });
            }
            commit("SET_COURSE_OPTIONS", list);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取班级下拉框列表
    getClassOptions({ commit }, data) {
      return new Promise((resolve, reject) => {
        getClassOptions(data)
          .then((res) => {
            // 键值对格式
            let list = res.data || [];
            if (list.length > 0) {
              list.map((item) => {
                item.label = item.className;
                item.value = item.id;
              });
            }
            commit("SET_CLASS_OPTIONS", list);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取授课老师下拉框列表
    getTeacherOptions({ commit }, data) {
      return new Promise((resolve, reject) => {
        getTeacherOptions(data)
          .then((res) => {
            // 键值对格式
            let list = res.data || [];
            if (list.length > 0) {
              list.map((item) => {
                item.label = item.staffName;
                item.value = item.staffCode;
              });
            }
            commit("SET_TEACHER_OPTIONS", list);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 授课计划 点击添加按钮
    addNewPlanData({ commit }, data) {
      return new Promise((resolve, reject) => {
        addNewPlanData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 删除某条授课计划
    deletePlanData({ commit }, data) {
      return new Promise((resolve, reject) => {
        deletePlanData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 授课计划手动保存
    updatePlanData({ commit }, data) {
      return new Promise((resolve, reject) => {
        updatePlanData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 授课计划自动保存
    autoUpdatePlanData({ commit }, data) {
      return new Promise((resolve, reject) => {
        autoUpdatePlanData(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 生成课表
    createTeachingSchedule({ commit }, data) {
      return new Promise((resolve, reject) => {
        createTeachingSchedule(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取教务管理-总课表-老师下拉框数据
    getSchoolStaffList({ commit }, data) {
      return new Promise((resolve, reject) => {
        getSchoolStaffList(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取总课表
    getGeneralCourseTable({ commit }, data) {
      return new Promise((resolve, reject) => {
        getGeneralCourseTable(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取总课表
    getGeneralImportCourse({ commit }, data) {
      return new Promise((resolve, reject) => {
        getGeneralImportCourse(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 我的-课程班级
    getMyCourseClassList({ commit }, data) {
      return new Promise((resolve, reject) => {
        getMyCourseClassList(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取总课表课程下拉框数据
    getTotalTableCourseOptions({ commit }, data) {
      return new Promise((resolve, reject) => {
        getTotalTableCourseOptions(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取总课表课程下拉框数据
    getTableCourseOptions({ commit }, data) {
      return new Promise((resolve, reject) => {
        getTableCourseOptions(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取总课表班级下拉框数据
    getTotalTableClassOptions({ commit }, data) {
      return new Promise((resolve, reject) => {
        getTotalTableClassOptions(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取总课表老师下拉框数据
    getTotalTableTeacherOptions({ commit }, data) {
      return new Promise((resolve, reject) => {
        getTotalTableTeacherOptions(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取总课表教室下拉框数据
    getTotalTableClassroomOptions({ commit }, data) {
      return new Promise((resolve, reject) => {
        getTotalTableClassroomOptions(data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
